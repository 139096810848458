<template>
  <div class="calc2rem mx-auto mt-4 mb-24 md:mb-0">
    <div class="flex justify-center">
      <h1 class="font-medium text-3xl">{{ $t("privacy.privacy_policy") }}</h1>
    </div>
    <p class="mt-3">{{ $t("privacy.maj") }}</p>
    <h2 class="mt-5 font-medium text-xl">{{ $t("privacy.collect") }}</h2>
    <p class="mt-3">{{ $t("privacy.collect_body") }}</p>
    <h2 class="mt-3">{{ $t("privacy.collect_what") }}</h2>
    <p class="mt-3">{{ $t("privacy.collect_what_body") }}</p>
    <h2 class="mt-5 font-medium text-xl">{{ $t("privacy.cookies") }}</h2>
    <p class="mt-3">{{ $t("privacy.cookies_body") }}</p>
    <h2 class="mt-5 font-medium text-xl">{{ $t("privacy.security") }}</h2>
    <p class="mt-3">{{ $t("privacy.security_body") }}</p>
    <h2 class="mt-5 font-medium text-xl">{{ $t("privacy.modification") }}</h2>
    <p class="mt-3">{{ $t("privacy.modification_body") }}</p>
    <h2 class="mt-5 font-medium text-xl">Contact</h2>
    <p class="mt-3 mb-12">{{ $t("privacy.contact") }}contact@baranimes.com.</p>
  </div>
</template>
<script setup>
  import { onMounted } from "vue";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();

  onMounted(() => {
    global.pageLoad();
  });
</script>
<style scoped></style>

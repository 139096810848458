<template>
  <ModalReuse3 :modal-active="modalActive" @close-modal="toggleModal"></ModalReuse3>
  <div>
    <div
      class="flex rounded-2xl justify-center m-6 mt-[3.5rem]"
      style="
        /* background: rgb(2 4 31 / 80%);
          border-bottom: 3px solid #008ca8;
          box-shadow: rgba(0, 140, 168, 0.4) 0px 15px 20px; */
        /* #080b2a */
      ">
      <div class="w-[16%] lg:block hidden max-w-[182px]">
        <div class="poster text-[180px] font-medium text-center imgPoster">
          <v-img v-if="imgPoster" loading="lazy" class="shadow-3xl rounded-xl" :src="'/images/thumbnail/' + imgPoster">
            <template v-slot:placeholder>
              <v-sheet>
                <v-skeleton-loader
                  type="image"
                  class="rounded-xl overflow-hidden shadow-3xl"
                  color="#020420"></v-skeleton-loader>
              </v-sheet>
            </template>
          </v-img>
          <span v-else class="block h-0 pb-[150%]">?</span>
        </div>
        <div class="flex flex-col font-medium mt-3">
          <span>Titre</span>
          <v-tooltip :text="varFront.song_name" location="top">
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="font-normal nowrap" style="padding: 0px 0px 15px 0px">{{
                varFront.song_name
              }}</span>
            </template>
          </v-tooltip>
          <span>Artiste</span>
          <v-tooltip :text="varFront.song_artist" location="top">
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="font-normal nowrap" style="padding: 0px 0px 15px 0px">{{
                varFront.song_artist
              }}</span>
            </template>
          </v-tooltip>
          <span>Type</span>
          <v-tooltip :text="varFront.song_type" location="top">
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="font-normal nowrap" style="padding: 0px 0px 15px 0px">{{
                varFront.song_type
              }}</span>
            </template>
          </v-tooltip>
        </div>
      </div>
      <div class="w-full lg:w-[60%] lg:px-6 max-w-[720px]">
        <div class="flex flex-col relative h-[65px] float game" id="small">
          <v-menu
            transition="slide-y-transition"
            open-delay="0"
            close-delay="0"
            open-on-hover
            open-on-focus
            :close-on-content-click="false"
            location="bottom">
            <template v-slot:activator="{ props }">
              <div
                v-bind="props"
                class="text-[30px] text-center lg:text-start w-full absolute font-bold duration-500"
                style="
                  line-height: 1.1;
                  filter: drop-shadow(black 2px 4px 6px);
                  font-size: 56px;
                  top: 70px;
                  color: white;
                  letter-spacing: 1.1px;
                ">
                Guess the Anime
              </div>
              <div
                v-bind="props"
                class="text-[30px] text-center lg:text-start w-full absolute font-bold duration-500"
                style="
                  color: transparent;
                  line-height: 1.1;
                  filter: drop-shadow(black 2px 4px 6px);
                  font-size: 91px;
                  top: 120px;
                  background: linear-gradient(90deg, #008ca8 0, #00fddc);
                  background-clip: text;
                  font-weight: 900;
                  letter-spacing: 1.5px;
                ">
                <!-- Anime -->
              </div>

              <div
                v-bind="props"
                class="text-[30px] text-center lg:text-start w-full absolute font-bold duration-500"
                style="
                  line-height: 1.1;
                  filter: drop-shadow(black 2px 4px 6px);
                  font-size: 59px;
                  top: 208px;
                  letter-spacing: 7.5px;
                  margin-left: 4px;
                  color: white;
                ">
                {{ setupRoom?.mediaType == 1 ? "Opening" : setupRoom?.mediaType == 2 ? "Ending" : "OST" }}
              </div>
              <div
                v-bind="props"
                class="text-[27px] text-center lg:text-start w-full absolute font-bold top-[31px] duration-500"
                style="
                  line-height: 1.1;
                  filter: drop-shadow(black 2px 4px 6px);
                  font-size: 50px;
                  top: 280px;

                  color: white;
                  text-transform: CAPITALIZE;
                ">
                <div v-if="setupRoom?.season && setupRoom?.year">
                  <span class="text-[#cfa2ee]">{{ setupRoom?.season }} {{ setupRoom?.year }}</span>
                  <span class="ntiktok"> - {{ nTiktok }}/5</span>
                </div>
                <div v-else-if="setupRoom?.tiktok == 3">
                  <span class="text-[#56ff56]">Easy</span>
                  <span> to </span>
                  <span class="text-[red]">Hard</span>
                  <span class="ntiktok"> - {{ nTiktok }}/5</span>
                </div>
                <div v-else>
                  <span
                    class="stick"
                    :style="{
                      color: difficultyTiktok == 1 ? '#56ff56' : difficultyTiktok == 2 ? 'orange' : 'red',
                    }"
                    >-
                  </span>
                  <span
                    :style="{
                      color: difficultyTiktok == 1 ? '#56ff56' : difficultyTiktok == 2 ? 'orange' : 'red',
                    }"
                    >{{ difficultyTiktok == 1 ? "Easy" : difficultyTiktok == 2 ? "Medium" : "Hard" }}</span
                  >
                  <span
                    class="stick"
                    :style="{
                      color: difficultyTiktok == 1 ? '#56ff56' : difficultyTiktok == 2 ? 'orange' : 'red',
                    }">
                    -</span
                  >
                  <span class="ntiktok"> - {{ nTiktok }}/5</span>
                </div>
              </div>
            </template>
            <v-card style="background-color: #0f112b; border-radius: 0.75rem">
              <div class="flex overflow-hidden">
                <div class="bg-second p-2">
                  <p class="text-white font-medium mb-1 text-lg">{{ varFront.title }}</p>
                  <p class="text-gray-100 m-0" v-html="varFront.others"></p>
                </div>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div
          class="flex justify-center rounded-2xl bg-linear overflow-hidden relative shadow-3xl mb-3 mt-2 duration-300"
          id="playerContainer"
          style="padding-bottom: 56.17978%">
          <v-img
            src="/images/global/subscribe.gif"
            id="subscribe"
            alt="subscribe"
            class="pointer-events-none rounded-full items-center justify-center self-center z-1 absolute opacity-0"></v-img>

          <div
            v-if="counterDown"
            id="counterDown"
            class="flex rounded-full items-center justify-center self-center top-[50%] absolute w-100">
            <v-progress-circular
              v-if="countCircle && countCircle != 110"
              :rotate="360"
              :size="125"
              :width="6"
              :model-value="chronoCircle"
              color="radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);">
              <template v-slot:default>
                <span style="text-align: center; font-weight: 700; font-size: 4.5em" class="text-white">
                  {{ chrono }}
                </span></template
              >
            </v-progress-circular>
            <button
              @click="toggleReadyStatus"
              v-if="!launch && interact && firstReady"
              style="padding: 100%"
              class="font-medium text-5xl sm:text-6xl">
              {{ isReady ? "Annuler" : "Prêt" }}
            </button>
            <span v-else-if="alreadyConnected" class="font-medium text-3xl flex flex-col"
              ><span>Déjà connecté</span></span
            >
            <span v-else-if="!launch && interact" class="font-medium text-3xl flex flex-col"
              ><span class="mb-6">Chargement...</span
              ><v-progress-linear indeterminate class="w-[150px]"></v-progress-linear
            ></span>

            <br />
            <button
              @click="toggleReadyStatusShift"
              v-if="!interact && firstReady"
              style="padding: 100%"
              class="font-medium text-4xl sm:text-5xl">
              Rejoindre
            </button>
            <span v-else-if="!interact" class="font-medium text-3xl flex flex-col"
              ><span class="mb-6">Chargement...</span
              ><v-progress-linear indeterminate class="w-[150px]"></v-progress-linear
            ></span>
            <span v-if="nextRound" class="font-medium text-3xl flex flex-col"
              ><span class="mb-6">En attente de la prochaine manche...</span></span
            >
          </div>
          <div
            v-if="fastForward"
            class="fastforward flex items-center justify-center self-center top-[50%] absolute z-10">
            <div class="arrow" style="--i: 0"></div>
            <div class="arrow" style="--i: 0"></div>
            <div class="arrow" style="--i: 0"></div>
          </div>
          <div
            v-if="hideVideo && !counterDown && !fastForward"
            class="flex items-center justify-center self-center top-0 h-full absolute z-10">
            <v-img class="w-[150px]" src="/images/global/favicon_no_bg.png" alt="Logo without background" />
          </div>
          <div id="subPlayerContainer" class="w-full"></div>
        </div>

        <div
          class="relative"
          v-if="answering == 1"
          @mouseleave="mouseOverAnime = false"
          @mouseenter="mouseOverAnime = true">
          <div
            @click="searchFocusIn"
            class="rounded-full p-1 text-center bg-second relative cursor-pointer mx-2 sm:mx-0"
            id="blind-rep-list">
            <span class="nowrap px-4">{{ animeSelected || "Titre de l'anime" }}</span>
            <svg
              class="absolute right-0 top-0"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="m11.3 14.3l-2.6-2.6q-.475-.475-.212-1.087T9.425 10h5.15q.675 0 .938.613T15.3 11.7l-2.6 2.6q-.15.15-.325.225T12 14.6q-.2 0-.375-.075T11.3 14.3Z" />
            </svg>
          </div>
          <div class="absolute w-full bg-first z-[14] invisible rounded-lg" id="searchAnimeContainer">
            <input
              id="searchAnime"
              autocomplete="off"
              placeholder="Rechercher par nom"
              @input="resetItem"
              @keydown="handleKeyDown"
              @focusin="searchFocusIn"
              @focusout="searchFocusOut"
              type="search"
              class="w-full my-1 py-2 px-4 rounded-lg border-none outline-none text-white bg-second" />
            <div v-if="!visibleAnime?.value" class="flex m-3 justify-center">
              <v-progress-circular :size="50" color="#00bdbf" indeterminate></v-progress-circular>
            </div>
            <v-virtual-scroll
              :class="{ hidden: visibleAnime?.value?.length === 0 }"
              :items="visibleAnime"
              height="275"
              :key="visibleAnime"
              id="animeList">
              <template v-slot:default="{ item, index }">
                <div
                  class="flex cursor-pointer items-center rounded-md m-1 overflow-hidden animeListItem"
                  :class="{ selected: index === selectedIndex }"
                  @click="selectAnime(index)">
                  <v-img
                    loading="lazy"
                    class="rounded-md w-[50px] h-[75px]"
                    :class="{ 'blur-sm': item.nsfw == 18 }"
                    :src="'/images/thumbnail/' + item.image"
                    :alt="item.title"
                    width="50"
                    height="75" />
                  <span class="ml-3 font-medium w-full">{{ item.title }}</span>
                  <v-menu
                    transition="slide-y-transition"
                    open-delay="0"
                    close-delay="0"
                    open-on-hover
                    open-on-click
                    :close-on-content-click="false"
                    location="top">
                    <template v-slot:activator="{ props }">
                      <div class="p-3 select-none" style="padding-right: 1.25rem" v-bind="props">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M12 7q.425 0 .713-.288T13 6q0-.425-.288-.712T12 5q-.425 0-.712.288T11 6q0 .425.288.713T12 7m-1 8h2V9h-2zm-9 7V4q0-.825.588-1.412T4 2h16q.825 0 1.413.588T22 4v12q0 .825-.587 1.413T20 18H6z" />
                        </svg>
                      </div>
                    </template>
                    <v-card style="background-color: #0f112b; border-radius: 0.75rem">
                      <div class="flex max-w-[650px] max-h-[210px] overflow-hidden">
                        <img
                          loading="lazy"
                          :src="'/images/thumbnail/' + item.image"
                          class="rounded-xl max-h-[210px]"
                          :alt="item.title" />
                        <div class="bg-second p-2 min-w-[180px]">
                          <p class="text-white font-medium mb-1 text-lg">{{ item.title }}</p>
                          <p class="text-gray-100 m-0" v-html="replaceCommasWithBr(item.others)"></p>
                        </div>
                      </div>
                    </v-card>
                  </v-menu>
                </div>
              </template>
            </v-virtual-scroll>
            <div v-if="visibleAnime?.value?.length === 0" class="flex m-3">
              <span class="font-medium w-full text-center">{{ $t("header.menu.no_result_found") }}</span>
            </div>
          </div>
        </div>
        <div v-else-if="answering == 2" class="w-full" id="answering">
          <div
            v-if="setupRoom?.tiktok == 3"
            class="text-[26px] text-center lg:text-start w-full font-bold diff difficulty float"
            style="filter: drop-shadow(black 2px 4px 6px)">
            <span class="text-[24px]">Level : </span>
            <span
              :style="{
                color: difficultyTiktok == 1 ? '#56ff56' : difficultyTiktok == 2 ? 'orange' : 'red',
              }"
              >{{ difficultyTiktok == 1 ? "Easy" : difficultyTiktok == 2 ? "Medium" : "Hard" }}</span
            >
          </div>
          <span class="w-full sm:mx-0 text-center revele font-bold float line-clamp-3" style="padding-bottom: 4px">{{
            varFront.title
          }}</span>
          <span
            class="w-full sm:mx-0 text-center revele float line-clamp-3 text-[21px] font-medium"
            style="padding: 0px 0px 15px 0px; font-size: 21px"
            >{{ varFront.song_type }}</span
          >
          <div class="flex flex-wrap" id="answeringChild">
            <div
              v-for="item in multiChoice"
              :key="item.id"
              class="w-1/2 animeChoiceItem px-5 mb-6 float duration-300 mt-[-5px]">
              <v-img
                loading="lazy"
                :src="'/images/upload/' + item.poster"
                class="w-full shadow-3xl overflow-hidden rounded-xl" />
            </div>
          </div>

          <div class="w-full sm:mx-0 text-center font-medium mt-3 revele float">
            <span>Title</span>
            <span
              class="font-normal line-clamp-1 overflow-hidden text-[18px]"
              style="padding: 0px 0px 0px 0px; font-size: 18px"
              >{{ varFront.song_name }}</span
            >
            <span>Artist</span>
            <span
              class="font-normal line-clamp-1 overflow-hidden text-[18px]"
              style="padding: 0px 0px 0px 0px; font-size: 18px"
              >{{ varFront.song_artist }}</span
            >
          </div>
        </div>
      </div>
      <div
        class="lg:flex w-[25%] bg-first rounded-xl flex-col overflow-auto hidden"
        style="border-bottom: 1px solid #00bdbf; box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5)">
        <form @submit.prevent="sendMessage" class="flex justify-between flex-col h-full">
          <label style="align-self: center; padding: 7px; font-size: 18px; font-weight: bold">Chat</label>
          <div class="rounded-md h-full w-full table table-fixed relative">
            <div class="p-2 h-full overflow-auto m-div w-full absolute">
              <div
                v-for="message in messages"
                :key="message.sender"
                class="m-container"
                :class="{ 'm-me': message.sender === socket.id }">
                <div class="m-message emote" v-if="message.emote">
                  <img
                    :class="'anim' + message.emote"
                    :src="'/images/emotes/' + message.link"
                    :alt="message.name"
                    :style="message.params"
                    loading="lazy" />
                </div>
                <div class="m-message" v-else>{{ message.message }}</div>
                <div class="m-sender">
                  <span class="font-medium">{{ message.username }}, </span><span>{{ message.time }}</span>
                </div>
              </div>
            </div>
          </div>
          <div style="padding: 3px 0; display: flex">
            <span class="emoji" @click="emoji(1)"><v-img src="/images/emotes/waving-hand.svg" loading="lazy" /></span>
            <span class="emoji" @click="emoji(3)"><v-img src="/images/emotes/heart.svg" loading="lazy" /></span>
            <span class="emoji" @click="emoji(2)"
              ><v-img src="/images/emotes/64fde5341d5448.90894800.png" loading="lazy"
            /></span>
            <span class="emoji" @click="emoji(4)"><v-img src="/images/emotes/aqua.png" loading="lazy" /></span>
            <span class="emoji">
              <v-menu
                class="all"
                transition="slide-y-transition"
                open-delay="0"
                close-delay="0"
                open-on-click
                :close-on-content-click="false"
                location="top">
                <template v-slot:activator="{ props }">
                  <v-img
                    v-bind="props"
                    src="https://icons.veryicon.com/png/o/miscellaneous/standard-general-linear-icon/plus-60.png"
                    loading="lazy" />
                </template>
                <v-card
                  style="background-color: #02041f; border-radius: 0.75rem"
                  class="flex flex-wrap max-w-[338px] max-h-[173px] overflow-auto p-1">
                  <div v-for="item in emotes" :key="item.id" class="flex">
                    <span class="emoji" style="width: 50px" @click="emoji(item.id)"
                      ><v-img :alt="item.titre" :src="'/images/emotes/' + item.lien" loading="lazy"
                    /></span>
                  </div>
                </v-card>
              </v-menu>
            </span>
          </div>
          <input
            v-model="message"
            autocomplete="off"
            :placeholder="auth.user.role === 'guest' ? 'Connecte-toi pour envoyer des messages' : 'Message'"
            :disabled="auth.user.role === 'guest'"
            type="text"
            class="py-3 px-4 block w-full rounded-xl text-sm bg-second outline-none" />
        </form>
      </div>
    </div>

    <div class="lg:flex justify-center hidden pr-[100px]">
      <div
        v-for="item in usersInRoom"
        :key="item.id"
        :class="'h-[350px] relative inline-grid text-center justify-items-center avatar' + item.id">
        <span
          :class="'rep' + item.id"
          class="blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible"></span>
        <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11]">
          <div
            class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
            style="
              filter: drop-shadow(black 2px 4px 6px);
              background: url(/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
              background-size: cover;
              margin: 39px 50px 0;
            ">
            <v-img
              src="/images/avatar_users/64f5bb5d1a2fd9.30522874.png"
              class="max-w-none overflow-hidden img-user-avatar"
              style="width: 180px; margin-bottom: 45px; margin-left: -3px" />
          </div>
        </div>
        <b
          class="shadow-base z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium"
          :class="'pts' + item.id"
          >0</b
        ><span
          class="shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]">
          {{ item.username }}</span
        ><span
          class="rounded-md top-[-74px] z-[12] p-0 border-none text-sm w-[50px] relative leading-[1.1]"
          :style="
            'filter: drop-shadow(black 2px 4px 6px);' +
            'background: linear-gradient(90deg, #008ca8 ' +
            item.pts +
            '%, #0f112b 0%);' +
            'box-shadow: -2px -1px 6px -1px #008ca8, 5px 5px 5px -5px #008ca8, -7px -7px 10px -5px transparent,' +
            '7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0),' +
            '0 55px 35px -20px rgba(0, 0, 0, 0.5);'
          "
          >{{ item.lvl }}</span
        >
      </div>
    </div>
    <v-virtual-scroll
      :items="resume"
      height="500"
      :key="resume"
      v-if="resume"
      class="mb-[125px] rounded-2xl"
      id="resume">
      <template v-slot:default="{ item, index }">
        <div class="flex rounded-md m-1 overflow-hidden justify-between" :class="{ selected: index === selectedIndex }">
          <div class="flex items-center">
            <a target="_blank" :href="'/anime/' + item.url"
              ><v-img
                loading="lazy"
                class="rounded-md max-w-[100px] overflow-hidden cursor-pointer"
                :class="{ 'blur-md': item.nsfw == 18 }"
                :src="'/images/thumbnail/' + item.poster"
                :alt="item.title"
                width="100"
                height="150"
            /></a>
            <div class="flex flex-col">
              <a target="_blank" :href="'/anime/' + item.url" class="ml-3 font-medium w-full cursor-pointer text-lg">{{
                item.title
              }}</a>
              <span class="ml-3 w-full">{{ item.song_name }}</span>
              <span class="ml-3 w-full">{{ item.song_artist }}</span>
              <span class="ml-3 w-full" v-if="item.song_type == 1">Opening {{ item.song_number }}</span>
              <span class="ml-3 w-full" v-else-if="item.song_type == 2">Ending {{ item.song_number }}</span>
              <span class="ml-3 w-full" v-else-if="item.song_type == 3">OST</span>
            </div>
          </div>
          <div class="flex items-center mr-3">
            <svg
              v-if="resumeRep[index + 1]"
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 24 24"
              class="text-[#09a909]">
              <circle cx="12" cy="12" r="8" fill="white" />
              <path
                fill="currentColor"
                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
            </svg>
            <svg
              v-else-if="resumeRep[index + 1] === false"
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 24 24"
              class="text-[red]">
              <circle cx="12" cy="12" r="8" fill="white" />
              <path
                fill="currentColor"
                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z" />
            </svg>
          </div>
        </div>
      </template>
    </v-virtual-scroll>
    <v-snackbar v-model="snackbar" timeout="5000" color="#ff5252">
      <p class="font-medium text-center">Lien mort</p>
    </v-snackbar>
    <v-snackbar v-model="link" v-if="false" timeout="-1" color="#008ca8" class="lg:ml-[-100px]">
      <p class="font-medium text-center">Lien pour rejoindre la partie :</p>
      <template v-slot:actions>
        <v-btn variant="text" class="mr-2 ml-[-15px]" @click="copy"> {{ varFront.copy }} </v-btn>
        <v-btn variant="text" class="lowercase" @click="link = false"> x </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="replay"
      v-if="!alreadyConnected && !modalActive"
      timeout="-1"
      color="#00000000"
      class="lg:ml-[-100px]">
      <template v-slot:actions>
        <button
          @click="toggleModal(1)"
          class="whitespace-nowrap play text-white py-10 px-20 rounded-full text-5xl font-sans outline-none font-bold border-none cursor-pointer inline-flex bg-cover bg-center transition duration-300 shadow-2xl"
          style="padding: 25px 150px">
          Nouvelle partie
        </button>
      </template>
    </v-snackbar>
    <v-snackbar v-model="replayAsk" v-if="!alreadyConnected" timeout="-1" color="#008ca8" class="lg:ml-[-100px]">
      <p class="font-medium text-center">L'hôte a lancé une nouvelle partie</p>
      <template v-slot:actions>
        <button class="p-1 w-1/2 select-none outline-none">
          <span
            @click="goToRoom"
            class="text-center button-reverse flex cursor-pointer items-center justify-center rounded-md overflow-hidden nowrap font-medium p-2 w-fit">
            Rejoindre
          </span>
        </button>
      </template>
    </v-snackbar>
    <v-snackbar v-model="alreadyConnected" timeout="-1" color="#ff5252" class="lg:ml-[-100px]">
      <p class="font-medium text-center">Tu es déjà connecté dans cette salle !</p>
    </v-snackbar>
  </div>
  <svg class="defs">
    <defs>
      <symbol id="s-star" viewBox="0 0 210 210">
        <polygon
          points="98.4999978 153.75 38.2520165 185.424245 49.7583542 118.337123 1.01670635 70.8257603 68.3760155 61.037872 98.5000012 1.1379786e-14 128.624005 61.0378871 195.98331 70.8258091 147.241642 118.337136 158.747982 185.424247"
          transform="translate(10,10)"></polygon>
      </symbol>

      <radialGradient id="gr-radial-lights" cx="50%" cy="50%" r="70%">
        <stop stop-color="white" offset="5%" stop-opacity="1"></stop>
        <stop stop-color="white" offset="100%" stop-opacity="0"></stop>
      </radialGradient>
    </defs>
  </svg>
  <div class="demo demo--lights bg-[#20b2aa] duration-300" id="demo">
    <svg viebox="0 0 1024 640">
      <svg viewBox="0 0 640 640">
        <g transform="scale(1)">
          <circle
            class="c-lights"
            r="64%"
            cx="50%"
            cy="50%"
            stroke-width="128%"
            stroke-dasharray="10%"
            stroke="url(#gr-radial-lights)"></circle>
        </g>
      </svg>
    </svg>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, onBeforeUnmount, watch } from "vue";
  import { useHead, useSeoMeta } from "@unhead/vue";
  import { useJoinRoomStore } from "@/stores/joinRoom";
  import { useAnimesStore } from "@/stores/animes";
  import { useGlobalStore } from "@/stores/global";
  import { useAuthStore } from "@/stores/auth";
  import ModalReuse3 from "@/components/ModalReuse3.vue";
  import { useRouter } from "vue-router";
  import { useFetch } from "@vueuse/core";
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  const router = useRouter();
  const joinRoom = useJoinRoomStore();
  const animes = useAnimesStore();
  const auth = useAuthStore();

  const $ = (selectors) => document.getElementById(selectors);
  const $$ = (selectors) => document.querySelectorAll(selectors);
  const socket = joinRoom.socket;
  const global = useGlobalStore();
  const message = ref();
  const messages = ref([]);
  const isReady = ref(false);
  const isSkipSong = ref(false);
  const isPauseSong = ref(false);
  const firstReady = ref(false);
  const interact = ref(true);
  const fastForward = ref(false);
  const hideVideo = ref(false);
  const launch = ref(false);
  const interval = {};
  const chrono = ref();
  const n = ref("-");
  const nTiktok = ref(0);
  const songsNumber = ref("-");
  const answering = ref(2);
  const resumeRep = ref([]);
  const setupRoom = ref({});
  const infoVideo = {};
  const showInfoVideo = {};
  const imgPoster = ref(null);
  const usersInRoom = ref([]);
  const snackbar = ref(false);
  const multiChoiceTempo = ref(null);
  const multiChoice = ref(null);
  const multiChoice2 = ref(null);
  const repIndex = ref(null);
  const counterDown = ref(true);
  const nextRound = ref(false);
  const alreadyConnected = ref(false);
  const replay = ref(false);
  const resume = ref(null);
  const replayAsk = ref(false);
  const modalActive = ref(null);
  const newSalle = ref(null);
  const emotes = ref(null);
  const chronoCircle = ref(0);
  const countCircle = ref(0);

  let activePlayer;
  let nObserver = 0;
  let idSegment;
  let firstRep;
  let tempsDebut;
  let tempsAll = [];
  let nextPlayer;
  let debutVideo;
  let finVideo;
  const videoReady = {
    player__1: 0,
    player__2: 0,
  };
  const videoPlayer = {
    player__1: "",
    player__2: "",
  };
  const videoPlayerStart = {
    player__1: 0,
    player__2: 0,
  };
  const varFront = ref({
    title: "Blind test anime",
    song_type: "-",
    song_artist: "-",
    song_name: "-",
    others: null,
    hideVideo: "Masquer la vidéo",
    pauseSong: "Écouter toute la chanson",
    fastForward: "Avance rapide",
    copy: "Copier le lien",
  });
  const search = ref("");
  const visibleAnime = ref(null);
  const selectedIndex = ref(-1);
  const animeSelected = ref(null);
  const animeIdSelected = ref(null);
  const mouseOverAnime = ref(false);
  const searchFocusOpen = ref(false);
  const startTime = ref(0);
  const endTime = ref(0);
  const ping = ref(0);
  const link = ref(true);
  let timeoutId;

  watch(
    () => global.volume,
    () => {
      updateVolume();
    }
  );
  const toggleModal = (toggle) => {
    if (toggle) {
      modalActive.value = true;
    } else {
      modalActive.value = false;
    }
  };
  const copy = () => {
    navigator.clipboard.writeText(window.location.href);
    varFront.value.copy = "Copié !";
  };
  const replaceCommasWithBr = (str) => {
    return str.replace(/,/g, "<br>");
  };

  const leaveRoom = () => {
    socket.emit("leave_room");
    return undefined;
  };
  async function setSegments() {
    if (idSegment) {
      await useFetch("/api/admin/tiktok/cut/segments", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tempsAll: tempsAll,
          idSegment: idSegment,
        }),
      });
    } else {
      let tempo;
      let tempo2;
      if (setupRoom.value.tiktok == 3) {
        tempo = "Easy to Hard";
      } else {
        tempo = difficultyTiktok.value == 1 ? "Easy" : difficultyTiktok.value == 2 ? "Medium" : "Hard";
      }
      if (setupRoom.value.mediaType == 1) {
        tempo2 = "Opening";
      } else if (setupRoom.value.mediaType == 2) {
        tempo2 = "Ending";
      } else {
        tempo2 = "OST";
      }

      const { data: response } = await useFetch("/api/admin/tiktok/cut/segments", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: firstRep,
          tempsAll: tempsAll,
          type: tempo2 + " " + tempo,
          idSegment: idSegment,
        }),
      });
      const r = JSON.parse(response.value);
      idSegment = r[0].id;
    }
  }
  const resetItem = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(function () {
      search.value = $("searchAnime").value;
      selectedIndex.value = 0;
      $("animeList").scrollTop = 0;
    }, 100);
  };

  const searchFocusIn = () => {
    if (!searchFocusOpen.value) {
      selectedIndex.value = 0;
      $("animeList").scrollTop = 0;
      $("searchAnimeContainer").classList.remove("invisible");
      $("searchAnime").focus();
      searchFocusOpen.value = true;
    } else {
      searchFocusOut(1);
    }
  };
  const searchFocusOut = (close) => {
    if (!mouseOverAnime.value || close == 1) {
      $("searchAnimeContainer").classList.add("invisible");
      search.value = "";
      $("searchAnime").value = "";
      searchFocusOpen.value = false;
    }
  };

  const selectAnime = (index) => {
    searchFocusOpen.value = false;
    $("animeList").scrollTop = 0;
    animeSelected.value = visibleAnime.value.value[index].title;
    animeIdSelected.value = visibleAnime.value.value[index].id;
    socket.emit("rep", {
      title: visibleAnime.value.value[index].title,
      id: visibleAnime.value.value[index].id,
    });
    $("searchAnime").blur();
    $("searchAnimeContainer").classList.add("invisible");
    search.value = "";
    $("searchAnime").value = "";
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (selectedIndex.value !== -1) {
        selectAnime(selectedIndex.value);
      }
    } else if (event.key === "ArrowDown") {
      if (selectedIndex.value < visibleAnime.value.value.length - 1) {
        selectedIndex.value++;
        $("animeList").querySelector(".animeListItem.selected").scrollIntoView({ block: "start" });
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      if (selectedIndex.value > 0) {
        selectedIndex.value--;
        $("animeList").querySelector(".animeListItem.selected").scrollIntoView({ block: "end" });
      }
    }
  };
  // const changeScale = (index) => {
  //   $$(".animeChoiceItem").forEach((_, i) => {
  //     $$(".animeChoiceItem")[i].style.transform = "scale(1)";
  //     $$(".animeChoiceItem")[i].style.boxShadow = "none";
  //     if (i === index) {
  //       $$(".animeChoiceItem")[i].style.transform = "scale(1.02)";
  //       $$(".animeChoiceItem")[i].style.boxShadow = "0 0px 12px 3px #008ca8";
  //     }
  //   });
  //   repIndex.value = index;
  //   socket.emit("rep", {
  //     title: multiChoice.value[index].title,
  //     id: multiChoice.value[index].id,
  //   });
  // };
  useHead({
    script: {
      src: "https://www.youtube.com/iframe_api",
    },
  });
  useSeoMeta({
    title: computed(() => t("home.title") + " - Baranimes"),
    ogTitle: computed(() => t("home.title") + " - Baranimes"),
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: computed(() => t("home.title") + " - Baranimes"),
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
  onMounted(async () => {
    const { data: response } = await useFetch("/api/emotes/all", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    emotes.value = JSON.parse(response.value);

    window.addEventListener("beforeunload", leaveRoom);
    document.documentElement.requestFullscreen();
    $("app").style.background = "#00000000";
    global.pageLoad();
    // interval["ping"] = setInterval(function () {
    //   startTime.value = new Date().getTime();
    //   socket.emit("ping");
    // }, 1000);
    document.documentElement.style.overflow = "hidden";

    waitScript();
    const myElement = document.querySelector("#vidline-ext-0.1.43");

    // Accéder au shadow root
    const shadowRoot = myElement.shadowRoot;
    // Créer une instance de MutationObserver
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          if (nObserver == 2) {
            tempsDebut = Date.now();
            nObserver = 0;
          }
        } else if (mutation.type === "characterData") {
          nObserver++;
        }
      }
    });

    // Configuration de l'observation (types de mutations à observer)
    const config = { attributes: true, childList: true, subtree: true, characterData: true };

    // Commencer à observer le shadow root
    observer.observe(shadowRoot, config);
  });
  socket.on("ping", function () {
    endTime.value = new Date().getTime();
    ping.value = endTime.value - startTime.value;
  });
  const updateVolume = () => {
    if (activePlayer == "player__1") {
      videoPlayer["player__1"].setVolume(global.volume);
    }
    if (activePlayer == "player__2") {
      videoPlayer["player__2"].setVolume(global.volume);
    }
  };

  const goToRoom = () => {
    socket.emit("leave_room");
    router.push({ name: "room", query: { id: newSalle.value } });
  };
  socket.on("message", function (msg) {
    let date = new Date();
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let options = { timeZone: timeZone, hour: "numeric", minute: "numeric" };
    let formattedTime = new Intl.DateTimeFormat(undefined, options).format(date);
    msg.time = formattedTime;
    messages.value.push(msg);
    setTimeout(function () {
      $$(".m-div")[0].scrollTop = $$(".m-div")[0].scrollHeight + 1000;
    }, 1);
  });
  socket.on("already_connected", function () {
    alreadyConnected.value = true;
  });
  socket.on("end", function () {
    for (let i = 0; i < 100; i++) {
      setTimeout(function () {
        videoPlayer[activePlayer].setVolume(global.volume * 0.01 * (100 - i - 1));
      }, i * 10 + 1000);
    }
  });
  socket.on("play", function (actPlayer, removeVideo, debut, fin) {
    launch.value = true;
    play(removeVideo, actPlayer, debut, fin);
  });
  socket.on("readyStatus", function (user, ready) {
    let el = $$(".rep" + user);
    for (let i = 0; i < el.length; i++) {
      if (ready) {
        el[i].textContent = "Prêt";
        el[i].classList.remove("invisible");
      } else {
        el[i].classList.add("invisible");
      }
    }
  });
  socket.on("replay", (data) => {
    newSalle.value = data;
    if (setupRoom.value?.host == auth.user.idUser) {
      replayAsk.value = true;
    }
  });
  socket.on("goToRoom", (data) => {
    socket.emit("leave_room");
    router.push({ name: "room", query: { id: data } });
  });
  socket.on("usersInRoom", function (users) {
    usersInRoom.value = users;
  });
  const difficultyTiktok = ref(null);
  socket.on("start_play", async function (setup) {
    setupRoom.value = setup;
    n.value = setupRoom.value.n;
    songsNumber.value = setupRoom.value.songsNumber;
    answering.value = setupRoom.value.answering;
    difficultyTiktok.value = setupRoom.value.difficulty;
    if (setupRoom.value.tiktok == 3) {
      socket.emit("updateSetup", 1);
    }
    const { data: response2 } = await useFetch("/api/tiktok/multi", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        difficulty: difficultyTiktok.value,
      }),
    });
    multiChoice2.value = JSON.parse(response2.value);
    multiChoice.value = [{ poster: "", id: 0 }];
    setTimeout(function () {
      $$("#answeringChild .animeChoiceItem")[0].classList.add("correct");
    }, 1);
    socket.emit("videoSetup");
  });
  socket.on("shift_play", function (actPlayer, setup) {
    setupRoom.value = setup;
    n.value = setupRoom.value.n;
    songsNumber.value = setupRoom.value.songsNumber;
    answering.value = setupRoom.value.answering;
    launch.value = !launch.value;
    interact.value = false;
    activePlayer = actPlayer;
    socket.emit("videoSetup");
  });
  socket.on("videoSetup", async function (video, player, choice) {
    multiChoiceTempo.value = choice;
    infoVideo.value = video;
    nextPlayer = player;
    loadVideo(player, video[0].lien, video[0].debut);
  });

  // socket.on("revele", function (rep, pts) {
  //   usersInRoom.value = rep;
  //   if (!nextRound.value) {
  //     revele();
  //   }
  //   rep.forEach(function (item) {
  //     let el = $$(".rep" + item.id);
  //     if (item.rep.title) {
  //       for (let i = 0; i < el.length; i++) {
  //         el[i].textContent = item.rep.title;
  //         el[i].classList.remove("invisible");
  //       }
  //     }
  //   });
  //   pts.forEach(function (item) {
  //     let el = $$(".pts" + item.id);
  //     for (let i = 0; i < el.length; i++) {
  //       $$(".rep" + item.id)[i].classList.remove("correct", "wrong");
  //       if (el[i].textContent == item.pts) {
  //         $$(".rep" + item.id)[i].classList.add("wrong");
  //       } else {
  //         $$(".rep" + item.id)[i].classList.add("correct");
  //       }
  //       el[i].textContent = item.pts;
  //     }
  //   });
  // });
  const waitScript = () => {
    // eslint-disable-next-line
    if (typeof YT === "undefined" || typeof YT.Player !== "function" || !animes.animes.value) {
      interval["waitScript"] = setTimeout(function () {
        waitScript();
      }, 200);
      return;
    }
    let filteredAnime = computed(() => {
      const filtered = animes.animes.value.filter((item) =>
        item.others
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^\w\s]/gi, "")
          .replace(/[-/\\^$*+?.()|[\]{}]/g, "")
          .replace(/\s+/g, "")
          .toLowerCase()
          .trim()
          .includes(
            search.value
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .replace(/[^\w\s]/gi, "")
              .replace(/[-/\\^$*+?.()|[\]{}]/g, "")
              .replace(/\s+/g, "")
              .toLowerCase()
              .trim()
          )
      );
      return filtered;
    });
    visibleAnime.value = computed(() => filteredAnime.value);
    socket.emit("askPlay");
  };

  const countDown = () => {
    animeSelected.value = null;
    animeIdSelected.value = null;
    let count = setupRoom.value.guessTime;
    countCircle.value = 0;
    chrono.value = count;
    let el = $$(".blind-rep");
    for (let i = 0; i < el.length; i++) {
      el[i].classList.add("invisible");
    }
    counterDown.value = true;
    $("playerContainer").classList.remove("active");
    varFront.value.song_name = "-";
    varFront.value.song_artist = "-";
    varFront.value.title = "-";
    varFront.value.song_type = "-";
    varFront.value.others = null;
    imgPoster.value = null;
    clearInterval(interval["countDown"]);
    interval["countDown"] = setInterval(() => {
      chrono.value = count <= 0 ? clearInterval(interval["countDown"]) : `${--count}`;
      if (count == 4 && nTiktok.value == 3) {
        $$("#subscribe img")[0].setAttribute("src", "/images/global/subscribe.gif");
      }
      if (count == 3 && nTiktok.value == 3) {
        $("subscribe").classList.remove("opacity-0");

        setTimeout(function () {
          $("subscribe").classList.add("opacity-0");
        }, 2100);
        setTimeout(function () {
          $$("#subscribe img")[0].setAttribute("src", "");
        }, 2450);
      }
    }, 1000);
    clearInterval(interval["countDownCircle"]);
    interval["countDownCircle"] = setInterval(() => {
      countCircle.value++;
      if (countCircle.value <= 100) {
        chronoCircle.value = countCircle.value;
      }
      if (countCircle.value >= 103) {
        revele();
        clearInterval(interval["countDownCircle"]);
      }
    }, 55);
  };
  const revele = () => {
    clearInterval(interval["countDown"]);
    fastForward.value = false;
    varFront.value.fastForward = "Avance rapide";
    isSkipSong.value = false;
    isPauseSong.value = false;
    varFront.value.pauseSong = "Écouter toute la chanson";
    $("playerContainer").classList.add("active");
    counterDown.value = false;

    $(activePlayer).classList.remove("opacity-0", "invisible", "w-0", "h-0");
    $(activePlayer).classList.add("w-full", "h-full");
    varFront.value.song_name = showInfoVideo.value[0]?.song_name;
    varFront.value.song_artist = showInfoVideo.value[0]?.song_artist;
    varFront.value.title = showInfoVideo.value[0]?.title;
    varFront.value.others = showInfoVideo.value[0]?.others;
    if (showInfoVideo.value[0]?.song_type == 1) {
      varFront.value.song_type = "Opening " + showInfoVideo.value[0]?.song_number;
    } else if (showInfoVideo.value[0]?.song_type == 2) {
      varFront.value.song_type = "Ending " + showInfoVideo.value[0]?.song_number;
    } else if (showInfoVideo.value[0]?.song_type == 3) {
      varFront.value.song_type = "OST";
    }
    imgPoster.value = showInfoVideo.value[0].poster;
    if (answering.value == 2) {
      if (answering.value == 2 && repIndex.value != null) {
        if (multiChoice.value[repIndex.value]?.id == showInfoVideo.value[0]?.id) {
          $$(".animeChoiceItem")[repIndex.value].classList.add("correct");
          resumeRep.value[n.value] = true;
        } else {
          $$(".animeChoiceItem")[repIndex.value].classList.add("wrong");
          $$(".animeChoiceItem")[showInfoVideo.value[0]?.rep].classList.add("correct");
          resumeRep.value[n.value] = false;
        }
        repIndex.value = null;
      } else {
        $$(".animeChoiceItem")[showInfoVideo.value[0]?.rep].classList.add("correct");
        $("answering").classList.add("reveles");

        resumeRep.value[n.value] = false;
      }
    } else {
      $("blind-rep-list").classList.remove("correct", "wrong");
      if (animeIdSelected.value == showInfoVideo.value[0]?.id) {
        $("blind-rep-list").classList.add("correct");
        resumeRep.value[n.value] = true;
      } else {
        $("blind-rep-list").classList.add("wrong");
        resumeRep.value[n.value] = false;
      }
    }
  };
  const sendMessage = () => {
    if (message.value.trim() != "") {
      socket.emit("message_room", {
        message: message.value.trim(),
        sender: socket.id,
      });
      message.value = "";
    }
  };
  const emoji = (emote) => {
    socket.emit("message_room", {
      emote: emote,
      sender: socket.id,
    });
    message.value = "";
  };

  const toggleReadyStatus = () => {
    isReady.value = !isReady.value;
    socket.emit("readyStatus", isReady.value);
  };
  const toggleReadyStatusShift = () => {
    interact.value = !interact.value;
    playShift();
  };

  const loadVideo = (player, videoId, start) => {
    if ($(player)) {
      videoPlayerStart[player] = start;
      // $(player).remove();
      videoPlayer[player].loadVideoById({
        videoId: videoId,
      });
      videoReady[player] = 0;
    } else {
      let div = document.createElement("div");
      div.id = player;
      div.classList = "opacity-0 invisible w-0 pointer-events-none h-0 absolute";
      const playerContainer = $("subPlayerContainer");
      playerContainer.appendChild(div);
      videoReady[player] = 0;
      videoPlayerStart[player] = start;
      // eslint-disable-next-line
      videoPlayer[player] = new YT.Player(player, {
        playerVars: {
          start: start,
          controls: 0,
          rel: 0,
          disablekb: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          showinfo: 0,
        },
        videoId: videoId,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
          onError: onError,
        },
      });
    }
  };

  const onPlayerReady = (e) => {
    let player = e.target.g.id;
    if (!videoPlayer[player]?.getCurrentTime()) {
      socket.emit("reloadVideoSetup");
      return;
    }
    videoPlayer[player].playVideo();
    videoPlayer[player].setVolume(0);
  };

  const onPlayerStateChange = (e) => {
    let player = e.target.g.id;

    if (e.data == 1 && !videoReady[player]) {
      socket.emit("readyPlayer");
      videoReady[player] = 1;
      videoPlayer[player].seekTo(videoPlayerStart[player]);
      videoPlayer[player].pauseVideo();
    }
    if (e.data == 2 && !firstReady.value) {
      firstReady.value = true;
    }
    // eslint-disable-next-line
    if (e.data == YT.PlayerState.ENDED) {
      videoPlayer[activePlayer].seekTo(debutVideo);
      videoPlayer[activePlayer].playVideo();
    }
  };
  const onError = () => {
    socket.emit("reloadVideoSetup");
  };
  const play = (removeVideo, actPlayer, debut, fin) => {
    isSkipSong.value = false;

    showInfoVideo.value = infoVideo.value;
    if (!videoReady[nextPlayer] || !nextPlayer || !firstReady.value) {
      interval["play"] = setTimeout(function () {
        play(removeVideo, actPlayer, debut, fin);
      }, 200);
      return;
    }
    if (removeVideo) {
      for (let i = 0; i < 100; i++) {
        setTimeout(function () {
          videoPlayer[removeVideo].setVolume(global.volume * 0.01 * (100 - i - 1));
        }, i * 10 + 1000);
      }
      setTimeout(function () {
        // $(removeVideo).remove();
        $(removeVideo).classList.add("opacity-0", "invisible", "w-0", "h-0");
        $(removeVideo).classList.remove("w-full", "h-full");
      }, 2000);
      setTimeout(function () {
        videoPlayer[removeVideo].pauseVideo();
      }, 2200);
    }

    videoPlayer[nextPlayer].playVideo();

    setTimeout(async function () {
      $("playerContainer").classList.remove(
        "bg-linear",
        "linear-orange",
        "linear-violet",
        "linear-green",
        "linear-red"
      );
      $("demo").classList.remove("bg-[#20b2aa]", "bg-[#ffa500]", "bg-[#ae47e2]", "bg-[#00ce5e]", "bg-[#fb405b]");

      if (nTiktok.value == 0 || nTiktok.value == 5) {
        // if (setupRoom.value.tiktok == 3) {
        //   $$(".difficulty")[0].classList.add("diff");
        // }

        // $("demo").classList.add("bg-[#fb405b]");
        // setTimeout(function () {
        //   $("demo").classList.remove("bg-[#fb405b]");
        //   $("demo").classList.add("bg-[#20b2aa]");
        // }, 100);

        // $("playerContainer").classList.remove("duration-300");
        // $("playerContainer").classList.add("opacity-0");
        // $("answeringChild").classList.add("reveles");
        // $$("#small div")[0].classList.remove("duration-500");
        // $$("#small div")[1].classList.remove("duration-500");
        // $$("#small div")[2].classList.remove("duration-500");
        // $$("#small div")[3].classList.remove("duration-500");
        // $("small").classList.remove("game");
        // $("small").classList.add("small");

        // setTimeout(function () {
        //   $$("#small div")[0].classList.add("duration-500");
        //   $$("#small div")[1].classList.add("duration-500");
        //   $$("#small div")[2].classList.add("duration-500");
        //   $$("#small div")[3].classList.add("duration-500");
        //   $("small").classList.remove("small");
        // }, 20);

        // setTimeout(function () {
        //   $$("#answeringChild .animeChoiceItem img")[0].setAttribute(
        //     "src",
        //     "/images/upload/" + multiChoice.value[0].poster
        //   );
        //   $("playerContainer").classList.add("duration-300");
        //   $("playerContainer").classList.remove("opacity-0");
        //   $("answeringChild").classList.remove("reveles");
        //   $("small").classList.add("game");
        //   $$("#answeringChild .animeChoiceItem")[0].classList.remove("correct");
        //   if (setupRoom.value.tiktok == 3) {
        //     $$(".difficulty")[0].classList.remove("diff");
        //   }
        //   countDown();
        // }, 1000);

        // for (let i = 1; i < 5; i++) {
        //   setTimeout(function () {
        //     $$("#answeringChild .animeChoiceItem img")[0].setAttribute(
        //       "src",
        //       "/images/upload/" + multiChoice2.value[i].poster
        //     );
        //   }, i * 200);
        // }
        const tempsFin = Date.now();
        if (!tempsDebut) {
          tempsDebut = tempsFin;
        }
        tempsAll.push((tempsFin - tempsDebut) / 1000);
        if (tempsAll.length > 1) {
          setSegments();
        }
        $("playerContainer").classList.add("bg-linear");
        $("demo").classList.add("bg-[#20b2aa]");
        if (setupRoom.value.tiktok == 3) {
          difficultyTiktok.value = 1;
        }
      }
      if (nTiktok.value == 1) {
        $("playerContainer").classList.add("linear-orange");
        $("demo").classList.add("bg-[#ffa500]");
        if (setupRoom.value.tiktok == 3) {
          socket.emit("updateSetup", 2);
        }
      }
      if (nTiktok.value == 2) {
        $("playerContainer").classList.add("linear-violet");
        $("demo").classList.add("bg-[#ae47e2]");
        if (setupRoom.value.tiktok == 3) {
          difficultyTiktok.value = 2;
        }
      }
      if (nTiktok.value == 3) {
        $("playerContainer").classList.add("linear-green");
        $("demo").classList.add("bg-[#00ce5e]");
        if (setupRoom.value.tiktok == 3) {
          socket.emit("updateSetup", 4);
        }
      }
      if (nTiktok.value == 4) {
        if (!firstRep) {
          firstRep = showInfoVideo.value[0]?.title;
        }
        $("playerContainer").classList.add("linear-red");
        $("demo").classList.add("bg-[#fb405b]");
        if (setupRoom.value.tiktok == 3) {
          difficultyTiktok.value = 4;
          socket.emit("updateSetup", 1);
        }
        const { data: response2 } = await useFetch("/api/tiktok/multi", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            difficulty: difficultyTiktok.value,
          }),
        });
        multiChoice2.value = JSON.parse(response2.value);
        multiChoice.value = [{ poster: "", id: 0 }];
      }
      fastForward.value = false;
      varFront.value.fastForward = "Avance rapide";
      activePlayer = actPlayer;
      debutVideo = debut;
      finVideo = fin;
      n.value++;
      nextRound.value = false;
      multiChoice.value = multiChoiceTempo.value;
      // if (nTiktok.value == 0 || nTiktok.value == 5) {
      //   setTimeout(function () {
      //     $$("#answeringChild .animeChoiceItem")[0].classList.add("correct");
      //   }, 1);
      // }
      if (answering.value == 1) {
        $("blind-rep-list").classList.remove("correct", "wrong");
      }
      $$(".animeChoiceItem").forEach((_, i) => {
        // if (nTiktok.value != 0 && nTiktok.value != 5) {
        $$(".animeChoiceItem")[i].classList.remove("correct", "wrong");
        // }
        $$(".animeChoiceItem")[i].removeAttribute("style");
      });
      $("answering").classList.remove("reveles");

      clearInterval(interval["checkVideoTimeVar"]);
      interval["checkVideoTimeVar"] = setInterval(checkVideoTime, 1000);
      // if (nTiktok.value != 0 && nTiktok.value != 5) {
      countDown();
      // }
      nTiktok.value++;
      if (nTiktok.value == 6) {
        nTiktok.value = 1;
      }
    }, 2000);

    for (let i = 1; i < 100; i++) {
      setTimeout(function () {
        videoPlayer[nextPlayer].setVolume(global.volume * 0.01 * (i + 1));
      }, i * 10 + 1900);
    }
  };
  const playShift = () => {
    if (!videoReady[activePlayer] || !activePlayer || !firstReady.value) {
      interval["playShift"] = setTimeout(function () {
        playShift();
      }, 200);
      return;
    }
    videoPlayer[activePlayer].playVideo();
    nextRound.value = true;
    for (let i = 1; i < 100; i++) {
      setTimeout(function () {
        videoPlayer[activePlayer].setVolume(global.volume * 0.01 * (i + 1));
      }, i * 10);
    }
  };
  const checkVideoTime = () => {
    if (videoPlayer[activePlayer].getCurrentTime() > finVideo && finVideo) {
      videoPlayer[activePlayer].seekTo(debutVideo);
      videoPlayer[activePlayer].playVideo();
    }
  };
  onBeforeUnmount(() => {
    window.removeEventListener("beforeunload", leaveRoom);
    socket.off();
    clearTimeout(interval["waitScript"]);
    clearTimeout(interval["play"]);
    clearTimeout(interval["playShift"]);
    clearInterval(interval["countDown"]);
    clearInterval(interval["checkVideoTimeVar"]);
    clearInterval(interval["countDownCircle"]);
    if ($("player__1")) {
      $("player__1").remove();
    }
    if ($("player__2")) {
      $("player__2").remove();
    }
  });
</script>

<style scoped>
  #subscribe {
    width: 156px;
    position: absolute;
    right: calc(50% + 3px);
    top: 50%;
    z-index: 1;
    margin-top: 7px;
    transform: translate(50%, -50%);
    transition: opacity 0.2s;
  }
  .small div:nth-child(1) {
    font-size: 15px !important;
    top: 164px !important;
  }
  .small div:nth-child(2) {
    font-size: 25px !important;
    top: 179px !important;
  }
  .small div:nth-child(3) {
    font-size: 13px !important;
  }
  .small div:nth-child(4) {
    font-size: 15px !important;
  }
  .ntiktok {
    display: none;
  }
  .game .ntiktok {
    display: initial;
  }
  .game div:nth-child(1) {
    font-size: 30px !important;
    top: 0 !important;
    /* left: -59px; */
  }
  .game div:nth-child(2) {
    font-size: 30px !important;
    top: 0 !important;
    left: 89px !important;
  }
  .game div:nth-child(3) {
    font-size: 30px !important;
    top: 0 !important;
    opacity: 0;
  }
  .game div:nth-child(4) {
    font-size: 30px !important;
    top: 30px !important;
  }

  .game .stick {
    display: none;
  }
  .revele {
    height: 0;
    width: 0;
    padding: 0;
    overflow: hidden;
    font-size: 27px;
    text-align: center;
    line-height: 1.1;
  }

  .revele span {
    font-size: 21px;
    line-height: 1.3;
  }
  .reveles .revele {
    height: initial;
    width: 100%;
    filter: drop-shadow(black 2px 4px 6px);
  }
  .reveles .animeChoiceItem {
    margin: 0;
    width: 0;
    opacity: 0;
    padding: 0;
  }
  .reveles .animeChoiceItem.correct {
    margin: initial;
    width: 100%;
    opacity: 1;
  }
  .reveles.flex {
    margin-top: 70px;
  }
  :deep(.reveles .animeChoiceItem.correct .v-img) {
    max-width: 130px;
  }
  :deep(.reveles.flex .animeChoiceItem.correct .v-img) {
    max-width: 65%;
  }
  @keyframes float {
    0% {
      transform: rotate(1deg) translateY(3px) translateX(2px);
    }

    50% {
      transform: rotate(-1deg) translateY(-2px) translateX(-1px);
    }

    100% {
      transform: rotate(1deg) translateY(3px) translateX(2px);
    }
  }
  .float {
    animation: float 4s ease-in-out infinite;
  }
  .defs {
    position: absolute;
    z-index: -2;
    top: 0;
  }

  .demo--lights {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(5px);
    z-index: -2;
  }
  svg {
    width: 100%;
    height: 100%;
  }

  .group--stars {
    transform: scale(0.9);
  }

  .star--outline {
    transform-origin: 75pxr 75px;
    transform: scale(1.2);
    fill: none;
    stroke: white;
    stroke-width: 2;
    stroke-dasharray: 4;
    animation: stardashoffset 2s infinite linear;
  }

  .group--stars-anim {
    use {
      transform-origin: 75pxr 75px;
      animation: starmove 8s infinite linear;
    }

    @for $item from 2 through 4 {
      use:nth-child(#{$item}) {
        transform: scale(1- ($item/5));
      }
    }
  }

  .c-lights {
    fill: none;
    transform-origin: 320px 320px;
    animation: rotation 20s infinite linear;
  }

  @keyframes starmove {
    $trans-min: 0.8;
    $trans-max: 1.2;

    $trans-min-offset: 0.7;
    $trans-max-offset: 1.3;

    0% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }

    12.5% {
      transform-origin: 75pxr 75px * $trans-min-offset * 0.95;
    }

    25% {
      transform-origin: 75pxr * $trans-max * 1.2 75px * $trans-min;
    }

    37.5% {
      transform-origin: 75pxr * $trans-max * 1.15 75px;
    }

    50% {
      transform-origin: 75pxr * $trans-max * 1.1 75px * $trans-max * 1.05;
    }

    65.5% {
      transform-origin: 75pxr 75px * $trans-max-offset * 1.1;
    }

    75% {
      transform-origin: 75pxr * $trans-min * 0.9 75px * $trans-max * 1.05;
    }

    87.5% {
      transform-origin: 75pxr * $trans-min-offset * 0.95 75px;
    }

    100% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }
  }

  @keyframes rotation {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes stardashoffset {
    100% {
      stroke-dashoffset: -32;
    }
  }
  :deep(.imgPoster .v-responsive__sizer),
  :deep(.imgPoster .v-skeleton-loader__image) {
    padding-bottom: 150%;
  }
  :deep(.img-user-avatar img) {
    position: initial;
  }

  .m-me {
    align-items: end;
  }
  .m-div {
    overflow-x: hidden;
  }
  .m-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .m-me .m-message {
    background: #00bdbf;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 10px;
  }
  .m-message.emote {
    background: transparent;
    padding: 0;
  }
  .m-sender {
    width: fit-content;
    font-size: 13px;
  }
  .m-message {
    border-radius: 10px;
    background: #008ca8;
    padding: 5px 10px;
    width: fit-content;
    line-height: normal;
    border-bottom-left-radius: 0;
    overflow-wrap: break-word;
    max-width: 100%;
  }

  #playerContainer.hide #subPlayerContainer {
    visibility: hidden;
  }
  #player__1,
  #player__2 {
    width: calc(100% + 1px);
  }
  .v-card {
    background: transparent;
    color: white;
  }
  .difficulty {
    margin-top: -4px;
    transition: 0.2s;
    height: 28px;
  }
  .reveles .difficulty,
  .diff.difficulty {
    opacity: 0;
    height: 0;
    margin-top: 0;
  }
  .blind-rep {
    border: 1px solid rgb(15, 17, 43);
    filter: drop-shadow(black 2px 4px 6px);
  }
  .animeChoiceItem.correct {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  .blind-rep.correct {
    border: 1px solid #09a909;
  }
  .blind-rep.wrong {
    border: 1px solid red;
  }
  #blind-rep-list {
    border-bottom: 1px solid #008ea9;
  }
  #blind-rep-list.correct {
    border-bottom: 1px solid #09a909;
  }
  #blind-rep-list.wrong {
    border-bottom: 1px solid red;
  }
  .button-reverse.wrong {
    background: linear-gradient(90deg, #008ca8 0, #ff005e 50%, #008ca8) !important;
    background-position: 0 !important;
    background-size: 200% auto !important;
  }
  .button-reverse.correct {
    background: linear-gradient(90deg, #008ca8 0, #00ff00 50%, #008ca8) !important;
    background-position: 0 !important;
    background-size: 200% auto !important;
  }
  .nowrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
  #counterDown {
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0, -50%);
  }
  #playerContainer::after {
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 5px;
    margin: auto;
  }

  #playerContainer.bg-linear.active::after {
    background: #008ea9;
    width: 100%;
    transition: width linear 7s;
  }
  #playerContainer.linear-orange.active::after {
    background: #ffa500;
    width: 100%;
    transition: width linear 7s;
  }
  #playerContainer.linear-violet.active::after {
    background: #ae47e2;
    width: 100%;
    transition: width linear 7s;
  }
  #playerContainer.linear-green.active::after {
    background: #00ce5e;
    width: 100%;
    transition: width linear 7s;
  }
  #playerContainer.linear-red.active::after {
    background: #fb405b;
    width: 100%;
    transition: width linear 7s;
  }
  .linear-orange {
    background: linear-gradient(135deg, #ffca00 0, #ff9000);
  }
  .linear-violet {
    background: linear-gradient(135deg, #d271f4 0, #8a28bb);
  }
  .linear-green {
    background: linear-gradient(135deg, #4af095 0, #18bd63);
  }
  .linear-red {
    background: linear-gradient(135deg, #f4677b 0, #d83049);
  }
  .animeListItem:hover {
    background: #0f112b;
  }

  .fastforward .arrow {
    width: 30px;
    height: 30px;
    background: #f0f0f0;
    margin: 0 2px;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    opacity: 0;
    animation: fadeInOut 1s ease-in-out infinite;
  }

  .fastforward .arrow:nth-child(2) {
    animation-delay: 0.2s;
  }

  .fastforward .arrow:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes fadeInOut {
    0%,
    40%,
    100% {
      opacity: 0;
    }

    90% {
      opacity: 1;
    }
  }
  .emoji {
    background: #0f112b;
    font-size: 27px;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    margin: 2.5px;
    width: calc(20% - 5px);
    cursor: pointer;
    min-width: calc(20% - 5px);
    user-select: none;
    max-height: 60px;
  }
  .emote .anim1 {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
    font-size: 30px;
  }

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    }
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .emote .anim2 {
    animation: skew 4.72s ease infinite;
    transform-origin: 50% 50%;
  }
  @keyframes skew {
    0% {
      transform: skewX(0deg);
    }
    25% {
      transform: skewX(5deg);
    }
    75% {
      transform: skewX(-5deg);
    }
    100% {
      transform: skewX(0deg);
    }
  }
  .emote .anim4 {
    animation: shake-animation 4.72s ease infinite;
    transform-origin: 50% 50%;
  }

  @keyframes shake-animation {
    0% {
      transform: translate(0, 0);
    }
    1.78571% {
      transform: translate(5px, 5px);
    }
    2.57143% {
      transform: translate(0, 0);
    }
    3.35714% {
      transform: translate(3px, 0);
    }
    4.14286% {
      transform: translate(0, 0);
    }
    5.92857% {
      transform: translate(0px, 3px);
    }
    6.71429% {
      transform: translate(0, 0);
    }
    29% {
      transform: translate(0, 0);
    }
    30% {
      transform: translate(0, 5px);
    }
    31% {
      transform: translate(0, 0);
    }
    32% {
      transform: translate(0, 5px);
    }
    33% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .emote .anim3 {
    animation-name: beat;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
  }

  @keyframes beat {
    0% {
      scale: 1;
    }

    50% {
      scale: 1.35;
    }

    100% {
      scale: 1;
    }
  }
  .flashing path {
    animation: glowing 1500ms infinite;
  }
  #pause-button.active path {
    color: #00fbdb;
    filter: drop-shadow(0px 0px 4px #00fbdb);
  }
  @keyframes glowing {
    0% {
      color: white;
      filter: none;
    }
    50% {
      color: orange;
      filter: drop-shadow(0px 0px 4px orange);
    }
    100% {
      color: white;
      filter: none;
    }
  }
  @keyframes quickPlayButtonEffect {
    100% {
      background-position-x: -200%;
    }
  }
  .play {
    animation: quickPlayButtonEffect 5s infinite linear;
    background: linear-gradient(
      -60deg,
      #00fddc 0%,
      #00fddc 10%,
      #008ca8 25%,
      #008ca8 30%,
      #00fddc 50%,
      #008ca8 80%,
      #008ca8 85%,
      #00fddc 92%
    );
    background-size: 200% 100%;
    margin-bottom: 10px;
  }
  .play:hover {
    box-shadow: 0px 15px 20px rgb(0 140 168 / 40%);
    transform: translateY(-7px);
  }
  .v-menu:deep(.v-overlay__content) {
    pointer-events: none;
  }
  .all.v-menu:deep(.v-overlay__content) {
    pointer-events: all;
  }

  :deep(.v-overlay__content .v-card) {
    min-width: 300px;
  }
</style>

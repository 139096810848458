<template>
  <div class="specific">
    <input type="number" v-model="min" class="bg-white" />
    <input type="number" v-model="max" class="bg-white ml-4" />
    <input type="text" v-model="title" class="bg-white ml-4" />
    <input type="text" v-model="subtitle" class="bg-white ml-4" />
    <button v-if="difficulty != 4 && difficulty != 7" type="button" @click="test" class="btn btn-default">
      Commencer
    </button>
    <button v-else type="button" @click="test4" class="btn btn-default">Commencer</button>

    <input type="radio" name="difficulty" v-model="difficulty" value="1" checked class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="2" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="3" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="5" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="6" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="7" @click="start()" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="4" @click="start()" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="8" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="9" class="bg-white ml-4" />
    <input type="radio" name="difficulty" v-model="difficulty" value="10" class="bg-white ml-4" />

    <select class="text-black bg-white" v-model="select">
      <option value="">Null</option>
      <option value="Easy">Easy</option>
      <option value="Medium">Medium</option>
      <option value="Hard">Hard</option>
      <option value="Easy to Hard">Easy to Hard</option>
    </select>

    <div id="teste" class="test w-[550px]" style="position: relative">
      <div
        class="demo demo--lights duration-300"
        id="demo"
        :class="{
          bg1: difficulty == 1,
          bg2: difficulty == 2,
          bg3: difficulty == 3,
          bg4: difficulty == 4,
          bg5: difficulty == 5,
          bg6: difficulty == 6,
          bg7: difficulty == 7,
          bg8: difficulty == 8,
          bg9: difficulty == 9,
          bg10: difficulty == 10,
        }">
        <img src="/img/icons/svgexport-7.svg" alt="test" class="h-full w-full" />
      </div>
      <div class="flex flex-col absolute w-[550px] h-[65px] float" style="top: 45px; z-index: 100">
        <div class="flex flex-col relative h-[65px] float game mb-[170px] mt-[-30px]" v-if="difficulty == 9">
          <div
            class="text-[65px] text-center w-full absolute font-bold duration-500"
            style="
              line-height: 1.1;
              filter: drop-shadow(black 2px 4px 6px);
              top: 10px;
              color: white;
              letter-spacing: 1.1px;
            ">
            Guess the
          </div>
          <div
            class="text-[65px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 48px;
              background: linear-gradient(to right, #0ccfbc, rgb(199 224 255), #08d1bd) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Anime
          </div>

          <div
            class="text-[65px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 102px;

              color: white;
            ">
            With The
          </div>
          <div
            class="text-[65px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 153px;
              background: linear-gradient(90deg, rgb(255 41 104) 0px, rgb(254 214 182)) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Random
          </div>
          <div
            class="text-[65px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 204px;

              color: white;
            ">
            Character
          </div>
        </div>
        <div class="flex flex-col relative h-[65px] float game mb-[170px] mt-[-30px]" v-else-if="difficulty == 8">
          <div
            class="text-[68px] text-center w-full absolute font-bold duration-500"
            style="
              line-height: 1.1;
              filter: drop-shadow(black 2px 4px 6px);
              top: 10px;
              color: white;
              letter-spacing: 1.1px;
            ">
            Guess the
          </div>
          <div
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 51px;
              background: linear-gradient(to right, #00378d, rgb(155 200 255), #00378d) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Anime
          </div>

          <div
            class="text-[68px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 110px;

              color: white;
            ">
            With The
          </div>
          <div
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 165px;
              background: linear-gradient(90deg, rgb(255 194 41) 0px, rgb(247 119 18)) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Picture
          </div>
        </div>
        <div
          class="flex flex-col relative h-[65px] float game mb-[170px] mt-[-30px]"
          id="small"
          v-else-if="difficulty == 4 || difficulty == 7">
          <div
            class="text-[74px] text-center w-full absolute font-bold duration-500"
            style="
              line-height: 1.1;
              filter: drop-shadow(black 2px 4px 6px);
              top: 10px;
              color: white;
              letter-spacing: 1.1px;
            ">
            Guess the
          </div>
          <div
            v-if="difficulty == 7"
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 60px;
              background: linear-gradient(90deg, #008ca8 0, #00fddc);
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Pixelated
          </div>
          <div
            v-else
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 60px;
              background: linear-gradient(to right, #680c61, #da96fc, #680c61) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Pixelated
          </div>
          <div
            class="text-[74px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 120px;

              color: white;
            ">
            {{ difficulty == 4 ? "Character" : "Anime" }}
          </div>
        </div>
        <div
          v-else
          targetref="[object Object]"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-59"
          class="text-[72px] text-center w-full absolute font-bold"
          style="
            line-height: 1;
            filter: drop-shadow(black 2px 4px 5px);
            text-align: center;
            padding: 0 38px;
            text-transform: capitalize;
          ">
          <span v-if="difficulty == 10">Guess the </span>
          <p
            v-if="difficulty == 10"
            class="uppercase whitespace-nowrap my-[-10px]"
            style="
              color: transparent;
              background: linear-gradient(to right, rgb(204 255 250), rgb(199, 224, 255)) text;
              background-clip: text;
              font-weight: 900;
            ">
            anime
          </p>
          <span v-if="difficulty == 10">voice</span>

          <span v-else>Anime {{ difficulty == 6 ? "Ending" : "Opening" }} Quiz</span>
        </div>
        <div
          v-if="
            (difficulty == 5 || select == 'Easy to Hard') &&
            difficulty != 4 &&
            difficulty != 7 &&
            difficulty != 8 &&
            difficulty != 9
          "
          targetref="[object Object]"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-59"
          class="text-[56px] text-center w-full absolute font-bold top-[31px]"
          style="top: 155px; filter: drop-shadow(black 2px 4px 6px); text-align: center; text-transform: capitalize">
          <span class="text-[#56ff56]">Easy</span>
          <span class="lowercase"> to </span>
          <span class="text-[red]">Hard</span>
          <span> - #{{ n }}</span>
        </div>
        <div
          v-else-if="difficulty != 4 && difficulty != 7 && difficulty != 8 && difficulty != 9"
          targetref="[object Object]"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-59"
          class="text-[56px] text-center w-full absolute font-bold top-[31px]"
          style="top: 155px; filter: drop-shadow(black 2px 4px 5px); text-align: center; text-transform: capitalize">
          <span
            :style="{
              color:
                difficulty == 1 || select == 'Easy'
                  ? '#56ff56'
                  : difficulty == 2 || select == 'Medium'
                  ? 'orange'
                  : difficulty == 3 || select == 'Hard'
                  ? 'red'
                  : '#cfa2ee',
            }"
            >{{ difficulty == 1 ? "Easy" : difficulty == 2 ? "Medium" : difficulty == 3 ? "Hard" : select }}</span
          >
          <span v-if="difficulty != 10"> - #{{ n }}</span>

          <span v-if="difficulty == 10" class="flex items-center justify-center mt-[360px]">
            <img
              v-if="difficulty == 10"
              class="mr-5"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1200px-Speaker_Icon.svg.png"
              style="width: 120px; filter: invert(1); margin-top: 5px" />
            #{{ n }}</span
          >
        </div>

        <!---->
      </div>
      <div
        v-if="difficulty != 4 && difficulty != 7 && difficulty != 8 && difficulty != 9"
        class="flex flex-wrap px-3"
        style="flex-wrap: nowrap; width: 550px; position: absolute; top: 340px">
        <div
          class="w-1/2 animeChoiceItem mb-6 float px-3"
          :class="{
            'px-3': difficulty != 10,
            'pl-[55px]': difficulty == 10,
            'mt-[-78px]': difficulty == 10,
            'mt-[-5px]': difficulty != 10,
          }">
          <div
            class="v-responsive v-img w-full shadow-3xl overflow-hidden rounded-xl"
            aria-label="Kakegurui"
            role="img"
            loading="lazy">
            <div class="v-responsive__sizer" style="padding-bottom: 150%"></div>
            <img
              v-if="difficulty == 10"
              @load="
                ready1 = true;
                test2();
              "
              class=""
              :src="'/images/characters/' + img1"
              alt="Kakegurui" />
            <img
              v-else
              @load="
                ready1 = true;
                test2();
              "
              class=""
              :src="'/images/upload/' + img1"
              alt="Kakegurui" />
          </div>
        </div>
        <div
          class="w-1/2 animeChoiceItem mb-6 float px-3"
          :class="{
            'pr-[55px]': difficulty == 10,
            'mt-[-78px]': difficulty == 10,
            'mt-[-5px]': difficulty != 10,
          }"
          style="margin-bottom: 40px">
          <div
            class="v-responsive v-img w-full shadow-3xl overflow-hidden rounded-xl"
            aria-label="One Piece"
            role="img"
            loading="lazy">
            <div class="v-responsive__sizer" style="padding-bottom: 150%"></div>
            <img
              v-if="difficulty == 10"
              @load="
                ready2 = true;
                test2();
              "
              class=""
              :src="'/images/characters/' + img2"
              alt="One Piece" />
            <img
              v-else
              @load="
                ready2 = true;
                test2();
              "
              class=""
              :src="'/images/upload/' + img2"
              alt="One Piece" />
          </div>
        </div>

        <div class="w-1/2 animeChoiceItem px-3 mb-6 mt-[-5px] float" v-if="difficulty != 10">
          <div
            class="v-responsive v-img w-full shadow-3xl overflow-hidden rounded-xl"
            aria-label="Dr. Stone"
            role="img"
            loading="lazy">
            <div class="v-responsive__sizer" style="padding-bottom: 150%"></div>
            <img
              @load="
                ready3 = true;
                test2();
              "
              class=""
              :src="'/images/upload/' + img3"
              alt="Dr. Stone" /><!----><!----><!----><!----><!---->
          </div>
        </div>
        <div
          class="overflow-hidden h-[300px]"
          style="padding: 0px 40px; max-width: 550px; position: absolute; top: 60px; left: 0">
          <img
            v-if="difficulty == 1 || difficulty == 5"
            class="float"
            src="/images/global/bar1.webp"
            alt="Dr. Stone"
            style="height: 400px" />
          <img
            v-if="difficulty == 2"
            class="float"
            src="/images/global/bar1.webp"
            alt="Dr. Stone"
            style="height: 400px; filter: hue-rotate(250deg) brightness(1.3)" />
          <img
            v-if="difficulty == 3"
            class="float"
            src="/images/global/bar3.webp"
            alt="Dr. Stone"
            style="height: 400px; filter: brightness(1.6) saturate(1.6)" />
          <img
            v-if="difficulty == 6"
            class="float"
            src="/images/global/bar4.png"
            alt="Dr. Stone"
            style="height: 400px; filter: saturate(0.5)" />
        </div>
      </div>
      <div
        v-else
        style="flex-wrap: nowrap; width: 550px; position: absolute; top: 260px"
        class="flex justify-center flex-col place-items-center">
        <canvas
          v-if="difficulty != 8 && difficulty != 9"
          id="canvas"
          width="200"
          height="312"
          class="float shadow-3xl overflow-hidden rounded-xl w-[40%]"></canvas>
        <img
          v-else-if="difficulty == 8"
          :src="'/images/screenshots/' + img1"
          class="float shadow-3xl overflow-hidden rounded-xl w-[80%] mt-[25px]"
          @load="test2()" />
        <img
          v-else
          :src="'/images/characters/' + img1"
          class="float shadow-3xl overflow-hidden rounded-xl w-[35%] mt-[60px]"
          @load="test2()" />
        <input id="blocks" @change="pixelate" type="range" min="1" max="100" value="14" class="hidden" />
        <div
          class="text-[56px] text-center w-full font-bold duration-500 mt-[-5px] float"
          style="
            line-height: 1.5;
            filter: drop-shadow(black 2px 4px 6px);

            color: white;
          ">
          #{{ n }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useHead } from "@unhead/vue";
  useHead({
    script: [
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/dom-to-image/2.6.0/dom-to-image.js",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.5/jszip.min.js",
      },
    ],
  });

  let ctx;
  let img;
  let play;
  const n = ref(0);
  const min = ref(0);
  const max = ref(0);
  const title = ref("Opening");
  const subtitle = ref("");
  const difficulty = ref(1);
  const img1 = ref(null);
  const img2 = ref(null);
  const img3 = ref(null);
  const ready1 = ref(false);
  const ready2 = ref(false);
  const ready3 = ref(false);
  const active = ref(false);
  const select = ref("");
  async function test3() {
    if (difficulty.value == 7) {
      const { data: response } = await useFetch("/api/admin/tiktok/pixel/anime", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia pixel anime",
        }),
      });
      const r = JSON.parse(response.value);

      // (C) Ken Fyrstenberg, Epistemex, License: CC3.0-attr
      ctx = document.getElementById("canvas").getContext("2d");
      img = new Image();
      play = false;

      // turn off image smoothing - this will give the pixelated effect
      ctx.mozImageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;

      // wait until image is actually available
      img.onload = pixelate;

      // some image, we are not struck with CORS restrictions as we
      // do not use pixel buffer to pixelate, so any image will do
      img.src = "https://baranimes.com/images/upload/" + r[0].poster;
    } else {
      const { data: response } = await useFetch("/api/admin/tiktok/character", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia pixel character",
        }),
      });
      const r = JSON.parse(response.value);

      // (C) Ken Fyrstenberg, Epistemex, License: CC3.0-attr
      ctx = document.getElementById("canvas").getContext("2d");
      img = new Image();
      play = false;

      // turn off image smoothing - this will give the pixelated effect
      ctx.mozImageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;

      // wait until image is actually available
      img.onload = pixelate;

      // some image, we are not struck with CORS restrictions as we
      // do not use pixel buffer to pixelate, so any image will do
      img.src = "https://baranimes.com/images/characters/" + r[0].photo;
    }
  }
  async function test4() {
    await start();
    // eslint-disable-next-line
    const zip = new JSZip();
    var node = document.getElementById("teste");
    for (let i = min.value; i <= max.value; i++) {
      n.value = i;
      // eslint-disable-next-line
      await domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          zip.file(`image_${i}.png`, dataUrl.split(",")[1], { base64: true });
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (n.value != max.value) {
        await test3();
      }
    }
    zip.generateAsync({ type: "blob" }).then((blob) => {
      // Créez un lien de téléchargement pour le fichier ZIP
      const lienZip = document.createElement("a");
      lienZip.href = URL.createObjectURL(blob);
      lienZip.download = "images.zip"; // Nom du fichier ZIP
      document.body.appendChild(lienZip);
      lienZip.click();
      document.body.removeChild(lienZip);
    });
  }
  async function test() {
    if (difficulty.value != 8 && difficulty.value != 9 && difficulty.value != 10) {
      const { data: response } = await useFetch("/api/tiktok/thumbnail", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          difficulty: difficulty.value,
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].poster;
      img2.value = r[1].poster;
      img3.value = r[2].poster;
    } else if (difficulty.value == 8) {
      const { data: response } = await useFetch("/api/admin/tiktok/screenshot", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia picture",
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].photo;
    } else if (difficulty.value == 10) {
      const { data: response } = await useFetch("/api/admin/tiktok/character/random", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia random character",
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].photo;
      const { data: response2 } = await useFetch("/api/admin/tiktok/character/random", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia random character",
        }),
      });
      const r2 = JSON.parse(response2.value);
      img2.value = r2[0].photo;
    } else {
      const { data: response } = await useFetch("/api/admin/tiktok/character/random", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia random character",
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].photo;
    }
  }
  async function test2() {
    console.log(ready1.value, ready2.value, ready3.value, active.value);
    if (
      ((!ready1.value || !ready2.value || !ready3.value) &&
        difficulty.value != 8 &&
        difficulty.value != 9 &&
        difficulty.value != 10) ||
      active.value
    ) {
      return;
    }
    if ((!ready1.value || !ready2.value) && difficulty.value == 10) {
      return;
    }
    active.value = true;
    // eslint-disable-next-line
    const zip = new JSZip();
    var node = document.getElementById("teste");
    for (let i = min.value; i <= max.value; i++) {
      n.value = i;
      // eslint-disable-next-line
      await domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          zip.file(`image_${i}.png`, dataUrl.split(",")[1], { base64: true });
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
      if (n.value != max.value) {
        await test();
      }
    }
    zip.generateAsync({ type: "blob" }).then((blob) => {
      // Créez un lien de téléchargement pour le fichier ZIP
      const lienZip = document.createElement("a");
      lienZip.href = URL.createObjectURL(blob);
      lienZip.download = "images.zip"; // Nom du fichier ZIP
      document.body.appendChild(lienZip);
      lienZip.click();
      document.body.removeChild(lienZip);
    });
    ready1.value = false;
    ready2.value = false;
    ready3.value = false;
    active.value = false;
  }
  async function start() {
    setTimeout(async function () {
      if (difficulty.value == 7) {
        const { data: response } = await useFetch("/api/admin/tiktok/pixel/anime", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Minia pixel anime",
          }),
        });
        const r = JSON.parse(response.value);

        // (C) Ken Fyrstenberg, Epistemex, License: CC3.0-attr
        ctx = document.getElementById("canvas").getContext("2d");
        img = new Image();
        play = false;

        // turn off image smoothing - this will give the pixelated effect
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;

        // wait until image is actually available
        img.onload = pixelate;

        // some image, we are not struck with CORS restrictions as we
        // do not use pixel buffer to pixelate, so any image will do
        img.src = "https://baranimes.com/images/upload/" + r[0].poster;
      } else {
        const { data: response } = await useFetch("/api/admin/tiktok/character", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Minia pixel character",
          }),
        });
        const r = JSON.parse(response.value);

        // (C) Ken Fyrstenberg, Epistemex, License: CC3.0-attr
        ctx = document.getElementById("canvas").getContext("2d");
        img = new Image();
        play = false;

        // turn off image smoothing - this will give the pixelated effect
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;

        // wait until image is actually available
        img.onload = pixelate;

        // some image, we are not struck with CORS restrictions as we
        // do not use pixel buffer to pixelate, so any image will do
        img.src = "https://baranimes.com/images/characters/" + r[0].photo;
      }
    }, 1);
  }
  // MAIN function
  function pixelate(v) {
    // if in play mode use that value, else use slider value
    var size = (play ? v : document.getElementById("blocks").value) * 0.01,
      // cache scaled width and height
      w = document.getElementById("canvas").width * size,
      h = document.getElementById("canvas").height * size;

    // draw original image to the scaled size
    ctx.drawImage(img, 0, 0, w, h);

    // then draw that scaled image thumb back to fill canvas
    // As smoothing is off the result will be pixelated
    ctx.drawImage(
      document.getElementById("canvas"),
      0,
      0,
      w,
      h,
      0,
      0,
      document.getElementById("canvas").width,
      document.getElementById("canvas").height
    );
  }
</script>
<style scoped>
  @keyframes float {
    0% {
      transform: rotate(0.5deg) translateY(3px) translateX(2px);
    }

    50% {
      transform: rotate(-0.5deg) translateY(-2px) translateX(-1px);
    }

    100% {
      transform: rotate(0.5deg) translateY(3px) translateX(2px);
    }
  }
  .float {
    /* animation: float 4s ease-in-out infinite; */
  }
  .defs {
    position: relative;
    z-index: -2;
    top: 0;
    animation: rotation 2s infinite linear;
  }
  .demo.bg1 {
    background: conic-gradient(from 225deg, #008ea9, #00ce5e, #008ea9);
  }
  .demo.bg2 {
    background: conic-gradient(from 225deg, #ffca00, #ff9000, #ffca00);
  }
  .demo.bg3 {
    background: conic-gradient(from 225deg, #fb405b, #ae47e2, #fb405b);
  }
  .demo.bg4 {
    background: conic-gradient(from 225deg, #680c61, #da96fc, #680c61);
  }
  .demo.bg5 {
    background: conic-gradient(from 225deg, #00ce5e, #ff005e, #00ce5e);
  }
  .demo.bg6 {
    background: conic-gradient(from 225deg, #008ea9, #00fddc, #008ea9);
  }
  .demo.bg7 {
    background: conic-gradient(from 225deg, #008ea9, #ae47e2, #008ea9);
  }
  .demo.bg8 {
    background: conic-gradient(from 225deg, #ff8b00, #4158ff, #ff8b00);
  }
  .demo.bg9 {
    background: conic-gradient(from 225deg, #05d3be, #ff4165, #05d3be);
  }
  .demo.bg10 {
    background: conic-gradient(from 225deg, rgb(195 27 136), rgb(87 215 202), rgb(195 27 136));
  }
  .demo--lights {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(5px);
    z-index: 0;
    width: 550px;
    height: 700px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  .group--stars {
    transform: scale(0.9);
  }

  .star--outline {
    transform-origin: 75pxr 75px;
    transform: scale(1.2);
    fill: none;
    stroke: white;
    stroke-width: 2;
    stroke-dasharray: 4;
    animation: stardashoffset 2s infinite linear;
  }

  .group--stars-anim {
    use {
      transform-origin: 75pxr 75px;
      animation: starmove 8s infinite linear;
    }

    @for $item from 2 through 4 {
      use:nth-child(#{$item}) {
        transform: scale(1- ($item/5));
      }
    }
  }

  .c-lights {
    fill: none;
    transform-origin: 320px 320px;
    animation: rotation 20s infinite linear;
  }

  @keyframes starmove {
    $trans-min: 0.8;
    $trans-max: 1.2;

    $trans-min-offset: 0.7;
    $trans-max-offset: 1.3;

    0% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }

    12.5% {
      transform-origin: 75pxr 75px * $trans-min-offset * 0.95;
    }

    25% {
      transform-origin: 75pxr * $trans-max * 1.2 75px * $trans-min;
    }

    37.5% {
      transform-origin: 75pxr * $trans-max * 1.15 75px;
    }

    50% {
      transform-origin: 75pxr * $trans-max * 1.1 75px * $trans-max * 1.05;
    }

    65.5% {
      transform-origin: 75pxr 75px * $trans-max-offset * 1.1;
    }

    75% {
      transform-origin: 75pxr * $trans-min * 0.9 75px * $trans-max * 1.05;
    }

    87.5% {
      transform-origin: 75pxr * $trans-min-offset * 0.95 75px;
    }

    100% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }
  }

  @keyframes rotation {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes stardashoffset {
    100% {
      stroke-dashoffset: -32;
    }
  }
</style>

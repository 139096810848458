<template>
  <Teleport to="body">
    <Transition name="modal-outer">
      <div
        v-show="modalActive"
        @click="$emit('close-modal')"
        class="fixed w-full bg-[#02041f] bg-opacity-90 h-screen top-0 left-0 flex justify-center z-50 cursor-pointer">
        <Transition name="modal-inner">
          <div v-if="modalActive" class="w-full">
            <div class="w-full h-full flex items-center justify-center">
              <div
                @click.stop
                class="relative h-4/5 overflow-hidden bg-[#02041f] shadow-md rounded-3xl w-full mx-2 md:mx-4 lg:mx-8 max-w-[1200px] cursor-auto"
                style="border-bottom: 3px solid #00fddc; box-shadow: 0px 15px 20px rgb(0 140 168 / 40%)">
                <div class="px-4 md:px-14 py-6 md:py-12 pt-12 justify-center flex flex-col h-full">
                  <div class="h-full overflow-hidden">
                    <div>
                      <button
                        @click="goToRoom"
                        class="py-[15px] button-reverse duration-500 bg-gradient-to-r from-[#008ca8] to-[#00fddc] text-white w-full justify-center md:py-8 px-10 rounded-full text-3xl md:text-5xl font-sans max-w-full outline-none font-bold border-none cursor-pointer inline-flex bg-cover bg-center mb-6">
                        <span v-if="!goToRoomActive">{{ $t("home.setup.host") }}</span>
                        <v-progress-circular v-if="goToRoomActive" :size="48" indeterminate></v-progress-circular>
                      </button>
                      <svg
                        @click="$emit('close-modal')"
                        class="absolute z-10 top-[15px] right-[12px] md:top-7 md:right-6 cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
                      </svg>
                    </div>
                    <div
                      class="flex flex-wrap justify-center overflow-auto h-4/5 md:h-3/4 content-baseline overflow-x-hidden">
                      <div class="m-6 mt-0">
                        <p class="text-center font-medium mb-1">
                          {{ $t("home.setup.answering.title") }}
                        </p>
                        <div class="flex justify-center flex-wrap">
                          <div class="tale m-1 flex justify-center items-center">
                            <input
                              type="radio"
                              value="1"
                              v-model="global.answering"
                              class="setup"
                              name="answering"
                              id="answering1"
                              :checked="global.answering == 1" />

                            <svg
                              class="absolute pointer-events-none left-1 icon-checked"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <label
                              for="answering1"
                              class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                <path
                                  fill="currentColor"
                                  d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z" />
                                <circle cx="16" cy="10" r="0" fill="currentColor">
                                  <animate
                                    attributeName="r"
                                    begin=".67"
                                    calcMode="spline"
                                    dur="1.5s"
                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                    repeatCount="indefinite"
                                    values="0;1.75;0;0" />
                                </circle>
                                <circle cx="12" cy="10" r="0" fill="currentColor">
                                  <animate
                                    attributeName="r"
                                    begin=".33"
                                    calcMode="spline"
                                    dur="1.5s"
                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                    repeatCount="indefinite"
                                    values="0;1.75;0;0" />
                                </circle>
                                <circle cx="8" cy="10" r="0" fill="currentColor">
                                  <animate
                                    attributeName="r"
                                    begin="0"
                                    calcMode="spline"
                                    dur="1.5s"
                                    keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
                                    repeatCount="indefinite"
                                    values="0;1.75;0;0" />
                                </circle>
                              </svg>
                              <h6 class="font-medium">{{ $t("home.setup.answering.typing") }}</h6>
                            </label>
                          </div>
                          <div class="tale m-1 flex justify-center items-center">
                            <input
                              type="radio"
                              value="2"
                              v-model="global.answering"
                              class="setup"
                              name="answering"
                              id="answering2"
                              :checked="global.answering == 2" />
                            <svg
                              class="absolute pointer-events-none left-1 icon-checked"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <label
                              for="answering2"
                              class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16">
                                <path
                                  fill="currentColor"
                                  d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                              </svg>
                              <h6 class="font-medium">{{ $t("home.setup.answering.multiple") }}</h6>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="m-6 mt-0">
                        <p class="text-center font-medium mb-1">
                          {{ $t("home.setup.difficulty.title") }}
                        </p>
                        <div class="flex justify-center flex-wrap">
                          <div
                            v-for="difficulty in difficulties"
                            :key="difficulty.value"
                            class="tale m-1 flex justify-center items-center">
                            <input
                              class="setup"
                              type="checkbox"
                              :id="'difficulte' + difficulty.value"
                              :value="difficulty.value"
                              v-model="difficulty.checked"
                              @change="updateDifficulty" />
                            <svg
                              class="absolute pointer-events-none left-1 icon-checked"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <label
                              :for="'difficulte' + difficulty.value"
                              class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                              <svg
                                class="flex"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 256 256">
                                <path fill="currentColor" :d="difficulty.icon" />
                              </svg>
                              <h6 class="font-medium">{{ difficulty.label }}</h6>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="m-6 mr-12 mt-0">
                        <p class="text-center font-medium mb-1">
                          {{ $t("home.setup.songs_number.title") }}
                        </p>
                        <div class="flex justify-center items-center mb-3 h-[74px] relative">
                          <v-slider
                            v-model="global.number"
                            color="#00bdbf"
                            class="w-[150px] h-[28px]"
                            min="5"
                            max="100"
                            step="5"></v-slider>
                          <span class="font-medium absolute right-[-30px] pt-[1px]">{{ global.number }}</span>
                        </div>
                      </div>
                      <div class="m-6 mt-0">
                        <p class="text-center font-medium mb-1">
                          {{ $t("home.setup.media_type.title") }}
                        </p>
                        <div class="flex justify-center flex-wrap">
                          <div
                            v-for="mediaType in mediaTypes"
                            :key="mediaType.value"
                            class="tale m-1 flex justify-center items-center">
                            <input
                              class="setup"
                              type="checkbox"
                              :id="'mediaType' + mediaType.value"
                              :value="mediaType.value"
                              v-model="mediaType.checked"
                              @change="updateMediaType" />
                            <svg
                              class="absolute pointer-events-none left-1 icon-checked"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <label
                              :for="'mediaType' + mediaType.value"
                              class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                              <svg
                                class="flex"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                :viewBox="mediaType.viewBox">
                                <path fill="currentColor" :d="mediaType.icon" />
                              </svg>
                              <h6 class="font-medium">{{ mediaType.label }}</h6>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="m-6 mr-12 mt-0">
                        <p class="text-center font-medium mb-1">
                          {{ $t("home.setup.guess_time.title") }}
                        </p>
                        <div class="flex justify-center items-center mb-3 h-[74px] relative">
                          <v-slider
                            v-model="global.time"
                            color="#00bdbf"
                            class="w-[150px] h-[28px]"
                            min="5"
                            max="60"
                            step="5"></v-slider>
                          <span class="font-medium absolute right-[-30px] pt-[1px]">{{ global.time }}s</span>
                        </div>
                      </div>
                      <div class="m-6 mt-0">
                        <p class="text-center font-medium mb-1">
                          {{ $t("home.setup.songs_selection.title") }}
                        </p>
                        <div class="flex justify-center">
                          <div class="tale m-1 flex justify-center items-center">
                            <input
                              type="checkbox"
                              id="list"
                              class="setup"
                              v-model="global.custom"
                              :checked="global.custom"
                              :disabled="auth.user.role == 'guest'" />
                            <svg
                              class="absolute pointer-events-none left-1 icon-checked"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <label
                              for="list"
                              class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                                <path
                                  fill="currentColor"
                                  d="M224 128a8 8 0 0 1-8 8h-88a8 8 0 0 1 0-16h88a8 8 0 0 1 8 8Zm-96-56h88a8 8 0 0 0 0-16h-88a8 8 0 0 0 0 16Zm88 112h-88a8 8 0 0 0 0 16h88a8 8 0 0 0 0-16ZM82.34 42.34L56 68.69L45.66 58.34a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0-11.32-11.32Zm0 64L56 132.69l-10.34-10.35a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0-11.32-11.32Zm0 64L56 196.69l-10.34-10.35a8 8 0 0 0-11.32 11.32l16 16a8 8 0 0 0 11.32 0l32-32a8 8 0 0 0-11.32-11.32Z" />
                              </svg>
                              <h6 class="font-medium">
                                {{ $t("home.setup.songs_selection.custom") }}
                                <v-tooltip v-model="show" location="top">
                                  <template v-slot:activator="{ props }">
                                    <v-btn icon v-bind="props">
                                      <v-icon color="grey-lighten-1"> mdi-help-circle-outline </v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t("home.setup.songs_selection.tooltip") }}</span>
                                </v-tooltip>
                              </h6>
                            </label>
                          </div>
                        </div>
                        <p class="text-center font-medium text-gray-300" v-if="auth.user.role == 'guest'">
                          ({{ $t("home.setup.members_only") }})
                        </p>
                      </div>
                      <div class="m-6 mt-0">
                        <p class="text-center font-medium mb-1">
                          {{ $t("home.setup.private_room.title") }}
                        </p>
                        <div class="flex justify-center">
                          <div class="tale m-1 flex justify-center items-center">
                            <input
                              type="checkbox"
                              id="lock"
                              class="setup"
                              v-model="global.privateRoom"
                              :checked="global.privateRoom"
                              :disabled="auth.user.role == 'guest'" />
                            <svg
                              class="absolute pointer-events-none left-1 icon-checked"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 24 24">
                              <path
                                fill="currentColor"
                                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                            </svg>
                            <label
                              for="lock"
                              class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                                <path
                                  fill="currentColor"
                                  d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm6-5q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z" />
                              </svg>
                              <h6 class="font-medium">{{ $t("home.setup.private_room.private") }}</h6>
                            </label>
                          </div>
                        </div>
                        <p class="text-center font-medium text-gray-300" v-if="auth.user.role == 'guest'">
                          ({{ $t("home.setup.members_only") }})
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
  import { ref, onMounted, computed } from "vue";

  import { useI18n } from "vue-i18n";
  import { useJoinRoomStore } from "@/stores/joinRoom";
  import { useAuthStore } from "@/stores/auth";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();
  const auth = useAuthStore();
  const goToRoomActive = ref(false);
  const joinRoom = useJoinRoomStore();

  const socket = joinRoom.socket;
  const { t } = useI18n();
  const difficulties = ref([
    {
      value: 1,
      checked:
        global.difficulty == 1 || global.difficulty == 3 || global.difficulty == 5 || global.difficulty == 7
          ? true
          : false,
      label: computed(() => t("home.setup.difficulty.easy")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88ZM80 108a12 12 0 1 1 12 12a12 12 0 0 1-12-12Zm96 0a12 12 0 1 1-12-12a12 12 0 0 1 12 12Zm-1.07 48c-10.29 17.79-27.4 28-46.93 28s-36.63-10.2-46.92-28a8 8 0 1 1 13.84-8c7.47 12.91 19.21 20 33.08 20s25.61-7.1 33.07-20a8 8 0 0 1 13.86 8Z`,
    },
    {
      value: 2,
      checked:
        global.difficulty == 2 || global.difficulty == 3 || global.difficulty == 6 || global.difficulty == 7
          ? true
          : false,
      label: computed(() => t("home.setup.difficulty.medium")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88Zm53.66-53.66a8 8 0 0 1-11.32 11.32L160 163.31l-10.34 10.35a8 8 0 0 1-11.32 0L128 163.31l-10.34 10.35a8 8 0 0 1-11.32 0L96 163.31l-10.34 10.35a8 8 0 0 1-11.32-11.32l16-16a8 8 0 0 1 11.32 0L112 156.69l10.34-10.35a8 8 0 0 1 11.32 0L144 156.69l10.34-10.35a8 8 0 0 1 11.32 0ZM80 108a12 12 0 1 1 12 12a12 12 0 0 1-12-12Zm72 0a12 12 0 1 1 12 12a12 12 0 0 1-12-12Z`,
    },
    {
      value: 4,
      checked:
        global.difficulty == 4 || global.difficulty == 5 || global.difficulty == 6 || global.difficulty == 7
          ? true
          : false,
      label: computed(() => t("home.setup.difficulty.hard")),
      icon: `M92 152a12 12 0 1 1 12-12a12 12 0 0 1-12 12Zm72-24a12 12 0 1 0 12 12a12 12 0 0 0-12-12Zm68 0A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Zm-44.44-46.66L128 110.39l-43.56-29a8 8 0 1 0-8.88 13.32l48 32a8 8 0 0 0 8.88 0l48-32a8 8 0 0 0-8.88-13.32Zm-15.13 96C148 171.73 139.94 168 128 168s-20 3.73-28.43 9.34a8 8 0 0 0 8.86 13.32C114.93 186.34 120 184 128 184s13.07 2.34 19.57 6.66a8 8 0 1 0 8.86-13.32Z`,
    },
  ]);

  const mediaTypes = ref([
    {
      value: 1,
      checked: global.type == 1 || global.type == 3 || global.type == 5 || global.type == 7 ? true : false,
      label: computed(() => t("home.setup.media_type.openings")),
      icon: `M23 3H1V1h22v2M2 22h4c0-3-2-5-2-5c6-4 7-13 7-13H2v18M22 4h-9s1 9 7 13c0 0-2 2-2 5h4V4Z`,
      viewBox: "0 0 24 24",
    },
    {
      value: 2,
      checked: global.type == 2 || global.type == 3 || global.type == 6 || global.type == 7 ? true : false,
      label: computed(() => t("home.setup.media_type.endings")),
      icon: `M23 3H1V1h22v2M2 22h9V4H2v18M22 4h-9v18h9V4Z`,
      viewBox: "0 0 24 24",
    },
    {
      value: 4,
      checked: global.type == 4 || global.type == 5 || global.type == 6 || global.type == 7 ? true : false,
      label: computed(() => t("home.setup.media_type.ost")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88Zm0-144a56.06 56.06 0 0 0-56 56a8 8 0 0 1-16 0a72.08 72.08 0 0 1 72-72a8 8 0 0 1 0 16Zm72 56a72.08 72.08 0 0 1-72 72a8 8 0 0 1 0-16a56.06 56.06 0 0 0 56-56a8 8 0 0 1 16 0Zm-40 0a32 32 0 1 0-32 32a32 32 0 0 0 32-32Zm-48 0a16 16 0 1 1 16 16a16 16 0 0 1-16-16Z`,
      viewBox: "0 0 256 256",
    },
  ]);
  onMounted(() => {
    updateDifficulty();
    updateMediaType();
  });

  const updateDifficulty = () => {
    let total = 0;
    for (const difficulty of difficulties.value) {
      if (difficulty.checked) {
        total += difficulty.value;
      }
    }
    global.difficulty = total;
  };
  const updateMediaType = () => {
    let total = 0;
    for (const mediaType of mediaTypes.value) {
      if (mediaType.checked) {
        total += mediaType.value;
      }
    }
    global.type = total;
  };

  const goToRoom = () => {
    goToRoomActive.value = true;
    socket.emit("goToRoomReplay", {
      answering: global.answering,
      difficulty: global.difficulty,
      songsNumber: global.number,
      mediaType: global.type,
      guessTime: global.time,
      songsSelection: global.custom,
      privateRoom: global.privateRoom,
      n: 0,
    });
  };

  defineEmits(["close-modal"]);
  defineProps({
    modalActive: {
      type: Boolean,
      default: false,
    },
  });
</script>

<style scoped>
  .tale {
    position: relative;
    width: 100px;
    height: 70px;
  }
  .setup-name {
    text-align: -webkit-center;
    top: -67px;
    position: relative;
    transition: 0.3s;
  }

  input[class="setup"] {
    appearance: none;
    outline: none;
    width: 100px;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    border: 3px solid #d8d8d8;
    background: #0f112b;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    &::before {
      content: "";
      left: 13px;
      top: 13px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 0px solid #d8d8d8;
      color: #00bdbf;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      transition: all 0.3s, border 0.3s;
    }
    &:hover {
      &::before {
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid #d8d8d8;
      }
    }
    &:checked {
      border: 3px solid #00bdbf;
      & ~ * {
        color: #00bdbf;
        width: 25px;
      }
      & ~ .icon-checked {
        width: 25px;
      }
      &::before {
        opacity: 0;
        transition: 0s;
      }
    }

    & ~ .icon-checked {
      width: 0;
      color: #00bdbf;
      transition: 0.3s;
      transform: translate(-50%, -50%);
      left: 16px;
      top: 16px;
    }
  }
  @keyframes selectKitchen {
    50% {
      font-size: 3rem;
    }
  }
  .modal-outer-enter-active,
  .modal-outer-leave-active {
    transition: opacity 0.1s cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }

  .modal-outer-enter-from,
  .modal-outer-leave-to {
    opacity: 0;
  }

  .modal-inner-enter-active {
    transition: all 0.1s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
  }

  .modal-inner-leave-active {
    transition: all 0.1s cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }

  .modal-inner-enter-from {
    opacity: 0;
    transform: scale(0.8);
  }

  .modal-inner-leave-to {
    transform: scale(0.8);
  }
</style>

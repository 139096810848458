<template>
  <img src="https://www.baranimes.com/images/global/favicon_no_bg.png" class="small" id="logo" alt="Baranimes" />
  <div class="main">
    <header class="header-section-main">
      <div class="custom-container">
        <div class="header-section-inner">
          <div class="header-logo flex md:block">
            <router-link to="/testhome">
              <img
                src="https://www.baranimes.com/images/global/favicon_no_bg.png"
                class="cursor-scale small"
                alt="valorant-logo" />
            </router-link>
            <div class="flex md:mt-4 items-center uppercase ml-[-10px]" id="back" @click="play">
              <svg xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" width="60" height="40" viewBox="0 0 24 24">
                <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                  <path d="m13 19l-6-7l6-7" />
                  <path d="m17 19l-6-7l6-7" opacity=".5" />
                </g>
              </svg>
              <span class="text-[15px] w-0 whitespace-nowrap ml-[-21px] cursor-pointer">⬩ Retour</span>
            </div>
            <div class="banner-left-vertical-main hidden md:flex" id="banner-left-vertical-main">
              <h6>BARANIMES</h6>
              <img src="https://www.yudiz.com/codepen/valorant-characters/gl-logo-first.svg" class="gl-logo-img" />
            </div>
          </div>

          <div class="header-menu hidden lg:block">
            <ul class="hidden lg:flex">
              <li class="active cursor-scale"><a href="javascript:void(0);">BLIND TEST ANIME</a></li>
              <li class="cursor-scale">
                <router-link to="/anime/animegataris">
                  <a @click="this.parentElement.classList.add('active')">Animes</a>
                </router-link>
              </li>
              <li class="cursor-scale"><a href="javascript:void(0);">Mes listes</a></li>
              <li class="cursor-scale"><a href="javascript:void(0);">Avatar</a></li>
            </ul>
          </div>
          <div class="header-right-icon">
            <a href="javascript:void(0);" class="cursor-scale"
              ><img src="https://www.yudiz.com/codepen/valorant-characters/header-right-arrow.svg" alt="right-icon"
            /></a>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script setup>
  import { useGlobalStore } from "@/stores/global";
  import { useRouter } from "vue-router";
  const global = useGlobalStore();
  const router = useRouter();

  function play() {
    global.back = false;
    if (location.pathname == "/fr/testhome") {
      document.getElementById("back").classList.remove("active");
      document.getElementById("banner-content-main").classList.remove("active");
      document.getElementById("section-home").classList.remove("active");
      document.getElementById("banner-section-second").classList.remove("active");

      setTimeout(function () {
        document.getElementById("banner-left-vertical-main").classList.remove("active");
        document.getElementById("controller-play").classList.remove("active");
      }, 100);
      setTimeout(function () {
        document.getElementById("play").textContent = "Jouer";
        document.getElementById("amq").textContent = "BLIND TEST ANIME";
      }, 50);
    } else if (location.pathname == "/fr/testroom") {
      document.getElementById("router").classList.add("test");
      document.getElementById("router").classList.add("active2");
      global.pageTransition = 1;
      setTimeout(function () {
        router.push({ name: "testhome" });
      }, 100);
    } else if (location.pathname == "/fr/testbattle-pass") {
      global.pageTransition = 2;
      document.getElementById("router").classList.add("translateY");
      document.getElementById("router").classList.add("test");
      setTimeout(function () {
        router.push({ name: "testhome" });
        document.getElementById("logo").classList.add("active");
      }, 100);
    } else {
      router.push({ name: "testhome" });
    }
  }
</script>
<style scoped>
  @keyframes slideInFromTop {
    0% {
      transform: translate(-50%, -200%);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, -150%);
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -150%);
      opacity: 0;
    }
  }
  #logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 150px;
    opacity: 0;
    transition: 0.1s;
  }
  #logo.active {
    transform: translate(-50%, -50%);
    opacity: 1;
    transition: 0.4s;
  }
  /* -- Header Section CSS Start -- */

  .custom-container {
    padding: 0 32px;
    margin: 0 auto;
  }
  h6 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 18.48px;
    color: var(--text-color-4);
    /* font-family: "VALORANT"; */
  }
  .banner-loop-third .banner-left-vertical-main h6 {
    color: var(--text-color-14);
  }
  .banner-loop-second .banner-left-vertical-main h6 {
    color: var(--text-color-7);
  }
  .banner-left-vertical-main h6 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }
  .banner-left-vertical-main img {
    width: 70px;
    height: 70px;
  }
  .banner-left-vertical-main {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .banner-left-vertical-main {
    transition: transform 0.3s, opacity 0.3s;
  }
  .banner-left-vertical-main.active {
    transform: translate(-100%);
  }
  .banner-left-vertical-main.active {
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0;
  }
  #back {
    transition: 0.4s;
    opacity: 0;
    pointer-events: none;
  }
  #back.active {
    transition: 0.2s;
    opacity: 1;
    pointer-events: all;
  }
  .header-section-main {
    position: fixed;
    padding-top: 40px;
    width: 100%;
    z-index: 99;
  }
  .header-section-inner {
    display: flex;
    justify-content: space-between;
    height: 60px;
  }
  .header-logo {
    flex-shrink: 0;
    user-select: none;
  }
  .header-logo .banner-left-vertical-main {
    justify-content: center;
    height: calc(100vh - 196px);
  }
  .header-logo img {
    width: 60px;
    height: 60px;
  }
  .header-menu {
    width: 80%;
  }
  .header-menu ul {
    gap: 90px;
    justify-content: end;
    list-style: none;
  }

  .header-menu ul li a {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    text-transform: uppercase;
    font-style: normal;
    color: var(--text-color-1);
    display: inline-block;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    /* font-family: "VALORANT"; */
  }
  .header-menu ul li a:hover,
  .header-menu ul li.active a {
    color: var(--light-t1);
  }
  .header-menu ul li a:hover:after,
  .header-menu ul li.active a:after {
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    opacity: 1;
  }
  .small {
    border: 1px solid rgba(187, 177, 231, 0.62);
    border-radius: 100%;
  }
  .header-menu ul li a:after {
    content: "";
    position: absolute;
    bottom: -50px;
    left: 50%;
    display: block;
    background: url("https://www.yudiz.com/codepen/valorant-characters/menu-hover-icon.svg") center center / cover;
    width: 70px;
    height: 70px;
    transform: translate(-50%, 30px);
    -webkit-transform: translate(-50%, 30px);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    opacity: 0;
  }

  .header-right-icon {
    flex-shrink: 0;
  }
  .header-right-icon img {
    width: 21px;
    height: 8px;
  }

  .header-section-orange .header-logo img {
    filter: hue-rotate(160deg);
  }
  .header-section-orange .header-menu ul li a {
    color: var(--text-color-7);
  }
  .header-section-orange .header-right-icon img {
    filter: hue-rotate(141deg);
  }
  .header-section-orange .header-menu ul li a:after {
    background: url(https://www.yudiz.com/codepen/valorant-characters/menu-hover-icon-2.svg) center center / cover;
  }
  .header-section-orange .header-menu ul li a:hover,
  .header-section-orange .header-menu ul li.active a {
    color: var(--text-color-8);
  }

  .header-section-green .header-logo img {
    filter: hue-rotate(197deg);
  }
  .header-section-green .header-menu ul li a {
    color: var(--text-color-14);
  }
  .header-section-green .header-right-icon img {
    filter: hue-rotate(217deg);
  }
  .header-section-green .header-menu ul li a:after {
    background: url(https://www.yudiz.com/codepen/valorant-characters/menu-hover-icon-3.svg) center center / cover;
  }
  .header-section-green .header-menu ul li a:hover,
  .header-section-green .header-menu ul li.active a {
    color: var(--text-color-14);
  }

  /* -- Header Section CSS End -- */
</style>

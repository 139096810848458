<template>
  <ModalReuse2 :modal-active="activeModal" @close-modal="toggleModal"
    ><div class="w-full h-full flex items-center justify-center">
      <section @click.stop class="flex items-center justify-center cursor-auto mx-2 w-full max-w-[1135px]">
        <div
          class="relative col-span-full overflow-hidden md:col-span-4 bg-[#02041f] shadow-md rounded-3xl w-full mx-4 sm:mx-14 max-w-[1023px] cursor-auto p-[30px]">
          <h1>Ajouter un anime</h1>
          <div class="flex">
            <v-text-field
              class="mt-[10px] mr-[10px] min-w-[150px] h-[60px] w-[50px]"
              label="MAL ID"
              type="number"
              v-model="infoMalUnique"></v-text-field>
            <v-btn class="button-reverse text-white" @click="btnSearch(infoMalUnique, 1)">Ajouter</v-btn>
          </div>
          <h1>Ajouter des animes</h1>
          <div class="flex">
            <v-text-field
              class="mt-[10px] mr-[10px] min-w-[150px] h-[60px] w-[50px]"
              label="MAL ID du premier anime"
              type="number"
              v-model="infoMalFirst"></v-text-field>
            <v-text-field
              class="mt-[10px] mr-[10px] min-w-[150px] h-[60px] w-[50px]"
              label="MAL ID du dernier anime"
              type="number"
              v-model="infoMalLast"></v-text-field>
            <v-btn class="button-reverse text-white" @click="btnSearch(infoMalFirst, 2)">Ajouter</v-btn>
          </div>
          <v-progress-circular
            class="mr-2"
            v-if="loading"
            :size="40"
            color="#00bdbf"
            indeterminate></v-progress-circular>
          <p v-if="infoResponse && infoResponse != 404" class="text-green-500">
            Anime {{ infoMalUnique ? infoMalUnique : infoMalFirst }} ajouté !
          </p>
          <p v-else-if="infoResponse == 404" class="text-red-500">
            Anime {{ infoMalUnique ? infoMalUnique : infoMalFirst }} erreur 404 !
          </p>
        </div>
      </section>
    </div></ModalReuse2
  >
  <v-card flat class="mb-10 bg-[#0f112b]">
    <v-card-title class="flex align-center pe-2">
      <v-icon icon="mdi-video-input-component"></v-icon> &nbsp; Gérer les animes

      <v-spacer></v-spacer>
      <v-progress-circular
        class="mr-2"
        v-if="updateSearchLoad"
        :size="40"
        color="#00bdbf"
        indeterminate></v-progress-circular>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 21 21"
        v-else
        @click="updateSearch"
        class="cursor-pointer mr-2">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="m6.5 10.5l4-4l4 4m-4-4v11m-7-14h14" />
      </svg>
      <v-progress-circular class="mr-2" v-if="!items[0]" :size="40" color="#00bdbf" indeterminate></v-progress-circular>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        @click="reloadTab"
        class="cursor-pointer mr-2">
        <path
          fill="currentColor"
          d="M4 20v-2h2.75l-.4-.35q-1.225-1.225-1.787-2.662T4 12.05q0-2.775 1.663-4.937T10 4.25v2.1Q8.2 7 7.1 8.563T6 12.05q0 1.125.425 2.188T7.75 16.2l.25.25V14h2v6zm10-.25v-2.1q1.8-.65 2.9-2.212T18 11.95q0-1.125-.425-2.187T16.25 7.8L16 7.55V10h-2V4h6v2h-2.75l.4.35q1.225 1.225 1.788 2.663T20 11.95q0 2.775-1.662 4.938T14 19.75" />
      </svg>
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        density="compact"
        label="Search"
        single-line
        flat
        clearable
        hide-details
        variant="solo-filled"></v-text-field>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        class="cursor-pointer ml-2"
        color="white"
        @click="toggleModal(1)">
        <path
          fill="currentColor"
          d="M11 13v3q0 .425.288.713T12 17q.425 0 .713-.288T13 16v-3h3q.425 0 .713-.288T17 12q0-.425-.288-.712T16 11h-3V8q0-.425-.288-.712T12 7q-.425 0-.712.288T11 8v3H8q-.425 0-.712.288T7 12q0 .425.288.713T8 13zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22" />
      </svg>
    </v-card-title>

    <v-divider></v-divider>
    <v-data-table
      v-model:search="search"
      :items="items"
      v-model:sort-by="sortBy"
      :items-per-page-options="[
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ]">
      <!-- eslint-disable-next-line -->
      <template v-slot:item.poster="{ item }">
        <a :href="'/anime/' + item.url" target="_blank">
          <v-card class="my-2" elevation="2" rounded>
            <v-img :src="`/images/thumbnail/${item.poster}`" height="90" cover></v-img>
          </v-card>
        </a>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.name_default>
        <div>Titre</div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name_default="{ item }">
        <a :href="'/anime/' + item.url" target="_blank">{{ item.name_default }}</a>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.mal_id>
        <div>Editer</div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.mal_id="{ item }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          class="cursor-pointer"
          @click.self="btnSearch(item.mal_id, 0, $event)">
          <path
            fill="currentColor"
            d="M4 20v-2h2.75l-.4-.35q-1.225-1.225-1.787-2.662T4 12.05q0-2.775 1.663-4.937T10 4.25v2.1Q8.2 7 7.1 8.563T6 12.05q0 1.125.425 2.188T7.75 16.2l.25.25V14h2v6zm10-.25v-2.1q1.8-.65 2.9-2.212T18 11.95q0-1.125-.425-2.187T16.25 7.8L16 7.55V10h-2V4h6v2h-2.75l.4.35q1.225 1.225 1.788 2.663T20 11.95q0 2.775-1.662 4.938T14 19.75" />
        </svg>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:header.id> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.id> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.name_en> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name_en> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.name_fr> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name_fr> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.name_es> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name_es> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.name_de> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name_de> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.name_jp> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name_jp> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.other_name> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.other_name> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.background_image> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.background_image> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.studio> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.studio> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.avg_time> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.avg_time> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.description> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.description> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.genre> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.genre> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.trailer> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.trailer> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.url> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.url> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.day_out> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.day_out> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.theme> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.theme> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.origine> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.origine> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.fin> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.fin> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.averti> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.averti> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.nbr_ep> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.nbr_ep> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.type_format> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.type_format> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.synopsis_mal> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.synopsis_mal> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.season> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.season> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.year> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.year> </template>
    </v-data-table>
  </v-card>
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import { useFetch } from "@vueuse/core";
  import ModalReuse2 from "@/components/ModalReuse2.vue";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();

  const search = ref("");
  const items = ref([]);
  const sortBy = ref([{ key: "statut", order: "desc" }]);
  const infoMalUnique = ref(null);
  const infoMalFirst = ref(null);
  const infoMalLast = ref(null);
  const infoResponse = ref(null);
  const activeModal = ref(false);
  const loading = ref(false);
  const updateSearchLoad = ref(false);

  const toggleModal = (toggle) => {
    if (toggle) {
      activeModal.value = true;
    } else {
      activeModal.value = false;
    }
  };

  onMounted(async () => {
    global.pageLoad();
    const { data: response } = await useFetch("/api/admin/anime/manage/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    items.value = JSON.parse(response.value);
  });
  async function reloadTab() {
    items.value = [];
    const { data: response } = await useFetch("/api/admin/anime/manage/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    items.value = JSON.parse(response.value);
  }
  async function updateSearch() {
    updateSearchLoad.value = true;
    await useFetch("/api/admin/anime/manage/list", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    updateSearchLoad.value = false;
  }
  async function btnSearch(mal_id, info, event) {
    if (info == 1) {
      infoMalFirst.value = null;
      infoMalLast.value = null;
      loading.value = true;
    } else if (info == 2) {
      infoMalUnique.value = null;
      loading.value = true;
    } else {
      event.target.classList.add("rotate");
      infoMalFirst.value = null;
      infoMalLast.value = null;
      infoMalUnique.value = null;
      const confirmation = confirm("Êtes-vous sûr de vouloir mettre à jour cet anime ?");
      if (!confirmation) {
        return;
      }
    }
    if (mal_id != "") {
      let personnage = [];
      let tempo;
      let xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.jikan.moe/v4/anime/" + mal_id + "/characters");
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          var reponse = JSON.parse(xhr.responseText);
          if (reponse.status != 404 && reponse.status != 408) {
            for (let i = 0; i < reponse.data.length; i++) {
              tempo = {};
              tempo.role = reponse.data[i].role;
              tempo.nom = inverseEtConcatene(reponse.data[i].character.name);
              tempo.photo = reponse.data[i].character.images.webp.image_url;
              tempo.mal_id_character = reponse.data[i].character.mal_id;
              for (let j = 0; j < reponse.data[i].voice_actors.length; j++) {
                if (reponse.data[i].voice_actors[j].language == "Japanese") {
                  tempo.voicesVO = inverseEtConcatene(reponse.data[i].voice_actors[j].person.name);
                }
                if (reponse.data[i].voice_actors[j].language == "French") {
                  tempo.voicesVF = inverseEtConcatene(reponse.data[i].voice_actors[j].person.name);
                }
              }
              personnage.push(tempo);
            }
          }
          suite(personnage, mal_id, event, info);
        }
      };
      xhr.send();
    }
  }
  async function translateText(text) {
    const url = "https://translate.googleapis.com/translate_a/single";
    const params = new URLSearchParams({
      client: "gtx",
      sl: "en",
      tl: "fr",
      dt: "t",
      q: text,
    });

    try {
      const response = await fetch(`${url}?${params.toString()}`);
      if (response.ok) {
        const data = await response.json();
        let translation = "";
        for (let i = 0; data[0].length > i; i++) {
          translation += data[0][i][0];
        }
        return translation;
      } else {
        console.error("Error:", response.status);
        return null;
      }
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  async function suite(personnage, mal_id, event, info) {
    var studios = "";
    var titleFrench = "";
    var title_synonyms = "";
    var demographics = "";
    var duration = "";
    var aired = "";
    var airedTo = "";
    var themes = "";
    var genres = "";
    var ann = 0;
    var anidb = 0;
    var relations = [];
    let xhr = new XMLHttpRequest();
    xhr.open("GET", "https://api.jikan.moe/v4/anime/" + mal_id + "/full");
    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4) {
        var reponse = JSON.parse(xhr.responseText);
        if (
          reponse.status != 404 &&
          reponse.data.images.webp.large_image_url !=
            "https://cdn.myanimelist.net/img/sp/icon/apple-touch-icon-256.png" &&
          reponse.data.images.webp.large_image_url !=
            // eslint-disable-next-line
            "https:\/\/cdn.myanimelist.net\/img\/sp\/icon\/apple-touch-icon-256.png"
        ) {
          for (let i = 0; i < reponse.data.studios.length; i++) {
            studios += reponse.data.studios[i].name + ",";
          }
          if (reponse.data.titles) {
            for (let i = 0; i < reponse.data.titles.length; i++) {
              if (reponse.data.titles[i].type == "French") {
                titleFrench = reponse.data.titles[i].title;
              }
            }
          }
          for (let i = 0; i < reponse.data.title_synonyms.length; i++) {
            title_synonyms += reponse.data.title_synonyms[i] + ":space:";
          }
          if (reponse.data.external) {
            for (let i = 0; i < reponse.data.external.length; i++) {
              if (reponse.data.external[i].name == "AniDB") {
                anidb = reponse.data.external[i].url;
                anidb = anidb.replace("https://anidb.net/perl-bin/animedb.pl?show=anime&aid=", "");
              }
              if (reponse.data.external[i].name == "ANN") {
                ann = reponse.data.external[i].url;
                ann = ann.replace("https://www.animenewsnetwork.com/encyclopedia/anime.php?id=", "");
              }
            }
          }
          for (let i = 0; i < reponse.data.themes.length; i++) {
            themes += reponse.data.themes[i].name + ",";
          }
          if (themes.length) {
            themes = themes.replace("Adult Cast", "Personnage adulte");
            themes = themes.replace("Anthropomorphic", "Anthropomorphe");
            themes = themes.replace("CGDCT", "Mignon");
            themes = themes.replace("Childcare", "Garderie");
            themes = themes.replace("Combat Sports", "Sport de Combat");
            themes = themes.replace("Crossdressing", "Travestissement");
            themes = themes.replace("Delinquents", "Délinquance");
            themes = themes.replace("Detective", "Détective");
            themes = themes.replace("Educational", "Éducatif");
            themes = themes.replace("Gag Humor,", "");
            themes = themes.replace("Gag Humor", "");
            themes = themes.replace("High Stakes Game", "Jeu dangereux");
            themes = themes.replace("Historical,", "");
            themes = themes.replace("Historical", "");
            themes = themes.replace("Idols (Female)", "Idols");
            themes = themes.replace("Idols (Male)", "Idols");
            themes = themes.replace("Isekai,", "");
            themes = themes.replace("Isekai", "");
            themes = themes.replace("Love Polygon", "Triangle amoureux");
            themes = themes.replace("Magical Sex Shift,", "");
            themes = themes.replace("Magical Sex Shift", "");
            themes = themes.replace("Mahou Shoujo", "Magical girl");
            themes = themes.replace("Martial Arts", "Arts martiaux");
            themes = themes.replace("Mecha,", "");
            themes = themes.replace("Mecha", "");
            themes = themes.replace("Medical", "Médical");
            themes = themes.replace("Military", "Militaire");
            themes = themes.replace("Music,", "");
            themes = themes.replace("Music", "");
            themes = themes.replace("Mythology", "Mythologie");
            themes = themes.replace("Organized Crime", "Crime organisé");
            themes = themes.replace("Otaku Culture", "Otaku");
            themes = themes.replace("Parody", "Parodie");
            themes = themes.replace("Performing Arts", "Sur scène");
            themes = themes.replace("Pets", "Animaux");
            themes = themes.replace("Psychological,", "");
            themes = themes.replace("Psychological", "");
            themes = themes.replace("Racing", "Course");
            themes = themes.replace("Reincarnation", "Réincarnation");
            themes = themes.replace("Reverse Harem", "Harem inversé");
            themes = themes.replace("Romantic Subtext,", "");
            themes = themes.replace("Romantic Subtext", "");
            themes = themes.replace("Samurai", "Samouraï");
            themes = themes.replace("School", "Ecole");
            themes = themes.replace("Showbiz", "Showbiz");
            themes = themes.replace("Space", "Espace");
            themes = themes.replace("Strategy Game", "Jeu de stratégie");
            themes = themes.replace("Super Power", "Super Pouvoir");
            themes = themes.replace("Survival", "Survie");
            themes = themes.replace("Team Sports", "Sport d'équipe");
            themes = themes.replace("Time Travel", "Voyage temporel");
            themes = themes.replace("Vampire", "Vampire");
            themes = themes.replace("Video Game", "Jeux vidéo");
            themes = themes.replace("Visual Arts", "Art");
            themes = themes.replace("Workplace", "Travail");
          }
          if (reponse.data.demographics[0]) {
            demographics = reponse.data.demographics[0].name;
          }
          for (let i = 0; i < reponse.data.genres.length; i++) {
            genres += reponse.data.genres[i].name + ",";
          }
          if (genres.length) {
            genres = genres.toLowerCase();
            genres = genres.replace("adventure", "aventure");
            genres = genres.replace("avant garde,", "");
            genres = genres.replace("award winning,", "");
            genres = genres.replace("boys love,", "");
            genres = genres.replace("comedy", "comedie");
            genres = genres.replace("drama", "drame");
            genres = genres.replace("fantasy", "fantastique");
            genres = genres.replace("girls love,", "");
            genres = genres.replace("gourmet,", "");
            genres = genres.replace("horror", "horreur");
            genres = genres.replace("mystery", "mystere");
            genres = genres.replace("sci-fi", "sci_fi");
            genres = genres.replace("slice of life", "tranche_de_vie");
            genres = genres.replace("sports", "sport");
            genres = genres.replace("surnatural", "surnaturel");
            genres = genres.replace("suspense", "thriller");
            genres = genres.replace("erotica", "erotique");

            if (demographics.includes("Seinen")) {
              genres += "seinen,";
            }
            if (demographics.includes("Josei")) {
              genres += "josei,";
            }
            if (demographics.includes("Shounen")) {
              genres += "shonen,";
            }
            if (demographics.includes("Shoujo")) {
              genres += "shojo,";
            }
            if (themes.includes("Mecha")) {
              genres += "mecha,";
            }
            if (themes.includes("Historical")) {
              genres += "historique,";
            }
            if (themes.includes("Isekai")) {
              genres += "isekai,";
            }
            if (themes.includes("Psychological")) {
              genres += "psychologique,";
            }
            if (themes.includes("Music")) {
              genres += "musique,";
            }
          }

          if (reponse.data.aired.from != null) {
            aired = new Date(reponse.data.aired.from);
          }
          if (reponse.data.duration) {
            if (reponse.data.duration.search("hr") == -1) {
              duration = reponse.data.duration.replace(" per ep", "");
            } else {
              duration = reponse.data.duration.replace(" 0 min", " 00");
              duration = duration.replace(" 1 min", " 01");
              duration = duration.replace(" 2 min", " 02");
              duration = duration.replace(" 3 min", " 03");
              duration = duration.replace(" 4 min", " 04");
              duration = duration.replace(" 5 min", " 05");
              duration = duration.replace(" 6 min", " 06");
              duration = duration.replace(" 7 min", " 07");
              duration = duration.replace(" 8 min", " 08");
              duration = duration.replace(" 9 min", " 09");
              duration = duration.replace(" hr ", "h");
              duration = duration.replace(" min", "");
            }
          }
          for (let i = 0; i < reponse.data.relations.length; i++) {
            for (let h = 0; h < reponse.data.relations[i].entry.length; h++) {
              relations.push({
                relation: reponse.data.relations[i].relation,
                type: reponse.data.relations[i].entry[h].type,
                mal_id: reponse.data.relations[i].entry[h].mal_id,
              });
            }
          }
          if (reponse.data.aired.to) {
            airedTo = new Date(reponse.data.aired.to);
          }
          let type = reponse.data.type;
          if (type == "ONA") {
            type = "ona";
          }
          if (type == "OVA") {
            type = "oav";
          }
          if (type == "Special") {
            type = "special";
          }
          if (type == "Movie") {
            type = "film";
          }

          let source = reponse.data.source;
          if (source == "Original") {
            source = "Oeuvre original";
          }
          if (source == "Game") {
            source = "Jeu video";
          }
          if (aired) {
            aired = `${aired.getFullYear()}-${padZero(aired.getMonth() + 1)}-${padZero(aired.getDate())}T${padZero(
              aired.getHours()
            )}:${padZero(aired.getMinutes())}`;
          }

          if (airedTo) {
            airedTo = `${airedTo.getFullYear()}-${padZero(airedTo.getMonth() + 1)}-${padZero(airedTo.getDate())}`;
          }

          let rating = reponse.data.rating;
          if (rating == "Rx - Hentai") {
            rating = 18;
          }
          if (rating == "R+ - Mild Nudity") {
            rating = 16;
          }
          if (rating == "R - 17+ (violence & profanity)") {
            rating = 12;
          }
          if (rating == "PG - Children") {
            rating = 1;
          }
          let synopsisFR = "";
          if (reponse.data.synopsis) {
            synopsisFR = await translateText(reponse.data.synopsis);
          }
          await useFetch("/api/admin/anime/manage/add/auto", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              mal_id: mal_id,
              relations: relations,
              title: reponse.data.title,
              titleEnglish: reponse.data.title_english,
              title_japanese: reponse.data.title_japanese,
              title_synonyms: title_synonyms,
              titleFrench: titleFrench,
              type: type,
              episodes: reponse.data.episodes,
              duration: duration,
              source: source,
              aired: aired,
              airedTo: airedTo,
              studios: studios,
              genresMal: genres,
              themes: themes,
              rating: rating,
              trailer: reponse.data.trailer.youtube_id,
              synopsis: reponse.data.synopsis,
              season: reponse.data.season,
              year: reponse.data.year,
              poster: reponse.data.images.webp.large_image_url,
              popularity: reponse.data.popularity,
              rank: reponse.data.rank,
              ann: ann,
              anidb: anidb,
              personnage: personnage,
              synopsisFR: synopsisFR,
            }),
          });
          infoResponse.value = 1;
          loading.value = false;
          setTimeout(function () {
            infoResponse.value = 0;
          }, 3000);
          if (!info) {
            console.log("ok");
            event.target.classList.remove("rotate");
          }

          if (infoMalFirst.value && infoMalLast.value && !infoMalUnique.value) {
            infoMalFirst.value++;
            if (infoMalFirst.value < infoMalLast.value) {
              setTimeout(function () {
                btnSearch(infoMalFirst.value, 2);
              }, 2000);
            }
          }
        } else {
          infoResponse.value = 404;
          loading.value = false;
          setTimeout(function () {
            infoResponse.value = 0;
          }, 3000);
          if (!info) {
            console.log("err");
            event.target.classList.remove("rotate");
          }

          if (infoMalFirst.value && infoMalLast.value && !infoMalUnique.value) {
            infoMalFirst.value++;
            if (infoMalFirst.value < infoMalLast.value) {
              setTimeout(function () {
                btnSearch(infoMalFirst.value, 2);
              }, 2000);
            }
          }
        }
      }
    };
    xhr.send();
  }
  function inverseEtConcatene(phrase) {
    // Divise la phrase en un tableau de mots
    var mots = phrase.split(", ");

    // Inverse l'ordre des mots
    var motsInverses = mots.reverse();

    // Rejoint les mots inversés avec un espace entre eux
    var resultat = motsInverses.join(" ");

    return resultat;
  }
  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }
</script>
<style scoped>
  .rotate {
    animation: rotate-animation 1s infinite linear;
  }
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .red {
    color: red;
  }
  .orange {
    color: orange;
  }
  .green {
    color: lightgreen;
  }
  :deep(.v-card-title),
  :deep(.v-data-table),
  :deep(.v-field),
  :deep(.v-card) {
    background: #0f112b;
    color: white;
  }
  :deep(.v-chip--variant-tonal) {
    color: auto;
  }
</style>

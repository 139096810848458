<template>
  <div class="flex flex-col items-center mx-auto calc2rem mb-24 md:mb-0">
    <div class="w-full">
      <div class="font-medium text-xl md:text-2xl mb-3 pt-2 mt-4 md:mt-0">{{ $t("settings.title") }}</div>
      <router-link
        to="/admin"
        v-if="auth.user.role == 'admin'"
        class="text-sm hover:bg-[#0f112b] px-4 py-2 cursor-pointer flex items-center"
        ><Icon name="material-symbols:admin-panel-settings" size="1.6rem" class="mr-3" />Espace admin</router-link
      >
      <div class="mt-5 ml-4">
        <div class="font-medium leading-5 my-1 text-lg">{{ $t("settings.pseudoLabel") }}</div>
        <div class="font-medium leading-5 my-1 text-gray-300">{{ username }}</div>
      </div>
      <div class="mt-5 ml-4">
        <div class="font-medium leading-5 my-1 text-lg">{{ $t("settings.emailLabel") }}</div>
        <div class="font-medium leading-5 my-1 text-gray-300">{{ email }}</div>
      </div>
      <div class="mt-5 ml-4">
        <div class="font-medium leading-5 mb-1 text-lg">{{ $t("settings.avatarLabel") }}</div>
        <v-file-input
          :label="$t('settings.select')"
          class="mt-4 max-w-[400px] cursor-pointer"
          accept=".png,.jpeg,.jpg,.webp"
          v-model="avatar"
          @change="onFileChanged($event)"></v-file-input>
      </div>
      <div class="ml-4">
        <div class="font-medium leading-5 my-1 text-lg">{{ $t("settings.animeListLabel") }}</div>
        <v-select
          v-model="prive"
          label="Select"
          :items="items"
          class="mt-4 max-w-[400px] cursor-pointer"
          item-title="title"
          item-value="id"></v-select>
      </div>
      <div class="ml-4">
        <div class="font-medium leading-5 mt-1 text-lg">{{ $t("settings.search") }}</div>
        <v-switch v-model="nsfw" :label="$t('settings.display')" color="#00bdbf"></v-switch>
        <v-btn class="button-reverse text-white mt-[-16px]" @click="sendAvatar">{{ $t("settings.saveButton") }}</v-btn>
      </div>
    </div>
    <div class="w-full mt-4">
      <div class="font-medium text-xl md:text-2xl pt-2 mb-3 md:mt-0">{{ $t("settings.import") }}</div>
      <div class="mt-5 ml-4">
        <div class="font-medium leading-5 my-1">
          {{ $t("settings.download") }}
        </div>
        <a href="https://myanimelist.net/panel.php?go=export" target="_blank" class="text-blue-400"
          >https://myanimelist.net/panel.php?go=export</a
        >
        <div class="font-medium leading-5 my-1 mt-2">{{ $t("settings.once") }}</div>
        <v-file-input
          :label="$t('settings.select')"
          class="mt-4 max-w-[400px] cursor-pointer"
          accept=".gz, .xml"
          v-model="list"
          @change="onFileChangedMAL($event)"></v-file-input>
        <v-btn class="button-reverse text-white" @click="sendList">{{ $t("settings.saveButton") }}</v-btn>
        <v-progress-circular v-if="loading" :size="40" color="#00bdbf" indeterminate></v-progress-circular>
      </div>
    </div>
    <div class="w-full mt-4 md:mb-12">
      <div class="font-medium text-xl md:text-2xl pt-2 mb-3 md:mt-0">{{ $t("settings.importAni") }}</div>
      <div class="mt-5 ml-4">
        <div class="font-medium leading-5 my-1">
          {{ $t("settings.ani") }}
        </div>
        <a href="https://malscraper.azurewebsites.net" target="_blank" class="text-blue-400"
          >https://malscraper.azurewebsites.net</a
        >
        <div class="font-medium leading-5 my-1 mt-2">{{ $t("settings.ani2") }}</div>
        <div class="font-medium leading-5 my-1 mt-2">{{ $t("settings.ani3") }}</div>
        <div class="font-medium leading-5 my-1 mt-3">{{ $t("settings.example") }}</div>
        <img src="/images/global/anilist.png" class="max-w-[400px] w-full rounded-md my-2" />
      </div>
    </div>
    <v-snackbar v-model="snackbar" timeout="3000" class="text-center" color="#008ca8">
      <p class="font-medium text-center">{{ $t("my_list.status_updated") }}</p>
    </v-snackbar>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useGlobalStore } from "@/stores/global";
  import { useAuthStore } from "@/stores/auth";
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  const global = useGlobalStore();
  const auth = useAuthStore();
  const file = ref(null);
  const prive = ref(null);
  const nsfw = ref(false);
  const email = ref(null);
  const username = ref(null);
  const fileMAL = ref(null);
  const loading = ref(false);
  const avatar = ref(null);
  const list = ref(null);
  const snackbar = ref(false);
  const items = ref([
    {
      title: computed(() => t("settings.list.publicH")),
      id: 0,
    },
    {
      title: computed(() => t("settings.list.public")),
      id: 1,
    },
    {
      title: computed(() => t("settings.list.privateA")),
      id: 2,
    },
    {
      title: computed(() => t("settings.list.private")),
      id: 3,
    },
  ]);
  onMounted(async () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0HY7K2YF6F");

    const { data: response } = await useFetch("/api/settings/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const r = JSON.parse(response.value);
    prive.value = r[0].prive;
    if (r[0].nsfw == 1) {
      nsfw.value = true;
    } else {
      nsfw.value = false;
    }
    email.value = r[0].email;
    username.value = r[0].username;
    global.pageLoad();
  });
  function onFileChanged($event) {
    const target = $event.target;

    if (target && target.files) {
      file.value = target.files[0];
    }
  }
  function onFileChangedMAL($event) {
    const target = $event.target;

    if (target && target.files) {
      fileMAL.value = target.files[0];
    }
  }
  async function sendAvatar() {
    const formData = new FormData();

    formData.append("file", file.value);
    formData.append("prive", prive.value);
    formData.append("nsfw", nsfw.value);
    const { data: response } = await useFetch("/api/settings/avatar", {
      method: "post",
      body: formData,
    });
    if (response.value) {
      auth.user.avatar = response.value;
    }
    avatar.value = null;
    snackbar.value = true;
  }
  async function sendList() {
    loading.value = true;

    const formData = new FormData();

    formData.append("file", fileMAL.value);

    await useFetch("/api/settings/mal", {
      method: "post",
      body: formData,
    });
    loading.value = false;
    list.value = null;
    snackbar.value = true;
  }
</script>

<style scoped></style>

<template>
  <div id="scale" class="h-[100vh]" :class="{ mobile: page == 1 }">
    <div class="flex flex-col relative h-[70px] float game mb-[160px] mt-[155px]" id="small">
      <div
        class="text-[54px] text-center lg:text-start w-full absolute font-bold duration-500 uppercase"
        style="
          line-height: 1.1;
          filter: drop-shadow(black 2px 4px 6px);
          top: 10px;
          color: white;
          letter-spacing: 1.1px;
        ">
        Guess the
      </div>
      <div
        @click="go"
        class="text-[56px] text-center lg:text-start w-full absolute font-bold duration-500 uppercase"
        style="
          color: transparent;
          line-height: 1.5;
          filter: drop-shadow(black 2px 4px 6px);
          top: 45px;
          background: linear-gradient(90deg, rgb(168 0 106) 0px, rgb(253 247 255)) text;
          background-clip: text;
          font-weight: 900;
          letter-spacing: 1.5px;
        ">
        Anime
      </div>
      <div
        class="text-[54px] text-center lg:text-start w-full absolute font-bold duration-500 uppercase"
        style="
          line-height: 1.1;
          filter: drop-shadow(black 2px 4px 6px);
          top: 105px;
          color: white;
          letter-spacing: 1.1px;
        ">
        with the
      </div>
      <div
        @click="go"
        class="text-[56px] text-center lg:text-start w-full absolute font-bold duration-500 uppercase"
        style="
          color: transparent;
          line-height: 1.5;
          filter: drop-shadow(black 2px 4px 6px);
          top: 140px;
          background: linear-gradient(90deg, rgb(0, 189, 191) 0, #00fddc) text;
          background-clip: text;
          font-weight: 900;
          letter-spacing: 1.5px;
        ">
        picture
      </div>
    </div>
    <div class="flex justify-center mb-[25px]" id="container">
      <img
        id="canvas"
        :src="'https://baranimes.com/images/screenshots/' + var_photo"
        class="float shadow-3xl overflow-hidden rounded-xl duration-300" />
      <input id="blocks" @change="pixelate" type="range" min="1" max="100" value="2" class="hidden" />
      <img
        id="poster"
        :src="'https://baranimes.com/images/upload/' + var_poster"
        class="float overflow-hidden rounded-xl duration-300" />
    </div>
    <div class="px-5 float" id="res">
      <div class="result">
        <div
          class="text-[35px] text-center lg:text-start w-full duration-500 font-bold mt-[-15px]"
          style="line-height: 1.2; filter: drop-shadow(black 2px 4px 6px); color: white">
          {{ var_anime }}
        </div>
      </div>
      <div class="progress bg-linear px-9" id="progress" style="background: none">
        <v-progress-linear
          color="light-blue"
          height="25"
          :model-value="chrono"
          striped
          class="rounded-2xl shadow-3xl"></v-progress-linear>
        <div
          class="text-[30px] text-center lg:text-start w-full duration-500 font-bold mt-2"
          style="line-height: 1.2; filter: drop-shadow(black 2px 4px 6px); color: white">
          {{ nTiktok + 1 }}/8
        </div>
      </div>
    </div>
  </div>
  <div id="subPlayerContainer">
    <img :src="'https://baranimes.com/images/screenshots/' + pre_photo" />
    <input id="blocks" @change="pixelate" type="range" min="1" max="100" value="2" class="hidden" />
  </div>
  <svg class="defs">
    <defs>
      <symbol id="s-star" viewBox="0 0 210 210">
        <polygon
          points="98.4999978 153.75 38.2520165 185.424245 49.7583542 118.337123 1.01670635 70.8257603 68.3760155 61.037872 98.5000012 1.1379786e-14 128.624005 61.0378871 195.98331 70.8258091 147.241642 118.337136 158.747982 185.424247"
          transform="translate(10,10)"></polygon>
      </symbol>

      <radialGradient id="gr-radial-lights" cx="50%" cy="50%" r="70%">
        <stop stop-color="white" offset="5%" stop-opacity="1"></stop>
        <stop stop-color="white" offset="100%" stop-opacity="0"></stop>
      </radialGradient>
    </defs>
  </svg>
  <div class="demo demo--lights bg-linear duration-500" id="demo">
    <svg viebox="0 0 1024 640">
      <svg viewBox="0 0 640 640">
        <g transform="scale(1)">
          <circle
            class="c-lights"
            r="64%"
            cx="50%"
            cy="50%"
            stroke-width="128%"
            stroke-dasharray="10%"
            stroke="url(#gr-radial-lights)"></circle>
        </g>
      </svg>
    </svg>
  </div>
</template>

<script setup>
  import { ref, onMounted, onBeforeUnmount } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useHead } from "@unhead/vue";

  const $ = (selectors) => document.getElementById(selectors);
  const interval = {};
  const chrono = ref(0);
  const nTiktok = ref(7);
  const var_anime = ref(null);
  const var_poster = ref(null);
  const var_photo = ref(null);
  const firstReady = ref(false);
  const pre_anime = ref(null);
  const pre_poster = ref(null);
  const pre_photo = ref(null);
  const dejaload = ref(false);
  const page = ref(0);
  const playerDoublons = ref(null);
  let activePlayer = 0;
  let n = 0;
  let idSegment;
  let firstRep;
  let tempsDebut;
  let tempsAll = [];
  const videoReady = {
    player__1: 0,
    player__2: 0,
  };
  const videoPlayer = {
    player__1: "",
    player__2: "",
  };
  const videoPlayerStart = {
    player__1: 0,
    player__2: 0,
  };
  const videoPlayerEnd = {
    player__1: 0,
    player__2: 0,
  };
  useHead({
    script: {
      src: "https://www.youtube.com/iframe_api",
    },
  });
  onMounted(async () => {
    document.getElementById("app").style.background = "#00000000";

    document.getElementsByTagName("html")[0].classList.add("overflow-hidden");
    document.getElementsByClassName("nav")[0].classList.add("hidden");
    document.getElementsByClassName("progressbar")[0].classList.add("hidden");

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    page.value = params.get("mobile");
    if (page.value) {
      document.documentElement.requestFullscreen();
    }

    const myElement = document.querySelector("#vidline-ext-0.1.43");

    // Accéder au shadow root
    const shadowRoot = myElement.shadowRoot;
    // Créer une instance de MutationObserver
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          if (n == 2) {
            tempsDebut = Date.now();
            n = 0;
          }
        } else if (mutation.type === "characterData") {
          n++;
        }
      }
    });

    // Configuration de l'observation (types de mutations à observer)
    const config = { attributes: true, childList: true, subtree: true, characterData: true };

    // Commencer à observer le shadow root
    observer.observe(shadowRoot, config);
  });
  function go() {
    setTimeout(function () {
      loadVideo("player__1", 1);
      charg();
    }, 2500);
  }
  const ytTimeToSecond = (temps) => {
    var regex = /PT(\d+M)?(\d+S)?/;
    var match = temps.match(regex);

    if (!match) {
      console.error("Format de temps invalide");
      return 0;
    }

    var minutes = match[1] ? parseInt(match[1]) : 0;
    var secondes = match[2] ? parseInt(match[2]) : 0;

    var tempsEnSecondes = minutes * 60 + secondes;
    return tempsEnSecondes;
  };
  async function loadVideo(player) {
    const { data: response } = await useFetch("/api/admin/tiktok/character/video", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        doublons: playerDoublons.value,
        player: player,
        type: "Video",
      }),
    });
    const r = JSON.parse(response.value);
    if (player == "player__1" || player == "player__3") {
      playerDoublons.value = r[0].videoId;
    }
    let debut;
    let temps_elements = r[0].start.split(":");
    let hour = parseInt(temps_elements[0], 10);
    let minutes = parseInt(temps_elements[1], 10);
    let secondes = parseInt(temps_elements[2], 10);
    debut = hour * 3600 + minutes * 60 + secondes;
    if (r[0].fin != "00:00:00") {
      let temps_elements = r[0].fin.split(":");
      let hour = parseInt(temps_elements[0], 10);
      let minutes = parseInt(temps_elements[1], 10);
      let secondes = parseInt(temps_elements[2], 10);
      videoPlayerEnd[player] = hour * 3600 + minutes * 60 + secondes;
    } else {
      videoPlayerEnd[player] = ytTimeToSecond(r[0].duration);
    }
    if ($(player)) {
      videoPlayerStart[player] = debut;
      // $(player).remove();
      videoPlayer[player].loadVideoById({
        videoId: r[0].videoId,
      });
      videoReady[player] = 0;
    } else {
      let div = document.createElement("div");
      div.id = player;
      div.classList = "opacity-0 invisible w-0 pointer-events-none h-0 absolute";
      const playerContainer = $("subPlayerContainer");
      playerContainer.appendChild(div);
      videoReady[player] = 0;
      videoPlayerStart[player] = debut;
      // eslint-disable-next-line
      videoPlayer[player] = new YT.Player(player, {
        playerVars: {
          start: debut,
        },
        videoId: r[0].videoId,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
    }
  }
  const onPlayerReady = (e) => {
    let player = e.target.g.id;
    videoPlayer[player].playVideo();
    videoPlayer[player].setVolume(0);
  };

  const onPlayerStateChange = (e) => {
    let player = e.target.g.id;

    if (e.data == 1 && !videoReady[player]) {
      videoReady[player] = 1;
      videoPlayer[player].seekTo(videoPlayerStart[player]);
      videoPlayer[player].pauseVideo();
    }
    if (e.data == 2 && !firstReady.value) {
      test();
      firstReady.value = true;
    }
    // eslint-disable-next-line
    // if (e.data == YT.PlayerState.ENDED) {
    // if (activePlayer == 0) {
    //   videoPlayer["player__2"].playVideo();
    //   for (let i = 0; i < 100; i++) {
    //     setTimeout(function () {
    //       videoPlayer["player__2"].setVolume(i + 1);
    //     }, i * 10);
    //   }
    // } else {
    //   videoPlayer["player__4"].playVideo();
    //   for (let i = 0; i < 100; i++) {
    //     setTimeout(function () {
    //       videoPlayer["player__4"].setVolume(i + 1);
    //     }, i * 10);
    //   }
    // }
    // }
  };

  // This runs the demo animation to give an impression of
  // performance.
  // eslint-disable-next-line
  async function charg() {
    const { data: response } = await useFetch("/api/admin/tiktok/screenshot", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "Picture",
      }),
    });
    const r = JSON.parse(response.value);
    pre_anime.value = r[1][0].anime;
    pre_poster.value = r[1][0].poster;

    pre_photo.value = r[0].photo;
  }

  async function test() {
    if (!firstRep && nTiktok.value == 7) {
      firstRep = pre_anime.value;
    }
    dejaload.value = false;

    var_anime.value = pre_anime.value;
    setTimeout(function () {
      var_poster.value = pre_poster.value;
    }, 1000);
    var_photo.value = pre_photo.value;
    toggleAnim();
  }
  const checkVideoTime = () => {
    if (activePlayer == 0) {
      if (videoPlayer["player__1"].getCurrentTime() > videoPlayerEnd["player__1"] - 3) {
        clearInterval(interval["checkVideoTimeVar"]);
        for (let i = 0; i < 100; i++) {
          setTimeout(function () {
            videoPlayer["player__1"].setVolume(100 - i - 1);
          }, i * 20);
        }
        setTimeout(function () {
          videoPlayer["player__1"].pauseVideo();
        }, 2000);
        videoPlayer["player__2"].playVideo();
        for (let i = 0; i < 100; i++) {
          setTimeout(function () {
            videoPlayer["player__2"].setVolume(i + 1);
          }, i * 20);
        }
      }
    } else {
      if (videoPlayer["player__3"].getCurrentTime() > videoPlayerEnd["player__3"] - 5) {
        clearInterval(interval["checkVideoTimeVar"]);
        for (let i = 0; i < 100; i++) {
          setTimeout(function () {
            videoPlayer["player__3"].setVolume(100 - i - 1);
          }, i * 10);
        }
        setTimeout(function () {
          videoPlayer["player__3"].pauseVideo();
        }, 2000);
        videoPlayer["player__4"].playVideo();
        for (let i = 0; i < 100; i++) {
          setTimeout(function () {
            videoPlayer["player__4"].setVolume(i + 1);
          }, i * 10);
        }
      }
    }
  };
  async function setSegments() {
    if (idSegment) {
      await useFetch("/api/admin/tiktok/cut/segments", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tempsAll: tempsAll,
          idSegment: idSegment,
        }),
      });
    } else {
      const { data: response } = await useFetch("/api/admin/tiktok/cut/segments", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: firstRep,
          tempsAll: tempsAll,
          type: "Picture",
          idSegment: idSegment,
        }),
      });
      const r = JSON.parse(response.value);
      idSegment = r[0].id;
    }
  }
  function toggleAnim() {
    chrono.value = 0;
    nTiktok.value++;
    if (nTiktok.value == 8) {
      nTiktok.value = 0;
    }
    document
      .getElementById("demo")
      .classList.remove("bg-linear", "linear-orange", "linear-violet", "linear-green", "linear-red");
    document.getElementById("progress").classList.remove("bg-linear", "bg-orange", "bg-violet", "bg-green", "bg-red");

    if (nTiktok.value == 0) {
      document.getElementById("scale").classList.add("active");
      clearInterval(interval["checkVideoTimeVar"]);
      interval["checkVideoTimeVar"] = setInterval(checkVideoTime, 1000);
      const tempsFin = Date.now();
      if (!tempsDebut) {
        tempsDebut = tempsFin;
      }
      tempsAll.push((tempsFin - tempsDebut) / 1000);
      if (tempsAll.length > 1) {
        setSegments();
      }
      if (activePlayer == 0) {
        videoPlayer["player__1"]?.playVideo();
        for (let i = 0; i < 100; i++) {
          setTimeout(function () {
            videoPlayer["player__1"].setVolume(i + 1);
          }, i * 10);
        }
        videoPlayer["player__3"]?.pauseVideo();
        videoPlayer["player__4"]?.pauseVideo();
        loadVideo("player__2", 1);
      } else {
        videoPlayer["player__3"]?.playVideo();
        for (let i = 0; i < 100; i++) {
          setTimeout(function () {
            videoPlayer["player__3"].setVolume(i + 1);
          }, i * 10);
        }
        videoPlayer["player__1"]?.pauseVideo();
        videoPlayer["player__2"]?.pauseVideo();
        loadVideo("player__4", 1);
      }
      document.getElementById("demo").classList.add("bg-linear");
      document.getElementById("progress").classList.add("bg-linear");
    }
    if (nTiktok.value == 1) {
      document.getElementById("scale").classList.remove("active");
      document.getElementById("demo").classList.add("linear-orange");
      document.getElementById("progress").classList.add("bg-orange");
    }
    if (nTiktok.value == 2) {
      document.getElementById("demo").classList.add("linear-violet");
      document.getElementById("progress").classList.add("bg-violet");
      if (activePlayer == 0) {
        loadVideo("player__3", 1);
      } else {
        loadVideo("player__1", 1);
      }
    }
    if (nTiktok.value == 3) {
      document.getElementById("demo").classList.add("linear-green");
      document.getElementById("progress").classList.add("bg-green");
    }
    if (nTiktok.value == 4) {
      document.getElementById("demo").classList.add("linear-red");
      document.getElementById("progress").classList.add("bg-red");
    }
    if (nTiktok.value == 5) {
      document.getElementById("demo").classList.add("bg-linear");
      document.getElementById("progress").classList.add("bg-linear");
    }
    if (nTiktok.value == 6) {
      document.getElementById("demo").classList.add("linear-orange");
      document.getElementById("progress").classList.add("bg-orange");
    }
    if (nTiktok.value == 7) {
      document.getElementById("demo").classList.add("linear-violet");
      document.getElementById("progress").classList.add("bg-violet");
      activePlayer == 1 ? (activePlayer = 0) : (activePlayer = 1);
    }
    interval["countDownCircle"] = setInterval(() => {
      chrono.value++;
      if (chrono.value >= 40 && dejaload.value == false) {
        dejaload.value = true;
        charg();
      }
      if (chrono.value >= 108) {
        clearInterval(interval["countDownCircle"]);
        document.getElementById("container").classList.add("reveles");
        document.getElementById("res").classList.add("reveles");
        interval["test"] = setTimeout(function () {
          test();
        }, 2300);
      }
    }, 50);
    document.getElementById("container").classList.remove("reveles");
    document.getElementById("res").classList.remove("reveles");
  }

  // poly-fill for requestAnmationFrame with fallback for older
  // browsers which do not support rAF.
  window.requestAnimationFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();
  onBeforeUnmount(() => {
    clearTimeout(interval["test"]);
    clearInterval(interval["checkVideoTimeVar"]);
    clearInterval(interval["countDownCircle"]);
  });
</script>

<style scoped>
  .mobile .text-\[35px\] {
    font-size: 24px !important;
  }
  .mobile .text-\[30px\] {
    font-size: 21px !important;
  }
  @keyframes scaleAnim {
    0% {
      scale: 0;
    }
    30% {
      scale: 0;
    }
    55% {
      scale: 1.1;
    }
    95% {
      scale: 1;
    }
    100% {
      scale: 1;
    }
  }
  #scale.active {
    animation: scaleAnim 1.2s;
  }
  .mobile .mt-\[155px\] {
    margin-top: 105px !important;
  }
  .linear-orange {
    background: linear-gradient(to right, #ffca00 0, #ff9000);
  }
  .linear-violet {
    background: linear-gradient(to right, #d271f4 0, #8a28bb);
  }
  .linear-green {
    background: linear-gradient(to right, #4af095 0, #18bd63);
  }
  .linear-red {
    background: linear-gradient(to right, #f4677b 0, #d83049);
  }
  #res .result {
    display: none;
  }
  #res.reveles .result {
    display: block;
  }
  #res.reveles .progress {
    display: none;
  }
  #container #poster {
    width: 0;
  }
  #container #canvas {
    width: 85%;
  }

  #container.reveles #poster {
    width: 45%;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
  }
  #container.reveles #canvas {
    width: 0;
    box-shadow: none;
  }
  :deep(.v-progress-linear__background) {
    background: white !important;
    opacity: 0.5 !important ;
  }
  /* $("demo").classList.remove("bg-[#20b2aa]", "bg-[#ffa500]", "bg-[#ae47e2]", "bg-[#00ce5e]", "bg-[#fb405b]"); */

  .progress.bg-linear :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #03a9f4 !important    ;
  }
  .progress.bg-orange :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #ffa500 !important    ;
  }
  .progress.bg-violet :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #ae47e2 !important    ;
  }
  .progress.bg-green :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #00ce5e !important    ;
  }
  .progress.bg-red :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #fb405b !important    ;
  }

  @keyframes float {
    0% {
      transform: rotate(1deg) translateY(3px) translateX(2px);
    }

    50% {
      transform: rotate(-1deg) translateY(-2px) translateX(-1px);
    }

    100% {
      transform: rotate(1deg) translateY(3px) translateX(2px);
    }
  }
  .float {
    animation: float 4s ease-in-out infinite;
  }
  .defs {
    position: absolute;
    z-index: -2;
    top: 0;
  }

  .demo--lights {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(5px);
    z-index: -2;
  }
  svg {
    width: 100%;
    height: 100%;
  }

  .group--stars {
    transform: scale(0.9);
  }

  .star--outline {
    transform-origin: 75pxr 75px;
    transform: scale(1.2);
    fill: none;
    stroke: white;
    stroke-width: 2;
    stroke-dasharray: 4;
    animation: stardashoffset 2s infinite linear;
  }

  .group--stars-anim {
    use {
      transform-origin: 75pxr 75px;
      animation: starmove 8s infinite linear;
    }

    @for $item from 2 through 4 {
      use:nth-child(#{$item}) {
        transform: scale(1- ($item/5));
      }
    }
  }

  .c-lights {
    fill: none;
    transform-origin: 320px 320px;
    animation: rotation 20s infinite linear;
  }

  @keyframes starmove {
    $trans-min: 0.8;
    $trans-max: 1.2;

    $trans-min-offset: 0.7;
    $trans-max-offset: 1.3;

    0% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }

    12.5% {
      transform-origin: 75pxr 75px * $trans-min-offset * 0.95;
    }

    25% {
      transform-origin: 75pxr * $trans-max * 1.2 75px * $trans-min;
    }

    37.5% {
      transform-origin: 75pxr * $trans-max * 1.15 75px;
    }

    50% {
      transform-origin: 75pxr * $trans-max * 1.1 75px * $trans-max * 1.05;
    }

    65.5% {
      transform-origin: 75pxr 75px * $trans-max-offset * 1.1;
    }

    75% {
      transform-origin: 75pxr * $trans-min * 0.9 75px * $trans-max * 1.05;
    }

    87.5% {
      transform-origin: 75pxr * $trans-min-offset * 0.95 75px;
    }

    100% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }
  }

  @keyframes rotation {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes stardashoffset {
    100% {
      stroke-dashoffset: -32;
    }
  }
</style>

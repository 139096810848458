<template>
  <div>404</div>
</template>

<script setup>
  import { onMounted } from "vue";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();

  onMounted(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0HY7K2YF6F");
    global.pageLoad();
  });
</script>

<style scoped></style>

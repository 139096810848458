<template>
  <ModalReuse :modal-active="modalActive" @close-modal="toggleModal"></ModalReuse>
  <div class="flex flex-col items-center mx-auto calc2rem">
    <div class="justify-center flex flex-col items-center sm:p-2 lg:p-10 rounded-lg">
      <h1
        class="font-bold mb-4 uppercase text-transparent bg-clip-text text-4xl sm:text-6xl lg:text-7xl my-5 title text-center"
        style="-webkit-background-clip: text; margin-top: 40px">
        {{ $t("home.title") }}
      </h1>
      <p class="text-center sm:text-xl lg:text-2xl mb-6 font-medium hidden md:block">
        {{ $t("home.description") }}
      </p>
      <p class="text-center sm:text-xl lg:text-2xl mb-6 font-medium md:hidden">
        {{ $t("home.description_min") }}
      </p>
    </div>
    <button
      @click="toggleModal(1)"
      class="sm:max-w-none justify-center mt-[20px] mb-8 sm:mb-[50px] play py-[15px] sm:py-[30px] w-full md:w-auto md:px-[300px] text-white rounded-full text-3xl sm:text-5xl font-sans outline-none font-bold border-none cursor-pointer inline-flex bg-cover bg-center transition duration-300 shadow-2xl">
      {{ $t("home.play") }}
    </button>
    <section class="flex flex-col justify-center antialiased p-1 md:p-4 w-full">
      <div class="h-full">
        <div class="mx-auto md:max-w-5xl">
          <div class="md:grid md:grid-cols-12 md:gap-6 justify-items-center flex justify-between flex-wrap">
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-[48.5%] mb-[10px] md:mb-0 md:w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link to="/battle-pass">
                <div class="md:px-5 md:pb-6 p-3">
                  <div>
                    <v-img
                      loading="lazy"
                      src="/images/avatar_users/64f5044245c4c7.45257794.png"
                      alt="Emi"
                      class="h-[115px] mb-[5px] md:mb-[0px] md:h-[250px]" />
                  </div>

                  <button
                    class="whitespace-nowrap button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-1 rounded-md shadow-sm text-white w-full">
                    {{ $t("home.battle_pass") }}
                  </button>
                </div>
              </router-link>
            </div>

            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-[48.5%] mb-[10px] md:mb-0 md:w-full"
              style="
                background: rgb(255 255 255 / 5%);
                /* background: rgb(3 7 18 / 14%); */
                border-top: 3px solid #00fddc;
              ">
              <router-link to="/avatar" v-if="auth.user.role != 'guest'">
                <div class="md:px-5 md:pb-6 p-3">
                  <div style="text-align: -webkit-center">
                    <v-img
                      loading="lazy"
                      src="/images/avatar_users/64f5bb5d1a2fd9.30522874.png"
                      alt="Minori"
                      class="h-[115px] mb-[5px] md:mb-[0px] md:h-[250px]" />
                  </div>
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-1 rounded-md shadow-sm text-white w-full">
                    {{ $t("home.avatar") }}
                  </button>
                </div>
              </router-link>

              <div v-else @click="global.signUp = true" class="md:px-5 md:pb-6 p-3">
                <div style="text-align: -webkit-center">
                  <v-img
                    loading="lazy"
                    src="/images/avatar_users/64f5bb5d1a2fd9.30522874.png"
                    alt="Minori"
                    class="h-[115px] mb-[5px] md:mb-[0px] md:h-[250px]" />
                </div>
                <button
                  class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-1 rounded-md shadow-sm text-white w-full">
                  {{ $t("home.avatar") }}
                </button>
              </div>
            </div>

            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link to="/">
                <div class="md:px-5 md:pb-6 px-3 pb-4">
                  <div class="md:px-5 p-3 md:pb-5 flex justify-center md:pt-7">
                    <v-img
                      loading="lazy"
                      class="h-[110px] md:h-[214px] max-w-[225px]"
                      src="/images/global/shop.png"
                      alt="Shop" />
                  </div>
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    {{ $t("home.shop") }}
                  </button>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="w-full text-center mt-3 mb-24 md:mb-3">
    <div>
      {{ new Date().getFullYear() }} - <strong class="mr-1">Baranimes</strong>|
      <router-link to="/privacy" class="ml-1 underline">{{ $t("privacy.privacy") }}</router-link>
    </div>
  </div>
</template>

<script setup>
  // definePageMeta({
  //   middleware: "protected",
  // });

  import { useAuthStore } from "@/stores/auth";
  import { useGlobalStore } from "@/stores/global";
  import ModalReuse from "@/components/ModalReuse.vue";
  import { ref, computed, onMounted } from "vue";
  import { useSeoMeta } from "@unhead/vue";
  import { useI18n } from "vue-i18n";

  const { t } = useI18n();
  const global = useGlobalStore();
  const auth = useAuthStore();

  const modalActive = ref(null);
  useSeoMeta({
    title: computed(() => t("home.title") + " - Baranimes"),
    ogTitle: computed(() => t("home.title") + " - Baranimes"),
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: computed(() => t("home.title") + " - Baranimes"),
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
  onMounted(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0HY7K2YF6F");
    global.pageLoad();
  });
  const toggleModal = (toggle) => {
    if (toggle) {
      modalActive.value = true;
    } else {
      modalActive.value = false;
    }
  };
</script>

<style scoped>
  @keyframes quickPlayButtonEffect {
    100% {
      background-position-x: -200%;
    }
  }

  .title {
    background: -webkit-linear-gradient(to right, #008ca8 0%, #00fddc 100%);
    background: -moz-linear-gradient(to right, #008ca8 0%, #00fddc 100%);
    background: linear-gradient(to right, #008ca8 0%, #00fddc 100%);
  }
  .play {
    animation: quickPlayButtonEffect 5s infinite linear;
    background: linear-gradient(
      -60deg,
      #00fddc 0%,
      #00fddc 10%,
      #008ca8 25%,
      #008ca8 30%,
      #00fddc 50%,
      #008ca8 80%,
      #008ca8 85%,
      #00fddc 92%
    );
    background-size: 200% 100%;
  }

  .play:hover {
    box-shadow: 0px 15px 20px rgb(0 140 168 / 40%);
    transform: translateY(-7px);
  }
</style>

<template>
  <div id="scale" class="h-[100vh]" :class="{ mobile: page == 1 }">
    <audio
      id="audioPlayer"
      class="hidden"
      @error="playNextAudio"
      @canplaythrough="goPlay"
      @ended="playNextAudio"></audio>
    <div class="flex flex-col relative h-[70px] float game mb-[165px] mt-[125px]" id="small">
      <div
        class="text-[47px] text-center lg:text-start w-full absolute font-bold duration-500 uppercase"
        style="
          line-height: 1.1;
          filter: drop-shadow(black 2px 4px 6px);
          top: 10px;
          color: white;
          letter-spacing: 1.1px;
        ">
        Guess the
      </div>
      <div
        @click="go"
        class="text-[47px] text-center lg:text-start w-full absolute font-bold duration-500 uppercase"
        style="
          color: transparent;
          line-height: 1.5;
          filter: drop-shadow(black 2px 4px 6px);
          top: 38px;
          background: linear-gradient(90deg, rgb(0, 189, 191) 0, #00fddc) text;
          background-clip: text;
          font-weight: 900;
          letter-spacing: 1.5px;
        ">
        Anime
      </div>
      <div
        class="text-[47px] text-center lg:text-start w-full absolute font-bold duration-500 uppercase"
        style="
          line-height: 1.1;
          filter: drop-shadow(black 2px 4px 6px);
          top: 86px;
          color: white;
          letter-spacing: 1.1px;
        ">
        Voice
      </div>
    </div>
    <div class="flex justify-center mb-[10px] flex-wrap" id="container">
      <img
        :src="'https://baranimes.com/images/characters/' + var_photo"
        class="float overflow-hidden rounded-xl duration-100 canvas" />
      <img
        :src="'https://baranimes.com/images/characters/' + var_photo2"
        class="float overflow-hidden rounded-xl duration-100 canvas" />
      <img
        :src="'https://baranimes.com/images/characters/' + var_photo3"
        class="float overflow-hidden rounded-xl duration-100 canvas" />
      <img
        :src="'https://baranimes.com/images/characters/' + var_photo4"
        class="float overflow-hidden rounded-xl duration-100 canvas" />
      <input id="blocks" @change="pixelate" type="range" min="1" max="100" value="2" class="hidden" />
      <img
        id="poster"
        :src="'https://baranimes.com/images/upload/' + var_poster"
        class="float overflow-hidden rounded-xl duration-300" />
    </div>
    <div class="px-5 float" id="res">
      <div class="result">
        <div
          class="text-[35px] text-center lg:text-start w-full duration-500 font-bold mt-[-5px]"
          style="line-height: 1.2; filter: drop-shadow(black 2px 4px 6px); color: white">
          {{ var_character }}
        </div>
        <div
          class="text-[30px] text-center lg:text-start w-full duration-500"
          style="line-height: 1; filter: drop-shadow(black 2px 4px 6px); color: white">
          {{ var_anime }}
        </div>
      </div>
      <div class="progress bg-linear px-9" id="progress" style="background: none">
        <v-progress-linear
          color="light-blue"
          height="25"
          :model-value="chrono"
          striped
          class="rounded-2xl shadow-3xl"></v-progress-linear>
        <div
          class="text-[30px] text-center lg:text-start w-full duration-500 font-bold mt-2"
          style="line-height: 1.2; filter: drop-shadow(black 2px 4px 6px); color: white">
          {{ nTiktok + 1 }}/5
        </div>
      </div>
    </div>
  </div>
  <div id="subPlayerContainer"></div>
  <svg class="defs">
    <defs>
      <symbol id="s-star" viewBox="0 0 210 210">
        <polygon
          points="98.4999978 153.75 38.2520165 185.424245 49.7583542 118.337123 1.01670635 70.8257603 68.3760155 61.037872 98.5000012 1.1379786e-14 128.624005 61.0378871 195.98331 70.8258091 147.241642 118.337136 158.747982 185.424247"
          transform="translate(10,10)"></polygon>
      </symbol>
      <radialGradient id="gr-radial-lights" cx="50%" cy="50%" r="70%">
        <stop stop-color="white" offset="5%" stop-opacity="1"></stop>
        <stop stop-color="white" offset="100%" stop-opacity="0"></stop>
      </radialGradient>
    </defs>
  </svg>
  <div class="demo demo--lights bg-linear duration-500" id="demo">
    <svg viebox="0 0 1024 640">
      <svg viewBox="0 0 640 640">
        <g transform="scale(1)">
          <circle
            class="c-lights"
            r="64%"
            cx="50%"
            cy="50%"
            stroke-width="128%"
            stroke-dasharray="10%"
            stroke="url(#gr-radial-lights)"></circle>
        </g>
      </svg>
    </svg>
  </div>
</template>

<script setup>
  import { ref, onMounted, onBeforeUnmount } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useHead } from "@unhead/vue";

  const $ = (selectors) => document.getElementById(selectors);
  const interval = {};
  const chrono = ref(0);
  const nTiktok = ref(4);
  const var_character = ref(null);
  const var_anime = ref(null);
  const var_poster = ref(null);
  const var_photo = ref(null);
  const var_photo2 = ref(null);
  const var_photo3 = ref(null);
  const var_photo4 = ref(null);
  const firstReady = ref(false);
  const page = ref(0);
  let n = 0;
  let idSegment;
  let firstRep;
  let tempsDebut;
  let tempsAll = [];
  const videoReady = {
    player__1: 0,
    player__2: 0,
  };
  const videoPlayer = {
    player__1: "",
    player__2: "",
  };

  useHead({
    script: {
      src: "https://www.youtube.com/iframe_api",
    },
  });
  onMounted(async () => {
    document.getElementById("app").style.background = "#00000000";

    document.getElementsByTagName("html")[0].classList.add("overflow-hidden");
    document.getElementsByClassName("nav")[0].classList.add("hidden");
    document.getElementsByClassName("progressbar")[0].classList.add("hidden");

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    page.value = params.get("mobile");
    if (page.value) {
      document.documentElement.requestFullscreen();
    }

    const myElement = document.querySelector("#vidline-ext-0.1.43");

    // Accéder au shadow root
    const shadowRoot = myElement.shadowRoot;
    // Créer une instance de MutationObserver
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          if (n == 2) {
            tempsDebut = Date.now();
            n = 0;
          }
        } else if (mutation.type === "characterData") {
          n++;
        }
      }
    });

    // Configuration de l'observation (types de mutations à observer)
    const config = { attributes: true, childList: true, subtree: true, characterData: true };

    // Commencer à observer le shadow root
    observer.observe(shadowRoot, config);
  });
  const audioFiles = ref([]);

  const currentIndex = ref(-1);

  function playNextAudio() {
    const audioPlayer = document.getElementById("audioPlayer");
    currentIndex.value++;
    console.log(currentIndex.value, audioFiles.value.length);
    if (currentIndex.value < audioFiles.value.length) {
      audioPlayer.src = "/audio/quotes/" + audioFiles.value[currentIndex.value].lien;
      audioPlayer.load();
    } else {
      console.log("Tous les fichiers audio ont été lus.");
    }
  }
  function goPlay() {
    document.getElementById("audioPlayer").play();
  }
  function go() {
    setTimeout(function () {
      loadVideo("player__1", 1);
    }, 3000);
  }

  async function loadVideo(player) {
    let div = document.createElement("div");
    div.id = player;
    div.classList = "opacity-0 invisible w-0 pointer-events-none h-0 absolute";
    const playerContainer = $("subPlayerContainer");
    playerContainer.appendChild(div);
    videoReady[player] = 0;
    // eslint-disable-next-line
    videoPlayer[player] = new YT.Player(player, {
      playerVars: {
        start: 0,
      },
      videoId: "hKKJyZYZQkk",
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  }
  const onPlayerReady = (e) => {
    let player = e.target.g.id;
    videoPlayer[player].playVideo();
    videoPlayer[player].setVolume(10);
  };

  const onPlayerStateChange = (e) => {
    let player = e.target.g.id;

    if (e.data == 1 && !videoReady[player]) {
      videoReady[player] = 1;
      videoPlayer[player].seekTo(1);
      videoPlayer[player].pauseVideo();
    }
    if (e.data == 2 && !firstReady.value) {
      test();
      firstReady.value = true;
    }
  };

  // eslint-disable-next-line
  async function test() {
    if (!firstRep && nTiktok.value == 4) {
      firstRep = var_character.value;
    }
    const { data: response } = await useFetch("/api/admin/tiktok/voice", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "Voice",
      }),
    });
    const r = JSON.parse(response.value);
    var_character.value = r[0][0].nom;
    var_anime.value = r[1][0].anime;
    setTimeout(function () {
      var_poster.value = r[1][0].poster;
    }, 1000);

    var_photo.value = r[2][0].photo;
    var_photo2.value = r[2][1].photo;
    var_photo3.value = r[2][2].photo;
    var_photo4.value = r[2][3].photo;

    document.querySelectorAll("#container img")[0].classList.remove("correct");
    document.querySelectorAll("#container img")[1].classList.remove("correct");
    document.querySelectorAll("#container img")[2].classList.remove("correct");
    document.querySelectorAll("#container img")[3].classList.remove("correct");
    let rdm = Math.floor(Math.random() * 4);

    if (rdm == 0) {
      var_photo.value = r[0][0].photo;
    } else if (rdm == 1) {
      var_photo2.value = r[0][0].photo;
    } else if (rdm == 2) {
      var_photo3.value = r[0][0].photo;
    } else if (rdm == 3) {
      var_photo4.value = r[0][0].photo;
    }
    document.querySelectorAll("#container img")[rdm].classList.add("correct");

    audioFiles.value = r[0];

    toggleAnim();
  }
  async function setSegments() {
    if (idSegment) {
      await useFetch("/api/admin/tiktok/cut/segments", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tempsAll: tempsAll,
          idSegment: idSegment,
        }),
      });
    } else {
      const { data: response } = await useFetch("/api/admin/tiktok/cut/segments", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: firstRep,
          tempsAll: tempsAll,
          type: "Voice",
          idSegment: idSegment,
        }),
      });
      const r = JSON.parse(response.value);
      idSegment = r[0].id;
    }
  }
  function toggleAnim() {
    chrono.value = 0;
    nTiktok.value++;
    if (nTiktok.value == 5) {
      nTiktok.value = 0;
    }
    document
      .getElementById("demo")
      .classList.remove("bg-linear", "linear-orange", "linear-violet", "linear-green", "linear-red");
    document.getElementById("progress").classList.remove("bg-linear", "bg-orange", "bg-violet", "bg-green", "bg-red");
    currentIndex.value = -1;
    if (nTiktok.value == 0) {
      document.getElementById("scale").classList.add("active");
      const tempsFin = Date.now();
      if (!tempsDebut) {
        tempsDebut = tempsFin;
      }
      tempsAll.push((tempsFin - tempsDebut) / 1000);
      if (tempsAll.length > 1) {
        setSegments();
      }
      videoPlayer["player__1"]?.seekTo(1);
      videoPlayer["player__1"]?.playVideo();

      document.getElementById("demo").classList.add("bg-linear");
      document.getElementById("progress").classList.add("bg-linear");
    }
    if (nTiktok.value == 1) {
      document.getElementById("scale").classList.remove("active");
      document.getElementById("demo").classList.add("linear-orange");
      document.getElementById("progress").classList.add("bg-orange");
    }
    if (nTiktok.value == 2) {
      document.getElementById("demo").classList.add("linear-violet");
      document.getElementById("progress").classList.add("bg-violet");
    }
    if (nTiktok.value == 3) {
      document.getElementById("demo").classList.add("linear-green");
      document.getElementById("progress").classList.add("bg-green");
    }
    if (nTiktok.value == 4) {
      document.getElementById("demo").classList.add("linear-red");
      document.getElementById("progress").classList.add("bg-red");
    }
    playNextAudio();
    interval["countDownCircle"] = setInterval(() => {
      chrono.value++;

      if (chrono.value >= 102) {
        clearInterval(interval["countDownCircle"]);
        document.getElementById("container").classList.add("reveles");
        document.getElementById("res").classList.add("reveles");
        for (let i = 10; i < 40; i++) {
          setTimeout(function () {
            videoPlayer["player__1"].setVolume(i + 1);
          }, i * 10);
        }
        for (let i = 0; i < 100; i++) {
          setTimeout(function () {
            document.getElementById("audioPlayer").volume = (100 - i) / 100;
          }, i * 10);
        }
        interval["test"] = setTimeout(function () {
          test();
        }, 2400);
      }
    }, 94);
    document.getElementById("container").classList.remove("reveles");
    document.getElementById("res").classList.remove("reveles");
    for (let i = 60; i < 90; i++) {
      setTimeout(function () {
        videoPlayer["player__1"].setVolume(100 - i - 1);
      }, i * 10);
    }
    for (let i = 0; i < 100; i++) {
      setTimeout(function () {
        document.getElementById("audioPlayer").volume = i / 100;
      }, i * 10);
    }
  }

  // poly-fill for requestAnmationFrame with fallback for older
  // browsers which do not support rAF.
  window.requestAnimationFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      }
    );
  })();
  onBeforeUnmount(() => {
    clearTimeout(interval["test"]);
    clearInterval(interval["countDownCircle"]);
  });
</script>

<style scoped>
  .mobile .text-\[60px\] {
    font-size: 50px !important;
  }
  .mobile .text-\[56px\] {
    font-size: 47px;
    top: 46px !important;
  }
  .mobile .top-\[31px\] {
    top: 88px !important;
  }
  @keyframes scaleAnim {
    0% {
      scale: 0;
    }
    30% {
      scale: 0;
    }
    55% {
      scale: 1.1;
    }
    95% {
      scale: 1;
    }
    100% {
      scale: 1;
    }
  }
  #scale.active {
    animation: scaleAnim 1.2s;
  }
  .mobile .mt-\[125px\] {
    margin-top: 65px !important;
  }
  .mobile .mb-\[165px\] {
    margin-bottom: 75px !important;
  }
  .mobile .text-\[35px\] {
    font-size: 24px !important;
  }
  .mobile .text-\[30px\] {
    font-size: 21px !important;
  }
  .mobile .text-\[54px\] {
    font-size: 46px !important;
  }
  .linear-orange {
    background: linear-gradient(to right, #ffca00 0, #ff9000);
  }
  .linear-violet {
    background: linear-gradient(to right, #d271f4 0, #8a28bb);
  }
  .linear-green {
    background: linear-gradient(to right, #4af095 0, #18bd63);
  }
  .linear-red {
    background: linear-gradient(to right, #f4677b 0, #d83049);
  }
  #res .result {
    display: none;
  }
  #res.reveles .result {
    display: block;
  }
  #res.reveles .progress {
    display: none;
  }
  #container #poster {
    width: 0;
  }
  #container .canvas {
    margin: 7px;
    width: 37%;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
  }
  #container.reveles .canvas {
    margin: 0;
    width: 0;
    box-shadow: none;
  }
  #container.reveles #poster {
    margin: 7px;
    width: 37%;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
  }

  #container.reveles .canvas.correct {
    margin: 7px;
    width: 37%;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
  }
  :deep(.v-progress-linear__background) {
    background: white !important;
    opacity: 0.5 !important ;
  }
  /* $("demo").classList.remove("bg-[#20b2aa]", "bg-[#ffa500]", "bg-[#ae47e2]", "bg-[#00ce5e]", "bg-[#fb405b]"); */

  .progress.bg-linear :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #03a9f4 !important    ;
  }
  .progress.bg-orange :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #ffa500 !important    ;
  }
  .progress.bg-violet :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #ae47e2 !important    ;
  }
  .progress.bg-green :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #00ce5e !important    ;
  }
  .progress.bg-red :deep(.v-progress-linear--striped .v-progress-linear__determinate) {
    background-color: #fb405b !important    ;
  }

  @keyframes float {
    0% {
      transform: rotate(1deg) translateY(3px) translateX(2px);
    }

    50% {
      transform: rotate(-1deg) translateY(-2px) translateX(-1px);
    }

    100% {
      transform: rotate(1deg) translateY(3px) translateX(2px);
    }
  }
  .float {
    animation: float 4s ease-in-out infinite;
  }
  .defs {
    position: absolute;
    z-index: -2;
    top: 0;
  }

  .demo--lights {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(5px);
    z-index: -2;
  }
  svg {
    width: 100%;
    height: 100%;
  }

  .group--stars {
    transform: scale(0.9);
  }

  .star--outline {
    transform-origin: 75pxr 75px;
    transform: scale(1.2);
    fill: none;
    stroke: white;
    stroke-width: 2;
    stroke-dasharray: 4;
    animation: stardashoffset 2s infinite linear;
  }

  .group--stars-anim {
    use {
      transform-origin: 75pxr 75px;
      animation: starmove 8s infinite linear;
    }

    @for $item from 2 through 4 {
      use:nth-child(#{$item}) {
        transform: scale(1- ($item/5));
      }
    }
  }

  .c-lights {
    fill: none;
    transform-origin: 320px 320px;
    animation: rotation 20s infinite linear;
  }

  @keyframes starmove {
    $trans-min: 0.8;
    $trans-max: 1.2;

    $trans-min-offset: 0.7;
    $trans-max-offset: 1.3;

    0% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }

    12.5% {
      transform-origin: 75pxr 75px * $trans-min-offset * 0.95;
    }

    25% {
      transform-origin: 75pxr * $trans-max * 1.2 75px * $trans-min;
    }

    37.5% {
      transform-origin: 75pxr * $trans-max * 1.15 75px;
    }

    50% {
      transform-origin: 75pxr * $trans-max * 1.1 75px * $trans-max * 1.05;
    }

    65.5% {
      transform-origin: 75pxr 75px * $trans-max-offset * 1.1;
    }

    75% {
      transform-origin: 75pxr * $trans-min * 0.9 75px * $trans-max * 1.05;
    }

    87.5% {
      transform-origin: 75pxr * $trans-min-offset * 0.95 75px;
    }

    100% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }
  }

  @keyframes rotation {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes stardashoffset {
    100% {
      stroke-dashoffset: -32;
    }
  }
</style>

<template>
  <main>
    <!-- Button 1 -->
    <div class="main-box container">
      <div class="button-box container">
        <div class="button" id="btn">
          <p class="description">Join us on<br />baranimes.com</p>
          <img src="https://baranimes.com/images/global/favicon.png" alt="Handshake" />
        </div>
      </div>
    </div>
  </main>
  <label for="display" class="mr-1">Afficher</label><input type="checkbox" id="display" @click="display" />
  <br />

  <label for="tempsaffi" class="mr-1">Afficher toutes les</label
  ><input type="number" step="1" v-model="tempsaffi" id="tempsaffi" @change="go" />min
  <br />
  <label for="temps" class="mr-1">Durer d'affichage</label
  ><input type="number" step="1" v-model="temps" id="temps" @change="go" />min
</template>
<script setup>
  import { ref, onMounted, onBeforeUnmount } from "vue";
  const disp = ref(false);
  const temps = ref(1);
  const tempsaffi = ref(10);
  let interval;
  onMounted(() => {
    go();
  });
  function go() {
    disp.value = false;
    clearInterval(interval);
    interval = setInterval(() => {
      document.getElementById("btn").classList.add("active");
      setTimeout(function () {
        document.getElementById("btn").classList.remove("active");
      }, 1000 * 60 * temps.value);
    }, 1000 * 60 * tempsaffi.value);
  }

  function display() {
    if (!disp.value) {
      document.getElementById("btn").classList.add("active");
    } else {
      document.getElementById("btn").classList.remove("active");
    }
    disp.value = !disp.value;
  }
  onBeforeUnmount(() => {
    clearInterval(interval);
  });
</script>
<style scoped>
  main {
    background-color: green;
  }

  :root {
    --lovelish: hsl(0, 0%, 18%);
    --handshake: hsl(40, 100%, 66%);
    --btn2: hsl(242, 43%, 65%);
    --btn3: hsl(160, 86%, 63%);
  }

  .main-box {
    height: 100vh;
    display: flex;
    margin: auto;
  }

  .button-box {
    height: 500px;
    max-width: 500px;
    align-items: center;
    justify-content: center;
    margin: auto;
    display: grid;
  }

  /* Button 1 */
  .button {
    height: 100px;
    width: 500px;
    display: flex;
    border-radius: 2.5rem;
    transition: 0.5s;
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    color: white;
    background: green;
    outline: none;
    cursor: pointer;
    border: none;
  }

  img {
    margin-left: auto;
    transition: 0.5s;
    width: 5.5rem;
    height: 5.5rem;
  }

  @keyframes fade {
    0% {
      opacity: 100%;
    }
    100% {
      opacity: 0%;
    }
  }

  .title {
    font-weight: bold;
    margin-top: 1.6rem;
    margin-left: 10rem;
    transition: 0.5s;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .button.active .title {
    transform: translateX(-140px);
    animation: fade 0.5s;
    opacity: 0%;
  }
  .button img {
    transform: rotate(-190deg);
    transition: 0.75s;
    opacity: 0%;
    width: 150px;
    height: 150px;
    align-self: center;
  }
  .button.active img {
    transform: translateX(-370px);
    opacity: 100%;
  }

  .description {
    position: absolute;
    margin-top: 1.4rem;
    font-weight: bold;
    line-height: 2rem;
    font-size: 2rem;
    font-style: italic;
    transition: 0.5s;
    opacity: 0%;
    margin-left: 6rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .button.active .description {
    transform: translateX(70px);
    opacity: 100%;
  }
</style>

<template>
  <ModalReuse2 :modal-active="activeModal" @close-modal="toggleModal"
    ><div class="w-full h-full flex items-center justify-center">
      <section @click.stop class="flex items-center justify-center cursor-auto mx-2 w-full max-w-[1135px]">
        <div
          class="relative col-span-full overflow-hidden md:col-span-4 bg-[#02041f] shadow-md rounded-3xl w-full mx-4 sm:mx-14 max-w-[1023px] cursor-auto flex">
          <div class="hidden lg:flex flex-wrap justify-evenly">
            <v-img v-if="infoAdd" width="250" src="/images/global/plus.png"></v-img>
            <v-img v-else width="250" :src="'/images/upload/' + infoImg"></v-img>
          </div>
          <div class="p-5 w-full">
            <v-autocomplete
              v-model="infoAnime"
              :items="animes.animes?.value"
              chips
              closable-chips="false"
              color="blue-grey-lighten-2"
              item-title="alternative"
              item-value="id"
              label="Anime"
              clearable
              class="h-[56px] pr-[10px]"
              auto-select-first>
              <template v-slot:chip="{ props, item }">
                <v-chip v-bind="props" :prepend-avatar="'/images/thumbnail/' + item?.raw?.image"></v-chip>
              </template>
              <template v-slot:item="{ props, item }">
                <v-list-item
                  class="bg-second text-white"
                  v-bind="props"
                  :prepend-avatar="'/images/thumbnail/' + item?.raw?.image"
                  :title="item?.raw?.title + ' | ' + item?.raw?.alternative.join(' | ')"></v-list-item>
              </template>
            </v-autocomplete>
            <div class="flex flex-wrap justify-evenly">
              <v-text-field
                class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                label="Titre"
                v-model="infoTitle"></v-text-field>
              <v-text-field
                class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                label="Artiste"
                v-model="infoArtist"></v-text-field>
              <v-select
                class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                label="Type"
                :items="['Opening', 'Ending', 'OST']"
                v-model="infoType"></v-select>
              <v-text-field
                class="mt-[10px] mr-[10px] min-w-[147px] h-[60px] w-[50px]"
                label="Numero"
                type="number"
                v-model="infoNumber"></v-text-field>
            </div>
            <div>
              <div class="flex flex-wrap justify-evenly">
                <iframe
                  class="mt-[10px] mr-[10px]"
                  width="250"
                  height="127"
                  :src="'https://www.youtube.com/embed/' + infoVideoEmbed + '?start=' + convertToSecond(infoDebut)"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen>
                </iframe>
                <div class="w-full md:w-[calc(100%-260px)] md:min-w-[330px]">
                  <div class="flex flex-wrap">
                    <v-text-field
                      class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                      label="Lien"
                      v-model="infoEmbed"
                      @change="infoVideoEmbed = infoEmbed"></v-text-field>
                    <v-text-field
                      class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                      label="Début"
                      v-model="infoDebut"
                      step="1"
                      type="time"></v-text-field>
                    <v-text-field
                      class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                      label="Fin"
                      v-model="infoFin"
                      step="1"
                      type="time"></v-text-field>
                  </div>
                  <div class="flex flex-wrap">
                    <v-text-field
                      class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                      label="Drop 1"
                      v-model="infoDrop"
                      step="1"
                      type="time"></v-text-field>
                    <v-text-field
                      class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                      label="Drop 2"
                      v-model="infoDrop2"
                      step="1"
                      type="time"></v-text-field>
                    <v-text-field
                      class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                      label="Drop 3"
                      v-model="infoDrop3"
                      step="1"
                      type="time"></v-text-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap" v-if="infoAdd">
                <v-select
                  class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                  label="Difficulté estimée"
                  :items="['Facile', 'Moyen', 'Défaut']"
                  v-model="infoDifficulty"></v-select>
                <v-switch v-model="infoFav" color="#00bdbf" label="Favori"></v-switch>
                <div class="mt-[10px] mr-[10px]">
                  <v-btn class="button-reverse text-white" @click="addSong">Ajouter</v-btn>
                  <p v-if="infoResponse" class="text-green-500">Ajouté !</p>
                </div>
              </div>
              <div class="flex flex-wrap" v-else>
                <v-select
                  class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                  label="Difficulté estimée"
                  :items="['Facile', 'Moyen', 'Défaut']"
                  v-model="infoDifficulty"></v-select>
                <v-text-field
                  class="mt-[10px] mr-[10px] min-w-[147px] h-[60px]"
                  label="Commentaire"
                  v-model="infoComment"></v-text-field>
                <v-switch v-model="infoFav" color="#00bdbf" label="Favori"></v-switch>
                <v-btn class="bg-red-500 text-white mt-[10px] mr-[10px]" @click="removeSong">Supprimer</v-btn>
                <div class="mt-[10px] mr-[10px]">
                  <v-btn class="button-reverse text-white" @click="save">Sauvergarder</v-btn>
                  <p v-if="infoResponse" class="text-green-500">Sauvergardé !</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div></ModalReuse2
  >
  <v-card flat class="mb-10 bg-[#0f112b] absolute left-0">
    <v-card-title class="flex align-center pe-2">
      <v-icon icon="mdi-video-input-component"></v-icon> &nbsp; Gérer les chansons

      <v-spacer></v-spacer>
      <v-progress-circular class="mr-2" v-if="loading3" :size="40" color="#00bdbf" indeterminate></v-progress-circular>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        @click="missSongs3"
        class="cursor-pointer mr-2 text-green-600">
        <path
          fill="currentColor"
          d="M11 20q-.425 0-.712-.288T10 19v-6L4.2 5.6q-.375-.5-.112-1.05T5 4h14q.65 0 .913.55T19.8 5.6L14 13v6q0 .425-.288.713T13 20z" />
      </svg>
      <v-progress-circular class="mr-2" v-if="loading2" :size="40" color="#00bdbf" indeterminate></v-progress-circular>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        @click="missSongs2"
        class="cursor-pointer mr-2 text-red-600">
        <path
          fill="currentColor"
          d="M11 20q-.425 0-.712-.288T10 19v-6L4.2 5.6q-.375-.5-.112-1.05T5 4h14q.65 0 .913.55T19.8 5.6L14 13v6q0 .425-.288.713T13 20z" />
      </svg>
      <v-progress-circular class="mr-2" v-if="loading" :size="40" color="#00bdbf" indeterminate></v-progress-circular>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        @click="missSongs"
        class="cursor-pointer mr-2">
        <path
          fill="currentColor"
          d="M11 20q-.425 0-.712-.288T10 19v-6L4.2 5.6q-.375-.5-.112-1.05T5 4h14q.65 0 .913.55T19.8 5.6L14 13v6q0 .425-.288.713T13 20z" />
      </svg>
      <v-progress-circular class="mr-2" v-if="!items[0]" :size="40" color="#00bdbf" indeterminate></v-progress-circular>

      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        @click="reloadTab"
        class="cursor-pointer mr-2">
        <path
          fill="currentColor"
          d="M4 20v-2h2.75l-.4-.35q-1.225-1.225-1.787-2.662T4 12.05q0-2.775 1.663-4.937T10 4.25v2.1Q8.2 7 7.1 8.563T6 12.05q0 1.125.425 2.188T7.75 16.2l.25.25V14h2v6zm10-.25v-2.1q1.8-.65 2.9-2.212T18 11.95q0-1.125-.425-2.187T16.25 7.8L16 7.55V10h-2V4h6v2h-2.75l.4.35q1.225 1.225 1.788 2.663T20 11.95q0 2.775-1.662 4.938T14 19.75" />
      </svg>
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        density="compact"
        label="Search"
        single-line
        flat
        clearable
        hide-details
        variant="solo-filled"></v-text-field>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        class="cursor-pointer ml-2"
        color="white"
        @click="toggleModal(2)">
        <path
          fill="currentColor"
          d="M11 13v3q0 .425.288.713T12 17q.425 0 .713-.288T13 16v-3h3q.425 0 .713-.288T17 12q0-.425-.288-.712T16 11h-3V8q0-.425-.288-.712T12 7q-.425 0-.712.288T11 8v3H8q-.425 0-.712.288T7 12q0 .425.288.713T8 13zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22" />
      </svg>
    </v-card-title>

    <v-divider></v-divider>
    <v-data-table
      v-model:search="search"
      :items="items"
      v-model:sort-by="sortBy"
      :items-per-page-options="[
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' },
      ]">
      <!-- eslint-disable-next-line -->
      <template v-slot:header.song_name>
        <div>Titre</div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.alternative> </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:header.song_type>
        <div>Type</div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.song_number>
        <div>N°</div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.id>
        <div>Editer</div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.lien> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.debut> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.debut> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.fav> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.fav> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.drop1> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.drop1> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.drop2> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.drop2> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.drop3> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.drop3> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.fin> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.fin> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.lien> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.difficulty> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.difficulty> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.anime="{ item }">
        <a :href="'/anime/' + item.url" target="_blank">{{ item.anime }}</a>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.poster="{ item }">
        <a :href="'/anime/' + item.url" target="_blank">
          <v-card class="my-2" elevation="2" rounded>
            <v-img :src="`/images/thumbnail/${item.poster}`" height="90" cover></v-img>
          </v-card>
        </a>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.commentaire="{ item }">
        <div v-if="item.commentaire">{{ item.commentaire }}</div>
        <div v-else-if="item.statut == 2">Vidéo non disponible</div>
        <div v-else-if="item.statut == 3">Intégration interdite</div>
        <div v-else-if="item.statut == 4">Vidéo +18</div>
        <div v-else-if="item.statut == 5">Pour les enfants</div>
        <div v-else-if="item.statut == 6">Bloqué en France</div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.alternative> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.id="{ item }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 24 24"
          class="cursor-pointer"
          @click="toggleModal(1, item)">
          <path
            fill="currentColor"
            d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h8.925l-2 2H5v14h14v-6.95l2-2V19q0 .825-.587 1.413T19 21zm4-7v-2.425q0-.4.15-.763t.425-.637l8.6-8.6q.3-.3.675-.45t.75-.15q.4 0 .763.15t.662.45L22.425 3q.275.3.425.663T23 4.4q0 .375-.137.738t-.438.662l-8.6 8.6q-.275.275-.637.438t-.763.162H10q-.425 0-.712-.288T9 14m12.025-9.6l-1.4-1.4zM11 13h1.4l5.8-5.8l-.7-.7l-.725-.7L11 11.575zm6.5-6.5l-.725-.7zl.7.7z" />
        </svg>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.statut="{ item }">
        <div>
          <v-chip
            :class="{ green: item.statut == 1, orange: item.statut == 0 }"
            :text="item.statut == 1 ? 'Actif' : item.statut == 0 ? 'Aucun lien' : 'Retiré'"
            class="text-uppercase red"
            label
            size="small"></v-chip>
        </div>
      </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.url> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.url> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:header.id_anime> </template>
      <!-- eslint-disable-next-line -->
      <template v-slot:item.id_anime> </template>
    </v-data-table>
  </v-card>
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import { useFetch } from "@vueuse/core";
  import ModalReuse2 from "@/components/ModalReuse2.vue";
  import { useAnimesStore } from "@/stores/animes";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();

  const animes = useAnimesStore();
  const search = ref("");
  const items = ref([]);
  const sortBy = ref([{ key: "statut", order: "desc" }]);
  const infoImg = ref(null);
  const infoAnime = ref(null);
  const infoTitle = ref(null);
  const infoArtist = ref(null);
  const infoType = ref(null);
  const infoNumber = ref(null);
  const infoEmbed = ref(null);
  const infoFav = ref(0);
  const infoDrop = ref(null);
  const infoDrop2 = ref(null);
  const infoDrop3 = ref(null);
  const infoVideoEmbed = ref(null);
  const infoDebut = ref(null);
  const infoFin = ref(null);
  const infoComment = ref(null);
  const infoId = ref(null);
  const infoResponse = ref(null);
  const infoDifficulty = ref(null);
  const infoAdd = ref(false);
  const activeModal = ref(false);
  const loading = ref(false);
  const loading2 = ref(false);
  const loading3 = ref(false);
  const toggleModal = (toggle, item) => {
    if (toggle == 1) {
      infoAdd.value = false;
      infoResponse.value = 0;
      infoId.value = item.id;
      infoImg.value = item.poster;
      infoAnime.value = { image: item.poster, title: item.anime, id: item.id_anime };
      infoTitle.value = item.song_name;
      infoArtist.value = item.artiste;
      infoType.value = item.song_type;
      infoNumber.value = item.song_number;
      infoEmbed.value = item.lien;
      infoVideoEmbed.value = item.lien;
      infoDebut.value = item.debut;
      infoFin.value = item.fin;
      infoComment.value = item.commentaire;
      infoDifficulty.value = item.difficulty;
      infoFav.value = item.fav;
      if (infoFav.value == 1) {
        infoFav.value = true;
      }
      infoDrop.value = item.drop1;
      infoDrop2.value = item.drop2;
      infoDrop3.value = item.drop3;
      activeModal.value = true;
    } else if (toggle == 2) {
      infoResponse.value = null;
      infoId.value = null;
      infoImg.value = null;
      infoAnime.value = null;
      infoTitle.value = null;
      infoArtist.value = null;
      infoType.value = null;
      infoNumber.value = null;
      infoEmbed.value = null;
      infoVideoEmbed.value = null;
      infoDebut.value = "00:00:00";
      infoFin.value = "00:00:00";
      infoDrop.value = "00:00:00";
      infoDrop2.value = "00:00:00";
      infoDrop3.value = "00:00:00";
      infoComment.value = null;
      infoDifficulty.value = null;
      infoAdd.value = true;
      activeModal.value = true;
    } else {
      activeModal.value = false;
    }
  };
  function convertToSecond(debut) {
    let temps_elements = debut.split(":");
    let hour = parseInt(temps_elements[0], 10);
    let minutes = parseInt(temps_elements[1], 10);
    let secondes = parseInt(temps_elements[2], 10);
    return hour * 3600 + minutes * 60 + secondes;
  }
  onMounted(async () => {
    global.pageLoad();
    const { data: response } = await useFetch("/api/admin/song/manage/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    items.value = JSON.parse(response.value);
  });
  async function reloadTab() {
    items.value = [];
    const { data: response } = await useFetch("/api/admin/song/manage/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    items.value = JSON.parse(response.value);
  }
  async function missSongs() {
    loading.value = true;
    const { data: response } = await useFetch("/api/admin/song/manage/miss", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const miss = JSON.parse(response.value);
    const idsAInclure = miss.map((objet) => objet.id);
    items.value = items.value.filter((objet) => idsAInclure.includes(objet.id));
    loading.value = false;
  }
  async function missSongs2() {
    loading2.value = true;
    const { data: response } = await useFetch("/api/admin/song/manage/miss2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    items.value = JSON.parse(response.value);
    loading2.value = false;
  }
  async function missSongs3() {
    loading3.value = true;
    const { data: response } = await useFetch("/api/admin/song/manage/miss3", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    items.value = JSON.parse(response.value);
    loading3.value = false;
  }
  async function removeSong() {
    const confirmation = confirm("Êtes-vous sûr de vouloir supprimer la chanson ?");
    if (confirmation) {
      await useFetch("/api/admin/song/manage/delete", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          infoId: infoId.value,
        }),
      });
      toggleModal(0);
      items.value = items.value.filter((objet) => objet.id !== infoId.value);
    }
  }
  async function save() {
    let type;
    let difficulty;
    if (infoType.value == "Opening") {
      type = 1;
    } else if (infoType.value == "Ending") {
      type = 2;
    } else if (infoType.value == "OST") {
      type = 3;
    }
    if (infoDifficulty.value == "Facile") {
      difficulty = 1;
    } else if (infoDifficulty.value == "Moyen") {
      difficulty = 2;
    } else {
      difficulty = 0;
    }
    const { data: response } = await useFetch("/api/admin/song/manage/update", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        infoAnime: infoAnime.value?.id ? infoAnime.value.id : infoAnime.value,
        infoTitle: infoTitle.value,
        infoArtist: infoArtist.value,
        infoType: type,
        infoNumber: infoNumber.value,
        infoEmbed: infoEmbed.value,
        infoDebut: infoDebut.value,
        infoFin: infoFin.value,
        infoComment: infoComment.value,
        infoId: infoId.value,
        fav: infoFav.value,
        drop1: infoDrop.value,
        drop2: infoDrop2.value,
        drop3: infoDrop3.value,
        infoDifficulty: difficulty,
      }),
    });
    infoResponse.value = response.value;
    let objetRecherche = items.value.find((objet) => objet.id === infoId.value);

    objetRecherche.song_name = infoTitle.value;
    objetRecherche.artiste = infoArtist.value;
    objetRecherche.song_type = infoType.value;
    objetRecherche.song_number = infoNumber.value;
    objetRecherche.lien = infoEmbed.value;
    objetRecherche.debut = infoDebut.value;
    objetRecherche.fin = infoFin.value;
    objetRecherche.commentaire = infoComment.value;
    objetRecherche.statut = 1;
    setTimeout(function () {
      infoResponse.value = 0;
    }, 3000);
  }
  async function addSong() {
    let type;
    let difficulty;
    if (infoType.value == "Opening") {
      type = 1;
    } else if (infoType.value == "Ending") {
      type = 2;
    } else if (infoType.value == "OST") {
      type = 3;
    }
    if (infoDifficulty.value == "Facile") {
      difficulty = 1;
    } else if (infoDifficulty.value == "Moyen") {
      difficulty = 2;
    } else {
      difficulty = 0;
    }
    const { data: response } = await useFetch("/api/admin/song/manage/add", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        infoAnime: infoAnime.value?.id ? infoAnime.value.id : infoAnime.value,
        infoTitle: infoTitle.value,
        infoArtist: infoArtist.value,
        infoType: type,
        infoNumber: infoNumber.value,
        infoEmbed: infoEmbed.value,
        infoDebut: infoDebut.value,
        infoFin: infoFin.value,
        infoDifficulty: difficulty,
        fav: infoFav.value,
        drop1: infoDrop.value,
        drop2: infoDrop2.value,
        drop3: infoDrop3.value,
      }),
    });

    infoResponse.value = response.value;
    infoTitle.value = null;
    infoArtist.value = null;
    infoType.value = null;
    infoNumber.value = null;
    infoEmbed.value = null;
    infoVideoEmbed.value = null;
    infoDebut.value = "00:00:00";
    infoFin.value = "00:00:00";
    infoComment.value = null;
    setTimeout(function () {
      infoResponse.value = 0;
    }, 3000);
  }
</script>
<style scoped>
  .red {
    color: red;
  }
  .orange {
    color: orange;
  }
  .green {
    color: lightgreen;
  }
  :deep(.v-card-title),
  :deep(.v-data-table),
  :deep(.v-field),
  :deep(.v-card) {
    background: #0f112b;
    color: white;
  }
  :deep(.v-chip--variant-tonal) {
    color: auto;
  }
</style>

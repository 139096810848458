<template>
  <div>
    <div class="fixed z-50 right-[25px] bottom-[25px] items-end flex pointer-events-none actionBtn">
      <div
        :class="'h-[300px] mb-[15px] md:mb-[70px] md:mr-[-75px] z-0 relative inline-grid text-center justify-items-center avatar invisible'"
        id="showAvatarContainer">
        <span
          class="blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible"></span>
        <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11]">
          <div
            class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
            style="
              filter: drop-shadow(black 2px 4px 6px);
              background: url(/images/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
              background-size: cover;
              margin: 36px 36px 0;
            "
            :style="'background: url(/images/global/bg_avatar.png),' + skinFond">
            <v-img
              :src="skinAvatar"
              class="max-w-none overflow-visible img-user-avatar"
              style="width: 300px; margin-bottom: 45px; margin-left: -3px" />
          </div>
        </div>
        <b
          class="z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium opacity-0 shadow-base"
          >0</b
        >
        <span
          class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base shadow-user-avatar"
          :style="`background: ${skinPseudo}; box-shadow: -5px -5px 5px -5px ${skinShadow1},
             5px 5px 5px -5px ${skinShadow2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`">
          {{ auth.user.username }}</span
        >
      </div>
      <button
        class="shadow-3xl hover:scale-110 bg-linear menu-anime h-[48px] w-[48px] md:h-[56px] md:w-[56px] z-10 right-[35px] bottom-[30px] pointer-events-auto"
        @click="showAvatar">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 36 36">
          <path
            fill="currentColor"
            d="M30.61 24.52a17.16 17.16 0 0 0-25.22 0a1.51 1.51 0 0 0-.39 1v6A1.5 1.5 0 0 0 6.5 33h23a1.5 1.5 0 0 0 1.5-1.5v-6a1.51 1.51 0 0 0-.39-.98"
            class="clr-i-solid clr-i-solid-path-1" />
          <circle cx="18" cy="10" r="7" fill="currentColor" class="clr-i-solid clr-i-solid-path-2" />
          <path fill="none" d="M0 0h36v36H0z" />
        </svg>
      </button>
    </div>
    <div class="hidden sm:block relative">
      <router-link to="/">
        <svg
          class="absolute z-10 top-[15px] left-[12px] md:top-7 md:left-6 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
          viewBox="0 0 24 24">
          <path
            fill="currentColor"
            d="m7.825 13l4.9 4.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-6.6-6.6q-.15-.15-.213-.325T4.426 12q0-.2.063-.375T4.7 11.3l6.6-6.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L7.825 11H19q.425 0 .713.288T20 12q0 .425-.288.713T19 13H7.825Z"></path>
        </svg>
      </router-link>
      <div class="justify-center flex">
        <div :class="'h-[300px] mb-10 relative inline-grid text-center justify-items-center avatar'">
          <span
            class="blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible"></span>
          <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11]">
            <div
              class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
              style="
                filter: drop-shadow(black 2px 4px 6px);
                background: url(/images/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
                background-size: cover;
                margin: 36px 36px 0;
              "
              :style="'background: url(/images/global/bg_avatar.png),' + skinFond">
              <v-img
                :src="skinAvatar"
                class="max-w-none overflow-visible img-user-avatar"
                style="width: 300px; margin-bottom: 45px; margin-left: -3px" />
            </div>
          </div>
          <b
            class="z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium opacity-0 shadow-base"
            >0</b
          >
          <span
            class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base shadow-user-avatar"
            :style="`background: ${skinPseudo}; box-shadow: -5px -5px 5px -5px ${skinShadow1},
             5px 5px 5px -5px ${skinShadow2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`">
            {{ auth.user.username }}</span
          >
        </div>
      </div>
    </div>
    <div class="submenu hidden sm:flex">
      <span @click="submenu(0)" class="slider_list_menu0"
        ><span>{{ $t("avatar.avatar") }} • {{ avatar[0]?.length }}</span></span
      >
      <span @click="submenu(1)"
        ><span>{{ $t("avatar.background") }} • {{ avatar[1]?.length }}</span></span
      >
      <span @click="submenu(2)"
        ><span>{{ $t("avatar.pseudo") }} • {{ avatar[2]?.length }}</span></span
      >
      <span @click="submenu(3)"
        ><span>{{ $t("avatar.shadow") }} • {{ avatar[3]?.length }}</span></span
      >
      <span @click="submenu(4)"
        ><span>{{ $t("avatar.title") }} • {{ avatar[4]?.length }}</span></span
      >
    </div>
    <div class="hidden sm:flex">
      <div v-for="(items, index) in avatar" :key="items.id" class="justify-center flex">
        <div :class="{ active: index === 0, ['avatar' + index]: true }" class="slider_list w-full">
          <div class="ml-2">
            <h2 v-if="index === 0" class="slide_title text-[#2ecc71]">{{ $t("avatar.avatar") }}</h2>
            <h2 v-if="index === 1" class="slide_title text-[#5d9cec]">{{ $t("avatar.background") }}</h2>
            <h2 v-if="index === 2" class="slide_title text-[#ac92ec]">{{ $t("avatar.pseudo") }}</h2>
            <h2 v-if="index === 3" class="slide_title text-[#fcc200]">{{ $t("avatar.shadow") }}</h2>
            <h2 v-if="index === 4" class="slide_title text-[#d8334a]">{{ $t("avatar.title") }}</h2>
          </div>
          <div class="flex-wrap justify-center flex min-h-[680px]">
            <div
              v-for="item in avatar[index]"
              :key="item.id"
              class="h-[300px] mb-10 relative inline-grid text-center justify-items-center avatar cursor-pointer"
              :class="{ active: item.active === item.id_item }"
              @click.self="selectItem($event, item.id, index, 1)">
              <span
                class="blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible pointer-events-none"></span>
              <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11] pointer-events-none">
                <div
                  class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl user-avatar"
                  :style="{
                    background:
                      item.type === 5
                        ? `linear-gradient(135deg, ${item.params1}, ${item.params2})`
                        : item.type === 4
                        ? `url(/images/global/bg_avatar.png), ${item.params}`
                        : item.type === 1
                        ? `url(/images/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8)`
                        : `${item.params || 'linear-gradient(to bottom, #c5c5c5, #008ca8)'}`,
                  }"
                  :data-shadow1="item?.params1"
                  :data-shadow2="item?.params2"
                  style="filter: drop-shadow(black 2px 4px 6px); background-size: cover; margin: 36px 36px 0">
                  <v-img
                    v-if="item.type === 1"
                    loading="lazy"
                    :src="'/images/avatar_users/' + item.lien"
                    class="max-w-none overflow-visible img-user-avatar"
                    :style="item.params" />
                  <div v-if="item.type == 2 && selectedLanguage == 'fr'">{{ item.titre }}</div>
                  <div v-else-if="item.type == 2">{{ item.titre_en }}</div>
                  <div class="absolute top-[5px] right-[5px] bg-second p-1 rounded-full check">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                      <path
                        fill="currentColor"
                        d="m232.49 80.49l-128 128a12 12 0 0 1-17 0l-56-56a12 12 0 1 1 17-17L96 183L215.51 63.51a12 12 0 0 1 17 17Z" />
                    </svg>
                  </div>
                </div>
              </div>
              <b
                class="pointer-events-none shadow-base z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium opacity-0"
                >0</b
              >
              <span
                v-if="item.type == 2"
                class="pointer-events-none shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
                >{{ $t("avatar.title") }}</span
              >
              <span
                v-else-if="selectedLanguage == 'fr' || item.type == 1"
                class="pointer-events-none shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
                >{{ item.titre }}</span
              >
              <span
                v-else
                class="pointer-events-none shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
                >{{ item.titre_en }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <swiper class="mySwiper sm:hidden" style="height: calc(100vh - 50px)" :pagination="pagination" :modules="modules">
      <swiper-slide key="5">
        <div class="ml-2 mt-[70px]">
          <h2 class="slide_title text-[#00bdbf]">{{ $t("avatar.preview") }}</h2>
        </div>
        <div class="justify-center flex">
          <div :class="'h-[300px] mb-10 relative inline-grid text-center justify-items-center avatar'">
            <span
              class="blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible"></span>
            <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11]">
              <div
                class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
                style="
                  filter: drop-shadow(black 2px 4px 6px);
                  background: url(/images/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
                  background-size: cover;
                  margin: 36px 36px 0;
                "
                :style="'background: url(/images/global/bg_avatar.png),' + skinFond">
                <v-img
                  :src="skinAvatar"
                  class="max-w-none overflow-visible img-user-avatar"
                  style="width: 300px; margin-bottom: 45px; margin-left: -3px" />
              </div>
            </div>
            <b
              class="z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium opacity-0 shadow-base"
              >0</b
            >
            <span
              class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base shadow-user-avatar"
              :style="`background: ${skinPseudo}; box-shadow: -5px -5px 5px -5px ${skinShadow1},
             5px 5px 5px -5px ${skinShadow2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`">
              {{ auth.user.username }}</span
            >
          </div>
        </div>
      </swiper-slide>
      <swiper-slide v-for="(items, index2) in avatar" :key="items.id" class="justify-center flex">
        <v-virtual-scroll
          :items="avatar[index2]"
          height="calc(100vh - 50px)"
          :key="avatar[index2]"
          item-height="300"
          :class="{ ['avatar-mobile' + index2]: true }">
          <template v-slot:default="{ item, index }">
            <div v-if="index === 0" class="ml-2 mt-[70px]">
              <h2 v-if="index2 === 0" class="slide_title text-[#2ecc71]">{{ $t("avatar.avatar") }}</h2>
              <h2 v-if="index2 === 1" class="slide_title text-[#5d9cec]">{{ $t("avatar.background") }}</h2>
              <h2 v-if="index2 === 2" class="slide_title text-[#ac92ec]">{{ $t("avatar.pseudo") }}</h2>
              <h2 v-if="index2 === 3" class="slide_title text-[#fcc200]">{{ $t("avatar.shadow") }}</h2>
              <h2 v-if="index2 === 4" class="slide_title text-[#d8334a]">{{ $t("avatar.title") }}</h2>
            </div>
            <div class="overflow-x-hidden flex justify-center">
              <div
                class="h-[300px] mb-10 relative inline-grid text-center justify-items-center avatar cursor-pointer"
                :class="{ active: item.active === item.id_item }"
                @click.self="selectItem($event, item.id, index2, 2)">
                <span
                  class="pointer-events-none blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible"></span>
                <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11] pointer-events-none">
                  <div
                    class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl user-avatar"
                    :style="{
                      background:
                        item.type === 5
                          ? `linear-gradient(135deg, ${item.params1}, ${item.params2})`
                          : item.type === 4
                          ? `url(/images/global/bg_avatar.png), ${item.params}`
                          : item.type === 1
                          ? `url(/images/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8)`
                          : `${item.params || 'linear-gradient(to bottom, #c5c5c5, #008ca8)'}`,
                    }"
                    style="filter: drop-shadow(black 2px 4px 6px); background-size: cover; margin: 36px 36px 0"
                    :data-shadow1="item?.params1"
                    :data-shadow2="item?.params2">
                    <v-img
                      v-if="item.type === 1"
                      loading="lazy"
                      :src="'/images/avatar_users/' + item.lien"
                      class="max-w-none overflow-visible img-user-avatar"
                      :style="item.params" />

                    <div v-if="item.type == 2 && selectedLanguage == 'fr'">{{ item.titre }}</div>
                    <div v-else-if="item.type == 2">{{ item.titre_en }}</div>
                    <div class="absolute top-[5px] right-[5px] bg-second p-1 rounded-full check">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256">
                        <path
                          fill="currentColor"
                          d="m232.49 80.49l-128 128a12 12 0 0 1-17 0l-56-56a12 12 0 1 1 17-17L96 183L215.51 63.51a12 12 0 0 1 17 17Z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <b
                  class="pointer-events-none shadow-base z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium opacity-0"
                  >0</b
                >
                <span
                  v-if="item.type == 2"
                  class="pointer-events-none shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
                  >{{ $t("avatar.title") }}</span
                >
                <span
                  v-else-if="selectedLanguage == 'fr' || item.type == 1"
                  class="pointer-events-none shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
                  >{{ item.titre }}</span
                >
                <span
                  v-else
                  class="pointer-events-none shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
                  >{{ item.titre_en }}</span
                >
              </div>
            </div>
          </template>
        </v-virtual-scroll>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script setup>
  import { ref, onMounted, computed } from "vue";
  import { useFetch } from "@vueuse/core";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Pagination } from "swiper/modules";
  import "swiper/css";
  import "swiper/css/pagination";
  import { useRouter } from "vue-router";
  import { useAuthStore } from "@/stores/auth";
  import { useSeoMeta } from "@unhead/vue";
  import { useI18n } from "vue-i18n";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();
  const { t } = useI18n();
  const auth = useAuthStore();
  const router = useRouter();
  const avatar = ref([]);
  const modules = [Pagination];
  const selectedLanguage = ref(localStorage.getItem("lang"));
  const renderBtn = ref([
    {
      title: selectedLanguage.value == "fr" ? "Aperçu" : "Preview",
    },
    {
      title: selectedLanguage.value == "fr" ? "Avatar" : "Avatar",
    },
    {
      title: selectedLanguage.value == "fr" ? "Fond" : "Background",
    },
    {
      title: selectedLanguage.value == "fr" ? "Pseudo" : "Pseudo",
    },
    {
      title: selectedLanguage.value == "fr" ? "Ombre" : "Shadow",
    },
    {
      title: selectedLanguage.value == "fr" ? "Titre" : "Title",
    },
  ]);
  const skinAvatar = ref(null);
  const skinFond = ref(null);
  const skinPseudo = ref(null);
  const skinShadow1 = ref(null);
  const skinShadow2 = ref(null);
  const pagination = ref({
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="shadow-3xl ${className}"><span>${renderBtn.value[index].title}</span></span>`;
    },
  });
  onMounted(async () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0HY7K2YF6F");
    const { data: response } = await useFetch("/api/avatar/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    avatar.value = JSON.parse(response.value);
    if (!avatar.value) {
      router.push({ name: "404" });
      return;
    }
    avatar.value[0].forEach((item) => {
      if (item.active === item.id_item) {
        skinAvatar.value = "/images/avatar_users/" + item.lien;
      }
    });
    avatar.value[1].forEach((item) => {
      if (item.active === item.id_item) {
        skinFond.value = item.params;
      }
    });
    avatar.value[2].forEach((item) => {
      if (item.active === item.id_item) {
        skinPseudo.value = item.params;
      }
    });
    avatar.value[3].forEach((item) => {
      if (item.active === item.id_item) {
        skinShadow1.value = item.params1;
        skinShadow2.value = item.params2;
      }
    });
    global.pageLoad();
  });
  useSeoMeta({
    title: computed(() => t("avatar.avatar") + " - Baranimes"),
    ogTitle: computed(() => t("avatar.avatar") + " - Baranimes"),
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: computed(() => t("avatar.avatar") + " - Baranimes"),
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
  async function selectItem(event, item, type, screen) {
    await useFetch("/api/avatar/update", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idItem: item,
        type: type,
      }),
    });

    if (screen == 1) {
      document.querySelectorAll(".avatar" + type + " .avatar.active")[0].classList.remove("active");
      event.target.classList.add("active");
      if (type == 0) {
        skinAvatar.value = document
          .querySelectorAll(".avatar0 .avatar.active .img-user-avatar img")[0]
          .getAttribute("src");
      }
      if (type == 1) {
        skinFond.value = document.querySelectorAll(".avatar1 .avatar.active .user-avatar")[0].style.background;
      }
      if (type == 2) {
        skinPseudo.value = document.querySelectorAll(".avatar2 .avatar.active .user-avatar")[0].style.background;
      }
      if (type == 3) {
        document.querySelectorAll(".shadow-user-avatar")[0].style.boxShadow = `-5px -5px 5px -5px ${document
          .querySelectorAll(".avatar3 .avatar.active .user-avatar")[0]
          .getAttribute("data-shadow1")},
             5px 5px 5px -5px ${document
               .querySelectorAll(".avatar3 .avatar.active .user-avatar")[0]
               .getAttribute("data-shadow2")}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`;
        document.querySelectorAll(".shadow-user-avatar")[1].style.boxShadow = `-5px -5px 5px -5px ${document
          .querySelectorAll(".avatar3 .avatar.active .user-avatar")[0]
          .getAttribute("data-shadow1")},
             5px 5px 5px -5px ${document
               .querySelectorAll(".avatar3 .avatar.active .user-avatar")[0]
               .getAttribute("data-shadow2")}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`;
        document.querySelectorAll(".shadow-user-avatar")[2].style.boxShadow = `-5px -5px 5px -5px ${document
          .querySelectorAll(".avatar3 .avatar.active .user-avatar")[0]
          .getAttribute("data-shadow1")},
             5px 5px 5px -5px ${document
               .querySelectorAll(".avatar3 .avatar.active .user-avatar")[0]
               .getAttribute("data-shadow2")}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`;
      }
    } else {
      document.querySelectorAll(".avatar-mobile" + type + " .avatar.active")[0]?.classList.remove("active");
      event.target.classList.add("active");
      if (type == 0) {
        skinAvatar.value = document
          .querySelectorAll(".avatar-mobile0 .avatar.active .img-user-avatar img")[0]
          .getAttribute("src");
      }
      if (type == 1) {
        skinFond.value = document.querySelectorAll(".avatar-mobile1 .avatar.active .user-avatar")[0].style.background;
      }
      if (type == 2) {
        skinPseudo.value = document.querySelectorAll(".avatar-mobile2 .avatar.active .user-avatar")[0].style.background;
      }
      if (type == 3) {
        document.querySelectorAll(".shadow-user-avatar")[0].style.boxShadow = `-5px -5px 5px -5px ${document
          .querySelectorAll(".avatar-mobile3 .avatar.active .user-avatar")[0]
          .getAttribute("data-shadow1")},
             5px 5px 5px -5px ${document
               .querySelectorAll(".avatar-mobile3 .avatar.active .user-avatar")[0]
               .getAttribute("data-shadow2")}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`;
        document.querySelectorAll(".shadow-user-avatar")[1].style.boxShadow = `-5px -5px 5px -5px ${document
          .querySelectorAll(".avatar-mobile3 .avatar.active .user-avatar")[0]
          .getAttribute("data-shadow1")},
             5px 5px 5px -5px ${document
               .querySelectorAll(".avatar-mobile3 .avatar.active .user-avatar")[0]
               .getAttribute("data-shadow2")}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`;
        document.querySelectorAll(".shadow-user-avatar")[2].style.boxShadow = `-5px -5px 5px -5px ${document
          .querySelectorAll(".avatar-mobile3 .avatar.active .user-avatar")[0]
          .getAttribute("data-shadow1")},
             5px 5px 5px -5px ${document
               .querySelectorAll(".avatar-mobile3 .avatar.active .user-avatar")[0]
               .getAttribute("data-shadow2")}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`;
      }
    }
  }
  function submenu(submenu) {
    document.querySelectorAll(".submenu>span")[0].removeAttribute("class");
    document.querySelectorAll(".submenu>span")[0].classList.add("slider_list_menu" + submenu);

    document.querySelectorAll(".slider_list").forEach((_, i) => {
      document.querySelectorAll(".slider_list")[i].classList.remove("active");
    });
    document.querySelectorAll(".slider_list")[submenu].classList.add("active");
  }
  function showAvatar() {
    document.getElementById("showAvatarContainer").classList.toggle("invisible");
  }
</script>
<style scoped>
  @media (max-width: 767px) {
    .actionBtn {
      width: 100%;
      left: 0px;
      bottom: 35px;
      position: fixed;
      z-index: 40;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .slider_list {
    display: none;
  }
  .active.slider_list {
    display: block;
  }
  .slide_title {
    margin-bottom: 15px;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    border-left: 4px solid;
    padding-left: 20px;
  }
  .submenu {
    white-space: nowrap;
    overflow: visible;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    margin-bottom: 35px;
    position: relative;
    margin-top: 5px;
  }
  .submenu > span {
    min-width: calc(20% - 10px);
    border-radius: 100px;
    font-weight: 600;
    height: 33px;
    margin: 0 5px;
    background: rgb(255 255 255 / 10%);
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .submenu > span:first-child::before {
    content: "";
    width: calc(20% - 10px);
    position: absolute;
    opacity: 1;
    border-radius: 100px;
    height: 33px;
    transition: 0.3s;
    pointer-events: none;
  }
  .submenu > span > span {
    position: relative;
  }
  .submenu .slider_list_menu0::before {
    background: #2ecc71;
    left: 5px;
  }

  .submenu .slider_list_menu1::before {
    background: #5d9cec;
    left: calc(20% + 5px);
  }

  .submenu .slider_list_menu2::before {
    background: #ac92ec;
    left: calc(40% + 5px);
  }

  .submenu .slider_list_menu3::before {
    background: #fcc200;
    left: calc(60% + 5px);
  }

  .submenu .slider_list_menu4::before {
    background: #d8334a;
    left: calc(80% + 5px);
  }
  .avatar .check {
    display: none;
  }
  .avatar.active .check {
    display: block;
  }
  :deep(
      .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
      .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet
    ) {
    min-width: calc(33.333333% - 10px);
    border-radius: 100px;
    font-weight: 600;
    height: 33px;
    margin: 0 5px;
    background: #02041f;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: 1;
    cursor: pointer;
    padding-top: 4px;
    transition: 0.3s;
  }
  :deep(
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction
    ) {
    top: 10px;
    height: 0;
    display: flex;
    justify-content: right;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(4).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
    ) {
    margin-right: calc(-33.33333% + 5px) !important;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(3).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
    ) {
    margin-right: calc(-66.66666% + 5px) !important;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(2).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
    ) {
    margin-right: calc(-100% + 5px) !important;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(1).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
    ) {
    margin-right: calc(-100% + 5px) !important;
  }
  :deep(.swiper-pagination-bullet:nth-child(6)::before) {
    content: "";
    width: calc(33.33333% - 10px);
    position: absolute;
    opacity: 1;
    border-radius: 100px;
    height: 33px;
    transition: 0.3s;
    pointer-events: none;
    top: 0px;
    background: #d8334a;
    left: calc(66.66666% + 5px);
  }
  :deep(.swiper-pagination-bullet > span) {
    position: relative;
    z-index: 1;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(1).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet::before
    ) {
    background: #00bdbf;
    left: 5px;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(2).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet::before
    ) {
    background: #2ecc71;
    left: calc(33.33333% + 5px);
  }

  :deep(
      .swiper-pagination-bullet:nth-child(3).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet::before
    ) {
    background: #5d9cec;
    left: calc(33.33333% + 5px);
  }

  :deep(
      .swiper-pagination-bullet:nth-child(4).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet::before
    ) {
    background: #ac92ec;
    left: calc(33.33333% + 5px);
  }

  :deep(.swiper-pagination-bullet:nth-child(5).swiper-pagination-bullet-active + .swiper-pagination-bullet::before) {
    background: #fcc200;
    left: calc(33.33333% + 5px);
  }
</style>

<template>
  <div class="nav md:hidden flex">
    <router-link to="/" class="w-1/5">
      <v-btn class="nav-slot round-top-left">
        <v-icon class="text-white">mdi-music-note</v-icon>
      </v-btn>
    </router-link>
    <router-link to="/anime" class="w-1/5" @click="global.animesSuggestIndex = 0">
      <v-btn class="nav-slot">
        <v-icon class="text-white">mdi-compass</v-icon>
      </v-btn>
    </router-link>
    <router-link to="/" class="w-[125px] max-w-[100px]">
      <div class="curve">
        <v-img
          role="button"
          class="floating-button shadow-3xl select-none"
          src="https://baranimes.com/images/global/favicon.png"
          alt="Baranimes" />
      </div>
    </router-link>
    <router-link v-if="auth.user.role != 'guest'" to="/profile" class="w-1/5">
      <v-btn class="nav-slot">
        <v-icon class="text-white">mdi-book</v-icon>
      </v-btn>
    </router-link>
    <div v-else class="w-1/5" @click="global.signUp = true">
      <v-btn class="nav-slot">
        <v-icon class="text-white">mdi-book</v-icon>
      </v-btn>
    </div>
    <router-link v-if="auth.user.role != 'guest'" to="/settings" class="w-1/5">
      <v-btn class="nav-slot round-top-right">
        <v-icon class="text-white">mdi-account-circle</v-icon>
      </v-btn>
    </router-link>
    <div v-else class="w-1/5" @click="global.signIn = true">
      <v-btn class="nav-slot">
        <v-icon class="text-white">mdi-account-circle</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script setup>
  import { useAuthStore } from "@/stores/auth";
  import { useGlobalStore } from "@/stores/global";

  const auth = useAuthStore();
  const global = useGlobalStore();
</script>
<style scoped>
  .nav {
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height: 50px;
    filter: drop-shadow(0px 0px 6px rgba(0, 140, 168, 0.4));
    position: fixed;
    bottom: 0px;
    z-index: 30;
  }

  .nav-slot {
    width: 100%;
    flex-basis: 15%;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    background: #020420 !important;
    box-shadow: none;
    font-size: 16px;
  }
  @media (max-width: 390px) {
    .nav-slot {
      min-width: 0;
    }
  }
  .nav-link {
    width: 25px;
    height: 25px;
    line-height: 1.5;
    align-items: center;
    color: #aab2bd;
    border-radius: 50rem;
    padding: 0.5rem 0.5rem;
    transition: 0.3s all;
  }

  .active {
    color: #2575fc;
  }

  .nav-link:hover {
    transition: 0.3s all;
    background: rgba(31, 31, 31, 0.1);
  }

  .curve {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23020420 '%3E%3Cpath d='M99 0a36.33 36.33 0 0 0-29 15 25 25 0 0 1-40 0A36.33 36.33 0 0 0 1 0H0v50h100V0Z'/%3E%3C/svg%3E") !important;
    width: 100px;
    flex-basis: 15%;
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
  }

  .floating-button {
    position: fixed;
    width: 48px;
    height: 48px;
    line-height: 3;
    text-align: center;
    border-radius: 50%;
    bottom: 35px;
    z-index: 1;
    transition: 0.3s all;
  }

  .floating-button:active {
    bottom: 40px;
    transition: 0.3s all;
  }

  .round-top-left {
    border-top-left-radius: 15px;
  }

  .round-top-right {
    border-top-right-radius: 15px;
  }

  svg {
    height: 100%;
  }
</style>

<template>
  <ModalMenuAnime :modal-active="menuAnimeModalActive" @close-modal="menuAnimeToggleModal"
    ><div class="w-full h-full flex items-center justify-center">
      <div
        @click.stop
        class="bg-[#02041f] shadow-md rounded-2xl mx-4 sm:mx-14 cursor-auto overflow-hidden modal-menu-anime"
        style="box-shadow: 0px 15px 20px rgb(0 140 168 / 40%)">
        <h3 class="bg-linear text-lg line-clamp-1 font-medium max-w-[402px] text-center p-1">
          {{ animeUser?.title }}
        </h3>
        <div class="flex justify-center flex-wrap p-4 pt-5">
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#2ecc71] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 1 }"
              @click="menuAnime(1)">
              <svg
                class="hover:text-[#2ecc71] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M8 17.175V6.825q0-.425.3-.713t.7-.287q.125 0 .263.037t.262.113l8.15 5.175q.225.15.338.375t.112.475q0 .25-.112.475t-.338.375l-8.15 5.175q-.125.075-.262.113T9 18.175q-.4 0-.7-.288t-.3-.712" />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.watching") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#5d9cec] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 2 }"
              @click="menuAnime(2)">
              <svg
                class="hover:text-[#5d9cec] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M13 11.6V8q0-.425-.288-.712T12 7q-.425 0-.712.288T11 8v3.975q0 .2.075.388t.225.337l3.3 3.3q.275.275.7.275T16 16q.275-.275.275-.7T16 14.6zM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22m0-2q3.325 0 5.663-2.337T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 3.325 2.338 5.663T12 20" />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.to_watch") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#ac92ec] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 3 }"
              @click="menuAnime(3)">
              <svg
                class="hover:text-[#ac92ec] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M7 14v6q0 .425-.288.713T6 21q-.425 0-.712-.288T5 20V5q0-.425.288-.712T6 4h7.175q.35 0 .625.225t.35.575L14.4 6H19q.425 0 .713.288T20 7v8q0 .425-.288.713T19 16h-5.175q-.35 0-.625-.225t-.35-.575L12.6 14z" />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.completed") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#fcc200] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 4 }"
              @click="menuAnime(4)">
              <svg
                class="hover:text-[#fcc200] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M16 19q-.825 0-1.412-.587T14 17V7q0-.825.588-1.412T16 5q.825 0 1.413.588T18 7v10q0 .825-.587 1.413T16 19m-8 0q-.825 0-1.412-.587T6 17V7q0-.825.588-1.412T8 5q.825 0 1.413.588T10 7v10q0 .825-.587 1.413T8 19" />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.on_hold") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px] mb-3">
            <button
              class="rounded-full bg-[#d8334a] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.status === 5 }"
              @click="menuAnime(5)">
              <svg
                class="hover:text-[#d8334a] duration-300 w-full h-full p-2"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M7 15.385v-6.77q0-.666.475-1.14Q7.949 7 8.615 7h6.77q.666 0 1.14.475q.475.474.475 1.14v6.77q0 .666-.475 1.14q-.474.475-1.14.475h-6.77q-.666 0-1.14-.475Q7 16.051 7 15.385" />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.dropped") }}</span>
          </div>
        </div>
        <div class="flex justify-evenly flex-wrap p-4 pt-0">
          <div class="flex flex-col items-center mb-4">
            <div class="flex">
              <svg
                class="cursor-pointer mt-[6px]"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                @click="rating = 0">
                <path
                  fill="currentColor"
                  d="m12 13.4l2.9 2.9q.275.275.7.275t.7-.275q.275-.275.275-.7t-.275-.7L13.4 12l2.9-2.9q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275L12 10.6L9.1 7.7q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7l2.9 2.9l-2.9 2.9q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275zm0 8.6q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22" />
              </svg>
              <v-rating
                hover
                half-increments
                v-model="rating"
                :length="5"
                :size="24"
                :model-value="animeUser?.score / 2"
                color="#ffffff"
                active-color="#FFB600"
                class="m-0 h-[26px]" />
            </div>
            <span class="font-medium text-sm mt-1">{{ $t("my_list.rating") }}</span>
          </div>
          <div class="flex flex-col items-center w-[74px]">
            <button
              class="rounded-full bg-[#ff7d7d] duration-300 hover:bg-white w-[50px] h-[50px] flex justify-center items-center"
              :class="{ active: animeUser?.favorite === 1 }"
              @click="menuFavAnime">
              <svg
                class="hover:text-[#ff7d7d] duration-300 w-full h-full p-2 mt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M12 20.325q-.35 0-.712-.125t-.638-.4l-1.725-1.575q-2.65-2.425-4.788-4.812T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.325 0 2.5.562t2 1.538q.825-.975 2-1.537t2.5-.563q2.35 0 3.925 1.575T22 8.15q0 2.875-2.125 5.275T15.05 18.25l-1.7 1.55q-.275.275-.637.4t-.713.125" />
              </svg></button
            ><span class="font-medium text-sm">{{ $t("my_list.favorite") }}</span>
          </div>
        </div>
      </div>
    </div>
  </ModalMenuAnime>
  <div>
    <div class="hidden sm:block mb-8 mt-5">
      <div class="flex">
        <v-avatar class="mr-6 ml-3 shadow-xl" :image="'/images/avatar_users/' + auth.user.avatar" size="195"></v-avatar>
        <div class="flex flex-col justify-evenly">
          <div>
            <h1 class="text-4xl font-bold">{{ auth.user.username }}</h1>
            <p>{{ $t("my_list.level") }} {{ auth.user.lvl }}</p>
          </div>
          <p class="text-xl font-medium">
            {{ $t("my_list.member_since") }} {{ formaterDate(auth.user.since) }} ({{
              daysElapsedSince(auth.user.since)
            }}
            {{ $t("my_list.days") }})
          </p>
        </div>
      </div>
    </div>
    <div class="submenu hidden sm:flex">
      <span @click="submenu(0)" class="slider_list_menu0"
        ><span>{{ $t("my_list.watching") }} • {{ anime[0]?.length }}</span></span
      >
      <span @click="submenu(1)"
        ><span>{{ $t("my_list.to_watch") }} • {{ anime[1]?.length }}</span></span
      >
      <span @click="submenu(2)"
        ><span>{{ $t("my_list.completed") }} • {{ anime[2]?.length }}</span></span
      >
      <span @click="submenu(3)"
        ><span>{{ $t("my_list.on_hold") }} • {{ anime[3]?.length }}</span></span
      >
      <span @click="submenu(4)"
        ><span>{{ $t("my_list.dropped") }} • {{ anime[4]?.length }}</span></span
      >
    </div>
    <div class="hidden sm:flex px-4 mb-10">
      <div
        v-for="(item, index) in anime"
        :key="item.id"
        class="justify-center flex slider_list w-full"
        :class="{ active: index === 0 }">
        <div class="flex justify-between" id="header-submenu">
          <h2 v-if="index === 0" class="slide_title text-[#2ecc71]">{{ $t("my_list.watching") }}</h2>
          <h2 v-if="index === 1" class="slide_title text-[#5d9cec]">{{ $t("my_list.to_watch") }}</h2>
          <h2 v-if="index === 2" class="slide_title text-[#ac92ec]">{{ $t("my_list.completed") }}</h2>
          <h2 v-if="index === 3" class="slide_title text-[#fcc200]">{{ $t("my_list.on_hold") }}</h2>
          <h2 v-if="index === 4" class="slide_title text-[#d8334a]">{{ $t("my_list.dropped") }}</h2>
          <h2 v-if="index === 5" class="slide_title text-[#d8334a]">Tous</h2>
          <v-text-field
            v-model="search"
            append-inner-icon="mdi-magnify"
            density="compact"
            label="Rechercher"
            single-line
            flat
            :loading="loading"
            clearable
            hide-details
            bg-color="#0f112b"
            @input="resetItem"
            @click:clear="
              search = '';
              resetItem();
            "
            class="max-w-[400px] rounded-lg border-none outline-none text-white overflow-hidden align-baseline"
            variant="solo"></v-text-field>
        </div>

        <div class="flex flex-wrap">
          <v-sheet
            v-for="(card, index2) in visibleAnime[index]"
            :key="card.id"
            :height="size"
            :class="{ 'bg-warning': card?.red }"
            class="flex align-center justify-start overflow-hidden bg-transparent w-full lg:w-1/2 pr-4 mb-3 rounded-2xl duration-300">
            <router-link :to="'/anime/' + card.url">
              <v-img
                :src="'/images/thumbnail/' + card.poster"
                loading="lazy"
                width="100"
                height="150"
                class="rounded-2xl shadow-3xl max-w-[100px] mr-4">
              </v-img>
            </router-link>
            <div class="w-full h-full py-3 pr-5">
              <div class="flex justify-between mb-1">
                <div class="pr-2">
                  <router-link :to="'/anime/' + card.url">
                    <h1 class="text-white font-medium text-xl line-clamp-3 leading-[1.4]">{{ card.title }}</h1>
                  </router-link>
                </div>
                <button
                  class="shadow-3xl hover:scale-110 bg-linear menu-anime"
                  @click="
                    menuAnimeToggleModal(
                      1,
                      card?.status,
                      card?.favorite,
                      card?.score,
                      card?.title,
                      card?.id,
                      index2 + (page - 1) * 10,
                      index
                    )
                  ">
                  <span class="line1"></span>
                  <span class="line2"></span>
                </button>
              </div>
              <div class="flex justify-between">
                <div class="flex">
                  <div class="flex">
                    <span class="font-medium text-gray-200">{{ $t("my_list.episode") }}: </span>
                    <span class="text-white ml-1">{{ card.nbEp }}</span>
                  </div>
                </div>
              </div>
            </div>
          </v-sheet>
        </div>
        <v-pagination v-if="index === 5" v-model="page2" :length="Math.ceil(searchResult?.length / 10)"></v-pagination>
        <v-pagination v-else v-model="page" :length="Math.ceil(anime[index]?.length / 10)"></v-pagination>
      </div>
    </div>
    <swiper class="mySwiper sm:hidden" style="height: calc(100vh - 50px)" :pagination="pagination" :modules="modules">
      <swiper-slide v-for="(items, index2) in [1, 2, 3, 4, 5]" :key="items.id" class="justify-center flex">
        <v-virtual-scroll :items="anime[index2]" height="calc(100vh - 50px)" :key="anime[index2]" item-height="150">
          <template v-slot:default="{ item, index }">
            <div v-if="index === 0" class="ml-2 mt-[70px]">
              <h2 v-if="index2 === 0" class="slide_title text-[#2ecc71]">{{ $t("my_list.watching") }}</h2>
              <h2 v-if="index2 === 1" class="slide_title text-[#5d9cec]">{{ $t("my_list.to_watch") }}</h2>
              <h2 v-if="index2 === 2" class="slide_title text-[#ac92ec]">{{ $t("my_list.completed") }}</h2>
              <h2 v-if="index2 === 3" class="slide_title text-[#fcc200]">{{ $t("my_list.on_hold") }}</h2>
              <h2 v-if="index2 === 4" class="slide_title text-[#d8334a]">{{ $t("my_list.dropped") }}</h2>
            </div>
            <v-sheet
              :height="size"
              :class="{ 'bg-warning': item?.red }"
              class="flex align-center justify-start overflow-hidden bg-transparent w-full px-2 mb-3 rounded-2xl duration-300">
              <router-link :to="'/anime/' + item.url">
                <v-img
                  :src="'/images/thumbnail/' + item?.poster"
                  loading="lazy"
                  width="100"
                  height="150"
                  class="rounded-2xl shadow-3xl max-w-[100px] mr-4">
                </v-img>
              </router-link>
              <div class="w-full h-full py-3 pr-5">
                <div class="flex justify-between mb-1">
                  <div class="pr-2">
                    <router-link :to="'/anime/' + item.url">
                      <h1 class="text-white font-medium text-xl line-clamp-3 leading-[1.4]">{{ item?.title }}</h1>
                    </router-link>
                  </div>
                  <button
                    class="shadow-3xl hover:scale-110 bg-linear menu-anime"
                    @click="
                      menuAnimeToggleModal(
                        1,
                        item?.status,
                        item?.favorite,
                        item?.score,
                        item?.title,
                        item?.id,
                        index,
                        index2
                      )
                    ">
                    <span class="line1"></span>
                    <span class="line2"></span>
                  </button>
                </div>
                <div class="flex justify-between">
                  <div class="flex">
                    <div class="flex">
                      <span class="font-medium text-gray-200">{{ $t("my_list.episode") }}: </span>
                      <span class="text-white ml-1">{{ item?.nbEp }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-sheet>
          </template>
        </v-virtual-scroll>
      </swiper-slide>
    </swiper>
    <v-snackbar v-model="snackbar" timeout="3000" class="text-center" color="#008ca8">
      <p class="font-medium text-center">{{ $t("my_list.status_updated") }}</p>
    </v-snackbar>
  </div>
</template>
<script setup>
  import { ref, onMounted, watch, computed } from "vue";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import { Pagination } from "swiper/modules";
  import { useAuthStore } from "@/stores/auth";
  import ModalMenuAnime from "@/components/ModalMenuAnime.vue";
  import "swiper/css";
  import "swiper/css/pagination";
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";
  import { useSeoMeta } from "@unhead/vue";
  import { useGlobalStore } from "@/stores/global";
  import { useFetch, useWebWorkerFn } from "@vueuse/core";

  const { workerFn, workerTerminate } = useWebWorkerFn(searchbar, {
    timeout: 30000,
  });
  const global = useGlobalStore();
  const { t } = useI18n();
  const router = useRouter();
  const auth = useAuthStore();
  const anime = ref([]);
  const modules = [Pagination];
  const page = ref(1);
  const page2 = ref(1);
  const itemsToShow = ref(10);
  const visibleAnime = ref([]);
  const activeSubMenu = ref(0);
  const animeUser = ref({});
  const menuAnimeModalActive = ref(null);
  const snackbar = ref(null);
  const search = ref("");
  const loading = ref(false);
  const searchResult = ref([]);
  let timeoutId;
  const rating = ref(null);
  const renderBtnIni = ref([
    {
      title: computed(() => t("my_list.watching")),
    },
    {
      title: computed(() => t("my_list.to_watch")),
    },
    {
      title: computed(() => t("my_list.completed")),
    },
    {
      title: computed(() => t("my_list.on_hold")),
    },
    {
      title: computed(() => t("my_list.dropped")),
    },
  ]);
  const renderBtn = ref([
    {
      title: computed(() => t("my_list.watching") + " • " + anime.value[0]?.length),
    },
    {
      title: computed(() => t("my_list.to_watch") + " • " + anime.value[1]?.length),
    },
    {
      title: computed(() => t("my_list.completed") + " • " + anime.value[2]?.length),
    },
    {
      title: computed(() => t("my_list.on_hold") + " • " + anime.value[3]?.length),
    },
    {
      title: computed(() => t("my_list.dropped") + " • " + anime.value[4]?.length),
    },
  ]);
  useSeoMeta({
    title: computed(() => t("header.menu.lists") + " - Baranimes"),
    ogTitle: computed(() => t("header.menu.lists") + " - Baranimes"),
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: computed(() => t("header.menu.lists") + " - Baranimes"),
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
  watch(page, (pageIndex) => {
    const start = (pageIndex - 1) * itemsToShow.value;
    const end = pageIndex * itemsToShow.value;
    visibleAnime.value[activeSubMenu.value] = [];
    for (let i = start; i < end && i < anime.value[activeSubMenu.value].length; i++) {
      visibleAnime.value[activeSubMenu.value].push(anime.value[activeSubMenu.value][i]);
    }
  });
  watch(page2, (pageIndex) => {
    const start = (pageIndex - 1) * itemsToShow.value;
    const end = pageIndex * itemsToShow.value;
    visibleAnime.value[5] = [];
    for (let i = start; i < end && i < anime.value[5].length; i++) {
      if (searchResult.value[i]) {
        visibleAnime.value[5].push(searchResult.value[i]);
      }
    }
  });
  watch(rating, async (value) => {
    animeUser.value.score = value * 2;
    await useFetch("/api/anime/user/update/rating", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: animeUser.value.id,
        value: value * 2,
      }),
    });
    anime.value[animeUser.value.status - 1][animeUser.value.index].score = value * 2;
    snackbar.value = true;
  });
  const pagination = ref({
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="shadow-3xl ${className}"><span class="nowrap flex place-content-center">${renderBtnIni.value[index].title}</span></span>`;
    },
  });
  onMounted(async () => {
    const { data: response } = await useFetch("/api/user/profile", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    anime.value = JSON.parse(response.value);
    if (!anime.value) {
      router.push({ name: "404" });
      return;
    }

    anime.value[5] = [].concat(anime.value[0], anime.value[1], anime.value[2], anime.value[3], anime.value[4]);

    global.pageLoad();
    updateSlicedArray();
    document.querySelectorAll(".swiper-pagination-bullet span").forEach(function (item, i) {
      item.innerText = renderBtn.value[i].title;
    });
  });
  const resetItem = () => {
    if (!search.value) {
      submenu(1);
      return;
    }
    loading.value = true;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(async function () {
      workerTerminate("PENDING");
      searchResult.value = await workerFn(JSON.stringify(anime.value[5]), JSON.stringify(search.value));
      visibleAnime.value[5] = [];
      for (let i = 0; i < 10 && i < anime.value[5].length; i++) {
        if (searchResult.value[i]) {
          visibleAnime.value[5].push(searchResult.value[i]);
        }
      }
      document.querySelectorAll(".submenu>span")[0].removeAttribute("class");
      document.querySelectorAll(".submenu>span")[0].classList.add("slider_list_menu5");

      document.querySelectorAll(".slider_list").forEach((_, i) => {
        document.querySelectorAll(".slider_list")[i].classList.remove("active");
      });
      document.querySelectorAll(".slider_list")[5].classList.add("active");
      activeSubMenu.value = 5;
      page2.value = 1;
      document.querySelectorAll("#header-submenu input")[5].focus();
      loading.value = false;
    }, 500);
  };
  function searchbar(animeInput, input) {
    animeInput = JSON.parse(animeInput);
    input = JSON.parse(input);
    let searchResultTempo = animeInput.filter((item) => {
      return item.title
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s]/gi, "")
        .replace(/[-/\\^$*+?.()|[\]{}]/g, "")
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim()
        .includes(
          input
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w\s]/gi, "")
            .replace(/[-/\\^$*+?.()|[\]{}]/g, "")
            .replace(/\s+/g, "")
            .toLowerCase()
            .trim()
        );
    });

    return searchResultTempo;
  }
  function formaterDate(dateStr) {
    if (!dateStr) {
      return "Inconnu";
    }
    let date = new Date(dateStr);
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let options = { timeZone: timeZone, year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat(undefined, options).format(date);
  }
  function daysElapsedSince(date) {
    const dateInMilliseconds = new Date(date).getTime();
    const currentDateInMilliseconds = new Date().getTime();
    const differenceInMilliseconds = currentDateInMilliseconds - dateInMilliseconds;
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return differenceInDays;
  }
  function submenu(submenu) {
    document.querySelectorAll(".submenu>span")[0].removeAttribute("class");
    document.querySelectorAll(".submenu>span")[0].classList.add("slider_list_menu" + submenu);

    document.querySelectorAll(".slider_list").forEach((_, i) => {
      document.querySelectorAll(".slider_list")[i].classList.remove("active");
    });
    document.querySelectorAll(".slider_list")[submenu].classList.add("active");
    activeSubMenu.value = submenu;
    updateSlicedArray();
  }
  const updateSlicedArray = () => {
    for (let idx = 0; idx < 5; idx++) {
      visibleAnime.value[idx] = [];
      for (let i = 0; i < itemsToShow.value && i < anime.value[idx].length; i++) {
        visibleAnime.value[idx].push(anime.value[idx][i]);
      }
    }
    page.value = 1;
  };
  async function menuAnime(value) {
    if (animeUser.value.status === value) {
      anime.value[animeUser.value.indexPrev][animeUser.value.index].red = 1;
      anime.value[animeUser.value.indexPrev][animeUser.value.index].status = 0;

      animeUser.value.status = 0;
    } else {
      anime.value[animeUser.value.indexPrev][animeUser.value.index].red = null;
      animeUser.value.status = value;
    }
    await useFetch("/api/anime/user/update/menu", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: animeUser.value.id,
        value: animeUser.value.status,
      }),
    });
    if (animeUser.value.status) {
      anime.value[animeUser.value.status - 1].push(anime.value[animeUser.value.indexPrev][animeUser.value.index]);
      anime.value[animeUser.value.indexPrev].splice(animeUser.value.index, 1);
      anime.value[animeUser.value.status - 1][anime.value[animeUser.value.status - 1].length - 1].status =
        animeUser.value.status;
      animeUser.value.index = anime.value[animeUser.value.status - 1].length - 1;
      animeUser.value.indexPrev = animeUser.value.status - 1;
    }

    const start = (page.value - 1) * itemsToShow.value;
    const end = page.value * itemsToShow.value;
    visibleAnime.value[activeSubMenu.value] = [];
    for (let i = start; i < end && i < anime.value[activeSubMenu.value].length; i++) {
      visibleAnime.value[activeSubMenu.value].push(anime.value[activeSubMenu.value][i]);
    }

    snackbar.value = true;
  }
  async function menuFavAnime() {
    if (animeUser.value?.favorite === 1) {
      animeUser.value.favorite = 0;
    } else {
      animeUser.value.favorite = 1;
    }
    await useFetch("/api/anime/user/update/fav", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idAnime: animeUser.value.id,
        value: animeUser.value.favorite,
      }),
    });
    anime.value[animeUser.value.status - 1][animeUser.value.index].favorite = animeUser.value.favorite;

    snackbar.value = true;
  }
  const menuAnimeToggleModal = (toggle, status, favorite, score, title, id, index, indexPrev) => {
    animeUser.value.id = id;
    animeUser.value.title = title;
    animeUser.value.status = status;
    animeUser.value.favorite = favorite;
    animeUser.value.score = score;
    animeUser.value.index = index;
    animeUser.value.indexPrev = indexPrev;

    if (toggle) {
      menuAnimeModalActive.value = true;
    } else {
      menuAnimeModalActive.value = false;
    }
  };
</script>
<style scoped>
  .slider_list {
    display: none;
  }
  .active.slider_list {
    display: block;
  }
  .slide_title {
    margin-bottom: 25px;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    border-left: 4px solid;
    padding-left: 20px;
  }
  .submenu {
    white-space: nowrap;
    overflow: visible;
    width: 100%;
    text-align: center;
    transition: 0.3s;
    margin-bottom: 35px;
    position: relative;
    margin-top: 5px;
  }
  .submenu > span {
    min-width: calc(20% - 10px);
    border-radius: 100px;
    font-weight: 600;
    height: 33px;
    margin: 0 5px;
    background: rgb(255 255 255 / 10%);
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .submenu > span:first-child::before {
    content: "";
    width: calc(20% - 10px);
    position: absolute;
    opacity: 1;
    border-radius: 100px;
    height: 33px;
    transition: 0.3s;
    pointer-events: none;
  }
  .submenu > span > span {
    position: relative;
  }
  .submenu .slider_list_menu0::before {
    background: #2ecc71;
    left: 5px;
  }

  .submenu .slider_list_menu1::before {
    background: #5d9cec;
    left: calc(20% + 5px);
  }

  .submenu .slider_list_menu2::before {
    background: #ac92ec;
    left: calc(40% + 5px);
  }

  .submenu .slider_list_menu3::before {
    background: #fcc200;
    left: calc(60% + 5px);
  }

  .submenu .slider_list_menu4::before {
    background: #d8334a;
    left: calc(80% + 5px);
  }
  :deep(
      .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
      .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet
    ) {
    min-width: calc(33.333333% - 10px);
    border-radius: 100px;
    font-weight: 600;
    height: 33px;
    margin: 0 5px;
    background: #02041f;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: 1;
    cursor: pointer;
    padding-top: 4px;
    transition: 0.3s;
  }
  :deep(
      .swiper-horizontal > .swiper-pagination-bullets,
      .swiper-pagination-bullets.swiper-pagination-horizontal,
      .swiper-pagination-custom,
      .swiper-pagination-fraction
    ) {
    top: 10px;
    height: 0;
    display: flex;
    justify-content: right;
  }

  :deep(
      .swiper-pagination-bullet:nth-child(3).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
    ) {
    margin-right: calc(-33.33333% + 5px) !important;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(2).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
    ) {
    margin-right: calc(-66.66666% + 5px) !important;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(1).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
    ) {
    margin-right: calc(-66.66666% + 5px) !important;
  }
  :deep(.swiper-pagination-bullet:nth-child(5)::before) {
    content: "";
    width: calc(33.33333% - 10px);
    position: absolute;
    opacity: 1;
    border-radius: 100px;
    height: 33px;
    transition: 0.3s;
    pointer-events: none;
    top: 0px;
    background: #d8334a;
    left: calc(66.66666% + 5px);
  }
  :deep(.swiper-pagination-bullet > span) {
    position: relative;
    z-index: 1;
  }
  :deep(
      .swiper-pagination-bullet:nth-child(1).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet::before
    ) {
    background: #2ecc71;
    left: 5px;
  }

  :deep(
      .swiper-pagination-bullet:nth-child(2).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet::before
    ) {
    background: #5d9cec;
    left: calc(33.33333% + 5px);
  }

  :deep(
      .swiper-pagination-bullet:nth-child(3).swiper-pagination-bullet-active
        + .swiper-pagination-bullet
        + .swiper-pagination-bullet::before
    ) {
    background: #ac92ec;
    left: calc(33.33333% + 5px);
  }

  :deep(.swiper-pagination-bullet:nth-child(4).swiper-pagination-bullet-active + .swiper-pagination-bullet::before) {
    background: #fcc200;
    left: calc(33.33333% + 5px);
  }
  .bg-warning {
    background: #99444485 !important;
  }
</style>

<template>
  <img
    :src="'https://baranimes.com/images/characters/' + var_photo"
    class="float overflow-hidden rounded-xl duration-100 canvas" />
  <div class="flex">
    <div class="bg-blue-400 text-white w-[500px] h-[500px] cursor-pointer" @click="updateGenre(1)" v-if="!load">
      HOMME
    </div>
    <div class="bg-pink-400 text-white w-[500px] h-[500px]cursor-pointer" @click="updateGenre(2)" v-if="!load">
      FEMME
    </div>
    <div class="bg-green-400 text-white w-[500px] h-[500px]cursor-pointer" @click="updateGenre(3)" v-if="!load">
      AUTRE
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted } from "vue";
  import { useFetch } from "@vueuse/core";

  const var_photo = ref(null);
  const load = ref(true);

  onMounted(async () => {
    const { data: response } = await useFetch("/api/admin/tiktok/tempo/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const r = JSON.parse(response.value);
    var_photo.value = r[0].photo;
    load.value = false;
  });
  async function updateGenre(genre) {
    load.value = true;
    await useFetch("/api/admin/tiktok/tempo/update", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        genre: genre,
        photo: var_photo.value,
      }),
    });
    const { data: response } = await useFetch("/api/admin/tiktok/tempo/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const r = JSON.parse(response.value);
    var_photo.value = r[0].photo;
    load.value = false;
  }
</script>

<style scoped></style>

<template>
  <div class="flex">
    <div class="containerVid mr-5">
      <div class="flex">
        <v-file-input
          v-if="!showVid && !loading2"
          class="mr-2 w-[400px] max-w-[250px] cursor-pointer"
          v-model="avatar"
          label="Vidéo mère non monté"
          @change="onFileChanged($event)"></v-file-input>
        <v-btn v-if="!showVid && avatar && !loading2" class="button-reverse text-white" @click="uploadFileInChunks"
          >Envoyer</v-btn
        >
        <v-file-input
          v-if="!showVid && !loading2"
          class="mr-2 w-[400px] max-w-[250px] cursor-pointer"
          v-model="avatar2"
          label="Vidéos déjà montées"
          multiple
          @change="onFileChanged2($event)"></v-file-input>
        <v-btn v-if="!showVid && avatar2 && type && !loading2" class="button-reverse text-white" @click="sendAvatar"
          >Envoyer</v-btn
        >
      </div>
      <span v-if="inventory?.length && !loading2 && !showVid">Vidéos en attente</span>
      <select
        v-model="selected"
        v-if="inventory?.length && !loading2 && !showVid"
        @change="selectedChange"
        class="text-black bg-white">
        <option v-for="(item, index) in inventory" :value="item.lien" :key="item.id">
          {{ index + 1 }}
        </option>
      </select>
      <v-progress-circular v-if="loading2" :size="40" color="#00bdbf" indeterminate></v-progress-circular>

      <video v-if="showVid && linkVid" @loadedmetadata="loadedmetadataFunc" id="video" width="250" preload="metadata">
        <source :src="'/videos/tiktok/' + linkVid" />
        Votre navigateur ne supporte pas la balise vidéo.
      </video>
      <input
        v-if="loading && !segments?.[0] && segments && linkVid"
        type="range"
        @input="inputFunc"
        v-model="timeSlider"
        min="0"
        max="15"
        step="0.01" />
      <div v-if="showVid">Début : <span id="currentTime">0</span>s</div>
    </div>

    <div>
      <span v-if="showVid">Type de vidéo</span>
      <br />
      <select v-if="showVid || avatar2" class="text-black bg-white" v-model="type" @change="typeChange" label="Type">
        <option value="Opening Easy">Opening Easy</option>
        <option value="Opening Medium">Opening Medium</option>
        <option value="Opening Hard">Opening Hard</option>
        <option value="Opening Easy to Hard">Opening Easy to Hard</option>
        <option value="Pixel anime">Pixel Anime</option>
        <option value="Pixel character">Pixel Character</option>
        <option value="Pixel video">Pixel Video</option>
        <option value="Picture">Picture</option>
        <option value="Random character">Random Character</option>
        <option value="Voice">Voice</option>
        <option value="Ending Easy">Ending Easy</option>
        <option value="Ending Medium">Ending Medium</option>
        <option value="Ending Hard">Ending Hard</option>
        <option value="Ending Easy to Hard">Ending Easy to Hard</option>
      </select>
      <br />
      <br />
      <span v-if="showVid && type && inventory2?.length">Parties récentes (dernière réponse de la 1ere partie)</span>
      <span v-else-if="showVid && type">Aucun partie récente dans cette catégorie</span>
      <br />
      <select
        v-model="selected2"
        v-if="showVid && type && inventory2?.length"
        @change="selectedChange2"
        class="text-black bg-white">
        <option v-for="item in inventory2" :value="item.segments" :key="item.id">
          {{ item.name + " " + item.creation }}
        </option>
      </select>
      <br />
      <br />
      <br />
      <br />
      <v-btn
        v-if="showVid && loading && type && selected2"
        class="button-reverse text-white mt-[-16px]"
        @click="sendFinal"
        >Envoyer</v-btn
      >
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import { useFetch } from "@vueuse/core";

  const type = ref("");
  const timeSlider = ref(0);
  const loading = ref(false);
  const loading2 = ref(false);
  const showVid = ref(false);
  const avatar = ref(null);
  const avatar2 = ref(null);
  const file = ref(null);
  const segments = ref(null);
  const linkVid = ref(null);
  const inventory = ref(null);
  const inventory2 = ref(null);
  const selected = ref(null);
  const selected2 = ref(null);
  const inputAva = ref(null);
  onMounted(async () => {
    const { data: response } = await useFetch("/api/admin/tiktok/cut/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    inventory.value = JSON.parse(response.value);
  });
  function selectedChange() {
    showVid.value = true;
    linkVid.value = selected.value;
    if (!linkVid.value) {
      showVid.value = false;
    }
  }
  function selectedChange2() {
    segments.value = selected2.value.split(",");
    for (let key in segments.value) {
      segments.value[key] = parseFloat(segments.value[key]);
    }
    document.getElementById("currentTime").textContent = segments.value[0];
    timeSlider.value = segments.value[0];
    document.getElementById("video").currentTime = (
      segments.value[1] -
      segments.value[0] -
      -timeSlider.value -
      0.5
    ).toFixed(2);
    console.log(
      (segments.value[1] - segments.value[0] - -timeSlider.value - 0.5).toFixed(2),
      segments.value[1],
      segments.value[0],
      timeSlider.value
    );
  }
  async function typeChange() {
    const { data: response } = await useFetch("/api/admin/tiktok/cut/get2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type.value,
      }),
    });
    inventory2.value = JSON.parse(response.value);
  }

  function loadedmetadataFunc() {
    loading.value = true;
  }

  // Mettre à jour la vidéo lorsque l'  input range change
  function inputFunc() {
    document.getElementById("currentTime").textContent = timeSlider.value;
    document.getElementById("video").currentTime = (
      segments.value[1] -
      segments.value[0] -
      -timeSlider.value -
      0.5
    ).toFixed(2);
    console.log(
      (segments.value[1] - segments.value[0] - -timeSlider.value - 0.5).toFixed(2),
      segments.value[1],
      segments.value[0],
      timeSlider.value
    );
  }
  function onFileChanged($event) {
    const target = $event.target;

    if (target && target.files) {
      file.value = target.files[0];
    }
  }
  function onFileChanged2($event) {
    const target = $event.target;

    if (target && target.files) {
      inputAva.value = target.files;
    }
  }
  async function sendAvatar() {
    loading2.value = true;
    for (let i = 0; i < inputAva.value.length; i++) {
      const formData = new FormData();
      formData.append("file", inputAva.value[i]);
      formData.append("type", type.value);
      await useFetch("/api/admin/tiktok/cut/add", {
        method: "post",
        body: formData,
      });
    }
    avatar2.value = null;
    loading2.value = false;
    type.value = null;
  }
  async function uploadFileInChunks() {
    loading2.value = true;
    const chunkSize = 100 * 1024 * 1024; // Taille des morceaux, ici 100MB
    const totalChunks = Math.ceil(file.value.size / chunkSize);
    const filename = Date.now() + "." + Math.round(Math.random() * 1e9) + ".mp4";
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(file.value.size, start + chunkSize);
      const chunk = file.value.slice(start, end);
      const formData = new FormData();
      formData.append("file", chunk);
      formData.append("chunkIndex", i);
      formData.append("totalChunks", totalChunks);
      formData.append("fileName", filename);

      await fetch("/api/upload/chunk", {
        method: "POST",
        body: formData,
      });
    }
    linkVid.value = filename;
    showVid.value = true;
    avatar.value = null;
    loading2.value = false;

    console.log("All chunks uploaded successfully");
  }
  async function sendFinal() {
    if (!type.value || !segments.value) {
      alert("Type de vidéo manquant");
      return;
    }
    const confirmation = confirm("Tout est correct ?");
    if (!confirmation) {
      return;
    }
    if (!segments.value[0]) {
      segments.value = segments.value.map((element) => element - -timeSlider.value);
      console.log(segments.value);
    }
    await useFetch("/api/admin/tiktok/cut", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        segments: segments.value,
        type: type.value,
        link: linkVid.value,
      }),
    });
    const { data: response } = await useFetch("/api/admin/tiktok/cut/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    inventory.value = JSON.parse(response.value);
    showVid.value = false;
    loading.value = false;
    type.value = null;
    selected.value = null;
    selected2.value = null;
  }
</script>
<style scoped>
  .containerVid {
    display: flex;
    flex-direction: column;
  }
  video {
    max-width: 100%;
    height: auto;
  }
  input[type="range"] {
    width: 250px;
  }
</style>

<template>
  <div>
    <div class="flex">
      <v-text-field
        class="mr-[10px] min-w-[150px] h-[60px] w-[50px]"
        label="Nombre de vidéos à télécharger (30 max)"
        type="number"
        min="1"
        max="30"
        step="1"
        v-model="nbrDL"></v-text-field>
      <div class="flex flex-col">
        <v-progress-circular
          class="mb-1"
          v-if="loading2"
          :size="40"
          color="#00bdbf"
          indeterminate></v-progress-circular>
        <span v-if="loading2" class="h-0 mt-[-6px] mr-1"
          >{{ Math.round(((nZipVideo - 1 + nZipVideo - 1 + nZipMinia - 1) / (nbrDL * 3)) * 100) }}%</span
        >
        <v-btn v-else class="button-reverse text-white mt-3" @click="getRandomBalancedArray">Télécharger</v-btn>
      </div>
    </div>
    <div class="flex justify-center flex-wrap p-1 mb-1 bg-second rounded-md">
      <div v-for="item in inventory" :key="item.id" class="m-2">
        <v-chip
          :class="{ green: item.nbr > 19, orange: item.nbr < 20 && item.nbr > 5, red: item.nbr < 6 }"
          :text="item.type + ' • ' + item.nbr"
          class="text-uppercase font-semibold"
          label></v-chip>
      </div>

      <div v-for="item in typesNonPresent" :key="item" class="m-2">
        <v-chip :text="item + ' • 0'" class="text-uppercase red font-semibold" label></v-chip>
      </div>
    </div>

    <v-card flat class="mb-10 bg-[#0f112b]">
      <v-card-title class="flex align-center pe-2">
        <v-icon icon="mdi-video-input-component"></v-icon> &nbsp; Gérer les tiktok

        <v-spacer></v-spacer>
        <span v-if="numero" class="mr-2 text-white">N°</span>
        <input
          v-if="numero"
          class="bg-white mr-10 w-[100px]"
          label="N°"
          type="number"
          min="1"
          step="1"
          @change="updateNumero"
          v-model="numero" />
        <span v-if="!loading" class="mr-2 text-white">Type :</span>
        <v-progress-circular class="mr-2" v-if="loading" :size="40" color="#00bdbf" indeterminate></v-progress-circular>
        <select v-else class="text-black bg-white mr-2" v-model="type" @change="typeChange" label="Type">
          <option value="Opening Easy">Opening Easy</option>
          <option value="Opening Medium">Opening Medium</option>
          <option value="Opening Hard">Opening Hard</option>
          <option value="Opening Easy to Hard">Opening Easy to Hard</option>
          <option value="Pixel anime">Pixel Anime</option>
          <option value="Pixel character">Pixel Character</option>
          <option value="Picture">Picture</option>
          <option value="Random character">Random Character</option>
          <option value="Random video">Random Video</option>
          <option value="Voice">Voice</option>
          <option value="Ending Easy">Ending Easy</option>
          <option value="Ending Medium">Ending Medium</option>
          <option value="Ending Hard">Ending Hard</option>
          <option value="Ending Easy to Hard">Ending Easy to Hard</option>
        </select>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          label="Search"
          single-line
          flat
          clearable
          hide-details
          variant="solo-filled"></v-text-field>
      </v-card-title>

      <v-divider></v-divider>
      <v-data-table
        v-model:search="search"
        :items="items"
        v-model:sort-by="sortBy"
        :items-per-page-options="[
          { value: 10, title: '10' },
          { value: 25, title: '25' },
          { value: 50, title: '50' },
          { value: 100, title: '100' },
        ]">
        <!-- eslint-disable-next-line -->
        <template v-slot:item.lien="{ item }">
          <div>
            <a target="_blank" :href="'https://baranimes.com/videos/tiktok/' + item.lien">{{ item.lien }}</a>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.etat="{ item }">
          <div>
            <v-chip
              :class="{ green: item.etat == 1, orange: item.etat == 2 }"
              :text="item.etat == 1 ? 'Disponible' : item.etat == 2 ? 'Déjà téléchargée' : ''"
              class="text-uppercase"
              label
              size="small"></v-chip>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.Téléchargement="{ item }">
          <svg
            v-if="item.etat == 1"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 24 24"
            class="cursor-pointer"
            @click.self="videoDL(['https://baranimes.com/videos/tiktok/' + item.lien], 1, $event)">
            <path
              fill="currentColor"
              d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z" />
          </svg>
          <svg
            v-else
            class="cursor-pointer"
            @click="restore(item.lien)"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 14 14">
            <g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round">
              <path d="m3.5 1.5l-3 3l3 3" />
              <path d="M.5 4.5h9a4 4 0 0 1 0 8h-5" />
            </g>
          </svg>
        </template>
      </v-data-table>
    </v-card>
    <div class="flex justify-center flex-wrap p-1 mb-1 bg-second rounded-md">
      <p>Vidéos mère en cours d'édition : {{ mere }}</p>
      <button class="reverse" @click="retest">Re dl minia</button>
    </div>
  </div>
  <div class="specific opacity-0 h-0 overflow-hidden">
    <input type="number" v-model="min" class="bg-white" />
    <input type="number" v-model="max" class="bg-white ml-4" />
    <input type="text" v-model="title" class="bg-white ml-4" />
    <input type="text" v-model="subtitle" class="bg-white ml-4" />
    <button
      v-if="difficulty != 4 && difficulty != 7"
      type="button"
      @click="test"
      id="commencer"
      class="btn btn-default">
      Commencer
    </button>
    <button v-else type="button" @click="start" class="btn btn-default" id="commencer">Commencer</button>
    <input
      type="radio"
      name="difficulty"
      id="opening_easy"
      v-model="difficulty"
      value="1"
      checked
      class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="opening_medium" v-model="difficulty" value="2" class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="opening_hard" v-model="difficulty" value="3" class="bg-white ml-4" />
    <input
      type="radio"
      name="difficulty"
      id="opening_easy_to_hard"
      v-model="difficulty"
      value="5"
      class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="ending" v-model="difficulty" value="6" class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="pixel_anime" v-model="difficulty" value="7" class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="pixel_character" v-model="difficulty" value="4" class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="picture" v-model="difficulty" value="8" class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="random_character" v-model="difficulty" value="9" class="bg-white ml-4" />
    <input type="radio" name="difficulty" id="voice" v-model="difficulty" value="10" class="bg-white ml-4" />

    <select class="text-black bg-white" v-model="select">
      <option value="">Null</option>
      <option value="Easy">Easy</option>
      <option value="Medium">Medium</option>
      <option value="Hard">Hard</option>
      <option value="Easy to Hard">Easy to Hard</option>
    </select>

    <div id="teste" class="test w-[550px]" style="position: relative">
      <div
        class="demo demo--lights"
        id="demo"
        :class="{
          bg1: difficulty == 1,
          bg2: difficulty == 2,
          bg3: difficulty == 3,
          bg4: difficulty == 4,
          bg5: difficulty == 5,
          bg6: difficulty == 6,
          bg7: difficulty == 7,
          bg8: difficulty == 8,
          bg9: difficulty == 9,
          bg10: difficulty == 10,
        }">
        <img src="/img/icons/svgexport-7.svg" alt="test" class="h-full w-full" />
      </div>
      <div class="flex flex-col absolute w-[550px] h-[65px] float" style="top: 45px; z-index: 100">
        <div class="flex flex-col relative h-[65px] float game mb-[170px] mt-[-30px]" v-if="difficulty == 9">
          <div
            class="text-[65px] text-center w-full absolute font-bold duration-500"
            style="
              line-height: 1.1;
              filter: drop-shadow(black 2px 4px 6px);
              top: 10px;
              color: white;
              letter-spacing: 1.1px;
            ">
            Guess the
          </div>
          <div
            class="text-[65px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 48px;
              background: linear-gradient(to right, #0ccfbc, rgb(199 224 255), #08d1bd) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Anime
          </div>

          <div
            class="text-[65px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 102px;

              color: white;
            ">
            With The
          </div>
          <div
            class="text-[65px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 153px;
              background: linear-gradient(90deg, rgb(255 41 104) 0px, rgb(254 214 182)) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Random
          </div>
          <div
            class="text-[65px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 204px;

              color: white;
            ">
            Character
          </div>
        </div>
        <div class="flex flex-col relative h-[65px] float game mb-[170px] mt-[-30px]" v-else-if="difficulty == 8">
          <div
            class="text-[68px] text-center w-full absolute font-bold duration-500"
            style="
              line-height: 1.1;
              filter: drop-shadow(black 2px 4px 6px);
              top: 10px;
              color: white;
              letter-spacing: 1.1px;
            ">
            Guess the
          </div>
          <div
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 51px;
              background: linear-gradient(to right, #00378d, rgb(155 200 255), #00378d) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Anime
          </div>

          <div
            class="text-[68px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 110px;

              color: white;
            ">
            With The
          </div>
          <div
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 165px;
              background: linear-gradient(90deg, rgb(255 194 41) 0px, rgb(247 119 18)) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Picture
          </div>
        </div>
        <div
          class="flex flex-col relative h-[65px] float game mb-[170px] mt-[-30px]"
          id="small"
          v-else-if="difficulty == 4 || difficulty == 7">
          <div
            class="text-[74px] text-center w-full absolute font-bold duration-500"
            style="
              line-height: 1.1;
              filter: drop-shadow(black 2px 4px 6px);
              top: 10px;
              color: white;
              letter-spacing: 1.1px;
            ">
            Guess the
          </div>
          <div
            v-if="difficulty == 7"
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 60px;
              background: linear-gradient(90deg, #008ca8 0, #00fddc);
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Pixelated
          </div>
          <div
            v-else
            class="text-[70px] text-center w-full absolute font-bold duration-500 uppercase"
            style="
              color: transparent;
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 60px;
              background: linear-gradient(to right, #680c61, #da96fc, #680c61) text;
              background-clip: text;
              font-weight: 900;
              letter-spacing: 1.5px;
            ">
            Pixelated
          </div>
          <div
            class="text-[74px] text-center w-full absolute font-bold top-[31px] duration-500"
            style="
              line-height: 1.5;
              filter: drop-shadow(black 2px 4px 6px);
              top: 120px;

              color: white;
            ">
            {{ difficulty == 4 ? "Character" : "Anime" }}
          </div>
        </div>
        <div
          v-else
          targetref="[object Object]"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-59"
          class="text-[72px] text-center w-full absolute font-bold"
          style="
            line-height: 1;
            filter: drop-shadow(black 2px 8px 5px);
            text-align: center;
            padding: 0 38px;
            text-transform: capitalize;
          ">
          <span v-if="difficulty == 10">Guess the </span>
          <p
            v-if="difficulty == 10"
            class="uppercase whitespace-nowrap my-[-10px]"
            style="
              color: transparent;
              background: linear-gradient(to right, rgb(204 255 250), rgb(199, 224, 255)) text;
              background-clip: text;
              font-weight: 900;
            ">
            anime
          </p>
          <span v-if="difficulty == 10">voice</span>

          <span v-else>Anime {{ difficulty == 6 ? "Ending" : "Opening" }} Quiz</span>
        </div>
        <div
          v-if="
            (difficulty == 5 || select == 'Easy to Hard') &&
            difficulty != 4 &&
            difficulty != 7 &&
            difficulty != 8 &&
            difficulty != 9
          "
          targetref="[object Object]"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-59"
          class="text-[56px] text-center w-full absolute font-bold top-[31px]"
          style="top: 155px; filter: drop-shadow(black 2px 8px 5px); text-align: center; text-transform: capitalize">
          <span class="text-[#56ff56]">Easy</span>
          <span class="lowercase"> to </span>
          <span class="text-[red]">Hard</span>
          <span> - #{{ n }}</span>
        </div>
        <div
          v-else-if="difficulty != 4 && difficulty != 7 && difficulty != 8 && difficulty != 9"
          targetref="[object Object]"
          aria-haspopup="menu"
          aria-expanded="false"
          aria-owns="v-menu-59"
          class="text-[56px] text-center w-full absolute font-bold top-[31px]"
          style="top: 155px; filter: drop-shadow(black 2px 8px 5px); text-align: center; text-transform: capitalize">
          <span
            :style="{
              color:
                difficulty == 1 || select == 'Easy'
                  ? '#56ff56'
                  : difficulty == 2 || select == 'Medium'
                  ? 'orange'
                  : difficulty == 3 || select == 'Hard'
                  ? 'red'
                  : '#cfa2ee',
            }"
            >{{ difficulty == 1 ? "Easy" : difficulty == 2 ? "Medium" : difficulty == 3 ? "Hard" : select }}</span
          >
          <span v-if="difficulty != 10"> - #{{ n }}</span>

          <span v-if="difficulty == 10" class="flex items-center justify-center mt-[360px]">
            <img
              v-if="difficulty == 10"
              class="mr-5"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Speaker_Icon.svg/1200px-Speaker_Icon.svg.png"
              style="width: 120px; filter: invert(1); margin-top: 5px" />
            #{{ n }}</span
          >
        </div>
        <!---->
      </div>
      <div
        v-if="difficulty != 4 && difficulty != 7 && difficulty != 8 && difficulty != 9"
        class="flex flex-wrap px-3"
        style="flex-wrap: nowrap; width: 550px; position: absolute; top: 340px">
        <div
          class="w-1/2 animeChoiceItem mb-6 float px-3"
          :class="{
            'px-3': difficulty != 10,
            'pl-[55px]': difficulty == 10,
            'mt-[-78px]': difficulty == 10,
            'mt-[-5px]': difficulty != 10,
          }">
          <div
            class="v-responsive v-img w-full shadow-3xl overflow-hidden rounded-xl"
            aria-label="Kakegurui"
            role="img"
            loading="lazy">
            <div class="v-responsive__sizer" style="padding-bottom: 150%"></div>
            <img
              v-if="difficulty == 10"
              @load="
                ready1 = true;
                test2();
              "
              class=""
              :src="'/images/characters/' + img1"
              alt="Kakegurui" />
            <img
              v-else
              @load="
                ready1 = true;
                test2();
              "
              class=""
              :src="'/images/upload/' + img1"
              alt="Kakegurui" />
          </div>
        </div>
        <div
          class="w-1/2 animeChoiceItem mb-6 float px-3"
          :class="{
            'pr-[55px]': difficulty == 10,
            'mt-[-78px]': difficulty == 10,
            'mt-[-5px]': difficulty != 10,
          }"
          style="margin-bottom: 40px">
          <div
            class="v-responsive v-img w-full shadow-3xl overflow-hidden rounded-xl"
            aria-label="One Piece"
            role="img"
            loading="lazy">
            <div class="v-responsive__sizer" style="padding-bottom: 150%"></div>
            <img
              v-if="difficulty == 10"
              @load="
                ready2 = true;
                test2();
              "
              class=""
              :src="'/images/characters/' + img2"
              alt="One Piece" />
            <img
              v-else
              @load="
                ready2 = true;
                test2();
              "
              class=""
              :src="'/images/upload/' + img2"
              alt="One Piece" />
          </div>
        </div>

        <div class="w-1/2 animeChoiceItem px-3 mb-6 mt-[-5px] float" v-if="difficulty != 10">
          <div
            class="v-responsive v-img w-full shadow-3xl overflow-hidden rounded-xl"
            aria-label="Dr. Stone"
            role="img"
            loading="lazy">
            <div class="v-responsive__sizer" style="padding-bottom: 150%"></div>
            <img
              @load="
                ready3 = true;
                test2();
              "
              :src="'/images/upload/' + img3"
              alt="Dr. Stone" /><!----><!----><!----><!----><!---->
          </div>
        </div>
        <div
          class="overflow-hidden h-[300px]"
          style="padding: 0px 40px; max-width: 550px; position: absolute; top: 60px; left: 0">
          <img
            v-if="difficulty == 1 || difficulty == 5"
            class="float"
            src="/images/global/bar1.webp"
            alt="Dr. Stone"
            style="height: 400px" />
          <img
            v-if="difficulty == 2"
            class="float"
            src="/images/global/bar1.webp"
            alt="Dr. Stone"
            style="height: 400px; filter: hue-rotate(250deg) brightness(1.3)" />
          <img
            v-if="difficulty == 3"
            class="float"
            src="/images/global/bar3.webp"
            alt="Dr. Stone"
            style="height: 400px; filter: brightness(1.6) saturate(1.6)" />
          <img
            v-if="difficulty == 6"
            class="float"
            src="/images/global/bar4.png"
            alt="Dr. Stone"
            style="height: 400px; filter: saturate(0.5)" />
        </div>
      </div>
      <div
        v-else
        style="flex-wrap: nowrap; width: 550px; position: absolute; top: 260px"
        class="flex justify-center flex-col place-items-center">
        <canvas
          v-if="difficulty != 8 && difficulty != 9"
          id="canvas"
          width="200"
          height="312"
          class="float shadow-3xl overflow-hidden rounded-xl w-[40%]"></canvas>
        <img
          v-else-if="difficulty == 8"
          :src="'/images/screenshots/' + img1"
          class="float shadow-3xl overflow-hidden rounded-xl w-[80%] mt-[25px]"
          @load="test2()" />
        <img
          v-else
          :src="'/images/characters/' + img1"
          class="float shadow-3xl overflow-hidden rounded-xl w-[35%] mt-[60px]"
          @load="test2()" />
        <input id="blocks" type="range" min="1" max="100" value="14" class="hidden" />
        <div
          class="text-[56px] text-center w-full font-bold duration-500 mt-[-5px] float"
          style="
            line-height: 1.5;
            filter: drop-shadow(black 2px 4px 6px);

            color: white;
          ">
          #{{ n }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useHead } from "@unhead/vue";
  useHead({
    script: [
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.5/jszip.min.js",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js",
      },
      {
        src: "https://cdnjs.cloudflare.com/ajax/libs/dom-to-image/2.6.0/dom-to-image.js",
      },
    ],
  });

  const inventory = ref(null);
  const search = ref("");
  const items = ref([]);
  const loading = ref(false);
  const loading2 = ref(false);
  const sortBy = ref([{ key: "statut", order: "desc" }]);
  const type = ref("");
  const nbrDL = ref(null);
  const mere = ref(0);
  const typesNonPresent = ref([]);
  const nZipMinia = ref(1);
  const nZipVideo = ref(1);
  const numero = ref(0);
  let resultMinia = [];
  let resultMiniaOri = [];

  onMounted(async () => {
    const { data: response } = await useFetch("/api/admin/tiktok/manage/get/mere", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    mere.value = JSON.parse(response.value)[0].nbr;
    getNumber();
  });
  async function updateNumero() {
    await useFetch("/api/admin/tiktok/manage/update/numero", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        numero: numero.value,
        type: type.value,
      }),
    });
  }
  async function getNumber() {
    const { data: response } = await useFetch("/api/admin/tiktok/manage/get/number", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    inventory.value = JSON.parse(response.value);

    // Le second tableau avec tous les types possibles
    const tousTypes = [
      "Opening Easy",
      "Opening Medium",
      "Opening Hard",
      "Opening Easy to Hard",
      "Pixel anime",
      "Pixel character",
      "Picture",
      "Random character",
      "Voice",
      "Ending Easy",
      "Ending Medium",
      "Ending Hard",
      "Ending Easy to Hard",
    ];

    // Extraire les types du premier tableau
    const typesDansTableau1 = inventory.value.map((item) => item.type);

    // Filtrer les types qui ne sont pas dans le premier tableau
    typesNonPresent.value = tousTypes.filter((type) => !typesDansTableau1.includes(type));
  }

  async function getRandomBalancedArray() {
    nZipMinia.value = 1;
    nZipVideo.value = 1;
    const x = nbrDL.value;
    if (!x || x < 1 || x > 30) {
      return;
    }
    loading2.value = true;

    let openingTotal = { type: "Opening", nbr: 0 };

    let nonOpening = [];

    inventory.value.forEach((item) => {
      if (item.type.startsWith("Opening")) {
        openingTotal.nbr += parseInt(item.nbr, 10);
      } else {
        nonOpening.push(item);
      }
    });

    let arr4;
    if (openingTotal.nbr > 0) {
      arr4 = [openingTotal, ...nonOpening];
    } else {
      arr4 = inventory.value;
    }
    let openingTotal2 = { type: "Ending", nbr: 0 };

    let nonOpening2 = [];

    arr4.forEach((item) => {
      if (item.type.startsWith("Ending")) {
        openingTotal2.nbr += parseInt(item.nbr, 10);
      } else {
        nonOpening2.push(item);
      }
    });
    let arr5;
    if (openingTotal2.nbr > 0) {
      arr5 = [openingTotal2, ...nonOpening2];
    } else {
      arr5 = arr4;
    }

    const arr3 = arr5.map((item) => item.type);
    const arr2 = arr5.filter((item) => item.nbr >= Math.floor(x / arr3.length) + 1);
    if (!arr2.length || (arr2.length == 1 && arr2[0].nbr < x)) {
      loading2.value = false;

      alert("Pas assez de vidéo disponible");
      return;
    }
    console.log(arr2);
    const confirmation = confirm("Télécharger les vidéos ?");
    if (!confirmation) {
      loading2.value = false;
      return;
    }
    const arr = arr2.map((item) => item.type);
    // const categoriesCount = arr.length;
    // const result = [];
    // const occurrences = new Array(categoriesCount).fill(0);
    // const maxOccurrences = Math.floor(x / categoriesCount) + 1;
    // const indexArray = [...Array(categoriesCount).keys()];

    // for (let i = 0; i < x; i++) {
    //   let added = false;
    //   while (!added) {
    //     const randomIndex = indexArray[Math.floor(Math.random() * indexArray.length)];
    //     if (occurrences[randomIndex] < maxOccurrences) {
    //       result.push(arr[randomIndex]);
    //       occurrences[randomIndex]++;
    //       added = true;
    //     }
    //   }
    // }
    const categoriesCount = arr.length;
    const result = [];
    const occurrences = new Array(categoriesCount).fill(0);
    const maxOccurrences = Math.floor(x / categoriesCount) + 1;
    const indexArray = [...Array(categoriesCount).keys()];

    // Fonction pour vérifier si un élément a été récemment ajouté
    const recentlyAdded = (index, recent, threshold) => {
      for (let i = recent.length - 1; i >= Math.max(0, recent.length - threshold); i--) {
        if (recent[i] === index) {
          return true;
        }
      }
      return false;
    };

    const recentElements = [];
    const maxRecent = Math.floor(x / categoriesCount);
    const maxAttempts = 10; // Nombre maximum de tentatives pour éviter une boucle infinie

    for (let i = 0; i < x; i++) {
      let added = false;
      let attempts = 0;

      while (!added && attempts < maxAttempts) {
        const randomIndex = indexArray[Math.floor(Math.random() * indexArray.length)];
        if (occurrences[randomIndex] < maxOccurrences && !recentlyAdded(randomIndex, recentElements, maxRecent)) {
          result.push(arr[randomIndex]);
          occurrences[randomIndex]++;
          recentElements.push(randomIndex);
          if (recentElements.length > maxRecent) {
            recentElements.shift();
          }
          added = true;
        }
        attempts++;
      }

      if (!added) {
        // Si nous n'avons pas pu ajouter un élément après maxAttempts, on ajoute un élément sans se soucier des répétitions
        const randomIndex = indexArray[Math.floor(Math.random() * indexArray.length)];
        result.push(arr[randomIndex]);
        occurrences[randomIndex]++;
        recentElements.push(randomIndex);
        if (recentElements.length > maxRecent) {
          recentElements.shift();
        }
      }
    }
    let end = [];
    for (let i = 0; i < result.length; i++) {
      const { data: response } = await useFetch("/api/admin/tiktok/manage/get/video", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: result[i],
        }),
      });
      const r = JSON.parse(response.value);
      end.push("https://baranimes.com/videos/tiktok/" + r[0]);
      if (r[1]) {
        result[i] = r[1];
      }
    }
    resultMinia = result;
    resultMiniaOri = result;
    videoDL(end);
  }

  async function typeChange() {
    if (type.value) {
      loading.value = true;
      items.value = [];
      numero.value = 0;
      const { data: response } = await useFetch("/api/admin/tiktok/manage/get", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: type.value,
        }),
      });
      items.value = JSON.parse(response.value);
      const { data: response2 } = await useFetch("/api/admin/tiktok/manage/get/numero", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: type.value,
        }),
      });
      numero.value = JSON.parse(response2.value)[0].numero;
      loading.value = false;
    }
  }
  async function restore(link) {
    const confirmation = confirm("Restaurer la vidéo ?");
    if (!confirmation) {
      return;
    }
    await useFetch("/api/admin/tiktok/manage/update", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        link: link,
        etat: 1,
      }),
    });
    typeChange();
    getNumber();
  }
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function videoDL(videoUrls, confirmer, event) {
    if (!videoUrls.length) {
      return;
    }

    if (confirmer) {
      const confirmation = confirm("Télécharger les vidéos ?");
      if (!confirmation) {
        loading2.value = false;
        return;
      }
      event.target.classList.add("rotate");
    }
    // eslint-disable-next-line
    const zip = new JSZip();
    // Fonction pour récupérer les vidéos et les ajouter au zip
    const fetchAndAddToZip = async (url, name) => {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`Error fetching ${url}: ${response.statusText}`);
        const blob = await response.blob();
        zip.file(name, blob);
        if (confirmer) {
          await useFetch("/api/admin/tiktok/manage/update", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              link: url.replace("https://baranimes.com/videos/tiktok/", ""),
              etat: 2,
            }),
          });
        }
        nZipVideo.value++;
      } catch (error) {
        console.error(`Error adding ${name} to zip:`, error);
      }
    };

    for (const url of videoUrls) {
      const filename = `${nZipVideo.value}_video_${Date.now().toString(16)}.mp4`;
      await fetchAndAddToZip(url, filename);
    }
    if (confirmer) {
      event.target.classList.remove("rotate");
    }
    try {
      const content = await zip.generateAsync({ type: "blob" });
      const a = document.createElement("a");
      a.href = URL.createObjectURL(content);
      a.download = "videos.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error generating zip:", error);
    }
    typeChange();
    getNumber();
    zipAuto();
  }

  //minia

  let ctx;
  let img;
  let play;
  const n = ref(0);
  const title = ref("Opening");
  const subtitle = ref("");
  const difficulty = ref(1);
  const img1 = ref(null);
  const img2 = ref(null);
  const img3 = ref(null);
  const ready1 = ref(false);
  const ready2 = ref(false);
  const ready3 = ref(false);
  const active = ref(false);
  const select = ref("");
  const skipZip = ref(false);
  const nZip = ref(0);
  let zipMinia;
  function loadZip() {
    // eslint-disable-next-line
    zipMinia = new JSZip();
  }
  function zipAuto() {
    loadZip();
    skipZip.value = true;
    nZip.value = 0;
    resultMinia = resultMinia.map((type) => type.replace(/\s+/g, "_").toLowerCase());
    let regex = /ending/i;
    if (regex.test(resultMinia[nZip.value])) {
      select.value = resultMinia[nZip.value].replace(/ending_/i, "");
      select.value.charAt(0).toUpperCase() + select.value.slice(1);
      setTimeout(function () {
        document.getElementById("ending").click();
      }, 1);
    } else {
      document.getElementById(resultMinia[nZip.value]).click();
    }
    setTimeout(function () {
      document.getElementById("commencer").click();
    }, 200);
  }

  async function test4() {
    if (!skipZip.value) {
      loadZip();
    }
    var node = document.getElementById("teste");
    const { data: response } = await useFetch("/api/admin/tiktok/manage/get/numero", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: resultMiniaOri[nZip.value],
      }),
    });
    n.value = JSON.parse(response.value)[0].numero;
    await useFetch("/api/admin/tiktok/manage/update/numero", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        numero: n.value + 1,
        type: resultMiniaOri[nZip.value],
      }),
    });
    await delay(200);
    // eslint-disable-next-line
    await domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        zipMinia.file(`${nZipMinia.value}_minia_${Date.now().toString(16)}.png`, dataUrl.split(",")[1], {
          base64: true,
        });
        nZipMinia.value++;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });

    nZip.value++;
    if (!resultMinia?.[nZip.value]) {
      skipZip.value = false;
      generateZip();
    } else {
      let regex = /ending/i;
      if (regex.test(resultMinia[nZip.value])) {
        select.value = resultMinia[nZip.value].replace(/ending_/i, "");
        select.value.charAt(0).toUpperCase() + select.value.slice(1);
        setTimeout(function () {
          document.getElementById("ending").click();
        }, 1);
      } else {
        document.getElementById(resultMinia[nZip.value]).click();
      }
      setTimeout(function () {
        document.getElementById("commencer").click();
      }, 200);
    }
  }
  function generateZip() {
    zipMinia.generateAsync({ type: "blob" }).then((blob) => {
      // Créez un lien de téléchargement pour le fichier ZIP
      const lienZip = document.createElement("a");
      lienZip.href = URL.createObjectURL(blob);
      lienZip.download = "images.zip"; // Nom du fichier ZIP
      document.body.appendChild(lienZip);
      lienZip.click();
      document.body.removeChild(lienZip);
    });
    typeChange();
    loading2.value = false;
  }
  async function test() {
    if (difficulty.value != 8 && difficulty.value != 9 && difficulty.value != 10) {
      const { data: response } = await useFetch("/api/tiktok/thumbnail", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          difficulty: difficulty.value,
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].poster;
      img2.value = r[1].poster;
      img3.value = r[2].poster;
    } else if (difficulty.value == 8) {
      const { data: response } = await useFetch("/api/admin/tiktok/screenshot", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia picture",
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].photo;
    } else if (difficulty.value == 10) {
      const { data: response } = await useFetch("/api/admin/tiktok/character/random", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia random character",
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].photo;
      const { data: response2 } = await useFetch("/api/admin/tiktok/character/random", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia random character",
        }),
      });
      const r2 = JSON.parse(response2.value);
      img2.value = r2[0].photo;
    } else {
      const { data: response } = await useFetch("/api/admin/tiktok/character/random", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          type: "Minia random character",
        }),
      });
      const r = JSON.parse(response.value);
      img1.value = r[0].photo;
    }
  }
  async function test2() {
    console.log(ready1.value, ready2.value, ready3.value, active.value);
    if (
      ((!ready1.value || !ready2.value || !ready3.value) &&
        difficulty.value != 8 &&
        difficulty.value != 9 &&
        difficulty.value != 10) ||
      active.value
    ) {
      return;
    }
    if ((!ready1.value || !ready2.value) && difficulty.value == 10) {
      return;
    }
    active.value = true;
    if (!skipZip.value) {
      loadZip();
    }
    var node = document.getElementById("teste");
    const { data: response } = await useFetch("/api/admin/tiktok/manage/get/numero", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: resultMiniaOri[nZip.value],
      }),
    });
    n.value = JSON.parse(response.value)[0].numero;
    await useFetch("/api/admin/tiktok/manage/update/numero", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        numero: n.value + 1,
        type: resultMiniaOri[nZip.value],
      }),
    });
    await delay(200);
    // eslint-disable-next-line
    await domtoimage
      .toPng(node)
      .then(function (dataUrl) {
        zipMinia.file(`${nZipMinia.value}_minia_${Date.now().toString(16)}.png`, dataUrl.split(",")[1], {
          base64: true,
        });
        nZipMinia.value++;
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });

    ready1.value = false;
    ready2.value = false;
    ready3.value = false;
    active.value = false;
    nZip.value++;
    if (!resultMinia?.[nZip.value]) {
      skipZip.value = false;
      generateZip();
    } else {
      let regex = /ending/i;
      if (regex.test(resultMinia[nZip.value])) {
        select.value = resultMinia[nZip.value].replace(/ending_/i, "");
        select.value.charAt(0).toUpperCase() + select.value.slice(1);
        setTimeout(function () {
          document.getElementById("ending").click();
        }, 1);
      } else {
        document.getElementById(resultMinia[nZip.value]).click();
      }
      setTimeout(function () {
        document.getElementById("commencer").click();
      }, 200);
    }
  }
  function retest() {
    ready1.value = true;
    ready2.value = true;
    ready3.value = true;
    test2();
  }
  async function start() {
    setTimeout(async function () {
      if (difficulty.value == 7) {
        const { data: response } = await useFetch("/api/admin/tiktok/pixel/anime", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Minia pixel anime",
          }),
        });
        const r = JSON.parse(response.value);

        // (C) Ken Fyrstenberg, Epistemex, License: CC3.0-attr
        ctx = document.getElementById("canvas").getContext("2d");
        img = new Image();
        play = false;

        // turn off image smoothing - this will give the pixelated effect
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;

        // wait until image is actually available
        img.onload = pixelate;

        // some image, we are not struck with CORS restrictions as we
        // do not use pixel buffer to pixelate, so any image will do
        img.src = "https://baranimes.com/images/upload/" + r[0].poster;
      } else {
        const { data: response } = await useFetch("/api/admin/tiktok/character", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Minia pixel character",
          }),
        });
        const r = JSON.parse(response.value);

        // (C) Ken Fyrstenberg, Epistemex, License: CC3.0-attr
        ctx = document.getElementById("canvas").getContext("2d");
        img = new Image();
        play = false;

        // turn off image smoothing - this will give the pixelated effect
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;

        // wait until image is actually available
        img.onload = pixelate;

        // some image, we are not struck with CORS restrictions as we
        // do not use pixel buffer to pixelate, so any image will do
        img.src = "https://baranimes.com/images/characters/" + r[0].photo;
      }
    }, 1);
  }
  // MAIN function
  function pixelate(v) {
    // if in play mode use that value, else use slider value
    var size = (play ? v : document.getElementById("blocks").value) * 0.01,
      // cache scaled width and height
      w = document.getElementById("canvas").width * size,
      h = document.getElementById("canvas").height * size;

    // draw original image to the scaled size
    ctx.drawImage(img, 0, 0, w, h);

    // then draw that scaled image thumb back to fill canvas
    // As smoothing is off the resultMinia will be pixelated
    ctx.drawImage(
      document.getElementById("canvas"),
      0,
      0,
      w,
      h,
      0,
      0,
      document.getElementById("canvas").width,
      document.getElementById("canvas").height
    );
    test4();
  }
</script>
<style scoped>
  .rotate {
    animation: rotate-animation 1s infinite linear;
  }
  @keyframes rotate-animation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .red {
    color: red;
  }
  .orange {
    color: orange;
  }
  .green {
    color: lightgreen;
  }
  :deep(.v-card-title),
  :deep(.v-data-table),
  :deep(.v-field),
  :deep(.v-card) {
    background: #0f112b;
    color: white;
  }
  :deep(.v-chip--variant-tonal) {
    color: auto;
  }
  @keyframes float {
    0% {
      transform: rotate(0.5deg) translateY(3px) translateX(2px);
    }

    50% {
      transform: rotate(-0.5deg) translateY(-2px) translateX(-1px);
    }

    100% {
      transform: rotate(0.5deg) translateY(3px) translateX(2px);
    }
  }
  .float {
    /* animation: float 4s ease-in-out infinite; */
  }
  .defs {
    position: relative;
    z-index: -2;
    top: 0;
    animation: rotation 2s infinite linear;
  }
  .demo.bg1 {
    background: conic-gradient(from 225deg, #008ea9, #00ce5e, #008ea9);
  }
  .demo.bg2 {
    background: conic-gradient(from 225deg, #ffca00, #ff9000, #ffca00);
  }
  .demo.bg3 {
    background: conic-gradient(from 225deg, #fb405b, #ae47e2, #fb405b);
  }
  .demo.bg4 {
    background: conic-gradient(from 225deg, #680c61, #da96fc, #680c61);
  }
  .demo.bg5 {
    background: conic-gradient(from 225deg, #00ce5e, #ff005e, #00ce5e);
  }
  .demo.bg6 {
    background: conic-gradient(from 225deg, #008ea9, #00fddc, #008ea9);
  }
  .demo.bg7 {
    background: conic-gradient(from 225deg, #008ea9, #ae47e2, #008ea9);
  }
  .demo.bg8 {
    background: conic-gradient(from 225deg, #ff8b00, #4158ff, #ff8b00);
  }
  .demo.bg9 {
    background: conic-gradient(from 225deg, #05d3be, #ff4165, #05d3be);
  }
  .demo.bg10 {
    background: conic-gradient(from 225deg, rgb(195 27 136), rgb(87 215 202), rgb(195 27 136));
  }
  .demo--lights {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(5px);
    z-index: 0;
    width: 550px;
    height: 700px;
  }

  .group--stars {
    transform: scale(0.9);
  }

  .star--outline {
    transform-origin: 75pxr 75px;
    transform: scale(1.2);
    fill: none;
    stroke: white;
    stroke-width: 2;
    stroke-dasharray: 4;
    animation: stardashoffset 2s infinite linear;
  }

  .group--stars-anim {
    use {
      transform-origin: 75pxr 75px;
      animation: starmove 8s infinite linear;
    }

    @for $item from 2 through 4 {
      use:nth-child(#{$item}) {
        transform: scale(1- ($item/5));
      }
    }
  }

  .c-lights {
    fill: none;
    transform-origin: 320px 320px;
    animation: rotation 20s infinite linear;
  }

  @keyframes starmove {
    $trans-min: 0.8;
    $trans-max: 1.2;

    $trans-min-offset: 0.7;
    $trans-max-offset: 1.3;

    0% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }

    12.5% {
      transform-origin: 75pxr 75px * $trans-min-offset * 0.95;
    }

    25% {
      transform-origin: 75pxr * $trans-max * 1.2 75px * $trans-min;
    }

    37.5% {
      transform-origin: 75pxr * $trans-max * 1.15 75px;
    }

    50% {
      transform-origin: 75pxr * $trans-max * 1.1 75px * $trans-max * 1.05;
    }

    65.5% {
      transform-origin: 75pxr 75px * $trans-max-offset * 1.1;
    }

    75% {
      transform-origin: 75pxr * $trans-min * 0.9 75px * $trans-max * 1.05;
    }

    87.5% {
      transform-origin: 75pxr * $trans-min-offset * 0.95 75px;
    }

    100% {
      transform-origin: 75pxr * $trans-min * 0.8 75px * $trans-min;
    }
  }

  @keyframes rotation {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes stardashoffset {
    100% {
      stroke-dashoffset: -32;
    }
  }
</style>

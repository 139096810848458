<template>
  <!-- <div
    class="container-ava"
    :style="`background:linear-gradient(45deg, #${characters[indexAva]?.backgroundGradientColors[0].slice(
      0,
      6
    )} 0%, #${characters[indexAva]?.backgroundGradientColors[1].slice(0, 6)} 30%, #${characters[
      indexAva
    ]?.backgroundGradientColors[2].slice(0, 6)} 65%, #${characters[indexAva]?.backgroundGradientColors[3].slice(
      0,
      6
    )} 100%)`"> -->
  <div class="container-ava">
    <span class="character-background">
      <p>{{ displayName }}</p>
      <p>{{ displayName }}</p>
      <p>{{ displayName }}</p>
      <p>{{ displayName }}</p>
      <p>{{ displayName }}</p>
      <p>{{ displayName }}</p>
    </span>
    <h2 class="character-name">
      {{ displayName }}
    </h2>
    <div class="characters" id="characters">
      <div
        class="portrait-container-ava"
        :style="`filter:
        drop-shadow(20px 10px 0 ${colorAvatar})`">
        <v-img :src="'/images/avatar_users/' + fullPortrait" :alt="displayName" style="position: revert" />
      </div>
    </div>

    <div class="character-select">
      <div class="character-select-wrapper" id="character-select">
        <div v-for="(el, index) in characters[0]" :key="el.id">
          <div @click="setActive(index)">
            <v-img :src="'/images/avatar_users/' + el.lien" :alt="el.titre" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import { useGlobalStore } from "@/stores/global";
  import { useFetch } from "@vueuse/core";

  const global = useGlobalStore();
  const characters = ref([]);
  const displayIcon = ref(null);
  const displayName = ref(null);
  const fullPortrait = ref(null);
  const colorAvatar = ref(null);
  const indexAva = ref(5);
  onMounted(async () => {
    global.pageLoad();
    if (global.pageTransition) {
      global.pageTransition = false;
      document.getElementById("router").classList.remove("translateNegativeY");
      document.getElementById("router").classList.add("translateYsecond");
      document.getElementById("logo").classList.remove("active");
      setTimeout(function () {
        document.getElementById("router").classList.remove("test");
        document.getElementById("router").classList.remove("translateYsecond");
      }, 50);
    } else {
      global.back = true;
      document.getElementById("back").classList.add("active");
    }
    const { data: response } = await useFetch("/api/avatar/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    characters.value = JSON.parse(response.value);
    setTimeout(function () {
      setActive(2, 1);
    }, 1);
  });

  function setActive(index, first) {
    let tempo = indexAva.value;
    indexAva.value = index;
    removeAllActive();
    document.getElementById("character-select").children[index]?.classList.add("active");

    updateAgent(index);
    if (first) {
      fullPortrait.value = characters.value[0][index].lien;
      colorAvatar.value = characters.value[0][index].couleur;
    } else {
      changeWallpaperPosition(tempo, index);
    }
  }

  function removeAllActive() {
    for (let i = 0; i < document.getElementById("character-select").children.length; i++) {
      document.getElementById("character-select").children[i].classList.remove("active");
    }
  }

  function updateAgent(index) {
    displayIcon.value = characters.value[0][index].background;
    displayName.value = characters.value[0][index].titre;
  }

  function changeWallpaperPosition(tempo, index) {
    document.getElementById("characters").classList.add("animate");
    if (index > tempo) {
      document.getElementById("characters").style.left = `-100vw`;
    } else if (index < tempo) {
      document.getElementById("characters").style.left = `100vw`;
    }
    setTimeout(function () {
      fullPortrait.value = characters.value[0][index].lien;
      colorAvatar.value = characters.value[0][index].couleur;

      document.getElementById("characters").classList.add("transi");
      if (index > tempo) {
        document.getElementById("characters").style.left = `100vw`;
      } else if (index < tempo) {
        document.getElementById("characters").style.left = `-100vw`;
      }
    }, 150);
    setTimeout(function () {
      document.getElementById("characters").classList.remove("transi");
      document.getElementById("characters").classList.add("right");
      document.getElementById("characters").style.left = 0;
    }, 160);

    setTimeout(function () {
      document.getElementById("characters").classList.remove("right");

      document.getElementById("characters").classList.remove("animate");
    }, 300);
  }
</script>
<style scoped>
  .container-ava {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
    transition: 0.3s;
  }
  .container-ava::before {
    content: "";
    position: absolute;
    inset: -15px;
    width: 110%;
    height: 110%;
    background: var(--background);
    filter: brightness(85%);
    z-index: -1;
  }

  .character-name {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20vw;
    font-weight: 800;
    text-transform: uppercase;
    max-width: 100vw;
    text-align: center;
    color: #c7cef8;
    text-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    user-select: none;
  }

  .character-background {
    position: absolute;
    top: 50%;
    left: -10%;
    transform: translateY(-50%);
    width: 70%;
    height: 100%;
    opacity: 0.1;
    pointer-events: none;
  }
  .character-background p {
    left: 37%;
    position: relative;
    top: 20%;
    font-size: 89px;
    text-transform: uppercase;
    font-family: "Insomnia";
    margin-top: -35px;
  }

  .info {
    position: absolute;
    right: 2%;
    top: 50%;
    max-width: 23%;
    height: 34%;
    background: rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    padding: 1.6em;
    border-radius: 0.8em;
    overflow-y: auto;
  }
  @media only screen and (max-width: 1500px) {
    .info {
      max-width: 34%;
    }
  }
  @media only screen and (max-width: 980px) {
    .info {
      height: auto;
      max-height: 34%;
    }
  }
  @media only screen and (max-width: 680px) {
    .info {
      display: none;
    }
  }
  .info .info-p {
    font-weight: 300;
    font-size: 21px;
    word-break: break-word;
    color: #fff;
  }
  @media only screen and (min-width: 1600px) {
    .info .info-p {
      font-size: 1.4vw;
    }
  }
  @media only screen and (max-width: 1500px) {
    .info .info-p {
      font-size: 18px;
    }
  }
  .info .abilities {
    position: relative;
    top: 0.8em;
    display: flex;
    gap: 4px;
  }
  .info .abilities .keybind {
    width: 100%;
    color: #fff;
    font-size: 24px;
    text-align: center;
    border-bottom: 2px solid #fff;
    font-weight: 700;
  }
  .info .abilities .ability {
    position: relative;
    width: 100px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 1670px) {
    .info .abilities .ability {
      width: 100%;
      height: 100%;
    }
  }
  .info .abilities .ability:hover::before {
    opacity: 1;
  }
  .info .abilities .ability::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--background-character);
    border-radius: 0.3em;
    opacity: 0.6;
    z-index: -1;
  }
  .info .abilities .ability-image {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 18px;
  }
  @media screen and (max-width: 1670px) {
    .info .abilities .ability-image {
      padding: 4px;
    }
  }
  .info .abilities .ability-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .characters {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    pointer-events: none;
    transition: left 0.6s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.14s ease-out;
    opacity: 1;
    animation: glitch 10s cubic-bezier(0.5, 0, 0.5, 1) infinite alternate;
  }
  @keyframes glitch {
    from,
    to {
      transform: translate(0);
    }
    12% {
      transform: translate(-2%, -1%);
    }
    40% {
      transform: translate(-1%, -3%);
    }
    80% {
      transform: translate(-2%, 1%);
    }
  }
  .characters.animate {
    opacity: 0;
  }
  .characters.transi {
    transition: left 0s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.14s ease-out !important;
  }
  .characters.right {
    transition: left 0.25s cubic-bezier(0.5, 0, 0, 1), opacity 0.14s ease-out !important;
  }

  .characters .portrait-container-ava {
    position: relative;
    width: 100vw;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
  }
  .characters .portrait-container-ava img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 680px) {
    .characters .portrait-container-ava img {
      object-fit: cover;
      box-sizing: border-box;
      padding: 6.6em;
    }
  }

  .character-select {
    position: absolute;
    left: 50%;
    bottom: 1em;
    transform: translateX(-50%);
    max-width: 100vw;
    overflow: hidden;
  }
  @media only screen and (max-width: 680px) {
    .character-select {
      bottom: 3em;
    }
  }
  .character-select-wrapper {
    display: grid;
    grid-auto-flow: column;
    gap: 4px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-inline: 1em;
  }
  .character-select-wrapper > div {
    width: 90px;
    height: 90px;
    background: #4e7d8c40;
    backdrop-filter: blur(6px);
    padding: 0.045em;
    border-radius: 0.4em;
    cursor: pointer;
  }
  @media only screen and (max-width: 680px) {
    .character-select-wrapper > div {
      width: 80px;
      height: 80px;
    }
  }
  .character-select-wrapper > div:hover {
    background: #4e5e8c;
  }
  .character-select-wrapper > div.active {
    background: var(--background-character);
  }
  .character-select-wrapper > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
</style>

<template>
  <section class="modal" id="claim">
    <div class="modal-content">
      <div class="lottie-container">
        <div id="anim1" class="lottie-glow"></div>
      </div>
      <div class="rewards">
        <div class="rewards-container">
          <div class="card-rewards">
            <div class="card-content p-0">
              <div>
                <div class="avatar-container blind-avatar ornement1-grp" style="filter: drop-shadow(2px 4px 6px black)">
                  <div
                    style="border-radius: 0; box-shadow: none; height: 323px; width: auto; margin: 0px 0 20px 0px"
                    id="animated"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="reward-comments">
          <h2 class="h2" style="font-weight: bold">{{ $t("battle_pass.unlocked") }}</h2>
          <p style="font-size: 16px">{{ $t("battle_pass.equip") }}</p>
          <div class="flex-container is-col is-justify-center">
            <button class="button m-y-md" @click="removeClaim">
              <span>{{ $t("battle_pass.claim") }}</span>
            </button>
          </div>
        </div>
      </div>
      <div class="glow glow-1"></div>
      <div class="glow glow-2"></div>
    </div>
  </section>
  <div class="hidden md:flex flex-col sm:p-2 lg:p-10 rounded-lg">
    <span class="slide_title">{{ $t("battle_pass.battle_pass") }}</span>
  </div>
  <div class="w-full justify-center hidden md:flex">
    <span>
      {{ $t("battle_pass.keep") }}
    </span>
  </div>
  <swiper
    class="hidden md:block"
    :modules="modules"
    :spaceBetween="50"
    :freeMode="true"
    :slidesPerView="1"
    :navigation="true"
    :allowTouchMove="false"
    :virtual="true"
    :breakpoints="{
      660: { slidesPerGroup: 1, slidesPerView: 2 },
      860: { slidesPerGroup: 2, slidesPerView: 3 },
      1060: { slidesPerGroup: 3, slidesPerView: 4 },
      1260: { slidesPerGroup: 4, slidesPerView: 5 },
    }">
    <swiper-slide
      v-for="(item, index) in pass"
      :key="index"
      :virtualIndex="index"
      class="min-w-[182px] max-w-[182px] imgPoster select-none min-h-[337px]">
      <v-timeline
        side="end"
        direction="horizontal"
        class="avatar"
        :class="{
          above: auth.user.lvl > item.numero,
          egal: auth.user.lvl === item.numero,
          first: index === 0,
          last: index === 99,
          'cursor-pointer': !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero,
        }"
        @touchstart="
          !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero
            ? startTimer($event, item.type, item.id_item)
            : null
        "
        @touchend="stopTimer"
        @mousedown="
          !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero
            ? startTimer($event, item.type, item.id_item)
            : null
        "
        @mouseup="stopTimer">
        <v-timeline-item dot-color="#0f112b" size="large">
          <template v-slot:opposite>
            <div
              :class="{ 'cursor-pointer': !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero }"
              class="h-[334px] relative inline-grid text-center justify-items-center avatar">
              <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11] pointer-events-none">
                <div
                  class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl pointer-events-none"
                  :style="{
                    background: item.shParams1
                      ? `linear-gradient(135deg, ${item.shParams1}, ${item.shParams2})`
                      : item.foParams
                      ? `url(/images/global/bg_avatar.png), ${item.foParams}`
                      : `${item.psParams || 'linear-gradient(to bottom, #c5c5c5, #008ca8)'}`,
                  }"
                  style="filter: drop-shadow(black 2px 4px 6px); background-size: cover; margin: 39px 50px 0">
                  <v-img
                    v-if="item.type == 1 || item.type == 6 || item.type == 8"
                    loading="lazy"
                    :src="
                      item.avLien
                        ? '/images/avatar_users/' + item.avLien
                        : item.emLien
                        ? '/images/emotes/' + item.emLien
                        : '/images/global/ticket.png'
                    "
                    class="max-w-none overflow-visible img-user-avatar pointer-events-none"
                    :style="
                      item.avLien
                        ? item.avParams
                        : item.emLien
                        ? 'max-width:100px;border-radius: 15px;overflow: hidden;'
                        : 'width:150px'
                    " />
                  <div v-if="item.type == 2 && selectedLanguage == 'fr'" class="pointer-events-none">
                    {{ item.tiTitre }}
                  </div>
                  <div v-else-if="item.type == 2" class="pointer-events-none">{{ item.tiTitreEn }}</div>
                  <div class="absolute top-[5px] infos left-[5px] bg-second p-1 rounded-full pointer-events-none">
                    <svg
                      v-if="item.type == 3"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M2 24v-2h20v2H2Zm8.696-15.02l4.016 4.022l-3.885 3.885q-.485.484-1.134.484q-.649 0-1.133-.484l-.193-.193l-1.155 1.133h-2.77l2.535-2.529l-.154-.154q-.485-.484-.49-1.14q-.006-.654.479-1.139l3.884-3.884Zm.708-.713l4.558-4.552q.484-.484 1.133-.484q.65 0 1.134.484l1.754 1.748q.484.485.484 1.134q0 .65-.484 1.134l-4.558 4.557l-4.021-4.02Z" />
                    </svg>
                    <svg
                      v-if="item.type == 4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m4 8l4-4m6 0L4 14m0 6L20 4m0 6L10 20m10-4l-4 4" />
                    </svg>
                    <svg
                      v-if="item.type == 6"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <g fill="none">
                        <path
                          d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                        <path
                          fill="currentColor"
                          d="M4 16a1 1 0 0 1 .993.883L5 17v2h2a1 1 0 0 1 .117 1.993L7 21H5a2 2 0 0 1-1.995-1.85L3 19v-2a1 1 0 0 1 1-1Zm16 0a1 1 0 0 1 1 1v2a2 2 0 0 1-2 2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Zm-5.2-2.143a1 1 0 1 1 1.4 1.428A5.984 5.984 0 0 1 12 17a5.984 5.984 0 0 1-4.2-1.715a1 1 0 1 1 1.4-1.428A3.984 3.984 0 0 0 12 15c1.09 0 2.077-.435 2.8-1.143ZM8.5 8a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3Zm7 0a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3ZM7 3a1 1 0 0 1 0 2H5v2a1 1 0 0 1-2 0V5a2 2 0 0 1 2-2h2Zm12 0a2 2 0 0 1 2 2v2a1 1 0 1 1-2 0V5h-2a1 1 0 1 1 0-2h2Z" />
                      </g>
                    </svg>
                    <svg
                      v-if="item.type == 5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12 0C5.373 0 0 5.373 0 12a11.97 11.97 0 0 0 3.918 8.87a4.457 4.457 0 0 1-.2-1.324a4.453 4.453 0 1 1 5.891 4.216c.773.156 1.572.238 2.391.238c6.627 0 12-5.373 12-12S18.627 0 12 0Z" />
                    </svg>
                    <svg
                      v-if="item.type == 8"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M15 20q-3.35 0-5.675-2.325T7 12q0-3.35 2.325-5.675T15 4q3.35 0 5.675 2.325T23 12q0 3.35-2.325 5.675T15 20Zm-9.375-.75q-2.1-.975-3.363-2.925T1 12q0-2.375 1.263-4.325T5.625 4.75q.525-.25.95.038T7 5.725q0 .25-.162.488t-.413.362q-1.575.725-2.5 2.188T3 12q0 1.775.925 3.238t2.5 2.187q.25.125.413.35t.162.5q0 .625-.425.925t-.95.05ZM15 12Zm0 6q2.5 0 4.25-1.75T21 12q0-2.5-1.75-4.25T15 6q-2.5 0-4.25 1.75T9 12q0 2.5 1.75 4.25T15 18Z" />
                    </svg>
                    <svg
                      v-if="item.type == 1 && item.numero != 100"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12 12q-1.65 0-2.825-1.175T8 8q0-1.65 1.175-2.825T12 4q1.65 0 2.825 1.175T16 8q0 1.65-1.175 2.825T12 12Zm-8 8v-2.8q0-.85.438-1.562T5.6 14.55q1.55-.775 3.15-1.162T12 13q1.65 0 3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2V20H4Z" />
                    </svg>
                    <svg
                      v-if="item.type == 2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <path fill="currentColor" d="M10.5 20V7H5V4h14v3h-5.5v13h-3Z" />
                    </svg>
                    <svg
                      v-if="item.numero == 100"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24">
                      <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                        <path d="M0 0h24v24H0z" />
                        <path
                          fill="currentColor"
                          d="m8.243 7.34l-6.38.925l-.113.023a1 1 0 0 0-.44 1.684l4.622 4.499l-1.09 6.355l-.013.11a1 1 0 0 0 1.464.944l5.706-3l5.693 3l.1.046a1 1 0 0 0 1.352-1.1l-1.091-6.355l4.624-4.5l.078-.085a1 1 0 0 0-.633-1.62l-6.38-.926l-2.852-5.78a1 1 0 0 0-1.794 0L8.243 7.34z" />
                      </g>
                    </svg>
                  </div>
                  <div class="absolute top-[5px] infos right-[5px] bg-second p-1 rounded-full">
                    <svg
                      v-if="!elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      class="bell"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h9V6q0-1.25-.875-2.125T12 3q-1.05 0-1.838.638T9.1 5.225q-.1.35-.413.563T8 6q-.425 0-.712-.275t-.213-.65q.275-1.7 1.662-2.888T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22H6Zm6-5q.825 0 1.413-.587T14 15q0-.825-.587-1.412T12 13q-.825 0-1.412.588T10 15q0 .825.588 1.413T12 17Z" />
                    </svg>
                    <svg
                      v-else-if="elementExiste(item.type, item.id_item)"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 256 256">
                      <path
                        fill="currentColor"
                        d="m232.49 80.49l-128 128a12 12 0 0 1-17 0l-56-56a12 12 0 1 1 17-17L96 183L215.51 63.51a12 12 0 0 1 17 17Z" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22H6Zm6-5q.825 0 1.413-.587T14 15q0-.825-.587-1.412T12 13q-.825 0-1.412.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z" />
                    </svg>
                  </div>
                </div>
              </div>
              <b
                class="z-[13] pointer-events-none bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium opacity-0"
                style="
                  filter: drop-shadow(black 2px 4px 6px);
                  box-shadow: -5px -5px 5px -5px #008ca8, 5px 5px 5px -5px #008ca8, -7px -7px 10px -5px transparent,
                    7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0),
                    0 55px 35px -20px rgba(0, 0, 0, 0.5);
                "
                >0</b
              >
              <span
                v-if="item.type == 2"
                class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
                >{{ $t("battle_pass.title") }}</span
              >
              <span
                v-else-if="item.type == 6"
                class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
                >{{ $t("battle_pass.emote") }}</span
              >
              <span
                v-else-if="item.type == 8"
                class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
                >Baraticket x100</span
              >
              <span
                v-else-if="selectedLanguage == 'fr'"
                class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
                >{{ item.avTitre || item.foTitre || item.psTitre || item.shTitre }}</span
              >
              <span
                v-else
                class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
                >{{ item.avTitre || item.foTitreEn || item.psTitreEn || item.shTitreEn }}</span
              >
            </div>
          </template>
          <template v-slot:icon>
            <span class="font-medium text-white">{{ item.numero }}</span>
          </template>
        </v-timeline-item>
      </v-timeline>
    </swiper-slide>
  </swiper>
  <v-virtual-scroll
    :items="pass"
    height="calc(100vh - 50px)"
    :key="pass"
    class="overflow-x-hidden md:mt-0 md:hidden"
    item-height="377">
    <template v-slot:default="{ item, index }">
      <div v-if="index === 0" class="flex">
        <span class="slide_title ml-2">{{ $t("battle_pass.battle_pass") }}</span>
      </div>
      <div class="w-full flex justify-center mt-[24px] mb-[-30px]" v-if="index === 0">
        <span class="px-2 text-center">
          {{ $t("battle_pass.keep") }}
        </span>
      </div>
      <v-timeline
        side="end"
        :class="{
          above: auth.user.lvl > item.numero,
          egal: auth.user.lvl === item.numero,
          first: index === 0,
          last: index === 99,
          'cursor-pointer': !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero,
        }">
        <v-timeline-item dot-color="#0f112b" size="large">
          <div
            @touchstart="
              !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero
                ? startTimer($event, item.type, item.id_item)
                : null
            "
            @touchend="stopTimer"
            @mousedown="
              !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero
                ? startTimer($event, item.type, item.id_item)
                : null
            "
            @mouseup="stopTimer"
            :class="{ 'cursor-pointer': !elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero }"
            class="h-[300px] relative inline-grid text-center justify-items-center avatar">
            <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11] pointer-events-none">
              <div
                class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl pointer-events-none"
                :style="{
                  background: item.shParams1
                    ? `linear-gradient(135deg, ${item.shParams1}, ${item.shParams2})`
                    : item.foParams
                    ? `url(/images/global/bg_avatar.png), ${item.foParams}`
                    : `${item.psParams || 'linear-gradient(to bottom, #c5c5c5, #008ca8)'}`,
                }"
                style="filter: drop-shadow(black 2px 4px 6px); background-size: cover; margin: 39px 50px 0">
                <v-img
                  v-if="item.type == 1 || item.type == 6 || item.type == 8"
                  loading="lazy"
                  :src="
                    item.avLien
                      ? '/images/avatar_users/' + item.avLien
                      : item.emLien
                      ? '/images/emotes/' + item.emLien
                      : '/images/global/ticket.png'
                  "
                  class="max-w-none overflow-visible img-user-avatar pointer-events-none"
                  :style="
                    item.avLien
                      ? item.avParams
                      : item.emLien
                      ? 'max-width:100px;border-radius: 15px;overflow: hidden;'
                      : 'width:150px'
                  " />
                <div v-if="item.type == 2 && selectedLanguage == 'fr'" class="pointer-events-none">
                  {{ item.tiTitre }}
                </div>
                <div v-else-if="item.type == 2" class="pointer-events-none">{{ item.tiTitreEn }}</div>

                <div class="absolute top-[5px] infos left-[5px] bg-second p-1 rounded-full pointer-events-none">
                  <svg
                    v-if="item.type == 3"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M2 24v-2h20v2H2Zm8.696-15.02l4.016 4.022l-3.885 3.885q-.485.484-1.134.484q-.649 0-1.133-.484l-.193-.193l-1.155 1.133h-2.77l2.535-2.529l-.154-.154q-.485-.484-.49-1.14q-.006-.654.479-1.139l3.884-3.884Zm.708-.713l4.558-4.552q.484-.484 1.133-.484q.65 0 1.134.484l1.754 1.748q.484.485.484 1.134q0 .65-.484 1.134l-4.558 4.557l-4.021-4.02Z" />
                  </svg>
                  <svg
                    v-if="item.type == 4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m4 8l4-4m6 0L4 14m0 6L20 4m0 6L10 20m10-4l-4 4" />
                  </svg>
                  <svg
                    v-if="item.type == 6"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <g fill="none">
                      <path
                        d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                      <path
                        fill="currentColor"
                        d="M4 16a1 1 0 0 1 .993.883L5 17v2h2a1 1 0 0 1 .117 1.993L7 21H5a2 2 0 0 1-1.995-1.85L3 19v-2a1 1 0 0 1 1-1Zm16 0a1 1 0 0 1 1 1v2a2 2 0 0 1-2 2h-2a1 1 0 1 1 0-2h2v-2a1 1 0 0 1 1-1Zm-5.2-2.143a1 1 0 1 1 1.4 1.428A5.984 5.984 0 0 1 12 17a5.984 5.984 0 0 1-4.2-1.715a1 1 0 1 1 1.4-1.428A3.984 3.984 0 0 0 12 15c1.09 0 2.077-.435 2.8-1.143ZM8.5 8a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3Zm7 0a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3ZM7 3a1 1 0 0 1 0 2H5v2a1 1 0 0 1-2 0V5a2 2 0 0 1 2-2h2Zm12 0a2 2 0 0 1 2 2v2a1 1 0 1 1-2 0V5h-2a1 1 0 1 1 0-2h2Z" />
                    </g>
                  </svg>
                  <svg
                    v-if="item.type == 5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 0C5.373 0 0 5.373 0 12a11.97 11.97 0 0 0 3.918 8.87a4.457 4.457 0 0 1-.2-1.324a4.453 4.453 0 1 1 5.891 4.216c.773.156 1.572.238 2.391.238c6.627 0 12-5.373 12-12S18.627 0 12 0Z" />
                  </svg>
                  <svg
                    v-if="item.type == 8"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M15 20q-3.35 0-5.675-2.325T7 12q0-3.35 2.325-5.675T15 4q3.35 0 5.675 2.325T23 12q0 3.35-2.325 5.675T15 20Zm-9.375-.75q-2.1-.975-3.363-2.925T1 12q0-2.375 1.263-4.325T5.625 4.75q.525-.25.95.038T7 5.725q0 .25-.162.488t-.413.362q-1.575.725-2.5 2.188T3 12q0 1.775.925 3.238t2.5 2.187q.25.125.413.35t.162.5q0 .625-.425.925t-.95.05ZM15 12Zm0 6q2.5 0 4.25-1.75T21 12q0-2.5-1.75-4.25T15 6q-2.5 0-4.25 1.75T9 12q0 2.5 1.75 4.25T15 18Z" />
                  </svg>
                  <svg
                    v-if="item.type == 1 && item.numero != 100"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 12q-1.65 0-2.825-1.175T8 8q0-1.65 1.175-2.825T12 4q1.65 0 2.825 1.175T16 8q0 1.65-1.175 2.825T12 12Zm-8 8v-2.8q0-.85.438-1.562T5.6 14.55q1.55-.775 3.15-1.162T12 13q1.65 0 3.25.388t3.15 1.162q.725.375 1.163 1.088T20 17.2V20H4Z" />
                  </svg>
                  <svg
                    v-if="item.type == 2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <path fill="currentColor" d="M10.5 20V7H5V4h14v3h-5.5v13h-3Z" />
                  </svg>
                  <svg
                    v-if="item.numero == 100"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24">
                    <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                      <path d="M0 0h24v24H0z" />
                      <path
                        fill="currentColor"
                        d="m8.243 7.34l-6.38.925l-.113.023a1 1 0 0 0-.44 1.684l4.622 4.499l-1.09 6.355l-.013.11a1 1 0 0 0 1.464.944l5.706-3l5.693 3l.1.046a1 1 0 0 0 1.352-1.1l-1.091-6.355l4.624-4.5l.078-.085a1 1 0 0 0-.633-1.62l-6.38-.926l-2.852-5.78a1 1 0 0 0-1.794 0L8.243 7.34z" />
                    </g>
                  </svg>
                </div>
                <div class="absolute top-[5px] infos right-[5px] bg-second p-1 rounded-full">
                  <svg
                    v-if="!elementExiste(item.type, item.id_item) && auth.user.lvl >= item.numero"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    class="bell"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h9V6q0-1.25-.875-2.125T12 3q-1.05 0-1.838.638T9.1 5.225q-.1.35-.413.563T8 6q-.425 0-.712-.275t-.213-.65q.275-1.7 1.662-2.888T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22H6Zm6-5q.825 0 1.413-.587T14 15q0-.825-.587-1.412T12 13q-.825 0-1.412.588T10 15q0 .825.588 1.413T12 17Z" />
                  </svg>
                  <svg
                    v-else-if="elementExiste(item.type, item.id_item)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 256 256">
                    <path
                      fill="currentColor"
                      d="m232.49 80.49l-128 128a12 12 0 0 1-17 0l-56-56a12 12 0 1 1 17-17L96 183L215.51 63.51a12 12 0 0 1 17 17Z" />
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22H6Zm6-5q.825 0 1.413-.587T14 15q0-.825-.587-1.412T12 13q-.825 0-1.412.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z" />
                  </svg>
                </div>
              </div>
            </div>
            <b
              class="z-[13] pointer-events-none bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium opacity-0"
              style="
                filter: drop-shadow(black 2px 4px 6px);
                box-shadow: -5px -5px 5px -5px #008ca8, 5px 5px 5px -5px #008ca8, -7px -7px 10px -5px transparent,
                  7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0),
                  0 55px 35px -20px rgba(0, 0, 0, 0.5);
              "
              >0</b
            >
            <span
              v-if="item.type == 2"
              class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
              >{{ $t("battle_pass.title") }}</span
            >
            <span
              v-else-if="item.type == 6"
              class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
              >{{ $t("battle_pass.emote") }}</span
            >
            <span
              v-else-if="item.type == 8"
              class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
              >Baraticket x100</span
            >
            <span
              v-else-if="selectedLanguage == 'fr'"
              class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
              >{{ item.avTitre || item.foTitre || item.psTitre || item.shTitre }}</span
            >
            <span
              v-else
              class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base unlock"
              >{{ item.avTitre || item.foTitreEn || item.psTitreEn || item.shTitreEn }}</span
            >
          </div>

          <template v-slot:icon>
            <span class="font-medium text-white">{{ item.numero }}</span>
          </template>
        </v-timeline-item>
      </v-timeline>
    </template>
  </v-virtual-scroll>
</template>
<script setup>
  import { ref, onMounted, computed } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useAuthStore } from "@/stores/auth";
  import { useHead } from "@unhead/vue";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/virtual";
  import { Navigation, Virtual } from "swiper/modules";
  import { useSeoMeta } from "@unhead/vue";
  import { useI18n } from "vue-i18n";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();
  const { t } = useI18n();
  const auth = useAuthStore();
  const pass = ref([]);
  const passUser = ref([]);
  const modules = [Navigation, Virtual];
  const timer = ref(null);
  const anim1 = ref(null);
  const selectedLanguage = ref(localStorage.getItem("lang"));

  onMounted(async () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0HY7K2YF6F");
    const { data: response } = await useFetch("/api/battlepass/season", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    pass.value = JSON.parse(response.value);
    const { data: responseUser } = await useFetch("/api/battlepass/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    passUser.value = JSON.parse(responseUser.value);
    waitScript();
  });

  useHead({
    script: {
      src: "https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.7.3/lottie.min.js",
    },
  });
  useSeoMeta({
    title: computed(() => t("battle_pass.battle_pass") + " - Baranimes"),
    ogTitle: computed(() => t("battle_pass.battle_pass") + " - Baranimes"),
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: computed(() => t("battle_pass.battle_pass") + " - Baranimes"),
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
  const waitScript = () => {
    // eslint-disable-next-line
    if (typeof lottie === "undefined") {
      setTimeout(function () {
        waitScript();
      }, 200);
      return;
    }
    global.pageLoad();
    // eslint-disable-next-line
    anim1.value = lottie.loadAnimation({
      container: document.getElementById("anim1"),
      renderer: "svg",
      loop: false,
      autoplay: true,
      path: "https://baranimes.com/json/animation.json",
    });
  };
  function elementExiste(typeRecherche, idRecherche) {
    return (
      passUser.value?.find((element) => element.type === typeRecherche && element.id_item === idRecherche) !== undefined
    );
  }
  function removeClaim() {
    document.getElementById("claim").classList.remove("is-in");
  }
  const startTimer = (event, type, item) => {
    event.target.classList.add("active");
    timer.value = setTimeout(async () => {
      var clone = event.target.cloneNode(true);
      clone.querySelectorAll(".infos")[0].remove();
      clone.querySelectorAll(".infos")[0].remove();
      clone.querySelectorAll(".v-timeline-divider")[0]?.remove();
      document.getElementById("animated").innerHTML = "";
      document.getElementById("animated").appendChild(clone);
      document.getElementById("claim").classList.add("is-in");
      anim1.value.goToAndPlay(100);
      await useFetch("/api/battlepass/update", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idItem: item,
          type: type,
        }),
      });
    }, 1000);
  };

  const stopTimer = (event) => {
    event.target.classList.remove("active");
    clearTimeout(timer.value);
  };
</script>
<style scoped>
  .slide_title {
    /* color: #1db5a9; */
    background-clip: text;
    margin-top: 40px;
    color: #00bdbf;
    margin-bottom: 0;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    border-left: 4px solid;
    padding-left: 20px;
  }
  :deep(.v-timeline--horizontal.v-timeline.v-timeline--side-end .v-timeline-item .v-timeline-item__opposite) {
    pointer-events: none;
    height: 340px;
  }
  :deep(.swiper-button-prev),
  :deep(.swiper-button-next) {
    color: white;
    font-size: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
    background: rgb(15 17 43 / 95%);
  }
  :deep(.swiper-button-prev::after),
  :deep(.swiper-button-next::after) {
    font-size: 27px;
    font-weight: bold;
  }
  @media (max-width: 767px) {
    :deep(.swiper-button-prev),
    :deep(.swiper-button-next) {
      display: none;
    }
  }
  :deep(.swiper-slide) {
    min-width: 232px !important;
    margin: 0 !important;
  }
  .avatar div svg {
    pointer-events: none;
  }
  .avatar .unlock {
    transition: 1s;
    pointer-events: none;
    background: linear-gradient(90deg, #008ca8 50%, #10151f 0%);
    transition: linear 1s;
    background-size: 200% auto !important;
    background-position: right center !important;
  }
  .avatar.active .unlock {
    background-position: left center !important;
  }
  .bell {
    animation: animateGroup 2.3s infinite;
  }

  @keyframes animateGroup {
    17.542% {
      transform: rotate(0deg);
    }

    35.084% {
      transform: rotate(-20deg);
    }

    48.2405% {
      transform: rotate(20deg);
    }

    57.0115% {
      transform: rotate(-20deg);
    }

    64.9054% {
      transform: rotate(8deg);
    }

    74.5535% {
      transform: rotate(-20deg);
    }

    87.542% {
      transform: rotate(8deg);
    }
  }
  :deep(.v-timeline-divider__before),
  :deep(.v-timeline-divider__after) {
    background: white !important;
  }

  :deep(.v-timeline-item__body) {
    padding-inline-start: 0 !important;
  }
  :deep(.first .v-timeline-divider__before),
  :deep(.last .v-timeline-divider__after) {
    display: none;
  }
  :deep(.egal .v-timeline-divider__dot),
  :deep(.above .v-timeline-divider__dot),
  :deep(.egal .v-timeline-divider__before),
  :deep(.above .v-timeline-divider__before),
  :deep(.above .v-timeline-divider__after) {
    background: #00bdbf !important;
  }
  /*----------------------------*/
  /* GENERAL */
  /*----------------------------*/

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html section.modal {
    background: #1a1b3a;
    font-size: 16px;
    font-family: "Roboto Condensed", sans-serif;
  }

  body section.modal {
    color: white;
  }

  /*----------------------------*/
  /* SIZING */
  /*----------------------------*/

  .p-0 {
    padding: 0 !important;
  }

  .p-md {
    padding: 1rem !important;
  }

  .p-x-md {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .p-y-md {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .m-0 {
    margin: 0 !important;
  }

  .m-md {
    margin: 1rem !important;
  }

  .m-x-md {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .m-y-md {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .m-t-md {
    margin-top: 1rem !important;
  }

  .w-full {
    width: 100%;
  }

  /*----------------------------*/
  /* TYPOGRAPHY */
  /*----------------------------*/

  .h2,
  .h3 {
    text-transform: uppercase;
    background: #fff;
    background: -webkit-linear-gradient(to right, #00a6fb 0%, #00fddc 100%);
    background: -moz-linear-gradient(to right, #00a6fb 0%, #00fddc 100%);
    background: linear-gradient(to right, #00a6fb 0%, #00fddc 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .h2,
  .h3,
  .is-in p {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .h2 {
    font-size: 38px;
  }

  .h3 {
    font-size: 32px;
  }

  .is-in p {
    opacity: 0.5;
    line-height: 1.4;
  }

  .text-center {
    text-align: center;
  }

  /*----------------------------*/
  /* CARD */
  /*----------------------------*/

  .card-rewards {
    background: radial-gradient(
      100% 100% at 50% 5%,
      rgba(255, 255, 255, 0.05) 0%,
      rgba(255, 255, 255, 0.03) 100%
    ) !important;
    border: 1px solid rgba(255, 255, 255, 0.05) !important;
    border-radius: 1rem;
    padding: 0.5rem;
    backdrop-filter: blur(4px);

    > div {
      background: #10151f !important;
      border-radius: 0.5rem;
      padding: 2rem;
      backdrop-filter: blur(8px);
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.05), 0 1.5px 1.1px rgba(0, 0, 0, 0.034),
        0 3.6px 2.5px rgba(0, 0, 0, 0.048), 0 6.8px 4.8px rgba(0, 0, 0, 0.06), 0 12.1px 8.5px rgba(0, 0, 0, 0.072),
        0 22.6px 15.9px rgba(0, 0, 0, 0.086), 0 54px 38px rgba(0, 0, 0, 0.12);
    }
  }

  .website-content {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .website {
    background-size: cover;
    background-position: center top;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .flex-container {
    display: flex;
  }

  /*----------------------------*/
  /* LOTTIE */
  /*----------------------------*/

  .lottie-container {
    position: absolute;
    pointer-events: none;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #anim1 {
    position: absolute;
    bottom: calc(50% - 225px + 75px);
    width: 550px;
    height: 550px;
    max-width: 90vw;
    filter: drop-shadow(0 0 6px rgba(0, 255, 128, 1)) drop-shadow(0 0 16px rgba(0, 255, 128, 0.75))
      drop-shadow(0 0 32px rgba(0, 255, 128, 0.5));

    @media only screen and (max-width: 767px) {
      width: 80vw;
      height: 80vw;
    }
  }

  /*----------------------------*/
  /* REWARDS */
  /*----------------------------*/

  .rewards {
    position: relative;
    z-index: 5;
    text-align: center;
    max-width: 800px;

    .rewards-container {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 2rem;
      justify-content: center;

      .card-content {
        justify-content: center;
        display: flex;

        .spinningasset {
          height: 240px;
          margin-top: 50px;
        }
      }

      @media only screen and (max-width: 767px) {
        flex-direction: column;

        .card-content {
          display: flex;
          flex-direction: row;
        }
      }

      > * {
        min-width: 300px;
        margin: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        > * {
          flex: 1;
          background: linear-gradient(180deg, #242547 0%, #1a1b3a 100%);
        }
      }
    }
  }

  .reward-comments {
    opacity: 0;
    transition: opacity 1.6s ease-out 0.4s;
    max-width: 500px;
    margin: auto;
  }

  .reward-text {
    position: absolute;
    bottom: 2.2rem;
    right: 0;
    left: 0;
    font-weight: 700;
    font-size: 19px;
    opacity: 0.75;
    font-family: "Roboto Condensed", sans-serif;
  }

  .reward-image {
    padding: 3rem 0 5rem;
    background-position: 50% 50%;
    background-size: 115% auto;
    background-repeat: no-repeat;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
      background-position: 50% 40%;
    }

    &.reward-coin {
      background-image: url("https://images.ctfassets.net/r5o0jpcspr8j/7qa3tKx7otIVbKoB1HHZV3/51f1a0924580f0285d7f43eea11deaab/coin-background.png");
    }

    &.reward-token {
      background-image: url("https://images.ctfassets.net/r5o0jpcspr8j/13qYpMeoe4qMNy9DxQ4knP/db9bda234e2cd7418341380b2d12e989/token-background.png");
    }

    > img {
      display: block;
      margin: auto;
    }
  }

  @keyframes bounce-in-top {
    0% {
      transform: translateY(-125px);
      animation-timing-function: ease-in;
      opacity: 0;
    }

    38% {
      transform: translateY(0);
      animation-timing-function: ease-out;
      opacity: 1;
    }

    55% {
      transform: translateY(-16.25px);
      animation-timing-function: ease-in;
    }

    72% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }

    81% {
      transform: translateY(-7px);
      animation-timing-function: ease-in;
    }

    90% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }

    95% {
      transform: translateY(-2px);
      animation-timing-function: ease-in;
    }

    100% {
      transform: translateY(0);
      animation-timing-function: ease-out;
    }
  }

  @keyframes scale-in-center {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .modal.is-in {
    z-index: 1100;

    .rewards-container {
      > * {
        animation: scale-in-center 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) both;

        .reward-image {
          background-size: 90% auto;

          > img {
            animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          }
        }

        .reward-text {
          animation: bounce-in-top 1.1s both;
        }

        &:nth-child(1) {
          animation-delay: 0.3s;

          .reward-image {
            > img {
              animation-delay: 0.3s;
            }
          }

          .reward-text {
            animation-delay: 0.3s;
          }
        }

        &:nth-child(2) {
          animation-delay: 0.4s;

          .reward-image {
            > img {
              animation-delay: 0.4s;
            }
          }

          .reward-text {
            animation-delay: 0.4s;
          }
        }

        &:nth-child(3) {
          animation-delay: 0.5s;

          .reward-image {
            > img {
              animation-delay: 0.5s;
            }
          }

          .reward-text {
            animation-delay: 0.5s;
          }
        }
      }
    }

    .reward-comments {
      opacity: 1;
    }
  }

  @keyframes slide-in-top {
    0% {
      transform: translateY(-1000px);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /*----------------------------*/
  /* BUTTON */
  /*----------------------------*/
  .button {
    font-family: "Roboto Condensed", sans-serif;
    outline: none;
    font-weight: 700;
    font-size: 19px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    background-size: 200% auto;
    background-image: linear-gradient(to right, #00a6fb 0%, #00fddc 50%, #00a6fb 100%);
    line-height: 1.1;
    background-position: left center;
    transition: all 0.4s ease-out;
    border-radius: 0.5rem;
    padding: 13px 19px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1), inset 0 -4px 2px -1px rgba(0, 0, 0, 0.15),
      0 0.5px 0.6px rgba(0, 0, 0, 0.039), 0 1.1px 1.3px rgba(0, 0, 0, 0.057), 0 2.1px 2.5px rgba(0, 0, 0, 0.07),
      0 3.8px 4.5px rgba(0, 0, 0, 0.083), 0 7.1px 8.4px rgba(0, 0, 0, 0.101), 0 17px 20px rgba(0, 0, 0, 0.14);

    &:hover {
      background-position: right center;
    }

    > span {
      color: #fff;
    }

    &.is-inverted {
      background: transparent;
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);

      > span {
        color: white;
      }

      &:hover {
        box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 1);
      }
    }

    &.is-glowing {
      position: relative;

      box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.1), inset 0 -4px 2px -1px rgba(0, 0, 0, 0.15),
        0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 0 rgba(255, 255, 255, 0.15), 0 0 0 0 rgba(255, 255, 255, 0.2),
        0 0 0 0 rgba(255, 255, 255, 0.3), 0 0.5px 0.6px rgba(0, 0, 0, 0.039), 0 1.1px 1.3px rgba(0, 0, 0, 0.057),
        0 2.1px 2.5px rgba(0, 0, 0, 0.07), 0 3.8px 4.5px rgba(0, 0, 0, 0.083), 0 7.1px 8.4px rgba(0, 0, 0, 0.101),
        0 17px 20px rgba(0, 0, 0, 0.14);

      &:hover {
        box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3), inset 0 -4px 2px -1px rgba(0, 0, 0, 0.15),
          0 0 30px 0 rgba(255, 255, 255, 0.1), 0 0 25px 0 rgba(255, 255, 255, 0.15), 0 0 10px 0 rgba(255, 255, 255, 0.2),
          0 0 3.33px 0 rgba(255, 255, 255, 0.3), 0 0.1px 0.2px rgba(0, 0, 0, 0.039), 0 0.3px 0.4px rgba(0, 0, 0, 0.057),
          0 0.5px 0.8px rgba(0, 0, 0, 0.07), 0 0.9px 1.3px rgba(0, 0, 0, 0.083), 0 1.7px 2.5px rgba(0, 0, 0, 0.101),
          0 4px 6px rgba(0, 0, 0, 0.14);

        > i {
          opacity: 1;
          animation: particlescontainer 0.5s ease-in infinite both;

          &::after {
            opacity: 1;
            animation: particles 0.5s ease-in infinite both;
          }
        }
      }

      > i {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transition: all 0.2s ease;

        &::after {
          transition: all 0.2s ease;
          opacity: 0;
          content: "";
          display: block;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background: white;
          filter: blur(0.75px);
        }

        &:nth-of-type(1) {
          left: calc(50% + 21%);
          top: calc(50% - 14%);

          &,
          &::after {
            animation-delay: 0.75s;
            animation-duration: 1.7s;
          }

          &::after {
            transform: scale(1.4);
          }
        }

        &:nth-of-type(2) {
          left: calc(50% + 13%);
          top: calc(50% - 15%);

          &,
          &::after {
            animation-delay: 0.6s;
            animation-duration: 0.7s;
          }
        }

        &:nth-of-type(3) {
          left: calc(50% - 1%);
          top: calc(50% - 10%);

          &,
          &::after {
            animation-delay: 0.8s;
            animation-duration: 0.8s;
          }
        }

        &:nth-of-type(4) {
          left: calc(50% - 17%);
          top: calc(50% - 12%);

          &,
          &::after {
            animation-delay: 0.15s;
            animation-duration: 1.6s;
          }
        }

        &:nth-of-type(5) {
          left: calc(50% - 29%);
          top: calc(50% - 9%);

          &,
          &::after {
            animation-delay: 0.3s;
            animation-duration: 1.6s;
          }

          &::after {
            transform: scale(0.75);
          }
        }

        &:nth-of-type(6) {
          left: calc(50% + 17%);
          top: calc(50% - 8%);

          &,
          &::after {
            animation-delay: 0.6s;
            animation-duration: 1.1s;
          }

          &::after {
            transform: scale(1.3);
          }
        }

        &:nth-of-type(7) {
          left: calc(50% - 42%);
          top: calc(50% + 11%);

          &,
          &::after {
            animation-delay: 0.7s;
            animation-duration: 1.15s;
          }

          &::after {
            transform: scale(1.2);
          }
        }

        &:nth-of-type(8) {
          left: calc(50% + 44%);
          top: calc(50% + 40%);

          &,
          &::after {
            animation-delay: 0.55s;
            animation-duration: 1.74s;
          }

          &::after {
            transform: scale(0.65);
          }
        }
      }
    }
  }

  /*----------------------------*/
  /* MODAL */
  /*----------------------------*/

  section.modal {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(14, 15, 30, 0.85);
    opacity: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.22s ease;
    backdrop-filter: blur(0);
    pointer-events: none;

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      overflow-x: hidden;
      background: none;
    }

    &.is-in {
      display: flex;
      opacity: 1;
      pointer-events: auto;
      backdrop-filter: blur(12px);

      .modal-content {
        opacity: 1;
        animation: modal-isin 0.2s ease 1 forwards;
      }
    }
  }

  @keyframes modal-isin {
    0% {
      display: none;
      opacity: 0;
    }

    1% {
      display: block;
      opacity: 0;
      transform: scale(0.2);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  /*----------------------------*/
  /* GLOWS */
  /*----------------------------*/

  .glow {
    width: 100px;
    height: 150px;
    border-radius: 800px;
    background: radial-gradient(95.76% 95.76% at 71.44% 4.3%, #ff00c7 0%, #009cff 28.65%, #00ffa3 100%);
    filter: blur(100px);
  }

  .glow-1 {
    position: absolute;
    top: 20px;
    left: calc(50vw - 200px);
  }

  .glow-2 {
    position: absolute;
    bottom: 100px;
    right: calc(50vw - 200px);
  }

  /*----------------------------*/
  /* FLEX */
  /*----------------------------*/

  .flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .flex-inline-container {
    display: inline-flex !important;
  }

  .is-col {
    flex-direction: column;
  }

  .is-row {
    flex-direction: row;
  }

  .is-wrap {
    flex-wrap: wrap;
  }

  .is-nowrap {
    flex-wrap: nowrap;
  }

  .is-justify-start {
    justify-content: flex-start !important;
  }

  .is-justify-center {
    justify-content: center !important;
  }

  .is-justify-end {
    justify-content: flex-end !important;
  }

  .is-justify-between {
    justify-content: space-between !important;
  }

  .is-justify-around {
    justify-content: space-around !important;
  }

  .is-items-stretch {
    align-items: stretch !important;
  }

  .is-items-start {
    align-items: flex-start !important;
  }

  .is-items-center {
    align-items: center !important;
  }

  .is-items-end {
    align-items: flex-end !important;
  }

  .is-items-baseline {
    align-items: baseline !important;
  }

  .is-content-start {
    align-content: flex-start !important;
  }

  .is-content-center {
    align-content: center !important;
  }

  .is-content-end {
    align-content: flex-end !important;
  }

  .is-content-between {
    align-content: space-between !important;
  }

  .is-content-around {
    align-content: space-around !important;
  }

  .is-self-auto {
    align-self: auto !important;
  }

  .is-self-start {
    align-self: flex-start !important;
  }

  .is-self-center {
    align-self: center !important;
  }

  .is-self-end {
    align-self: flex-end !important;
  }

  .is-self-stretch {
    align-self: stretch !important;
  }

  .is-fullviewheight {
    height: 100vh !important;
  }

  .flex-initial {
    flex: initial !important;
  }

  .flex-auto {
    -webkit-box-flex: 1 !important;
    flex: auto !important;
    min-width: 0;
  }

  .flex-none {
    -webkit-box-flex: 0 !important;
    flex: none !important;
    min-width: 0;
  }

  .flex-grow {
    -webkit-box-flex: 1 !important;
    flex-grow: 1 !important;
  }

  .flex-grow-no {
    -webkit-box-flex: 0 !important;
    flex-grow: 0 !important;
  }

  .flex-shrink {
    flex-shrink: 1 !important;
  }

  .flex-shrink-no {
    flex-shrink: 0 !important;
  }

  /*----------------------------*/
  /* GLOWING ASSET */
  /*----------------------------*/

  .glowingasset {
    position: relative;

    > img {
      position: relative;
      z-index: 2;
      filter: drop-shadow(0px 221.516px 128.032px rgba(0, 0, 0, 0.13))
        drop-shadow(0px 110.866px 64.0787px rgba(0, 0, 0, 0.0988432))
        drop-shadow(0px 66.7806px 38.598px rgba(0, 0, 0, 0.0847066))
        drop-shadow(0px 42.7965px 24.7356px rgba(0, 0, 0, 0.0742163))
        drop-shadow(0px 27.7373px 16.0316px rgba(0, 0, 0, 0.065))
        drop-shadow(0px 17.4603px 10.0917px rgba(0, 0, 0, 0.0557837))
        drop-shadow(0px 10.032px 5.79832px rgba(0, 0, 0, 0.0452935))
        drop-shadow(0px 4.41535px 2.55199px rgba(0, 0, 0, 0.0311568));
    }

    > i {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      animation: particlescontainer 2s ease-in infinite both;

      &::after {
        animation: particles 2s ease-in infinite both;
        content: "";
        display: block;
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background: white;
        filter: blur(0.75px);
      }

      &:nth-of-type(1) {
        left: calc(50% + 11%);
        top: calc(50% - 14%);

        &,
        &::after {
          animation-delay: 1.5s;
          animation-duration: 3.2s;
        }

        &::after {
          transform: scale(1.4);
        }
      }

      &:nth-of-type(2) {
        left: calc(50% + 3%);
        top: calc(50% - 5%);

        &,
        &::after {
          animation-delay: 1.1s;
          animation-duration: 1.4s;
        }
      }

      &:nth-of-type(3) {
        left: calc(50% - 1%);
        top: calc(50% - 10%);

        &,
        &::after {
          animation-delay: 1.6s;
          animation-duration: 1.6s;
        }
      }

      &:nth-of-type(4) {
        left: calc(50% + 20%);
        top: calc(50% - 20%);

        &,
        &::after {
          animation-delay: 2.9s;
          animation-duration: 1.2s;
        }
      }

      &:nth-of-type(5) {
        left: calc(50% - 19%);
        top: calc(50% - 9%);

        &,
        &::after {
          animation-delay: 0.3s;
          animation-duration: 2.9s;
        }

        &::after {
          transform: scale(0.75);
        }
      }

      &:nth-of-type(6) {
        left: calc(50% + 7%);
        top: calc(50% - 8%);

        &,
        &::after {
          animation-delay: 1.2s;
          animation-duration: 2.16s;
        }

        &::after {
          transform: scale(1.3);
        }
      }

      &:nth-of-type(7) {
        left: calc(50% - 11%);
        top: calc(50% - 11%);

        &,
        &::after {
          animation-delay: 1.4s;
          animation-duration: 2.34s;
        }

        &::after {
          transform: scale(1.74);
        }
      }

      &:nth-of-type(8) {
        left: calc(50% - 6%);
        top: calc(50% + 1%);

        &,
        &::after {
          animation-delay: 1.2s;
          animation-duration: 2.54s;
        }

        &::after {
          transform: scale(1.65);
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 30%;
      left: 0;
      width: 40%;
      height: 60%;
      margin: auto;
      z-index: 1;
      border-radius: 1000px;
      filter: blur(40px);
      opacity: 0.25;
      background: radial-gradient(234.48% 232.94% at 71.44% 4.3%, #ff00c7 0, #009cff 28.65%, #00ffa3 100%);
      animation: glow 5s ease-in infinite both;
    }

    &.token {
      &::after {
        opacity: 0.75;
        background: radial-gradient(
          97.58% 87.19% at 51.31% 11.73%,
          #ec79fe 0%,
          #df6ff6 14.29%,
          #bc56e2 40.83%,
          #852ec1 76.42%,
          #5c10a9 100%
        );
      }
    }

    &.coin {
      &::after {
        background: radial-gradient(
          94.44% 94.44% at 49.54% 5.4%,
          rgba(253, 251, 69, 0.44) 0%,
          rgba(255, 150, 0, 0.62) 100%
        );
      }
    }

    &.is-sm {
      > img {
        filter: drop-shadow(0px 109px 63px rgba(0, 0, 0, 0.13))
          drop-shadow(0px 54.5532px 31.5308px rgba(0, 0, 0, 0.0988432))
          drop-shadow(0px 32.8603px 18.9927px rgba(0, 0, 0, 0.0847066))
          drop-shadow(0px 21.0586px 12.1715px rgba(0, 0, 0, 0.0742163))
          drop-shadow(0px 13.6485px 7.88858px rgba(0, 0, 0, 0.065))
          drop-shadow(0px 8.59157px 4.96577px rgba(0, 0, 0, 0.0557837))
          drop-shadow(0px 4.93639px 2.85314px rgba(0, 0, 0, 0.0452935))
          drop-shadow(0px 2.17263px 1.25574px rgba(0, 0, 0, 0.0311568));
      }

      &::after {
        filter: blur(20px);
      }

      > i {
        &::after {
          width: 1px;
          height: 1px;
        }
      }
    }
  }

  @keyframes particlescontainer {
    0% {
      transform: translateY(-20px) scaleY(1) scaleX(1);
    }

    100% {
      transform: translateY(-120px) scaleY(1.5) scaleX(0.75);
    }
  }

  @keyframes particles {
    0%,
    100% {
      opacity: 0;
      filter: blur(0.5px);
    }

    50% {
      opacity: 0.24;
      filter: blur(1px);
    }
  }

  @keyframes glow {
    0%,
    100% {
      opacity: 0.25;
    }

    50% {
      opacity: 0.45;
    }
  }

  /*----------------------------*/
  /* SPINNING OBJECTS */
  /*----------------------------*/

  @keyframes brightness {
    0%,
    50%,
    100% {
      filter: blur(0px) brightness(120%) drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1))
        drop-shadow(0 0 5px rgba(255, 255, 255, 0.075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045))
        drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
    }

    25%,
    75% {
      filter: blur(1px) brightness(50%) drop-shadow(0 0 2.5px rgba(255, 255, 255, 0.1))
        drop-shadow(0 0 5px rgba(255, 255, 255, 0.075)) drop-shadow(0 0 7.5px rgba(255, 255, 255, 0.045))
        drop-shadow(0 0 10px rgba(255, 255, 255, 0.025));
    }
  }

  @keyframes spin {
    0% {
      transform: rotateY(-180deg);
    }

    100% {
      transform: rotateY(180deg);
    }
  }

  .spinningasset {
    text-align: left;
    transition: all 0.4s ease-out;
    cursor: pointer;
    animation: brightness 2.5s infinite linear;

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 8px;
      width: 11px;
      margin: auto;
      background-size: 100px 400%;
      background-position: center;
    }

    > div {
      position: relative;
      z-index: 2;
      perspective: 10000px;
      transform-style: preserve-3d;
      transform-origin: center;
      animation: spin 2.5s infinite linear;

      > * {
        width: 100%;
        height: 100%;
        position: absolute;
        backface-visibility: inherit;
        background-size: cover;
      }

      > div:first-child {
        transform: translateZ(-6px);
      }

      > div:last-child {
        transform: translateZ(6px);
        background-image: url(https://baranimes.fr/save/ticket2.png);
      }

      em {
        filter: blur(0.5px);

        &:first-of-type {
          transform: translateZ(0px) rotateY(-6deg);
        }

        &:last-of-type {
          transform: translateZ(0px) rotateY(6deg);
        }
      }

      i {
        filter: blur(0.5px);

        &:nth-of-type(1) {
          transform: translateZ(-5px);
        }

        &:nth-of-type(2) {
          transform: translateZ(-4px);
        }

        &:nth-of-type(3) {
          transform: translateZ(-3px);
        }

        &:nth-of-type(4) {
          transform: translateZ(-2px);
        }

        &:nth-of-type(5) {
          transform: translateZ(-1px);
        }

        &:nth-of-type(6) {
          transform: translateZ(0px);
        }

        &:nth-of-type(7) {
          transform: translateZ(1px);
        }

        &:nth-of-type(8) {
          transform: translateZ(2px);
        }

        &:nth-of-type(9) {
          transform: translateZ(3px);
        }

        &:nth-of-type(10) {
          transform: translateZ(4px);
        }

        &:nth-of-type(11) {
          transform: translateZ(5px);
        }
      }
    }

    &.is-sm {
      width: 24px;
      height: 24px;
      transform: scale(0.24);
      transform-origin: left top;
      filter: none;

      &,
      > div {
      }
    }

    &.coin {
      > div {
        width: 100px;
        height: 100px;

        > div:first-child {
          background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632752594/Marketing/202109_gloot2/Coins_side_back.svg);
        }

        > div:last-child,
        &::after,
        i,
        em {
          background-image: url(https://baranimes.fr/save/ticket2.png);
        }
      }
    }

    &.token {
      /* filter: drop-shadow(0 0 3px rgba(236, 121, 254, 0.3))
			drop-shadow(0 0 6px rgba(236, 121, 254, 0.2))
			drop-shadow(0 0 20px rgba(236, 121, 254, 0.1))
			drop-shadow(0 0 30px rgba(236, 121, 254, 0.05)); */
      &:hover {
        /* filter: drop-shadow(0 0 3px rgba(236, 121, 254, 0.8))
				drop-shadow(0 0 6px rgba(236, 121, 254, 0.6))
				drop-shadow(0 0 20px rgba(236, 121, 254, 0.4))
				drop-shadow(0 0 30px rgba(236, 121, 254, 0.2)); */
      }

      > div {
        width: 100px;
        height: 102px;

        > div:first-child {
          background-image: url(https://baranimes.fr/save/ticket2.png);
        }

        > div:last-child,
        &::after,
        i,
        em {
          background-image: url(https://baranimes.fr/save/ticket2.png);
        }
      }

      &.is-sm {
        transform: scale(0.24) translateY(-33%);
      }
    }

    &.ticket {
      transform: translate;
      filter: drop-shadow(0 0 3px rgba(250, 234, 148, 0.4)) drop-shadow(0 0 6px rgba(250, 234, 148, 0.3))
        drop-shadow(0 0 20px rgba(250, 234, 148, 0.2)) drop-shadow(0 0 30px rgba(250, 234, 148, 0.1));

      &:hover {
        filter: drop-shadow(0 0 3px rgba(250, 234, 148, 0.6)) drop-shadow(0 0 6px rgba(250, 234, 148, 0.4))
          drop-shadow(0 0 20px rgba(250, 234, 148, 0.2)) drop-shadow(0 0 30px rgba(250, 234, 148, 0.1));
      }

      > div {
        width: 150px;
        height: 80px;

        > div:first-child {
          transform: translateZ(-1px);
        }

        > div:last-child {
          transform: translateZ(1px);
        }

        i {
          display: none;
        }

        em {
          &:first-of-type {
            transform: translateZ(0px) rotateY(-1deg);
          }

          &:last-of-type {
            transform: translateZ(0px) rotateY(1deg);
          }
        }

        > div:first-child {
          background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632816242/Marketing/202109_gloot2/ticket_side_back.svg);
        }

        > div:last-child,
        &::after,
        i,
        em {
          background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632816242/Marketing/202109_gloot2/ticket_side_front.svg);
        }
      }
    }

    &.ticket2 {
      transform: translate;

      > div {
        width: 173px;
        height: 150px;

        > div:first-child {
          transform: translateZ(-1px);
        }

        > div:last-child {
          transform: translateZ(1px);
        }

        i {
          display: none;
        }

        em {
          &:first-of-type {
            transform: translateZ(0px) rotateY(-1deg);
          }

          &:last-of-type {
            transform: translateZ(0px) rotateY(1deg);
          }
        }

        > div:first-child {
          background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632818517/Marketing/202109_gloot2/ticket_2_back.svg);
        }

        > div:last-child,
        &::after,
        i,
        em {
          background-image: url(https://res.cloudinary.com/gloot/image/upload/v1632818305/Marketing/202109_gloot2/ticket_2_front.svg);
        }
      }

      &.is-sm {
        width: 40px;
        transform: scale(0.24) translateY(-100%);
      }
    }
  }

  .add-avatar {
    display: flex;
  }

  .avatar-btn {
    height: 125px;
    flex-flow: column;
    width: 50%;
    margin-left: 20px;
  }

  @media (max-width: 575px) {
    .add-avatar {
      display: block;
    }

    .avatar-btn {
      width: 100%;
      margin-left: 0;
    }
  }
</style>

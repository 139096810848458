<template>
  <button @click="start">Start</button>
  <button @click="stop">Stop</button>
</template>
<script setup>
  let mediaRecorder;

  async function start() {
    let stream = await recordScreen();
    mediaRecorder = createRecorder(stream);
    let node = document.createElement("p");
    node.textContent = "Started recording";
    document.body.appendChild(node);
  }

  function stop() {
    mediaRecorder.stop();
    let node = document.createElement("p");
    node.textContent = "Stopped recording";
    document.body.appendChild(node);
  }

  async function recordScreen() {
    return await navigator.mediaDevices.getDisplayMedia({
      audio: { autoGainControl: false, echoCancellation: false, googAutoGainControl: false, noiseSuppression: false },

      video: {
        width: { ideal: 2160, max: 2160 },
        height: { ideal: 3840, max: 3840 },
      },
      preferredVideoCodecs: ["H264"],

      frameRate: {
        ideal: 60,
      },
    });
  }

  function createRecorder(stream) {
    // the stream data is stored in this array
    let recordedChunks = [];

    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = function (e) {
      if (e.data.size > 0) {
        recordedChunks.push(e.data);
      }
    };
    mediaRecorder.onstop = function () {
      saveFile(recordedChunks);
      recordedChunks = [];
    };
    mediaRecorder.start(200); // For every 200ms the stream data will be stored in a separate chunk.
    return mediaRecorder;
  }

  function saveFile(recordedChunks) {
    const blob = new Blob(recordedChunks, {
      type: "video/webm",
    });
    let filename = window.prompt("Enter file name"),
      downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${filename}.webm`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(blob); // clear from memory
    document.body.removeChild(downloadLink);
  }
</script>

import { defineStore } from "pinia";
import { io } from "socket.io-client";
const socketIo = io();
export const useJoinRoomStore = defineStore("join_room", () => {
  var socket = socketIo;
  function joinRoom(to) {
    const userLocale = navigator.language || navigator.userLanguage;
    const userCountryCode = new Intl.Locale(userLocale).region;
    socket.emit("join_room", {
      id: to,
      region: userCountryCode,
    });
  }
  function leaveRoom() {
    socket.emit("leave_room");
  }

  return { joinRoom, leaveRoom, socket };
});

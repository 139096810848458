<template>
  <div class="w-full">
    <div class="w-full h-full flex items-center justify-center">
      <div @click.stop class="relative h-4/5 overflow-hidden rounded-3xl w-full max-w-[1200px] cursor-auto">
        <div class="px-7 md:px-14 py-6 md:py-12 pt-12 justify-center flex flex-col h-full">
          <div class="h-full overflow-hidden">
            <div>
              <button
                @click="goToRoom"
                class="py-[20px] button-reverse duration-500 bg-gradient-to-r from-[#008ca8] to-[#00fddc] text-white w-full justify-center md:py-8 px-10 rounded-full text-3xl md:text-5xl font-sans max-w-full outline-none font-bold border-none cursor-pointer inline-flex bg-cover bg-center mb-8">
                <span v-if="!goToRoomActive">{{ $t("home.setup.host") }}</span>
                <v-progress-circular v-if="goToRoomActive" :size="48" indeterminate></v-progress-circular>
              </button>
            </div>
            <div class="flex flex-wrap justify-center overflow-auto h-4/5 md:h-3/4 content-baseline overflow-x-hidden">
              <div class="m-6 mt-0">
                <p class="text-center font-medium mb-1">
                  {{ $t("home.setup.answering.title") }}
                </p>
                <div class="flex justify-center flex-wrap">
                  <div class="tale m-1 flex justify-center items-center">
                    <input
                      type="radio"
                      value="4"
                      checked
                      v-model="answering"
                      class="setup"
                      name="answering"
                      id="answering1"
                      @click="songsNumberTikTok = 5" />
                    <svg
                      class="absolute pointer-events-none left-1 icon-checked"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                    </svg>
                    <label
                      for="answering1"
                      class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16">
                        <path
                          fill="currentColor"
                          d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                      </svg>
                      <h6 class="font-medium">Openings</h6>
                    </label>
                  </div>
                  <div class="tale m-1 flex justify-center items-center">
                    <input
                      type="radio"
                      value="2"
                      v-model="answering"
                      class="setup"
                      name="answering"
                      id="answering2"
                      @click="songsNumberTikTok = 6" />
                    <svg
                      class="absolute pointer-events-none left-1 icon-checked"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                    </svg>
                    <label
                      for="answering2"
                      class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16">
                        <path
                          fill="currentColor"
                          d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                      </svg>
                      <h6 class="font-medium">Personnage</h6>
                    </label>
                  </div>
                  <div class="tale m-1 flex justify-center items-center">
                    <input
                      type="radio"
                      value="3"
                      v-model="answering"
                      class="setup"
                      name="answering"
                      id="answering3"
                      @click="songsNumberTikTok = 5" />
                    <svg
                      class="absolute pointer-events-none left-1 icon-checked"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                    </svg>
                    <label
                      for="answering3"
                      class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16">
                        <path
                          fill="currentColor"
                          d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                      </svg>
                      <h6 class="font-medium">Easy to Hard</h6>
                    </label>
                  </div>
                  <!-- <div class="tale m-1 flex justify-center items-center">
                    <input
                      type="radio"
                      value="4"
                      v-model="answering"
                      class="setup"
                      name="answering"
                      id="answering4"
                      @click="songsNumberTikTok = 6" />
                    <svg
                      class="absolute pointer-events-none left-1 icon-checked"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                    </svg>
                    <label
                      for="answering4"
                      class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 16 16">
                        <path
                          fill="currentColor"
                          d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
                      </svg>
                      <h6 class="font-medium">V2</h6>
                    </label>
                  </div> -->
                </div>
              </div>
              <div class="m-6 mt-0">
                <p class="text-center font-medium mb-1">
                  {{ $t("home.setup.difficulty.title") }}
                </p>
                <div class="flex justify-center flex-wrap">
                  <div
                    v-for="difficulty in difficulties"
                    :key="difficulty.value"
                    class="tale m-1 flex justify-center items-center">
                    <input
                      class="setup"
                      type="checkbox"
                      name="difficulte"
                      :id="'difficulte' + difficulty.value"
                      :value="difficulty.value"
                      v-model="difficulty.checked"
                      @change="updateDifficulty" />
                    <svg
                      class="absolute pointer-events-none left-1 icon-checked"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                    </svg>
                    <label
                      :for="'difficulte' + difficulty.value"
                      class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                      <svg class="flex" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                        <path fill="currentColor" :d="difficulty.icon" />
                      </svg>
                      <h6 class="font-medium">{{ difficulty.label }}</h6>
                    </label>
                  </div>
                </div>
              </div>
              <div class="m-6 mr-12 mt-0">
                <p class="text-center font-medium mb-1">
                  {{ $t("home.setup.songs_number.title") }}
                </p>
                <div class="flex justify-center items-center mb-3 h-[74px] relative">
                  <v-slider
                    v-model="songsNumber"
                    color="#00bdbf"
                    class="w-[150px] h-[28px]"
                    :min="songsNumberTikTok"
                    max="500"
                    :step="songsNumberTikTok"></v-slider>
                  <span class="font-medium absolute right-[-30px] pt-[1px]">{{ songsNumber }}</span>
                </div>
              </div>
              <div class="m-6 mt-0">
                <p class="text-center font-medium mb-1">
                  {{ $t("home.setup.media_type.title") }}
                </p>
                <div class="flex justify-center flex-wrap">
                  <div
                    v-for="mediaType in mediaTypes"
                    :key="mediaType.value"
                    class="tale m-1 flex justify-center items-center">
                    <input
                      class="setup"
                      type="checkbox"
                      :id="'mediaType' + mediaType.value"
                      :value="mediaType.value"
                      v-model="mediaType.checked"
                      @change="updateMediaType" />
                    <svg
                      class="absolute pointer-events-none left-1 icon-checked"
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
                    </svg>
                    <label
                      :for="'mediaType' + mediaType.value"
                      class="duration-300 absolute cursor-pointer select-none items-center flex flex-col">
                      <svg
                        class="flex"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        :viewBox="mediaType.viewBox">
                        <path fill="currentColor" :d="mediaType.icon" />
                      </svg>
                      <h6 class="font-medium">{{ mediaType.label }}</h6>
                    </label>
                  </div>
                </div>
              </div>
              <div class="m-6 mt-0">
                <p class="text-center font-medium mb-1">Drop</p>
                <div class="flex justify-center flex-wrap">
                  <v-switch v-model="drop" color="#00bdbf"></v-switch>
                </div>
              </div>
              <div class="m-6 mt-0">
                <p class="text-center font-medium mb-1">Favoris</p>
                <div class="flex justify-center flex-wrap">
                  <v-switch v-model="fav" color="#00bdbf"></v-switch>
                </div>
              </div>
              <div class="m-6 mt-0">
                <p class="text-center font-medium mb-1">Ignorer les chansons déjà jouées</p>
                <div class="flex justify-center flex-wrap">
                  <v-switch v-model="keep" color="#00bdbf"></v-switch>
                </div>
              </div>
            </div>
            <v-select label="Année" :items="annees" clearable v-model="year"></v-select>
            <v-select
              label="Saison"
              :items="['winter', 'spring', 'summer', 'fall']"
              clearable
              v-model="season"></v-select>
            <v-autocomplete
              v-model="songs"
              :items="animesSongs"
              chips
              closable-chips
              color="blue-grey-lighten-2"
              item-title="name"
              item-value="id"
              label="Select"
              auto-select-first
              multiple>
              <template v-slot:chip="{ props, item }">
                <v-chip
                  v-bind="props"
                  :prepend-avatar="'/images/thumbnail/' + item?.raw?.poster"
                  :text="item.raw.short + ' - ' + item?.raw?.song_type + ' ' + item?.raw?.song_number"></v-chip>
              </template>

              <template v-slot:item="{ props, item }">
                <v-list-item
                  class="bg-second text-white"
                  v-bind="props"
                  :prepend-avatar="'/images/thumbnail/' + item?.raw?.poster"
                  :title="item?.raw?.name"
                  :subtitle="
                    item?.raw?.song_type + ' ' + item?.raw?.song_number + ' - ' + item?.raw?.song_name
                  "></v-list-item>
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, onMounted, onBeforeUnmount, computed } from "vue";
  import { useFetch } from "@vueuse/core";

  import { useI18n } from "vue-i18n";
  import { useRouter } from "vue-router";
  import { useJoinRoomStore } from "@/stores/joinRoom";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();

  const router = useRouter();

  const answering = ref(4);
  const goToRoomActive = ref(false);
  const joinRoom = useJoinRoomStore();

  const socket = joinRoom.socket;
  const mediaTypesFinal = ref(0);
  const { t } = useI18n();
  const songsNumber = ref(20);
  const songsNumberTikTok = ref(5);
  const songsSelection = ref(false);
  const songs = ref([]);
  const annees = ref([]);
  const year = ref(null);
  const season = ref(null);
  const animesSongs = ref([]);
  const drop = ref(false);
  const fav = ref(false);
  const keep = ref(true);
  const difficulties = ref([
    {
      value: 1,
      checked: true,
      label: computed(() => t("home.setup.difficulty.easy")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88ZM80 108a12 12 0 1 1 12 12a12 12 0 0 1-12-12Zm96 0a12 12 0 1 1-12-12a12 12 0 0 1 12 12Zm-1.07 48c-10.29 17.79-27.4 28-46.93 28s-36.63-10.2-46.92-28a8 8 0 1 1 13.84-8c7.47 12.91 19.21 20 33.08 20s25.61-7.1 33.07-20a8 8 0 0 1 13.86 8Z`,
    },
    {
      value: 2,
      checked: false,
      label: computed(() => t("home.setup.difficulty.medium")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88Zm53.66-53.66a8 8 0 0 1-11.32 11.32L160 163.31l-10.34 10.35a8 8 0 0 1-11.32 0L128 163.31l-10.34 10.35a8 8 0 0 1-11.32 0L96 163.31l-10.34 10.35a8 8 0 0 1-11.32-11.32l16-16a8 8 0 0 1 11.32 0L112 156.69l10.34-10.35a8 8 0 0 1 11.32 0L144 156.69l10.34-10.35a8 8 0 0 1 11.32 0ZM80 108a12 12 0 1 1 12 12a12 12 0 0 1-12-12Zm72 0a12 12 0 1 1 12 12a12 12 0 0 1-12-12Z`,
    },
    {
      value: 4,
      checked: false,
      label: computed(() => t("home.setup.difficulty.hard")),
      icon: `M92 152a12 12 0 1 1 12-12a12 12 0 0 1-12 12Zm72-24a12 12 0 1 0 12 12a12 12 0 0 0-12-12Zm68 0A104 104 0 1 1 128 24a104.11 104.11 0 0 1 104 104Zm-16 0a88 88 0 1 0-88 88a88.1 88.1 0 0 0 88-88Zm-44.44-46.66L128 110.39l-43.56-29a8 8 0 1 0-8.88 13.32l48 32a8 8 0 0 0 8.88 0l48-32a8 8 0 0 0-8.88-13.32Zm-15.13 96C148 171.73 139.94 168 128 168s-20 3.73-28.43 9.34a8 8 0 0 0 8.86 13.32C114.93 186.34 120 184 128 184s13.07 2.34 19.57 6.66a8 8 0 1 0 8.86-13.32Z`,
    },
  ]);
  const difficultyFinal = ref(0);

  const mediaTypes = ref([
    {
      value: 1,
      checked: true,
      label: computed(() => t("home.setup.media_type.openings")),
      icon: `M23 3H1V1h22v2M2 22h4c0-3-2-5-2-5c6-4 7-13 7-13H2v18M22 4h-9s1 9 7 13c0 0-2 2-2 5h4V4Z`,
      viewBox: "0 0 24 24",
    },
    {
      value: 2,
      checked: false,
      label: computed(() => t("home.setup.media_type.endings")),
      icon: `M23 3H1V1h22v2M2 22h9V4H2v18M22 4h-9v18h9V4Z`,
      viewBox: "0 0 24 24",
    },
    {
      value: 4,
      checked: false,
      label: computed(() => t("home.setup.media_type.ost")),
      icon: `M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24Zm0 192a88 88 0 1 1 88-88a88.1 88.1 0 0 1-88 88Zm0-144a56.06 56.06 0 0 0-56 56a8 8 0 0 1-16 0a72.08 72.08 0 0 1 72-72a8 8 0 0 1 0 16Zm72 56a72.08 72.08 0 0 1-72 72a8 8 0 0 1 0-16a56.06 56.06 0 0 0 56-56a8 8 0 0 1 16 0Zm-40 0a32 32 0 1 0-32 32a32 32 0 0 0 32-32Zm-48 0a16 16 0 1 1 16 16a16 16 0 0 1-16-16Z`,
      viewBox: "0 0 256 256",
    },
  ]);
  onMounted(async () => {
    const { data: response } = await useFetch("/api/tiktok/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    animesSongs.value = JSON.parse(response.value);
    updateDifficulty();
    updateMediaType();
    anneesDepuis1950();
    global.pageLoad();
  });
  function anneesDepuis1950() {
    const anneeCourante = new Date().getFullYear();

    for (let annee = anneeCourante; annee >= 1950; annee--) {
      annees.value.push(annee);
    }
  }

  const updateDifficulty = () => {
    let total = 0;
    for (const difficulty of difficulties.value) {
      if (difficulty.checked) {
        total += difficulty.value;
      }
    }
    difficultyFinal.value = total;
  };
  const updateMediaType = () => {
    let total = 0;
    for (const mediaType of mediaTypes.value) {
      if (mediaType.checked) {
        total += mediaType.value;
      }
    }
    mediaTypesFinal.value = total;
  };

  const goToRoom = () => {
    goToRoomActive.value = true;
    let guessTime;
    if (answering.value == 2) {
      guessTime = 3;
    } else {
      guessTime = 5;
    }
    if (answering.value == 3) {
      difficultyFinal.value = 1;
    }
    socket.emit("goToRoomSetup", {
      answering: 2,
      difficulty: difficultyFinal.value,
      songsNumber: songsNumber.value,
      mediaType: mediaTypesFinal.value,
      guessTime: guessTime,
      songsSelection: songsSelection.value,
      privateRoom: true,
      n: 0,
      year: year.value,
      season: season.value,
      tiktok: answering.value,
      tiktokSongs: songs.value,
      drop: drop.value,
      fav: fav.value,
      keep: keep.value,
    });
  };
  socket.on("goToRoom", (data) => {
    if (answering.value == 1) {
      router.push({ name: "tiktokv2", query: { id: data } });
    } else if (answering.value == 2) {
      router.push({ name: "tiktokCharacter", query: { id: data } });
    } else if (answering.value == 3) {
      router.push({ name: "tiktokv2", query: { id: data } });
    } else {
      router.push({ name: "tiktokv2", query: { id: data } });
    }
  });
  onBeforeUnmount(() => {
    socket.off();
  });
</script>
<style scoped>
  .tale {
    position: relative;
    width: 100px;
    height: 70px;
  }

  input[class="setup"] {
    appearance: none;
    outline: none;
    width: 100px;
    height: 70px;
    border-radius: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    border: 3px solid #d8d8d8;
    background: #0f112b;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    &::before {
      content: "";
      left: 13px;
      top: 13px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 0px solid #d8d8d8;
      color: #00bdbf;
      width: 0;
      height: 0;
      transform: translate(-50%, -50%);
      transition: all 0.3s, border 0.3s;
    }
    &:hover {
      &::before {
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid #d8d8d8;
      }
    }
    &:checked {
      border: 3px solid #00bdbf;
      & ~ * {
        color: #00bdbf;
        width: 25px;
      }
      & ~ .icon-checked {
        width: 25px;
      }
      &::before {
        opacity: 0;
        transition: 0s;
      }
    }

    & ~ .icon-checked {
      width: 0;
      color: #00bdbf;
      transition: 0.3s;
      transform: translate(-50%, -50%);
      left: 16px;
      top: 16px;
    }
  }
  @keyframes selectKitchen {
    50% {
      font-size: 3rem;
    }
  }
  .modal-outer-enter-active,
  .modal-outer-leave-active {
    transition: opacity 0.1s cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }

  .modal-outer-enter-from,
  .modal-outer-leave-to {
    opacity: 0;
  }

  .modal-inner-enter-active {
    transition: all 0.1s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
  }

  .modal-inner-leave-active {
    transition: all 0.1s cubic-bezier(0.52, 0.02, 0.19, 1.02);
  }

  .modal-inner-enter-from {
    opacity: 0;
    transform: scale(0.8);
  }

  .modal-inner-leave-to {
    transform: scale(0.8);
  }
</style>

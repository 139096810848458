<template>
  <div class="flex flex-col mx-auto calc2rem mb-24 md:pb-0">
    <div class="justify-center flex flex-col sm:p-2 lg:p-10 rounded-lg">
      <h1 class="admin-title mb-[30px] md:mb-0">Espace admin</h1>
    </div>
    <section class="flex flex-col justify-center antialiased p-1 md:p-4 w-full">
      <div class="h-full">
        <div class="mx-auto md:max-w-5xl">
          <div class="md:grid md:grid-cols-12 md:gap-6 justify-items-center flex justify-between flex-wrap">
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-[48.5%] mb-[10px] md:mb-0 md:w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link to="/admin/anime/manage">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="whitespace-nowrap button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-1 rounded-md shadow-sm text-white w-full">
                    Gérer les animes
                  </button>
                </div>
              </router-link>
            </div>

            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-[48.5%] mb-[10px] md:mb-0 md:w-full"
              style="
                background: rgb(255 255 255 / 5%);
                /* background: rgb(3 7 18 / 14%); */
                border-top: 3px solid #00fddc;
              ">
              <router-link to="/modo/song/manage">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-1 rounded-md shadow-sm text-white w-full">
                    Gérer les chansons
                  </button>
                </div>
              </router-link>
            </div>

            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link to="/admin/tiktok/menu">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok
                  </button>
                </div>
              </router-link>
            </div>

            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link to="/admin/tiktok/thumbnail">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    Miniature TikTok
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link to="/admin/tiktok/cut">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Cut
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link to="/admin/tiktok/manage">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Manage
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link :to="`/admin/tiktok/pixel/anime?mobile=${mobile == 1 ? '1' : '0'}`">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Pixel Anime
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link :to="`/admin/tiktok/pixel/character?mobile=${mobile == 1 ? '1' : '0'}`">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Pixel Personnage
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link :to="`/admin/tiktok/pixel/video?mobile=${mobile == 1 ? '1' : '0'}`">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Pixel Video
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link :to="`/admin/tiktok/character/random?mobile=${mobile == 1 ? '1' : '0'}`">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Random Personnage
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link :to="`/admin/tiktok/screenshot?mobile=${mobile == 1 ? '1' : '0'}`">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Screenshot
                  </button>
                </div>
              </router-link>
            </div>
            <div
              class="relative col-span-full md:col-span-4 bg-gray-950 shadow-md rounded-2xl border-t-4 home-card w-full"
              style="background: rgb(255 255 255 / 5%); border-top: 3px solid #008ea9">
              <router-link :to="`/admin/tiktok/voice?mobile=${mobile == 1 ? '1' : '0'}`">
                <div class="md:px-5 md:py-4 p-3">
                  <button
                    class="button-reverse border-none font-medium cursor-pointer text-sm inline-flex items-center justify-center md:px-3 md:py-2 p-[0.35rem] rounded-md shadow-sm text-white w-full">
                    TikTok Voice
                  </button>
                </div>
              </router-link>
            </div>
            <v-switch class="md:col-span-4" v-model="mobile" label="Version mobile" color="#00bdbf"></v-switch>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
  // definePageMeta({
  //   middleware: "protected",
  // });

  import { ref, computed, onMounted } from "vue";
  import { useSeoMeta } from "@unhead/vue";
  import { useI18n } from "vue-i18n";
  import { useGlobalStore } from "@/stores/global";

  const global = useGlobalStore();
  const mobile = ref(true);
  onMounted(() => {
    global.pageLoad();
  });
  const { t } = useI18n();

  useSeoMeta({
    title: computed(() => t("home.title") + " - Baranimes"),
    ogTitle: computed(() => t("home.title") + " - Baranimes"),
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: computed(() => t("home.title") + " - Baranimes"),
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
</script>

<style scoped>
  @keyframes quickPlayButtonEffect {
    100% {
      background-position-x: -200%;
    }
  }

  .admin-title {
    background-clip: text;
    margin-top: 40px;
    color: #00bdbf;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    border-left: 4px solid;
    padding-left: 20px;
  }
  .play {
    animation: quickPlayButtonEffect 5s infinite linear;
    background: linear-gradient(
      -60deg,
      #00fddc 0%,
      #00fddc 10%,
      #008ca8 25%,
      #008ca8 30%,
      #00fddc 50%,
      #008ca8 80%,
      #008ca8 85%,
      #00fddc 92%
    );
    background-size: 200% 100%;
  }

  .play:hover {
    box-shadow: 0px 15px 20px rgb(0 140 168 / 40%);
    transform: translateY(-7px);
  }
</style>

<template>
  <div>
    <div class="font-medium text-xl md:text-2xl mb-4">{{ $t("header.modal.sign_password.reset_password") }}</div>
    <form class="px-8 pt-6 pb-8 mb-4 rounded" @submit.prevent="changePassword" v-if="!change">
      <div class="mb-4">
        <div class="mb-4 md:mr-2 md:mb-0">
          <label class="block mb-2 text-sm font-bold text-gray-700" for="password">
            {{ $t("header.modal.sign_up.password") }}
          </label>
          <input
            class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            placeholder="******************"
            required
            v-model="password1" />
        </div>
        <div class="md:ml-2">
          <label class="block mb-2 text-sm font-bold text-gray-700" for="c_password">
            {{ $t("header.modal.sign_up.confirm_password") }}
          </label>
          <input
            class="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            id="c_password"
            type="password"
            placeholder="******************"
            required
            v-model="password2" />
        </div>
      </div>
      <div class="mb-6 text-center">
        <button
          type="submit"
          class="w-full px-4 py-2 font-bold text-white button-reverse rounded-lg focus:outline-none focus:shadow-outline">
          {{ $t("header.modal.sign_password.update_password") }}
        </button>
      </div>
    </form>
    <div v-else>
      {{ $t("header.modal.sign_password.changed") }}
      <br />
      <router-link to="/">Accueil</router-link>
    </div>
  </div>
</template>
<script setup>
  import { ref } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useRoute } from "vue-router";

  const change = ref(false);
  const password1 = ref("");
  const password2 = ref("");
  const route = useRoute();

  const changePassword = async () => {
    if (password1.value === password2.value) {
      await useFetch("/api/auth/password/update", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password1.value,
          key: route.query.key,
        }),
      });
      change.value = true;
    }
  };
</script>
<style scoped></style>

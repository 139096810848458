<template>
  <div>
    <v-text-field
      class="mt-[10px] mr-[10px] min-w-[150px] h-[60px] w-[50px]"
      label="MAL ID"
      type="number"
      v-model="mal_id"></v-text-field>
    <v-btn v-if="items.length" class="button-reverse text-white" @click="btnSearch()">Ajouter</v-btn>
    <v-switch v-model="boucle" label="Auto" color="#00bdbf"></v-switch>
    {{ mal_id }} - {{ items?.[mal_id]?.mal_id }}
  </div>
</template>
<script setup>
  import { ref, onMounted } from "vue";
  import { useFetch } from "@vueuse/core";
  const boucle = ref(false);
  const items = ref([]);
  const mal_id = ref(0);

  onMounted(async () => {
    const { data: response } = await useFetch("/api/admin/song/auto/get", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    items.value = JSON.parse(response.value);
  });
  async function btnSearch() {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", "https://shikimori.one/api/animes/" + items.value[mal_id.value]?.mal_id + "/videos");
    xhr.onreadystatechange = async function () {
      if (xhr.readyState === 4) {
        var reponse = JSON.parse(xhr.responseText);
        if (reponse.status != 404 && reponse.status != 408 && reponse.code != 408 && reponse.code != 404) {
          let result = [];
          reponse.forEach(function (item) {
            if (item.hosting == "youtube") {
              result.push(item);
            }
          });
          if (result.length) {
            console.log(result);
            await useFetch("/api/admin/song/auto/add", {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                result: result,
                idAnime: items.value[mal_id.value]?.id,
              }),
            });
            if (boucle.value) {
              setTimeout(function () {
                mal_id.value++;
                btnSearch();
              }, 1000);
            }
          } else {
            if (boucle.value) {
              setTimeout(function () {
                mal_id.value++;
                btnSearch();
              }, 1000);
            }
          }
        } else if (boucle.value) {
          setTimeout(function () {
            mal_id.value++;
            btnSearch();
          }, 1000);
        }
      }
    };
    xhr.send();
  }
</script>
<style scoped></style>

import { createApp } from "vue";
import { createPinia } from "pinia";
import router from "./router";
import { createHead } from "@unhead/vue";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import App from "./App.vue";

import i18n from "./i18n";

import "./index.css";
import "./registerServiceWorker";
const head = createHead();

const pinia = createPinia();

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
  },
  components: {
    ...components,
  },
  directives,
});
createApp(App).use(pinia).use(router).use(head).use(vuetify).use(i18n()).mount("#app");

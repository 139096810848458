<template>
  <ModalReuse3 :modal-active="modalActive" @close-modal="toggleModal"></ModalReuse3>
  <div class="mb-24 md:mb-0">
    <div
      class="flex rounded-2xl justify-center"
      style="
        /* background: rgb(2 4 31 / 80%);
        border-bottom: 3px solid #008ca8;
        box-shadow: rgba(0, 140, 168, 0.4) 0px 15px 20px; */
        /* #080b2a */
      ">
      <div class="w-[16%] lg:block hidden max-w-[182px]">
        <div class="poster text-[180px] font-medium text-center imgPoster">
          <v-img v-if="imgPoster" loading="lazy" class="shadow-3xl rounded-xl" :src="'/images/upload/' + imgPoster">
            <template v-slot:placeholder>
              <v-sheet>
                <v-skeleton-loader
                  type="image"
                  class="rounded-xl overflow-hidden shadow-3xl"
                  color="#020420"></v-skeleton-loader>
              </v-sheet>
            </template>
          </v-img>
          <span v-else class="block h-0 pb-[150%]">?</span>
        </div>
        <div class="flex flex-col font-medium mt-3">
          <span>{{ $t("room.title") }}</span>
          <v-tooltip :text="varFront.song_name" location="top">
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="font-normal nowrap" style="padding: 0px 0px 15px 0px">{{
                varFront.song_name
              }}</span>
            </template>
          </v-tooltip>
          <span>{{ $t("room.artist") }}</span>
          <v-tooltip :text="varFront.song_artist" location="top">
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="font-normal nowrap" style="padding: 0px 0px 15px 0px">{{
                varFront.song_artist
              }}</span>
            </template>
          </v-tooltip>
          <span>{{ $t("room.type") }}</span>
          <v-tooltip :text="varFront.song_type" location="top">
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="font-normal nowrap" style="padding: 0px 0px 15px 0px">{{
                varFront.song_type
              }}</span>
            </template>
          </v-tooltip>
        </div>
      </div>
      <div class="w-full lg:w-[60%] lg:px-6 max-w-[720px]">
        <div class="md:flex flex-col hidden relative h-[39px]">
          <v-menu
            transition="slide-y-transition"
            open-delay="0"
            close-delay="0"
            open-on-hover
            open-on-focus
            :close-on-content-click="false"
            location="bottom">
            <template v-slot:activator="{ props }">
              <div
                v-bind="props"
                class="text-[30px] font-medium text-center nowrap lg:text-start w-full absolute"
                style="line-height: 1.3">
                {{ varFront.title }}
              </div>
            </template>
            <v-card style="background-color: #0f112b; border-radius: 0.75rem">
              <div class="flex overflow-hidden">
                <div class="bg-second p-2">
                  <p class="text-white font-medium mb-1 text-lg">{{ varFront.title }}</p>
                  <p class="text-gray-100 m-0" v-html="varFront.others"></p>
                </div>
              </div>
            </v-card>
          </v-menu>
        </div>
        <div
          class="flex justify-center mt-0 sm:mt-8 md:mt-2 sm:rounded-2xl sm:my-2 mb-3 bg-linear overflow-hidden relative sm:shadow-3xl"
          id="playerContainer"
          style="padding-bottom: 56.17978%">
          <div
            v-if="counterDown"
            id="counterDown"
            class="flex rounded-full items-center justify-center self-center top-[50%] absolute w-100">
            <span v-if="chrono" style="text-align: center; font-weight: 700; font-size: 6.5em">
              {{ chrono }}
            </span>
            <button
              @click="toggleReadyStatus"
              v-if="!launch && interact && firstReady"
              style="padding: 100%"
              class="font-medium text-5xl sm:text-6xl">
              {{ isReady ? $t("room.cancel") : $t("room.ready") }}
            </button>
            <span v-else-if="alreadyConnected" class="font-medium text-3xl flex flex-col"
              ><span>{{ $t("room.already_logged_in") }}</span></span
            >
            <span v-else-if="!launch && interact" class="font-medium text-3xl flex flex-col"
              ><span class="mb-6">{{ $t("room.loading") }}</span
              ><v-progress-linear indeterminate class="w-[150px]"></v-progress-linear
            ></span>

            <br />
            <button
              @click="toggleReadyStatusShift"
              v-if="!interact && firstReady"
              style="padding: 100%"
              class="font-medium text-4xl sm:text-5xl">
              {{ $t("room.join") }}
            </button>
            <span v-else-if="!interact" class="font-medium text-3xl flex flex-col"
              ><span class="mb-6">{{ $t("room.loading") }}</span
              ><v-progress-linear indeterminate class="w-[150px]"></v-progress-linear
            ></span>
            <span v-if="nextRound" class="font-medium text-3xl flex flex-col"
              ><span class="mb-6">{{ $t("room.waiting") }}</span></span
            >
          </div>
          <div
            v-if="fastForward"
            class="fastforward flex items-center justify-center self-center top-[50%] absolute z-10">
            <div class="arrow" style="--i: 0"></div>
            <div class="arrow" style="--i: 0"></div>
            <div class="arrow" style="--i: 0"></div>
          </div>
          <div
            v-if="hideVideo && !counterDown && !fastForward"
            class="flex items-center justify-center self-center top-0 h-full absolute z-10">
            <v-img class="w-[150px]" src="/images/global/favicon_no_bg.png" alt="Logo without background" />
          </div>
          <div id="subPlayerContainer" class="w-full"></div>
        </div>
        <div class="flex justify-between mx-2 sm:mx-0">
          <div class="flex justify-center items-center mb-3">
            <span class="font-medium mr-3">{{ n || "-" }}/{{ setupRoom.value?.songsNumber || "-" }}</span>
            <div @click="toggleMute" v-if="muteIcon == 'volume_off'" class="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M16.775 19.575q-.275.175-.55.325t-.575.275q-.375.175-.762 0t-.538-.575q-.15-.375.038-.738t.562-.537q.175-.075.325-.163t.3-.187L12 14.8v2.775q0 .675-.613.938T10.3 18.3L7 15H4q-.425 0-.713-.288T3 14v-4q0-.425.288-.713T4 9h2.2L2.1 4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l17 17q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275l-2.325-2.325ZM19.6 16.8l-1.45-1.45q.425-.775.638-1.625t.212-1.75q0-2.075-1.1-3.787t-2.95-2.563q-.375-.175-.55-.537t-.05-.738q.15-.4.537-.575t.788 0Q18.1 4.85 19.55 7.05T21 11.975q0 1.325-.363 2.55T19.6 16.8Zm-3.35-3.35L14 11.2V8.85q0-.3.263-.437t.512.012Q15.6 8.95 16.05 10t.45 2q0 .375-.063.738t-.187.712ZM12 9.2L9.4 6.6l.9-.9q.475-.475 1.088-.213t.612.938V9.2Z" />
              </svg>
            </div>
            <div @click="toggleMute" v-else-if="muteIcon == 'volume_down'" class="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9 15H6q-.425 0-.713-.288T5 14v-4q0-.425.288-.713T6 9h3l3.3-3.3q.475-.475 1.088-.213t.612.938v11.15q0 .675-.613.938T12.3 18.3L9 15Zm9.5-3q0 1.05-.475 1.988t-1.25 1.537q-.25.15-.512.013T16 15.1V8.85q0-.3.263-.438t.512.013q.775.625 1.25 1.575t.475 2Z" />
              </svg>
            </div>
            <div @click="toggleMute" v-else-if="muteIcon == 'volume_up'" class="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M19 11.975q0-2.075-1.1-3.787t-2.95-2.563q-.375-.175-.55-.537t-.05-.738q.15-.4.537-.575t.788 0Q18.1 4.85 19.55 7.063T21 11.974q0 2.7-1.45 4.913t-3.875 3.287q-.4.175-.788 0t-.537-.575q-.125-.375.05-.738t.55-.537q1.85-.85 2.95-2.563t1.1-3.787ZM7 15H4q-.425 0-.713-.288T3 14v-4q0-.425.288-.713T4 9h3l3.3-3.3q.475-.475 1.088-.213t.612.938v11.15q0 .675-.613.938T10.3 18.3L7 15Zm9.5-3q0 1.05-.475 1.988t-1.25 1.537q-.25.15-.513.013T14 15.1V8.85q0-.3.263-.438t.512.013q.775.625 1.25 1.575t.475 2Z" />
              </svg>
            </div>
            <v-slider
              v-model="global.volume"
              color="#00bdbf"
              class="w-[70px] sm:w-[100px] h-[28px] mt-[-3px]"
              step="1"></v-slider>
            <div class="font-medium ml-2">{{ global.volume }}</div>
          </div>
          <div class="flex select-none">
            <v-tooltip :text="varFront.hideVideo" location="top" v-model="multiChoiceVMenu[4]">
              <template v-slot:activator="{ props }">
                <svg
                  v-if="!hideVideo"
                  @touchstart="vMenuStart(4)"
                  @touchend="vMenuEnd"
                  @click="videoHidden"
                  v-bind="props"
                  class="cursor-pointer outline-none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M12 16q1.875 0 3.188-1.313T16.5 11.5q0-1.875-1.313-3.188T12 7q-1.875 0-3.188 1.313T7.5 11.5q0 1.875 1.313 3.188T12 16Zm0-1.8q-1.125 0-1.913-.788T9.3 11.5q0-1.125.788-1.913T12 8.8q1.125 0 1.913.788T14.7 11.5q0 1.125-.787 1.913T12 14.2Zm0 4.8q-3.475 0-6.35-1.838T1.3 12.2q-.075-.125-.1-.312t-.025-.388q0-.2.025-.388t.1-.312q1.475-3.125 4.35-4.963T12 4q3.475 0 6.35 1.838T22.7 10.8q.075.125.1.313t.025.387q0 .2-.025.388t-.1.312q-1.475 3.125-4.35 4.963T12 19Z" />
                </svg>
                <svg
                  v-else
                  @touchstart="vMenuStart(4)"
                  @touchend="vMenuEnd"
                  @click="videoHidden"
                  v-bind="props"
                  class="cursor-pointer outline-none"
                  width="28"
                  height="28"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="m19.8 22.6l-4.2-4.15q-.875.275-1.775.413T12 19q-3.35 0-6.125-1.8t-4.35-4.75q-.125-.225-.187-.462t-.063-.488q0-.25.063-.488t.187-.462q.55-.975 1.175-1.9T4.15 7L1.4 4.2l1.4-1.4l18.4 18.4l-1.4 1.4ZM12 16q.275 0 .525-.025t.5-.1l-5.4-5.4q-.075.25-.1.5T7.5 11.5q0 1.875 1.313 3.188T12 16Zm7.3.45l-3.175-3.15q.175-.425.275-.875t.1-.925q0-1.875-1.313-3.188T12 7q-.475 0-.925.1t-.875.3L7.65 4.85q1.05-.425 2.138-.637T12 4q3.35 0 6.138 1.813t4.362 4.762q.125.2.188.438t.062.487q0 .25-.05.488t-.175.437q-.6 1.175-1.388 2.2T19.3 16.45Zm-4.625-4.6l-3-3q.65-.125 1.263.1t1.037.7q.425.45.613 1.025t.087 1.175Z" />
                </svg>
              </template>
            </v-tooltip>
            <v-tooltip :text="varFront.pauseSong" location="top" v-model="multiChoiceVMenu[5]">
              <template v-slot:activator="{ props }">
                <svg
                  @touchstart="vMenuStart(5)"
                  @touchend="vMenuEnd"
                  @click="pauseSong"
                  v-bind="props"
                  class="cursor-pointer mx-2 overflow-visible opacity-50 pointer-events-none outline-none"
                  id="pause-button"
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M10.5 17q1.05 0 1.775-.725T13 14.5V9h2q.425 0 .713-.288T16 8q0-.425-.288-.713T15 7h-2q-.425 0-.713.288T12 8v4.5q-.325-.225-.7-.363T10.5 12q-1.05 0-1.775.725T8 14.5q0 1.05.725 1.775T10.5 17ZM4 20q-.825 0-1.413-.588T2 18V6q0-.825.588-1.413T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.588 1.413T20 20H4Zm0-2h16V6H4v12Zm0 0V6v12Z" />
                </svg>
              </template>
            </v-tooltip>
            <v-tooltip :text="varFront.fastForward" location="top" v-model="multiChoiceVMenu[6]">
              <template v-slot:activator="{ props }">
                <svg
                  @touchstart="vMenuStart(6)"
                  @touchend="vMenuEnd"
                  @click="skipSong"
                  v-bind="props"
                  class="cursor-pointer outline-none overflow-visible opacity-50 pointer-events-none"
                  id="skip-button"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="28"
                  viewBox="0 0 16 16">
                  <g fill="currentColor">
                    <path
                      d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z" />
                    <path
                      d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z" />
                  </g>
                </svg>
              </template>
            </v-tooltip>
          </div>
        </div>
        <div
          class="relative"
          v-if="answering == 1"
          @mouseleave="mouseOverAnime = false"
          @mouseenter="mouseOverAnime = true">
          <div
            @click="searchFocusIn"
            class="rounded-full p-1 text-center bg-second relative cursor-pointer mx-2 sm:mx-0"
            id="blind-rep-list">
            <span class="nowrap px-4">{{ animeSelected || $t("room.anime_title") }}</span>
            <svg
              class="absolute right-0 top-0"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="m11.3 14.3l-2.6-2.6q-.475-.475-.212-1.087T9.425 10h5.15q.675 0 .938.613T15.3 11.7l-2.6 2.6q-.15.15-.325.225T12 14.6q-.2 0-.375-.075T11.3 14.3Z" />
            </svg>
          </div>
          <div class="absolute w-full bg-first z-[14] invisible rounded-lg" id="searchAnimeContainer">
            <v-text-field
              @click:clear="
                search = '';
                resetItem();
              "
              append-inner-icon="mdi-magnify"
              density="compact"
              label="Rechercher"
              single-line
              flat
              :loading="loading"
              clearable
              hide-details
              bg-color="#0f112b"
              id="searchAnime"
              v-model="search"
              autocomplete="off"
              :placeholder="$t('room.search')"
              @input="resetItem"
              @keydown="handleKeyDown"
              @focusin="searchFocusIn"
              @focusout="searchFocusOut"
              type="search"
              class="w-full my-1 rounded-lg border-none outline-none text-white bg-second"
              variant="solo"></v-text-field>
            <div v-if="!visibleAnime?.value?.length === 0" class="flex m-3 justify-center">
              <v-progress-circular :size="50" color="#00bdbf" indeterminate></v-progress-circular>
            </div>
            <v-virtual-scroll
              :class="{ hidden: visibleAnime?.value?.length === 0 }"
              :items="visibleAnime"
              height="275"
              :key="visibleAnime"
              id="animeList">
              <template v-slot:default="{ item, index }">
                <div
                  class="flex cursor-pointer items-center rounded-md m-1 overflow-hidden animeListItem"
                  :class="{ selected: index === selectedIndex }"
                  @click="selectAnime(index)">
                  <v-img
                    loading="lazy"
                    class="rounded-md w-[50px] h-[75px]"
                    :class="{ 'blur-sm': item.nsfw == 18 }"
                    :src="'/images/thumbnail/' + item.image"
                    :alt="item.title"
                    width="50"
                    height="75" />
                  <span class="ml-3 font-medium w-full">{{ item.title }}</span>

                  <v-menu
                    transition="slide-y-transition"
                    open-delay="0"
                    close-delay="0"
                    open-on-hover
                    open-on-click
                    :close-on-content-click="false"
                    location="top">
                    <template v-slot:activator="{ props }">
                      <div class="p-3 select-none" style="padding-right: 1.25rem" v-bind="props">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M12 7q.425 0 .713-.288T13 6q0-.425-.288-.712T12 5q-.425 0-.712.288T11 6q0 .425.288.713T12 7m-1 8h2V9h-2zm-9 7V4q0-.825.588-1.412T4 2h16q.825 0 1.413.588T22 4v12q0 .825-.587 1.413T20 18H6z" />
                        </svg>
                      </div>
                    </template>
                    <v-card style="background-color: #0f112b; border-radius: 0.75rem">
                      <div class="flex max-w-[650px] max-h-[210px] overflow-hidden">
                        <img
                          loading="lazy"
                          :src="'/images/thumbnail/' + item.image"
                          class="rounded-xl max-h-[210px]"
                          :alt="item.title" />
                        <div class="bg-second p-2 min-w-[180px]">
                          <p class="text-white font-medium mb-1 text-lg">{{ item.title }}</p>
                          <p class="text-gray-100 m-0" v-for="(it, index) in item.alternative" :key="index">{{ it }}</p>
                        </div>
                      </div>
                    </v-card>
                  </v-menu>
                </div>
              </template>
            </v-virtual-scroll>
            <div v-if="visibleAnime?.value?.length === 0" class="flex m-3">
              <span class="font-medium w-full text-center">{{ $t("header.menu.no_result_found") }}</span>
            </div>
          </div>
        </div>
        <div v-else-if="answering == 2" class="flex flex-wrap">
          <v-menu
            transition="slide-y-transition"
            open-delay="0"
            close-delay="0"
            open-on-hover
            :close-on-content-click="false"
            location="top"
            v-for="(item, index) in multiChoice"
            v-model="multiChoiceVMenu[index]"
            :key="item.id">
            <template v-slot:activator="{ props }">
              <button
                @touchstart="vMenuStart(index)"
                @touchend="vMenuEnd"
                v-bind="props"
                class="p-1 w-1/2 select-none outline-none"
                @click="changeScale(index)"
                :class="{ 'pb-0': index === 2 || index === 3 }">
                <span
                  class="text-center button-reverse flex cursor-pointer items-center justify-center rounded-md overflow-hidden nowrap animeChoiceItem font-medium p-2">
                  {{ item.title }}
                </span>
              </button>
            </template>
            <v-card style="background-color: #0f112b; border-radius: 0.75rem">
              <div class="flex max-w-[650px] max-h-[210px] overflow-hidden">
                <img
                  loading="lazy"
                  :src="'/images/thumbnail/' + item.poster"
                  class="rounded-xl max-h-[210px]"
                  :alt="item.title" />
                <div class="bg-second p-2 min-w-[180px]">
                  <p class="text-white font-medium mb-1 text-lg">{{ item.title }}</p>
                  <p class="text-gray-100 m-0" v-html="item.others"></p>
                </div>
              </div>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div
        class="lg:flex w-[25%] bg-first rounded-xl flex-col overflow-auto hidden"
        style="border-bottom: 1px solid #00bdbf; box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5)">
        <form @submit.prevent="sendMessage" class="flex justify-between flex-col h-full">
          <label style="align-self: center; padding: 7px; font-size: 18px; font-weight: bold">{{
            $t("room.chat")
          }}</label>
          <div class="rounded-md h-full w-full table table-fixed relative">
            <div class="p-2 h-full overflow-auto m-div w-full absolute">
              <div
                v-for="message in messages"
                :key="message.idUser"
                class="m-container"
                :class="{ 'm-me': message.idUser === auth.user.idUser }">
                <div class="m-message emote" v-if="message.emote">
                  <img
                    :class="'rounded-md anim' + message.emote"
                    :src="'/images/emotes/' + message.link"
                    :alt="message.name"
                    :style="message.params"
                    loading="lazy" />
                </div>
                <div class="m-message" v-else>{{ message.message }}</div>
                <div class="m-sender">
                  <span class="font-medium">{{ message.username }}, </span><span>{{ message.time }}</span>
                </div>
              </div>
            </div>
          </div>
          <div style="padding: 3px 0; display: flex">
            <span class="emoji" @click="emoji(1)"><v-img src="/images/emotes/waving-hand.svg" loading="lazy" /></span>
            <span class="emoji" @click="emoji(3)"><v-img src="/images/emotes/heart.svg" loading="lazy" /></span>
            <span class="emoji" @click="emoji(2)"
              ><v-img src="/images/emotes/64fde5341d5448.90894800.png" loading="lazy"
            /></span>
            <span class="emoji" @click="emoji(4)"><v-img src="/images/emotes/aqua.png" loading="lazy" /></span>
            <span class="emoji">
              <v-menu
                class="all"
                transition="slide-y-transition"
                open-delay="0"
                close-delay="0"
                open-on-click
                :close-on-content-click="false"
                location="top">
                <template v-slot:activator="{ props }">
                  <v-img v-bind="props" src="/images/global/plus.png" loading="lazy" />
                </template>
                <v-card
                  style="background-color: #02041f; border-radius: 0.75rem"
                  class="flex flex-wrap max-w-[338px] max-h-[173px] overflow-auto p-1">
                  <div v-for="item in emotes" :key="item.id" class="flex">
                    <span class="emoji" style="width: 50px" @click="emoji(item.id)"
                      ><v-img class="rounded-md" :alt="item.titre" :src="'/images/emotes/' + item.lien" loading="lazy"
                    /></span>
                  </div>
                </v-card>
              </v-menu>
            </span>
          </div>
          <input
            v-model="message"
            autocomplete="off"
            :placeholder="auth.user.role === 'guest' ? 'Connecte-toi pour envoyer des messages' : 'Message'"
            :disabled="auth.user.role === 'guest'"
            type="text"
            class="py-3 px-4 block w-full rounded-xl text-sm bg-second outline-none" />
        </form>
      </div>
    </div>
    <v-card class="mx-auto lg:hidden">
      <v-tabs v-model="tabs" color="#00bdbf" grow bg-color="transparant">
        <v-tab :value="1"> {{ $t("room.players") }} </v-tab>

        <v-tab :value="2"> {{ $t("room.info") }} </v-tab>

        <v-tab :value="3"> {{ $t("room.chat") }} </v-tab>
      </v-tabs>

      <v-window v-model="tabs">
        <v-window-item :key="1" :value="1" class="flex flex-wrap justify-center text-center">
          <div
            v-for="item in usersInRoom"
            :key="item.id"
            :class="'h-[350px] relative inline-grid text-center justify-items-center avatar' + item.id">
            <span
              :class="'rep' + item.id"
              class="blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible"></span>
            <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11]">
              <div
                v-if="item.type === 'guest'"
                class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
                style="
                  filter: drop-shadow(black 2px 4px 6px);
                  background: url(/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
                  background-size: cover;
                  margin: 39px 50px 0;
                ">
                <v-img
                  src="/images/avatar_users/64f5bb5d1a2fd9.30522874.png"
                  class="max-w-none overflow-hidden img-user-avatar"
                  style="width: 180px; margin-bottom: 45px; margin-left: -3px" />
              </div>
              <div
                v-else
                class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
                style="
                  filter: drop-shadow(black 2px 4px 6px);
                  background: url(/images/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
                  background-size: cover;
                  margin: 36px 36px 0;
                "
                :style="'background: url(/images/global/bg_avatar.png),' + item.foParams">
                <v-img
                  :src="'/images/avatar_users/' + item.avLien"
                  class="max-w-none overflow-visible img-user-avatar h-[252px]"
                  :style="item.avParams" />
              </div>
            </div>

            <b
              v-if="item.type === 'guest'"
              class="shadow-base z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium"
              :class="'pts' + item.id"
              style="padding: 0"
              >0</b
            >
            <b
              v-else
              class="shadow-base z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium"
              :class="'pts' + item.id"
              style="padding: 0"
              :style="`background: ${item.psParams}; box-shadow: -5px -5px 5px -5px ${item.shParams1},
             5px 5px 5px -5px ${item.shParams2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`"
              >0</b
            >
            <span
              v-if="item.type === 'guest'"
              class="shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
              data-title="Défaut">
              {{ item.username }}</span
            >
            <span
              v-else
              class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base shadow-user-avatar"
              :style="`background: ${item.psParams}; box-shadow: -5px -5px 5px -5px ${item.shParams1},
             5px 5px 5px -5px ${item.shParams2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`">
              {{ item.username }}</span
            >
            <span
              v-if="item.type === 'guest'"
              class="rounded-md top-[-74px] z-[12] p-0 border-none text-sm w-[50px] relative leading-[1.1]"
              :style="
                'filter: drop-shadow(black 2px 4px 6px);' +
                'background: linear-gradient(90deg, #008ca8 ' +
                item.pts +
                '%, #0f112b 0%);' +
                'box-shadow: -2px -1px 6px -1px #008ca8, 5px 5px 5px -5px #008ca8, -7px -7px 10px -5px transparent,' +
                '7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0),' +
                '0 55px 35px -20px rgba(0, 0, 0, 0.5);'
              "
              >{{ item.lvl }}</span
            >
            <span
              v-else
              class="rounded-md top-[-74px] z-[12] p-0 border-none text-sm w-[50px] relative leading-[1.1]"
              :style="`
            filter: drop-shadow(black 2px 4px 6px);
            background: linear-gradient(90deg, #008ca8 ${item.pts}%, #0f112b 0%);
            box-shadow: -5px -5px 5px -5px ${item.shParams1},
             5px 5px 5px -5px ${item.shParams2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`"
              >{{ item.lvl }}</span
            >
          </div>
        </v-window-item>
        <v-window-item :key="2" :value="2">
          <div class="text-[24px] font-medium text-center my-3" style="line-height: 1.2">
            {{ varFront.title }}
          </div>
          <div class="poster text-[180px] font-medium text-center imgPoster flex justify-center">
            <v-img
              v-if="imgPoster"
              loading="lazy"
              width="182"
              height="273"
              class="shadow-3xl rounded-xl max-w-[182px]"
              :src="'/images/upload/' + imgPoster">
              <template v-slot:placeholder>
                <v-sheet>
                  <v-skeleton-loader
                    type="image"
                    class="rounded-xl overflow-hidden shadow-3xl"
                    color="#020420"></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-img>
            <span v-else class="poster text-[180px] font-medium flex justify-center">?</span>
          </div>
          <div class="flex flex-col font-medium mt-3 mx-2 sm:mx-0 items-center">
            <span>{{ $t("room.title") }}</span>
            <span class="font-normal" style="padding: 0px 0px 15px 0px">{{ varFront.song_name }}</span>
            <span>{{ $t("room.artist") }}</span>
            <span class="font-normal" style="padding: 0px 0px 15px 0px">{{ varFront.song_artist }}</span>
            <span>{{ $t("room.type") }}</span>
            <span class="font-normal" style="padding: 0px 0px 15px 0px">{{ varFront.song_type }}</span>
          </div>
        </v-window-item>
        <v-window-item :key="3" :value="3">
          <form @submit.prevent="sendMessage" class="flex justify-between flex-col h-full">
            <div style="padding: 3px 0; display: flex">
              <span class="emoji" @click="emoji(1)"><v-img src="/images/emotes/waving-hand.svg" loading="lazy" /></span>
              <span class="emoji" @click="emoji(3)"><v-img src="/images/emotes/heart.svg" loading="lazy" /></span>
              <span class="emoji" @click="emoji(2)"
                ><v-img src="/images/emotes/64fde5341d5448.90894800.png" loading="lazy"
              /></span>
              <span class="emoji" @click="emoji(4)"><v-img src="/images/emotes/aqua.png" loading="lazy" /></span>
              <span class="emoji">
                <v-menu
                  class="all"
                  transition="slide-y-transition"
                  open-delay="0"
                  close-delay="0"
                  open-on-click
                  :close-on-content-click="false"
                  location="top">
                  <template v-slot:activator="{ props }">
                    <v-img v-bind="props" src="/images/global/plus.png" loading="lazy" />
                  </template>
                  <v-card
                    style="background-color: #02041f; border-radius: 0.75rem"
                    class="flex flex-wrap max-w-[338px] max-h-[173px] overflow-auto p-1">
                    <div v-for="item in emotes" :key="item.id" class="flex">
                      <span class="emoji" style="width: 50px" @click="emoji(item.id)"
                        ><v-img
                          class="rounded-md"
                          :alt="item.titre"
                          :src="'/images/emotes/' + item.lien"
                          loading="lazy"
                      /></span>
                    </div>
                  </v-card> </v-menu
              ></span>
            </div>
            <input
              v-model="message"
              autocomplete="off"
              type="text"
              :placeholder="auth.user.role === 'guest' ? $t('room.connect') : $t('room.message')"
              :disabled="auth.user.role === 'guest'"
              class="py-3 px-4 block w-full rounded-xl text-sm bg-second outline-none" />
            <div class="rounded-md h-full min-h-[300px]">
              <div class="p-2 overflow-auto m-div">
                <div
                  v-for="message in messages.slice().reverse()"
                  :key="message.idUser"
                  class="m-container"
                  :class="{ 'm-me': message.idUser === auth.user.idUser }">
                  <div class="m-message emote" v-if="message.emote">
                    <img
                      :class="'rounded-md anim' + message.emote"
                      :src="'/images/emotes/' + message.link"
                      :alt="message.name"
                      :style="message.params"
                      loading="lazy" />
                  </div>
                  <div class="m-message" v-else>{{ message.message }}</div>
                  <div class="m-sender">
                    <span class="font-medium">{{ message.username }}, </span><span>{{ message.time }}</span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </v-window-item>
      </v-window>
    </v-card>
    <div class="lg:flex justify-center hidden pr-[100px]">
      <div
        v-for="item in usersInRoom"
        :key="item.id"
        :class="'h-[350px] relative inline-grid text-center justify-items-center avatar' + item.id">
        <span
          :class="'rep' + item.id"
          class="blind-rep text-center text-[14px] rounded-lg bg-[#02042080] p-1 z-[12] max-w-[150px] absolute top-[30px] w-full invisible"></span>
        <div class="rounded-none shadow-none h-[323px] w-auto mb-5 z-[11]">
          <div
            v-if="item.type === 'guest'"
            class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
            style="
              filter: drop-shadow(black 2px 4px 6px);
              background: url(/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
              background-size: cover;
              margin: 39px 50px 0;
            ">
            <v-img
              src="/images/avatar_users/64f5bb5d1a2fd9.30522874.png"
              class="max-w-none overflow-hidden img-user-avatar"
              style="width: 180px; margin-bottom: 45px; margin-left: -3px" />
          </div>
          <div
            v-else
            class="shadow-none z-0 border-none overflow-visible justify-center flex items-center w-[168px] h-[252px] rounded-3xl"
            style="
              filter: drop-shadow(black 2px 4px 6px);
              background: url(/images/global/bg_avatar.png), linear-gradient(to bottom, #c5c5c5, #008ca8);
              background-size: cover;
              margin: 36px 36px 0;
            "
            :style="'background: url(/images/global/bg_avatar.png),' + item.foParams">
            <v-img
              :src="'/images/avatar_users/' + item.avLien"
              class="max-w-none overflow-visible img-user-avatar h-[252px]"
              :style="item.avParams" />
          </div>
        </div>

        <b
          v-if="item.type === 'guest'"
          class="shadow-base z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium"
          :class="'pts' + item.id"
          style="padding: 0"
          >0</b
        >
        <b
          v-else
          class="shadow-base z-[13] bottom-[45px] rounded-full border-none text-sm bg-second top-[-102px] flex w-[32px] min-w-[32px] h-[32px] min-h-[32px] relative justify-center items-center left-[50px] font-medium"
          :class="'pts' + item.id"
          style="padding: 0"
          :style="`background: ${item.psParams}; box-shadow: -5px -5px 5px -5px ${item.shParams1},
             5px 5px 5px -5px ${item.shParams2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`"
          >0</b
        >
        <span
          v-if="item.type === 'guest'"
          class="shadow-base bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px]"
          data-title="Défaut">
          {{ item.username }}</span
        >
        <span
          v-else
          class="bg-second top-[-74px] z-[12] text-center text-2xl mt-[-42px] relative rounded-xl bottom-[10px] min-w-[150px] shadow-base shadow-user-avatar"
          :style="`background: ${item.psParams}; box-shadow: -5px -5px 5px -5px ${item.shParams1},
             5px 5px 5px -5px ${item.shParams2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`">
          {{ item.username }}</span
        >
        <span
          v-if="item.type === 'guest'"
          class="rounded-md top-[-74px] z-[12] p-0 border-none text-sm w-[50px] relative leading-[1.1]"
          :style="
            'filter: drop-shadow(black 2px 4px 6px);' +
            'background: linear-gradient(90deg, #008ca8 ' +
            item.pts +
            '%, #0f112b 0%);' +
            'box-shadow: -2px -1px 6px -1px #008ca8, 5px 5px 5px -5px #008ca8, -7px -7px 10px -5px transparent,' +
            '7px 7px 10px -5px transparent, 0 0 5px 0px rgba(255, 255, 255, 0),' +
            '0 55px 35px -20px rgba(0, 0, 0, 0.5);'
          "
          >{{ item.lvl }}</span
        >
        <span
          v-else
          class="rounded-md top-[-74px] z-[12] p-0 border-none text-sm w-[50px] relative leading-[1.1]"
          :style="`
            filter: drop-shadow(black 2px 4px 6px);
            background: linear-gradient(90deg, #008ca8 ${item.pts}%, #0f112b 0%);
            box-shadow: -5px -5px 5px -5px ${item.shParams1},
             5px 5px 5px -5px ${item.shParams2}, -7px -7px 10px -5px transparent, 7px 7px 10px -5px  transparent,
              0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5)`"
          >{{ item.lvl }}</span
        >
      </div>
    </div>
    <v-virtual-scroll
      :items="resume"
      height="500"
      :key="resume"
      v-if="resume"
      class="mb-[125px] rounded-2xl"
      id="resume">
      <template v-slot:default="{ item, index }">
        <div
          class="flex rounded-md m-1 mb-3 overflow-hidden justify-between"
          :class="{ selected: index === selectedIndex }">
          <div class="flex items-center">
            <a target="_blank" :href="'/anime/' + item.url"
              ><v-img
                loading="lazy"
                class="rounded-xl max-w-[100px] overflow-hidden cursor-pointer"
                :class="{ 'blur-md': item.nsfw == 18 }"
                :src="'/images/thumbnail/' + item.poster"
                :alt="item.title"
                width="100"
                height="150"
            /></a>
            <div class="flex flex-col">
              <a target="_blank" :href="'/anime/' + item.url" class="ml-3 font-medium w-full cursor-pointer text-lg">{{
                item.title
              }}</a>
              <span class="ml-3 w-full">{{ item.song_name }}</span>
              <span class="ml-3 w-full">{{ item.song_artist }}</span>
              <span class="ml-3 w-full" v-if="item.song_type == 1">Opening {{ item.song_number }}</span>
              <span class="ml-3 w-full" v-else-if="item.song_type == 2">Ending {{ item.song_number }}</span>
              <span class="ml-3 w-full" v-else-if="item.song_type == 3">OST</span>
            </div>
          </div>
          <div class="flex items-center mr-3">
            <svg
              v-if="resumeRep[index + 1]"
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 24 24"
              class="text-[#09a909]">
              <circle cx="12" cy="12" r="8" fill="white" />
              <path
                fill="currentColor"
                d="m10.6 16.6l7.05-7.05l-1.4-1.4l-5.65 5.65l-2.85-2.85l-1.4 1.4l4.25 4.25ZM12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Z" />
            </svg>
            <svg
              v-else-if="resumeRep[index + 1] === false"
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 24 24"
              class="text-[red]">
              <circle cx="12" cy="12" r="8" fill="white" />
              <path
                fill="currentColor"
                d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2zm-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8l-1.4 1.4z" />
            </svg>
          </div>
        </div>
      </template>
    </v-virtual-scroll>
    <!-- <v-snackbar location="right" v-model="alerte" timeout="-1" color="#f54f67" class="min-w-[auto]">
      <p class="font-medium text-center">{{ $t("alerte") }}</p>
      <template v-slot:actions>
        <v-btn variant="text" class="lowercase" @click="alerte = false"> x </v-btn>
      </template>
    </v-snackbar> -->
    <v-snackbar
      v-model="link"
      v-if="!alreadyConnected && !replay && !replayAsk"
      timeout="-1"
      color="#008ca8"
      class="lg:ml-[-100px] min-w-[auto]">
      <p class="font-medium text-center">{{ $t("room.link_join") }}</p>
      <template v-slot:actions>
        <v-btn variant="text" class="mr-2 ml-[-15px]" @click="copy"> {{ varFront.copy }} </v-btn>
        <v-btn variant="text" class="lowercase" @click="link = false"> x </v-btn>
      </template>
    </v-snackbar>
    <div v-if="!alreadyConnected && !modalActive && replay" class="new-game">
      <button
        @click="toggleModal(1)"
        class="whitespace-nowrap sm:max-w-none justify-center mt-[20px] mb-8 sm:mb-[50px] play py-[15px] sm:py-[30px] w-full md:w-auto md:px-[250px] text-white rounded-full text-3xl sm:text-5xl font-sans outline-none font-bold border-none cursor-pointer inline-flex bg-cover bg-center transition duration-300 shadow-2xl">
        {{ $t("room.new_game") }}
      </button>
    </div>
    <v-snackbar
      v-model="replayAsk"
      v-if="!alreadyConnected"
      timeout="-1"
      color="#008ca8"
      class="lg:ml-[-100px] min-w-[auto]">
      <p class="font-medium text-center">{{ $t("room.host_new_game") }}</p>
      <template v-slot:actions>
        <button class="p-1 select-none outline-none">
          <span
            @click="goToRoom"
            class="text-center button-reverse flex cursor-pointer items-center justify-center rounded-md overflow-hidden nowrap font-medium p-2 w-fit">
            {{ $t("room.join") }}
          </span>
        </button>
        <v-btn variant="text" class="lowercase" @click="replayAsk = false"> x </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="alreadyConnected" timeout="-1" color="#ff5252" class="lg:ml-[-100px] min-w-[auto]">
      <p class="font-medium text-center">{{ $t("room.already_connect") }}</p>
    </v-snackbar>
    <v-snackbar v-model="diconnected" timeout="-1" color="#ff5252" class="lg:ml-[-100px] min-w-[auto]">
      <p class="font-medium text-center">{{ $t("room.reconnect") }}</p>
    </v-snackbar>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, onBeforeUnmount, watch } from "vue";
  import { useHead, useSeoMeta } from "@unhead/vue";
  import { useJoinRoomStore } from "@/stores/joinRoom";
  import { useAnimesStore } from "@/stores/animes";
  import { useGlobalStore } from "@/stores/global";
  import { useAuthStore } from "@/stores/auth";
  import ModalReuse3 from "@/components/ModalReuse3.vue";
  import { useRouter } from "vue-router";
  import { useI18n } from "vue-i18n";
  import { useFetch, useWebWorkerFn } from "@vueuse/core";

  const { t } = useI18n();
  const router = useRouter();
  const joinRoom = useJoinRoomStore();
  const animes = useAnimesStore();
  const auth = useAuthStore();

  const $ = (selectors) => document.getElementById(selectors);
  const $$ = (selectors) => document.querySelectorAll(selectors);
  const socket = joinRoom.socket;
  const global = useGlobalStore();
  const message = ref();
  const messages = ref([]);
  const loading = ref(false);
  const isReady = ref(false);
  const isSkipSong = ref(false);
  const isPauseSong = ref(false);
  const isPauseActive = ref(false);
  const lien = ref(null);
  const indexSelect = ref(null);
  const readyStatusByRoom = ref(null);
  const resumes = ref(null);
  const firstReady = ref(false);
  const interact = ref(true);
  const fastForward = ref(false);
  const hideVideo = ref(false);
  const launch = ref(false);
  const volumeSave = ref(0);
  const interval = {};
  const chrono = ref();
  const chrono2 = ref();
  const n = ref("-");
  const songsNumber = ref("-");
  const answering = ref(2);
  const resumeRep = ref([]);
  const setupRoom = {};
  const infoVideo = {};
  const showInfoVideo = {};
  const imgPoster = ref(null);
  const usersInRoom = ref([]);
  const multiChoiceTempo = ref(null);
  const multiChoice = ref(null);
  const repIndex = ref(null);
  const tabs = ref(null);
  const counterDown = ref(true);
  const nextRound = ref(false);
  const alreadyConnected = ref(false);
  const replay = ref(false);
  const resume = ref(null);
  const replayAsk = ref(false);
  const modalActive = ref(null);
  const newSalle = ref(null);
  const emotes = ref(null);
  const selectedLanguage = ref(localStorage.getItem("lang"));
  const reveleInfo = ref(true);
  let activePlayer;
  let nextPlayer;
  let debutVideo;
  let finVideo;
  const videoReady = {
    player__1: 0,
    player__2: 0,
  };
  const videoPlayer = {
    player__1: "",
    player__2: "",
  };
  const videoPlayerStart = {
    player__1: 0,
    player__2: 0,
  };
  const varFront = ref({
    title: selectedLanguage.value == "fr" ? "Blind Test Anime" : "Anime Music Quiz",
    song_type: "-",
    song_artist: "-",
    song_name: "-",
    others: null,
    hideVideo: computed(() => t("room.hide_video")),
    pauseSong: computed(() => t("room.pause_song")),
    fastForward: computed(() => t("room.fast_forward")),
    copy: computed(() => t("room.copy_link")),
  });
  const search = ref("");
  const visibleAnime = ref(null);
  const selectedIndex = ref(-1);
  const animeSelected = ref(null);
  const animeIdSelected = ref(null);
  const mouseOverAnime = ref(false);
  const searchFocusOpen = ref(false);
  const startTime = ref(0);
  const endTime = ref(0);
  const ping = ref(0);
  const link = ref(true);
  const videoByRoom = ref(null);
  let animesLocal;

  // const alerte = ref(true);
  const multiChoiceVMenu = ref([false, false, false, false, false, false, false]);
  // let timeoutId;

  watch(
    () => global.volume,
    () => {
      updateVolume();
    }
  );
  const toggleModal = (toggle) => {
    if (toggle) {
      modalActive.value = true;
    } else {
      modalActive.value = false;
    }
  };
  const vMenuStart = (item) => {
    interval["vmenu"] = setTimeout(function () {
      multiChoiceVMenu.value[item] = true;
    }, 300);
  };
  const vMenuEnd = () => {
    clearTimeout(interval["vmenu"]);
    for (let i = 0; i < multiChoiceVMenu.value.length; i++) {
      multiChoiceVMenu.value[i] = false;
    }
  };
  const copy = () => {
    navigator.clipboard.writeText(window.location.href.replace("/en/", "/").replace("/fr/", "/"));
    varFront.value.copy = computed(() => t("room.copied"));
  };

  const leaveRoom = () => {
    socket.emit("leave_room");
    return undefined;
  };
  const videoHidden = () => {
    $("subPlayerContainer").classList.toggle("invisible");

    hideVideo.value = !hideVideo.value;
    if (hideVideo.value) {
      varFront.value.hideVideo = computed(() => t("room.show_video"));
    } else {
      varFront.value.hideVideo = computed(() => t("room.hide_video"));
    }
  };
  const resetItem = async () => {
    loading.value = true;
    // clearTimeout(timeoutId);
    // timeoutId = setTimeout(async function () {
    workerTerminate("PENDING");
    const input = search.value;
    visibleAnime.value = await workerFn(animesLocal, input);
    selectedIndex.value = 0;
    $("animeList").scrollTop = 0;
    loading.value = false;
    // }, 100);
  };

  const searchFocusIn = () => {
    if (!searchFocusOpen.value) {
      selectedIndex.value = 0;
      $("animeList").scrollTop = 0;
      $("searchAnimeContainer").classList.remove("invisible");
      $("searchAnime").focus();
      search.value = "";
      searchFocusOpen.value = true;
      resetItem();
    } else {
      searchFocusOut(1);
    }
  };
  const searchFocusOut = (close) => {
    if (!mouseOverAnime.value || close == 1) {
      $("searchAnimeContainer").classList.add("invisible");

      searchFocusOpen.value = false;
    }
  };

  const selectAnime = (index) => {
    indexSelect.value = index;
    searchFocusOpen.value = false;
    $("animeList").scrollTop = 0;
    animeSelected.value = visibleAnime.value[index].title;
    animeIdSelected.value = visibleAnime.value[index].id;
    socket.emit("rep", {
      title: visibleAnime.value[index].title,
      id: visibleAnime.value[index].id,
    });
    $("searchAnime").blur();
    $("searchAnimeContainer").classList.add("invisible");
    search.value = "";
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (selectedIndex.value !== -1) {
        selectAnime(selectedIndex.value);
      }
    } else if (event.key === "ArrowDown") {
      if (selectedIndex.value < visibleAnime.value.length - 1) {
        selectedIndex.value++;
        $("animeList").querySelector(".animeListItem.selected").scrollIntoView({ block: "start" });
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      if (selectedIndex.value > 0) {
        selectedIndex.value--;
        $("animeList").querySelector(".animeListItem.selected").scrollIntoView({ block: "end" });
      }
    }
  };
  const changeScale = (index) => {
    indexSelect.value = index;
    $$(".animeChoiceItem").forEach((_, i) => {
      $$(".animeChoiceItem")[i].style.transform = "scale(1)";
      $$(".animeChoiceItem")[i].style.boxShadow = "none";
      if (i === index) {
        $$(".animeChoiceItem")[i].style.transform = "scale(1.02)";
        $$(".animeChoiceItem")[i].style.boxShadow = "0 0px 12px 3px #008ca8";
      }
    });
    repIndex.value = index;
    socket.emit("rep", {
      title: multiChoice.value[index].title,
      id: multiChoice.value[index].id,
    });
  };
  useHead({
    script: {
      src: "https://www.youtube.com/iframe_api",
    },
  });
  useSeoMeta({
    title: computed(() => t("home.title") + " - Baranimes"),
    ogTitle: computed(() => t("home.title") + " - Baranimes"),
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: computed(() => t("home.title") + " - Baranimes"),
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
  onMounted(async () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0HY7K2YF6F");

    const { data: response } = await useFetch("/api/emotes/all", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });

    emotes.value = JSON.parse(response.value);

    window.addEventListener("beforeunload", leaveRoom);
    global.pageLoad();
    // interval["ping"] = setInterval(function () {
    //   startTime.value = new Date().getTime();
    //   socket.emit("ping");
    // }, 1000);
    waitScript();
  });
  socket.on("ping", function () {
    endTime.value = new Date().getTime();
    ping.value = endTime.value - startTime.value;
  });
  socket.on("skipSongAdmin", function () {
    $("skip-button").classList.remove("opacity-50", "pointer-events-none");
    $("pause-button").classList.remove("opacity-50", "pointer-events-none");
    if (readyStatusByRoom.value?.started && answering.value == 1 && visibleAnime.value[indexSelect.value]?.id) {
      socket.emit("rep", {
        title: visibleAnime.value[indexSelect.value]?.title,
        id: visibleAnime.value[indexSelect.value]?.id,
      });
    } else if (readyStatusByRoom.value?.started && answering.value == 2 && multiChoice.value[indexSelect.value]?.id) {
      socket.emit("rep", {
        title: multiChoice.value[indexSelect.value]?.title,
        id: multiChoice.value[indexSelect.value]?.id,
      });
    }
  });
  const muteIcon = computed(() => {
    return global.volume == 0 ? "volume_off" : global.volume < 50 ? "volume_down" : "volume_up";
  });

  const updateVolume = () => {
    if (activePlayer == "player__1") {
      videoPlayer["player__1"].setVolume(global.volume);
    }
    if (activePlayer == "player__2") {
      videoPlayer["player__2"].setVolume(global.volume);
    }
  };

  const toggleMute = () => {
    if (global.volume === 0) {
      global.volume = volumeSave.value;
    } else {
      volumeSave.value = global.volume;
      global.volume = 0;
    }
    updateVolume();
  };
  const goToRoom = () => {
    socket.emit("leave_room");
    router.push({ name: "room", query: { id: newSalle.value } });
  };
  socket.on("rep_points", function (user) {
    let el = $$(".rep" + user);
    for (let i = 0; i < el.length; i++) {
      if (!reveleInfo.value) {
        el[i].textContent = "...";
        el[i].classList.remove("invisible");
      }
    }
  });

  socket.on("message", function (msg) {
    let date = new Date();
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let options = { timeZone: timeZone, hour: "numeric", minute: "numeric" };
    let formattedTime = new Intl.DateTimeFormat(undefined, options).format(date);
    msg.time = formattedTime;
    messages.value.push(msg);
    setTimeout(function () {
      $$(".m-div")[0].scrollTop = $$(".m-div")[0].scrollHeight + 1000;
    }, 1);
  });
  socket.on("already_connected", function () {
    alreadyConnected.value = true;
  });
  socket.on("end", function (v) {
    setTimeout(function () {
      resume.value = v;
      $("playerContainer").classList.remove("active");
      setTimeout(function () {
        $("resume").scrollIntoView({ behavior: "smooth", block: "center" });
      }, 1);
      if (setupRoom.value.host == auth.user.idUser) {
        replay.value = true;
      }
    }, 2000);
  });
  socket.on("play", function (actPlayer, removeVideo, debut, fin, rsby) {
    readyStatusByRoom.value = rsby;
    launch.value = true;
    play(removeVideo, actPlayer, debut, fin);
  });
  socket.on("readyStatus", function (user, ready) {
    let el = $$(".rep" + user);
    for (let i = 0; i < el.length; i++) {
      if (ready) {
        if (selectedLanguage.value == "fr") {
          el[i].textContent = "Prêt";
        } else {
          el[i].textContent = "Ready";
        }
        el[i].classList.remove("invisible");
      } else {
        el[i].classList.add("invisible");
      }
    }
  });
  socket.on("replay", (data) => {
    newSalle.value = data;
    replayAsk.value = true;
  });
  socket.on("goToRoom", (data) => {
    socket.emit("leave_room");
    router.push({ name: "room", query: { id: data } });
  });
  socket.on("usersInRoom", function (users) {
    usersInRoom.value = users;
  });
  socket.on("start_play", function (setup) {
    setupRoom.value = setup;
    n.value = setupRoom.value.n;
    songsNumber.value = setupRoom.value.songsNumber;
    answering.value = setupRoom.value.answering;
    socket.emit("videoSetup");
  });
  socket.on("shift_play", function (actPlayer, setup) {
    setupRoom.value = setup;
    n.value = setupRoom.value.n;
    songsNumber.value = setupRoom.value.songsNumber;
    answering.value = setupRoom.value.answering;
    launch.value = !launch.value;
    interact.value = false;
    activePlayer = actPlayer;
    socket.emit("videoSetup");
  });
  socket.on("videoSetup", async function (video, player, choice, videoBR, link, resum) {
    lien.value = link;
    resumes.value = resum;
    videoByRoom.value = videoBR;
    multiChoiceTempo.value = choice;
    infoVideo.value = video;
    nextPlayer = player;
    loadVideo(player, video[0].lien, video[0].debut);
  });
  socket.on("skipSong", function (status, ready, total) {
    if (status == "ok") {
      isSkipSong.value = false;
      fastForward.value = true;
      varFront.value.fastForward = computed(() => t("room.fast_forward"));
      clearInterval(interval["countDown"]);
      chrono.value = 0;
      $("skip-button").classList.remove("flashing");
      $("skip-button").classList.add("opacity-50", "pointer-events-none");
      $("pause-button").classList.add("opacity-50", "pointer-events-none");
    } else if (status == "wait") {
      $("skip-button").classList.add("flashing");
      varFront.value.fastForward = computed(() => t("room.fast_forward") + "(" + ready + "/" + total + ")");
    } else if (status == "init") {
      $("skip-button").classList.remove("flashing");
      varFront.value.fastForward = computed(() => t("room.fast_forward"));
    }
  });
  socket.on("pauseSong", function (status, ready, total) {
    if (status == "ok") {
      isPauseSong.value = false;
      varFront.value.pauseSong = computed(() => t("room.pause_song"));
      $("pause-button").classList.remove("flashing");
      $("pause-button").classList.add("pointer-events-none", "active");
      $("playerContainer").classList.remove("active");
      isPauseActive.value = true;
    } else if (status == "wait") {
      $("pause-button").classList.add("flashing");
      varFront.value.pauseSong = computed(() => t("room.pause_song") + "(" + ready + "/" + total + ")");
    } else if (status == "init") {
      $("pause-button").classList.remove("flashing");
      varFront.value.pauseSong = computed(() => t("room.pause_song"));
    }
  });
  const ptsByRoom = ref(null);
  socket.on("revele", function (rep, pts, rsby) {
    readyStatusByRoom.value = rsby;
    ptsByRoom.value = pts;
    usersInRoom.value = rep;
    if (!nextRound.value) {
      revele();
    }
    rep.forEach(function (item) {
      let el = $$(".rep" + item.id);
      if (item.rep.title) {
        for (let i = 0; i < el.length; i++) {
          el[i].textContent = item.rep.title;
          el[i].classList.remove("invisible");
        }
      }
    });
    pts.forEach(function (item) {
      let el = $$(".pts" + item.id);
      for (let i = 0; i < el.length; i++) {
        $$(".rep" + item.id)[i].classList.remove("correct", "wrong");
        if (el[i].textContent == item.pts) {
          $$(".rep" + item.id)[i].classList.add("wrong");
        } else {
          $$(".rep" + item.id)[i].classList.add("correct");
        }
        el[i].textContent = item.pts;
      }
    });
  });
  const searchbar = (animeInput, input) => {
    let searchResultTempo = animeInput.filter((item) => {
      return item.alternative
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/[^\w\s]/gi, "")
        .replace(/[-/\\^$*+?.()|[\]{}]/g, "")
        .replace(/\s+/g, "")
        .toLowerCase()
        .trim()
        .includes(
          input
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace(/[^\w\s]/gi, "")
            .replace(/[-/\\^$*+?.()|[\]{}]/g, "")
            .replace(/\s+/g, "")
            .toLowerCase()
            .trim()
        );
    });

    return searchResultTempo.slice(0, 50);
  };
  const { workerFn, workerTerminate } = useWebWorkerFn(searchbar, {
    timeout: 30000,
  });

  const waitScript = () => {
    // eslint-disable-next-line
    if (typeof YT === "undefined" || typeof YT.Player !== "function" || !animes.animes.value) {
      interval["waitScript"] = setTimeout(function () {
        waitScript();
      }, 200);
      return;
    }
    animesLocal = JSON.parse(JSON.stringify(animes.animes.value));
    visibleAnime.value = animesLocal.slice(0, 50);
    socket.emit("askPlay");
  };

  const countDown = () => {
    animeSelected.value = null;
    animeIdSelected.value = null;
    let count = setupRoom.value.guessTime;
    chrono.value = count;
    let el = $$(".blind-rep");
    for (let i = 0; i < el.length; i++) {
      el[i].classList.add("invisible");
      el[i].classList.remove("correct", "wrong");
    }
    counterDown.value = true;
    $("playerContainer").classList.remove("active");
    varFront.value.song_name = "-";
    varFront.value.song_artist = "-";
    varFront.value.title = "-";
    varFront.value.song_type = "-";
    varFront.value.others = null;
    imgPoster.value = null;
    clearInterval(interval["countDown"]);
    interval["countDown"] = setInterval(() => {
      chrono.value = count <= 0 ? clearInterval(interval["countDown"]) : `${--count}`;
    }, 1000);
  };
  const revele = () => {
    let count = 15;
    clearInterval(interval["countDown2"]);
    interval["countDown2"] = setInterval(() => {
      chrono2.value = count <= 0 ? clearInterval(interval["countDown2"]) : `${--count}`;
    }, 1000);
    reveleInfo.value = true;
    $("skip-button").classList.remove("flashing");
    if (n.value != setupRoom.value.songsNumber) {
      $("skip-button").classList.remove("opacity-50", "pointer-events-none");
      $("pause-button").classList.remove("opacity-50", "pointer-events-none");
    } else {
      $("skip-button").classList.add("opacity-50", "pointer-events-none");
    }
    clearInterval(interval["countDown"]);
    fastForward.value = false;
    varFront.value.fastForward = computed(() => t("room.fast_forward"));
    isSkipSong.value = false;
    isPauseSong.value = false;
    varFront.value.pauseSong = computed(() => t("room.pause_song"));
    $("playerContainer").classList.add("active");
    counterDown.value = false;
    $(activePlayer).classList.remove("opacity-0", "invisible", "w-0", "h-0");
    $(activePlayer).classList.add("w-full", "h-full");
    varFront.value.song_name = showInfoVideo.value[0]?.song_name;
    varFront.value.song_artist = showInfoVideo.value[0]?.song_artist;
    varFront.value.title = showInfoVideo.value[0]?.title;
    varFront.value.others = showInfoVideo.value[0]?.others;
    if (showInfoVideo.value[0]?.song_type == 1) {
      varFront.value.song_type = "Opening " + showInfoVideo.value[0]?.song_number;
    } else if (showInfoVideo.value[0]?.song_type == 2) {
      varFront.value.song_type = "Ending " + showInfoVideo.value[0]?.song_number;
    } else if (showInfoVideo.value[0]?.song_type == 3) {
      varFront.value.song_type = "OST";
    }
    imgPoster.value = showInfoVideo.value[0].poster;
    if (answering.value == 2) {
      if (answering.value == 2 && repIndex.value != null) {
        if (multiChoice.value[repIndex.value]?.id == showInfoVideo.value[0]?.id) {
          $$(".animeChoiceItem")[repIndex.value].classList.add("correct");
          resumeRep.value[n.value] = true;
        } else {
          $$(".animeChoiceItem")[repIndex.value].classList.add("wrong");
          $$(".animeChoiceItem")[showInfoVideo.value[0]?.rep].classList.add("correct");
          resumeRep.value[n.value] = false;
        }
        repIndex.value = null;
      } else {
        $$(".animeChoiceItem")[showInfoVideo.value[0]?.rep].classList.add("correct");
        resumeRep.value[n.value] = false;
      }
    } else {
      $("blind-rep-list").classList.remove("correct", "wrong");
      if (animeIdSelected.value == showInfoVideo.value[0]?.id) {
        $("blind-rep-list").classList.add("correct");
        resumeRep.value[n.value] = true;
      } else {
        $("blind-rep-list").classList.add("wrong");
        resumeRep.value[n.value] = false;
      }
    }
  };
  const sendMessage = () => {
    if (message.value.trim() != "") {
      socket.emit("message_room", {
        message: message.value.trim(),
        idUser: auth.user.idUser,
      });
      message.value = "";
    }
  };
  const emoji = (emote) => {
    socket.emit("message_room", {
      emote: emote,
      idUser: auth.user.idUser,
    });
    message.value = "";
  };
  const skipSong = () => {
    isSkipSong.value = !isSkipSong.value;
    socket.emit("skipSong", isSkipSong.value);
  };

  const pauseSong = () => {
    isPauseSong.value = !isPauseSong.value;
    socket.emit("pauseSong", isPauseSong.value);
  };
  const toggleReadyStatus = () => {
    isReady.value = !isReady.value;
    socket.emit("readyStatus", isReady.value);
  };
  const toggleReadyStatusShift = () => {
    interact.value = !interact.value;
    playShift();
  };

  const loadVideo = (player, videoId, start) => {
    if ($(player)) {
      videoPlayerStart[player] = start;
      // $(player).remove();
      videoPlayer[player].loadVideoById({
        videoId: videoId,
      });
      videoReady[player] = 0;
    } else {
      let div = document.createElement("div");
      div.id = player;
      div.classList = "opacity-0 invisible w-0 pointer-events-none h-0 absolute";
      const playerContainer = $("subPlayerContainer");
      playerContainer.appendChild(div);
      videoReady[player] = 0;
      videoPlayerStart[player] = start;
      // eslint-disable-next-line
      videoPlayer[player] = new YT.Player(player, {
        playerVars: {
          start: start,
          controls: 0,
          rel: 0,
          disablekb: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          showinfo: 0,
        },
        videoId: videoId,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
          onError: onError,
        },
      });
    }
  };

  const onPlayerReady = (e) => {
    let player = e.target.g.id;
    if (!videoPlayer[player]?.getCurrentTime()) {
      socket.emit("reloadVideoSetup");
      return;
    }
    videoPlayer[player].playVideo();
    videoPlayer[player].setVolume(0);
  };

  const onPlayerStateChange = (e) => {
    let player = e.target.g.id;

    if (e.data == 1 && !videoReady[player]) {
      socket.emit("readyPlayer");
      videoReady[player] = 1;
      videoPlayer[player].seekTo(videoPlayerStart[player]);
      console.log(3, videoPlayerStart[player]);
      videoPlayer[player].pauseVideo();
    }
    if (e.data == 2 && !firstReady.value) {
      firstReady.value = true;
    }
    // eslint-disable-next-line
    if (e.data == YT.PlayerState.ENDED) {
      videoPlayer[activePlayer].seekTo(debutVideo);
      console.log(4, debutVideo);
      videoPlayer[activePlayer].playVideo();
    }
  };
  const onError = () => {
    socket.emit("reloadVideoSetup");
  };
  const play = (removeVideo, actPlayer, debut, fin) => {
    isSkipSong.value = false;
    $("skip-button").classList.remove("flashing");
    $("pause-button").classList.remove("active", "flashing");
    isPauseActive.value = false;
    $("skip-button").classList.add("opacity-50", "pointer-events-none");
    $("pause-button").classList.add("opacity-50", "pointer-events-none");
    showInfoVideo.value = infoVideo.value;
    if (!videoReady[nextPlayer] || !nextPlayer || !firstReady.value) {
      interval["play"] = setTimeout(function () {
        play(removeVideo, actPlayer, debut, fin);
      }, 200);
      return;
    }
    if (removeVideo) {
      for (let i = 0; i < 100; i++) {
        setTimeout(function () {
          videoPlayer[removeVideo].setVolume(global.volume * 0.01 * (100 - i - 1));
        }, i * 20);
      }
      setTimeout(function () {
        // $(removeVideo).remove();
        $(removeVideo).classList.add("opacity-0", "invisible", "w-0", "h-0");
        $(removeVideo).classList.remove("w-full", "h-full");
      }, 2000);
      setTimeout(function () {
        videoPlayer[removeVideo].pauseVideo();
      }, 2200);
    }

    videoPlayer[nextPlayer].playVideo();
    videoPlayer[nextPlayer].setVolume(0);

    setTimeout(function () {
      reveleInfo.value = false;
      fastForward.value = false;
      varFront.value.fastForward = computed(() => t("room.fast_forward"));
      activePlayer = actPlayer;
      debutVideo = debut;
      finVideo = fin;
      n.value++;
      setupRoom.value.n++;
      nextRound.value = false;
      multiChoice.value = multiChoiceTempo.value;
      if (answering.value == 1) {
        $("blind-rep-list").classList.remove("correct", "wrong");
      }
      $$(".animeChoiceItem").forEach((_, i) => {
        $$(".animeChoiceItem")[i].classList.remove("correct", "wrong");
        $$(".animeChoiceItem")[i].removeAttribute("style");
      });
      clearInterval(interval["checkVideoTimeVar"]);
      interval["checkVideoTimeVar"] = setInterval(checkVideoTime, 1000);
      countDown();
    }, 2000);

    for (let i = 1; i < 100; i++) {
      setTimeout(function () {
        videoPlayer[nextPlayer].setVolume(global.volume * 0.01 * (i + 1));
      }, i * 20 + 1980);
    }
    setTimeout(function () {
      $("skip-button").classList.remove("opacity-50", "pointer-events-none");
    }, 3800);
  };
  const playShift = () => {
    if (!videoReady[activePlayer] || !activePlayer || !firstReady.value) {
      interval["playShift"] = setTimeout(function () {
        playShift();
      }, 200);
      return;
    }
    videoPlayer[activePlayer].playVideo();
    nextRound.value = true;
    for (let i = 1; i < 100; i++) {
      setTimeout(function () {
        videoPlayer[activePlayer].setVolume(global.volume * 0.01 * (i + 1));
      }, i * 20);
    }
  };
  const checkVideoTime = () => {
    if (videoPlayer[activePlayer].getCurrentTime() > finVideo && finVideo != 0) {
      videoPlayer[activePlayer].seekTo(debutVideo);
      videoPlayer[activePlayer].playVideo();
      console.log(1, videoPlayer[activePlayer].getCurrentTime(), finVideo, debutVideo);
    } else if (
      videoPlayer[activePlayer].getCurrentTime() > ytTimeToSecond(videoByRoom.value[activePlayer][0].duration)
    ) {
      console.log(2);
      videoPlayer[activePlayer].seekTo(debutVideo);
      videoPlayer[activePlayer].playVideo();
    }
  };
  onBeforeUnmount(() => {
    window.removeEventListener("beforeunload", leaveRoom);
    socket.off();
    clearTimeout(interval["waitScript"]);
    clearTimeout(interval["play"]);
    clearTimeout(interval["playShift"]);
    clearInterval(interval["countDown"]);
    clearInterval(interval["countDown2"]);
    // clearInterval(interval["ping"]);
    clearInterval(interval["checkVideoTimeVar"]);
    // if ($("player__1")) {
    //   $("player__1").remove();
    // }
    // if ($("player__2")) {
    //   $("player__2").remove();
    // }
  });
  const diconnected = ref(false);
  socket.on("connect", () => {
    if (diconnected.value) {
      socket.emit("leave_room", new URLSearchParams(window.location.search).get("id"));
      const userLocale = navigator.language || navigator.userLanguage;
      const userCountryCode = new Intl.Locale(userLocale).region;
      setupRoom.value.timeVideo = videoPlayer[activePlayer]?.getCurrentTime() || 0;
      let time = null;
      if (readyStatusByRoom.value?.started && isPauseActive.value) {
        if (finVideo != 0) {
          time = (finVideo - videoPlayer[activePlayer].getCurrentTime()) * 1000 - 3000;
        } else {
          time =
            (ytTimeToSecond(videoByRoom.value[activePlayer][0].duration) - videoPlayer[activePlayer].getCurrentTime()) *
              1000 -
            3000;
        }
      } else if (readyStatusByRoom.value?.started) {
        if (counterDown.value) {
          time = chrono.value * 1000;
        } else {
          time = chrono2.value * 1000 - 2500;
        }
      }
      socket.emit("join_room", {
        id: new URLSearchParams(window.location.search).get("id"),
        region: userCountryCode,
        reconnect: true,
        setup: setupRoom.value,
        pts: ptsByRoom.value,
        videoByRoom: videoByRoom.value,
        lien: lien.value,
        resume: resumes.value,
        revele: counterDown.value,
        readyStatusByRoom: readyStatusByRoom.value,
        usersInRoom: usersInRoom.value.length,
        time: time,
      });
      socket.emit("readyPlayer");

      diconnected.value = false;
    }
  });
  socket.on("disconnect", () => {
    $("skip-button").classList.add("opacity-50", "pointer-events-none");
    $("pause-button").classList.add("opacity-50", "pointer-events-none");
    diconnected.value = true;
  });
  const ytTimeToSecond = (temps) => {
    var regex = /PT(\d+M)?(\d+S)?/;
    var match = temps.match(regex);

    if (!match) {
      console.error("Format de temps invalide");
      return 0;
    }

    var minutes = match[1] ? parseInt(match[1]) : 0;
    var secondes = match[2] ? parseInt(match[2]) : 0;

    var tempsEnSecondes = minutes * 60 + secondes;
    return tempsEnSecondes;
  };
</script>

<style scoped>
  :deep(.imgPoster .v-responsive__sizer),
  :deep(.imgPoster .v-skeleton-loader__image) {
    padding-bottom: 150%;
  }
  :deep(.img-user-avatar img) {
    position: initial;
  }

  .m-me {
    align-items: end;
  }
  .m-div {
    overflow-x: hidden;
  }
  .m-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .m-me .m-message {
    background: #00bdbf;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 10px;
  }
  .m-message.emote {
    background: transparent;
    padding: 0;
  }
  .m-sender {
    width: fit-content;
    font-size: 13px;
  }
  .m-message {
    border-radius: 10px;
    background: #008ca8;
    padding: 5px 10px;
    width: fit-content;
    line-height: normal;
    border-bottom-left-radius: 0;
    overflow-wrap: break-word;
    max-width: 100%;
  }

  #playerContainer.hide #subPlayerContainer {
    visibility: hidden;
  }
  #player__1,
  #player__2 {
    width: calc(100% + 1px);
  }
  .v-card {
    background: transparent;
    color: white;
  }
  .blind-rep {
    border: 1px solid rgb(15, 17, 43);
    filter: drop-shadow(black 2px 4px 6px);
  }
  .blind-rep.correct {
    border: 1px solid #09a909;
  }
  .blind-rep.wrong {
    border: 1px solid red;
  }
  #blind-rep-list {
    border-bottom: 1px solid #008ea9;
  }
  #blind-rep-list.correct {
    border-bottom: 1px solid #09a909;
  }
  #blind-rep-list.wrong {
    border-bottom: 1px solid red;
  }
  .button-reverse.wrong {
    background: linear-gradient(90deg, #008ca8 0, #ff005e 50%, #008ca8) !important;
    background-position: 0 !important;
    background-size: 200% auto !important;
  }
  .button-reverse.correct {
    background: linear-gradient(90deg, #008ca8 0, #00ff00 50%, #008ca8) !important;
    background-position: 0 !important;
    background-size: 200% auto !important;
  }
  .nowrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
  #counterDown {
    -webkit-transform: translate(0%, -50%);
    -moz-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    -o-transform: translate(0%, -50%);
    transform: translate(0, -50%);
  }
  #playerContainer::after {
    background-color: #008ca8;
    content: " ";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 5px;
    margin: auto;
  }
  #playerContainer.active::after {
    width: 100%;
    transition: linear 15s;
  }
  .animeListItem:hover {
    background: #0f112b;
  }

  .fastforward .arrow {
    width: 30px;
    height: 30px;
    background: #f0f0f0;
    margin: 0 2px;
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
    opacity: 0;
    animation: fadeInOut 1s ease-in-out infinite;
  }

  .fastforward .arrow:nth-child(2) {
    animation-delay: 0.2s;
  }

  .fastforward .arrow:nth-child(3) {
    animation-delay: 0.4s;
  }
  .new-game {
    z-index: 2000;
    position: fixed;
    bottom: 10px;
    width: 100%;
    left: 0;
    text-align: center;
  }
  @media (max-width: 1023px) {
    .new-game button {
      width: calc(100vw - 2rem);
    }
  }
  @keyframes fadeInOut {
    0%,
    40%,
    100% {
      opacity: 0;
    }

    90% {
      opacity: 1;
    }
  }
  .emoji {
    background: #0f112b;
    font-size: 27px;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    margin: 2.5px;
    width: calc(20% - 5px);
    cursor: pointer;
    min-width: calc(20% - 5px);
    user-select: none;
    max-height: 60px;
  }
  .emote .anim1 {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
    font-size: 30px;
  }

  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    }
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  .emote .anim2 {
    animation: skew 4.72s ease infinite;
    transform-origin: 50% 50%;
  }
  @keyframes skew {
    0% {
      transform: skewX(0deg);
    }
    25% {
      transform: skewX(5deg);
    }
    75% {
      transform: skewX(-5deg);
    }
    100% {
      transform: skewX(0deg);
    }
  }
  .emote .anim4 {
    animation: shake-animation 4.72s ease infinite;
    transform-origin: 50% 50%;
  }

  @keyframes shake-animation {
    0% {
      transform: translate(0, 0);
    }
    1.78571% {
      transform: translate(5px, 5px);
    }
    2.57143% {
      transform: translate(0, 0);
    }
    3.35714% {
      transform: translate(3px, 0);
    }
    4.14286% {
      transform: translate(0, 0);
    }
    5.92857% {
      transform: translate(0px, 3px);
    }
    6.71429% {
      transform: translate(0, 0);
    }
    29% {
      transform: translate(0, 0);
    }
    30% {
      transform: translate(0, 5px);
    }
    31% {
      transform: translate(0, 0);
    }
    32% {
      transform: translate(0, 5px);
    }
    33% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  .emote .anim3 {
    animation-name: beat;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
  }

  @keyframes beat {
    0% {
      scale: 1;
    }

    50% {
      scale: 1.35;
    }

    100% {
      scale: 1;
    }
  }
  .flashing path {
    animation: glowing 1500ms infinite;
  }
  #pause-button.active path {
    color: #00fbdb;
    filter: drop-shadow(0px 0px 4px #00fbdb);
  }
  @keyframes glowing {
    0% {
      color: white;
      filter: none;
    }
    50% {
      color: orange;
      filter: drop-shadow(0px 0px 4px orange);
    }
    100% {
      color: white;
      filter: none;
    }
  }
  @keyframes quickPlayButtonEffect {
    100% {
      background-position-x: -200%;
    }
  }
  .play {
    animation: quickPlayButtonEffect 5s infinite linear;
    background: linear-gradient(
      -60deg,
      #00fddc 0%,
      #00fddc 10%,
      #008ca8 25%,
      #008ca8 30%,
      #00fddc 50%,
      #008ca8 80%,
      #008ca8 85%,
      #00fddc 92%
    );
    background-size: 200% 100%;
    margin-bottom: 10px;
  }
  .play:hover {
    box-shadow: 0px 15px 20px rgb(0 140 168 / 40%);
    transform: translateY(-7px);
  }
  .v-menu:deep(.v-overlay__content) {
    pointer-events: none;
  }
  .all.v-menu:deep(.v-overlay__content) {
    pointer-events: all;
  }

  :deep(.v-overlay__content .v-card) {
    min-width: 300px;
  }
</style>

<template>
  <div class="calc2rem mx-auto" id="calc2rem">
    <v-virtual-scroll
      :items="global.animesSuggest"
      height="100vh"
      ref="virtualScroller"
      item-height="350"
      class="animeScroll left-0 top-0 absolute w-[100vw] flex justify-center pb-24 md:pb-0"
      @scroll="handleScroll">
      <template v-slot:default="{ item, index }">
        <div v-if="index === 2" class="h-0">
          <div
            class="max-h-[500px] md:max-h-[700px] overflow-hidden bg-transparent"
            id="parallax"
            style="position: absolute; left: 0px; top: 0px; width: 100%">
            <v-skeleton-loader
              v-if="!isLoading"
              type="image"
              class="h-[500px] md:h-[700px]"
              color="#00000000"></v-skeleton-loader>
            <v-parallax
              class="hidden md:block h-[700px] xl:h-auto"
              @load="isLoading = true"
              :src="'/images/banner/dandadan-key-art-wide.png'"></v-parallax>
            <v-parallax
              class="md:hidden"
              @load="isLoading = true"
              :src="'/images/original/6/1925e91c74b.0294a21e1e81d.webp'"></v-parallax>
          </div>
          <div
            class="flex md:py-[10rem] absolute rounded-3xl mt-3 md:top-0 md:max-w-[800px] md:pl-8 md:px-8 top-[300px] calc4rem"
            id="infos">
            <router-link to="/anime/dandadan">
              <v-img
                :src="'/images/title/dandandan-logo.png'"
                alt="Kaii to Otome to Kamikakushi"
                loading="lazy"
                class="max-w-[250px] md:max-w-[400px] h-[135px] md:h-[200px]">
                <template v-slot:placeholder>
                  <v-sheet>
                    <v-skeleton-loader
                      type="image"
                      class="rounded-xl overflow-hidden shadow-3xl"
                      color="#020420"></v-skeleton-loader>
                  </v-sheet>
                </template>
              </v-img>
            </router-link>
            <div class="flex flex-col justify-center">
              <p class="mt-3 md:mt-7 line-clamp-2 md:line-clamp-4" v-if="selectedLanguage == 'fr'">
                Après avoir été agressivement rejetée, Momo Ayase se retrouve à bouder lorsqu'elle tombe sur un garçon
                victime d'intimidation. Sauvé par sa gentillesse téméraire, le garçon obsédé par les extraterrestres
                tente de lui parler des intérêts extraterrestres qu'il croit partager. Rejetant ses affirmations, Ayase
                a proclamé qu'elle croyait plutôt aux fantômes, déclenchant une dispute entre les deux pour savoir
                lequel est réel. Dans le but de déterminer qui a raison, les deux décident de visiter séparément des
                lieux associés à la fois à l'extraterrestre et au surnaturel : Ayase visitant le premier et le garçon
                visitant le second. Lorsque les deux atteignent leurs places respectives, il s’avère qu’aucun des deux
                n’avait tort et que les extraterrestres et les fantômes existent bel et bien. Cela marque le début de
                l'aventure d'Ayase et du garçon, alors qu'ils tentent de réparer les éléments surréalistes, surnaturels
                et extraterrestres qui les entourent pour revenir à une vie normale.
              </p>
              <p class="mt-3 md:mt-7 line-clamp-2 md:line-clamp-4" v-else>
                After being aggressively rejected, Momo Ayase finds herself sulking when she stumbles across a boy being
                bullied. Saved by her rash kindness, the alien-obsessed boy attempts to speak to her about
                extraterrestrial interests he believes they share. Rejecting his claims, Ayase proclaimed that she
                instead is a believer in ghosts, starting an argument between the two over which is real. In a bet to
                determine who is correct, the two decide to separately visit locations associated with both the
                extraterrestrial and the supernatural—Ayase visiting the former and the boy visiting the latter. When
                the two reach their respective places, it turns out that neither was wrong and that both aliens and
                ghosts do exist. This marks the beginning of Ayase and the boy's adventure, as they attempt to fix the
                surreal, supernatural, and extraterrestrial elements around them to return to a normal life.
              </p>
            </div>
          </div>
          <div class="flex justify-center relative z-[1] bottom-[80px]" v-if="auth.user.role == 'admin'">
            <div class="submenu hidden md:flex">
              <span @click="submenu(0)" class="slider_list_menu0"><span>Découvrir</span></span>
              <span @click="submenu(1)"><span>Rechercher un anime</span></span>
            </div>
          </div>
        </div>
        <div v-if="index === 0" class="h-[350px]"></div>
        <div v-if="index === 1" class="h-[350px]"></div>
        <div v-if="index === 2" class="overlay h-[500px] md:h-[700px]"></div>
        <div v-if="index === 2" class="overlay-bottom h-[350px] top-[500px] md:top-[700px]"></div>
        <div class="relative z-[1] h-[350px] md:top-[-25px]" v-if="index === 2">
          <div class="search">
            <div class="flex flex-wrap mt-8 rounded-2xl p-5 shadow-3xl pb-0" style="background: #020420">
              <v-text-field clearable label="Titre" @change="search" v-model="titre" class="w-4/5 px-2"></v-text-field>
              <div class="w-1/5 flex justify-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 24 24"
                  class="cursor-pointer">
                  <path
                    fill="currentColor"
                    d="M7 21q-.825 0-1.412-.587T5 19V6q-.425 0-.712-.288T4 5q0-.425.288-.712T5 4h4q0-.425.288-.712T10 3h4q.425 0 .713.288T15 4h4q.425 0 .713.288T20 5q0 .425-.288.713T19 6v13q0 .825-.587 1.413T17 21zm5-7.1l1.9 1.9q.275.275.7.275t.7-.275q.275-.275.275-.7t-.275-.7l-1.9-1.9l1.9-1.9q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275L12 11.1l-1.9-1.9q-.275-.275-.7-.275t-.7.275q-.275.275-.275.7t.275.7l1.9 1.9l-1.9 1.9q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275z" />
                </svg>
              </div>
              <v-autocomplete
                density="compact"
                @change="search"
                v-model="genre"
                class="w-1/2 px-2"
                clearable
                label="Genres"
                multiple
                chips
                :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-autocomplete>
              <v-autocomplete
                density="compact"
                @change="search"
                v-model="theme"
                class="w-1/2 px-2"
                clearable
                label="Themes"
                multiple
                chips
                :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-autocomplete>

              <v-autocomplete
                density="compact"
                @change="search"
                v-model="studio"
                class="w-1/3 px-2"
                clearable
                label="Studio"
                chips
                :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-autocomplete>
              <v-autocomplete
                density="compact"
                @change="search"
                v-model="origine"
                class="w-1/3 px-2"
                clearable
                label="origine"
                chips
                :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-autocomplete>
              <v-select
                density="compact"
                @change="search"
                v-model="typeFormat"
                clearable
                class="w-1/3 px-2"
                label="Type"
                :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-select>
              <v-select
                density="compact"
                @change="search"
                v-model="nbrEp"
                clearable
                class="max-w-[33.333333%] w-1/3 px-2"
                label="Nbr ep"
                :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-select>

              <v-select
                density="compact"
                @change="search"
                v-model="saison"
                clearable
                class="max-w-[33.333333%] w-1/3 px-2"
                label="Saison"
                :items="['California', 'Colorado', 'Florida', 'Georgia', 'Texas', 'Wyoming']"></v-select>
              <v-range-slider
                @change="search"
                v-model="annee"
                label="Année"
                class="max-w-[30%] w-1/3 px-2 pt-2"
                color="#008ca8"
                thumb-label="always"
                step="1"
                min="1961"
                :max="new Date().getFullYear()"></v-range-slider>
            </div>
            <div>
              <p class="text-center text-2xl uppercase font-medium mt-6 mb-4">{{ anime.length }} resultats</p>
              <div class="flex flex-wrap justify-center">
                <v-sheet
                  v-for="card in visibleAnime"
                  :key="card.id"
                  class="min-w-[168px] max-w-[168px] imgPoster select-none bg-transparent m-2 mb-6">
                  <router-link :to="'/anime/' + card?.url">
                    <v-img
                      :alt="card?.title"
                      loading="lazy"
                      class="rounded-xl bg-second"
                      :src="'/images/upload/' + card?.poster"
                      width="168"
                      height="250">
                      <template v-slot:placeholder>
                        <v-sheet class="h-full">
                          <v-skeleton-loader
                            type="image"
                            class="rounded-xl overflow-hidden shadow-3xl h-[250px]"
                            color="#020420"></v-skeleton-loader>
                        </v-sheet>
                      </template>
                    </v-img>
                  </router-link>
                  <router-link :to="'/anime/' + card?.url">
                    <div class="font-medium line-clamp-2 leading-5 my-1 text-white">{{ card?.title }}</div>
                  </router-link>
                  <div class="text-gray-200 text-xs">{{ formaterDate(card?.debut) }}</div>
                </v-sheet>
              </div>
              <v-pagination v-model="page" :length="Math.ceil(anime?.length / itemsToShow)"></v-pagination>
            </div>
          </div>
          <div class="discover">
            <div class="font-medium text-xl md:text-2xl mb-4">{{ $t("anime.now") }}</div>
            <swiper
              class="hidden md:block"
              :modules="modules"
              :spaceBetween="15"
              :slidesPerView="1"
              :navigation="true"
              :freeMode="true"
              :virtual="true"
              :breakpoints="{
                660: { slidesPerGroup: 2, slidesPerView: 3 },
                860: { slidesPerGroup: 3, slidesPerView: 4 },
                1060: { slidesPerGroup: 4, slidesPerView: 5 },
                1260: { slidesPerGroup: 5, slidesPerView: 6 },
              }">
              <swiper-slide
                v-for="(card, index2) in global.animesSuggest[2]"
                :key="index2"
                :virtualIndex="index2"
                class="min-w-[168px] max-w-[168px] imgPoster select-none">
                <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                  <v-img
                    :alt="card?.title"
                    loading="lazy"
                    class="rounded-xl bg-second"
                    :src="'/images/upload/' + card?.poster"
                    width="168"
                    height="250">
                    <template v-slot:placeholder>
                      <v-sheet class="h-full">
                        <v-skeleton-loader
                          type="image"
                          class="rounded-xl overflow-hidden shadow-3xl h-[250px]"
                          color="#020420"></v-skeleton-loader>
                      </v-sheet>
                    </template>
                  </v-img>
                </router-link>
                <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                  <div class="font-medium line-clamp-2 leading-5 my-1">{{ card?.title }}</div>
                </router-link>
                <div class="text-gray-200 text-xs">{{ formaterDate(card?.debut) }}</div>
              </swiper-slide>
            </swiper>
          </div>
          <div class="md:hidden flex overflow-auto scrollbar-none">
            <div v-for="(card, index2) in global.animesSuggest[2]" :key="index2">
              <div v-if="index2 < 15" class="min-w-[136px] max-w-[136x] imgPoster select-none mr-[12px]">
                <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                  <v-img
                    :alt="card?.title"
                    loading="lazy"
                    class="rounded-xl bg-second"
                    :src="'/images/thumbnail/' + card?.poster"
                    width="136"
                    height="204">
                  </v-img>
                </router-link>
                <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                  <div class="font-medium line-clamp-2 leading-5 my-1">{{ card?.title }}</div>
                </router-link>
                <div class="text-gray-200 text-xs">{{ formaterDate(card?.debut) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="relative z-[1] h-[350px] discover" v-if="index === 3">
          <div class="font-medium text-xl md:text-2xl mb-4 pt-2">{{ $t("anime.coming") }}</div>
          <swiper
            class="hidden md:block"
            :modules="modules"
            :spaceBetween="15"
            :slidesPerView="1"
            :navigation="true"
            :freeMode="true"
            :virtual="true"
            :breakpoints="{
              660: { slidesPerGroup: 2, slidesPerView: 3 },
              860: { slidesPerGroup: 3, slidesPerView: 4 },
              1060: { slidesPerGroup: 4, slidesPerView: 5 },
              1260: { slidesPerGroup: 5, slidesPerView: 6 },
            }">
            <swiper-slide
              v-for="(card, index2) in global.animesSuggest[3]"
              :key="index2"
              :virtualIndex="index2"
              class="min-w-[168px] max-w-[168px] imgPoster select-none">
              <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                <v-img
                  :alt="card?.title"
                  loading="lazy"
                  class="rounded-xl bg-second"
                  :src="'/images/upload/' + card?.poster"
                  width="168"
                  height="250">
                  <template v-slot:placeholder>
                    <v-sheet class="h-full">
                      <v-skeleton-loader
                        type="image"
                        class="rounded-xl overflow-hidden shadow-3xl h-[250px]"
                        color="#020420"></v-skeleton-loader>
                    </v-sheet>
                  </template>
                </v-img>
              </router-link>
              <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                <div class="font-medium line-clamp-2 leading-5 my-1">{{ card?.title }}</div>
              </router-link>
              <div class="text-gray-200 text-xs">{{ formaterDate(card?.debut) }}</div>
            </swiper-slide>
          </swiper>
          <div class="md:hidden flex overflow-auto scrollbar-none">
            <div v-for="(card, index2) in global.animesSuggest[3]" :key="index2">
              <div v-if="index2 < 15" class="min-w-[136px] max-w-[136x] imgPoster select-none mr-[12px]">
                <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                  <v-img
                    :alt="card?.title"
                    loading="lazy"
                    class="rounded-xl bg-second"
                    :src="'/images/thumbnail/' + card?.poster"
                    width="136"
                    height="204">
                  </v-img>
                </router-link>
                <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                  <div class="font-medium line-clamp-2 leading-5 my-1">{{ card?.title }}</div>
                </router-link>
                <div class="text-gray-200 text-xs">{{ formaterDate(card?.debut) }}</div>
              </div>
            </div>
          </div>
        </div>
        <v-sheet
          v-if="index > 3 && item?.type && index !== global.animesSuggest.length - 1"
          :height="size"
          class="overflow-hidden bg-transparent aos-animate relative z-[1] flex flex-col justify-center discover">
          <div class="flex md:align-center justify-start flex-col md:flex-row">
            <router-link
              :to="'/anime/' + item?.url"
              class="max-h-[350px] rounded-2xl h-[180px] overflow-hidden md:overflow-visible"
              @click="global.animesSuggestIndex = index">
              <v-img
                :alt="card?.title"
                :src="'/images/upload/' + item?.poster"
                loading="lazy"
                class="rounded-2xl shadow-3xl md:w-[200px] md:max-w-[200px] max-w-[100%] max-h-[unset] md:mr-9 w-full md:h-[300px]">
              </v-img>
            </router-link>
            <div
              class="flex flex-col justify-between md:justify-evenly h-[96px] py-1 overflow-hidden md:h-[300px] w-full">
              <div>
                <router-link :to="'/anime/' + item?.url" @click="global.animesSuggestIndex = index">
                  <h1
                    class="text-white md:py-0 font-medium text-xl md:text-2xl lg:text-3xl line-clamp-1 md:line-clamp-3 overflow-hidden"
                    style="line-height: 1.2">
                    {{ item?.title }}
                  </h1>
                </router-link>
                <div class="Stars" style="--rating: 0"></div>
              </div>
              <div class="anime-genres md:mt-1 flex overflow-hidden md:hidden">
                <div
                  v-if="item?.genre?.indexOf('action') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #f6bb42">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M19 16V6h3v10zM12 4H7S2 4 2 8v6c0 1.77 1 2.76 2.07 3.31A3.996 3.996 0 0 1 8 14h3v2H8a2 2 0 0 0-2 2a2 2 0 0 0 2 2h5c4 0 4-4 4-4V6s-1-2-5-2" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('aventure') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #8cc152">
                  <v-icon class="text-white">mdi-compass</v-icon>
                </div>

                <div
                  v-if="item?.genre?.indexOf('comedie') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #e8ce4d">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M7 22q-2.5 0-4.25-1.75T1 16V9h12v7q0 2.5-1.75 4.25T7 22m-2-7.5q.425 0 .713-.288T6 13.5q0-.425-.288-.712T5 12.5q-.425 0-.712.288T4 13.5q0 .425.288.713T5 14.5m2 3.4q.975 0 1.738-.513T9.5 16h-5q0 .875.763 1.388T7 17.9m2-3.4q.425 0 .713-.287T10 13.5q0-.425-.288-.712T9 12.5q-.425 0-.712.288T8 13.5q0 .425.288.713T9 14.5m8 .5q-.65 0-1.287-.137T14.5 14.45V7.5H11V2h12v7q0 2.5-1.75 4.25T17 15m-2-7.5q.425 0 .713-.288T16 6.5q0-.425-.288-.712T15 5.5q-.425 0-.712.288T14 6.5q0 .425.288.713T15 7.5m-.5 3.4h5q0-.875-.763-1.388T17 9q-.85 0-1.675.45T14.5 10.9M19 7.5q.425 0 .713-.288T20 6.5q0-.425-.288-.712T19 5.5q-.425 0-.712.288T18 6.5q0 .425.288.713T19 7.5" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('drame') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #3c3b3d">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 2c5.523 0 10 4.477 10 10a9.959 9.959 0 0 1-1.065 4.496a1.975 1.975 0 0 0-.398-.775l-.123-.135L19 14.172l-1.414 1.414l-.117.127a2 2 0 0 0 1.679 3.282A9.974 9.974 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2m0 13c-1.38 0-2.63.56-3.534 1.463l-.166.174l.945.86C10.035 17.182 10.982 17 12 17c.905 0 1.754.144 2.486.396l.269.1l.945-.86A4.987 4.987 0 0 0 12 15m-3.5-5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m7 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('ecchi') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #ec87c0">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                    <path
                      fill="currentColor"
                      d="M226 88.08c-.4-1-.82-2-1.25-3a87.93 87.93 0 0 0-30.17-37H216a8 8 0 0 0 0-16H112a88.12 88.12 0 0 0-87.72 81A32 32 0 0 0 0 144a8 8 0 0 0 16 0a16 16 0 0 1 8.57-14.16A87.69 87.69 0 0 0 46 178.22l12.56 35.16A16 16 0 0 0 73.64 224h12.72a16 16 0 0 0 15.07-10.62l1.92-5.38h57.3l1.92 5.38A16 16 0 0 0 177.64 224h12.72a16 16 0 0 0 15.07-10.62L221.64 168H224a24 24 0 0 0 24-24v-32a24 24 0 0 0-22-23.92M152 72h-40a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16m28 56a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('fantastique') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #2aba66">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M14.5 12a2.5 2.5 0 0 1-5 0a2.5 2.5 0 0 1 5 0m7.5 0c0 5.52-4.48 10-10 10S2 17.52 2 12S6.48 2 12 2s10 4.48 10 10m-2 0h-4c0-2.21-1.79-4-4-4s-4 1.79-4 4H4c0 4.41 3.59 8 8 8s8-3.59 8-8" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('historique') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #aa8e69">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M6.5 20c1.7 0 4.15.65 5.5 1.5c1.35-.85 3.8-1.5 5.5-1.5c1.65 0 3.35.3 4.75 1.05c.1.05.15.05.25.05c.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1c-1.11-.35-2.33-.5-3.5-.5c-1.95 0-4.05.4-5.5 1.5c-1.45-1.1-3.55-1.5-5.5-1.5c-1.17 0-2.39.15-3.5.5c-.75.25-1.4.55-2 1v14.6c0 .25.25.5.5.5c.1 0 .15 0 .25-.05C3.15 20.3 4.85 20 6.5 20m5.5-.5V8c1.35-.85 3.8-1.5 5.5-1.5c1.2 0 2.4.15 3.5.5v11.5c-1.1-.35-2.3-.5-3.5-.5c-1.7 0-4.15.65-5.5 1.5" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('horreur') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #434a54">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M6.5 16.5h-2q-.46 0-.73-.27q-.27-.27-.27-.73V7H3q-.213 0-.356-.144q-.144-.144-.144-.357t.144-.356Q2.787 6 3 6h3.5V3.5h-1q-.213 0-.356-.144Q5 3.212 5 3t.144-.356Q5.288 2.5 5.5 2.5h3q.213 0 .356.144Q9 2.788 9 3t-.144.356Q8.713 3.5 8.5 3.5h-1V6H11q.213 0 .356.144q.144.144.144.357t-.144.356Q11.213 7 11 7h-.5v8.5q0 .46-.27.73q-.27.27-.73.27h-2V21q0 .213-.144.356q-.144.144-.357.144t-.356-.144Q6.5 21.213 6.5 21zm-2-1h5v-2.308H7.25q-.177 0-.31-.132q-.132-.133-.132-.31t.132-.31q.133-.132.31-.132H9.5v-2.116H7.25q-.177 0-.31-.132q-.132-.133-.132-.31t.132-.31q.133-.132.31-.132H9.5V7h-5zm10 6q-.46 0-.73-.27q-.27-.27-.27-.73v-7q0-.717.308-1.186q.307-.47.582-.781q.256-.293.433-.515q.177-.222.177-.518v-1h-.5q-.213 0-.356-.144Q14 9.212 14 9t.144-.356q.144-.143.356-.143h5q.213 0 .356.144Q20 8.788 20 9t-.144.356q-.143.143-.356.143H19v1q0 .296.183.527q.182.23.444.523q.275.312.574.777q.299.465.299 1.166V20.5q0 .46-.27.73q-.27.27-.73.27zm0-7.192h5V13.5q0-.49-.225-.823q-.225-.333-.538-.677q-.262-.28-.5-.626Q18 11.03 18 10.5v-1h-2v1q0 .518-.238.863q-.237.345-.493.631q-.313.344-.541.68q-.228.336-.228.826zm0 6.192h5v-2.308h-5z" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('isekai') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #baa286">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12q0-.175-.012-.363t-.013-.312q-.125.725-.675 1.2T18 13h-2q-.825 0-1.412-.587T14 11v-1h-4V8q0-.825.588-1.412T12 6h1q0-.575.313-1.012t.762-.713q-.5-.125-1.012-.2T12 4Q8.65 4 6.325 6.325T4 12h5q1.65 0 2.825 1.175T13 16v1h-3v2.75q.5.125.988.188T12 20" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('josei') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #48cfad">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('magique') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #e9573f">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 14 14">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="m12.35 3.824l.84-2.48a.4.4 0 0 0-.51-.51l-2.48.84a.41.41 0 0 1-.37-.05L7.73.054a.4.4 0 0 0-.6.33v2.62a.43.43 0 0 1-.17.33l-2.14 1.51a.4.4 0 0 0 .11.71l2.5.78a.38.38 0 0 1 .26.26l.78 2.5a.4.4 0 0 0 .71.11l1.46-2.1a.43.43 0 0 1 .33-.17h2.62a.4.4 0 0 0 .33-.64l-1.57-2.1a.41.41 0 0 1 0-.37M.293 12.293l5.241-5.241l1.096.342l.33 1.06l-5.253 5.253a1 1 0 0 1-1.414-1.414"
                      clip-rule="evenodd" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('mecha') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #aab2bd">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M18.41 4L16 6.41v.18L18.41 9H22v2h-4.41L16 9.41V12h-1a2 2 0 0 1-2-2V7.5H9.86c-.09.37-.24.72-.44 1.05L15.18 19H20a2 2 0 0 1 2 2v1H2v-1a2 2 0 0 1 2-2h6.61l-4.69-8.5a4.006 4.006 0 0 1-3.81-3A4 4 0 0 1 5 2.63c2.13-.55 4.31.73 4.86 2.87H13V3a2 2 0 0 1 2-2h1v2.59L17.59 2H22v2zM6 4.5a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('musique') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #a0cecb">
                  <v-icon class="text-white">mdi-music-note</v-icon>
                </div>

                <div
                  v-if="item?.genre?.indexOf('mystere') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #8067b7">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M11.75 19h-.25q-3.55 0-6.025-2.475T3 10.5q0-3.55 2.475-6.025T11.5 2q1.775 0 3.313.662t2.7 1.825q1.162 1.163 1.824 2.7T20 10.5q0 3.35-1.888 6.225t-4.762 4.5q-.25.125-.5.138t-.45-.113q-.2-.125-.35-.325t-.175-.475zm-.275-3.025q.425 0 .725-.3t.3-.725q0-.425-.3-.725t-.725-.3q-.425 0-.725.3t-.3.725q0 .425.3.725t.725.3M9.3 8.375q.275.125.55.013t.45-.363q.225-.3.525-.463T11.5 7.4q.6 0 .975.337t.375.863q0 .325-.187.65t-.663.8q-.625.55-.925 1.038t-.3.987q0 .3.213.513t.512.212q.3 0 .5-.225t.3-.525q.125-.425.45-.775t.6-.625q.525-.525.788-1.05t.262-1.05q0-1.15-.788-1.85T11.5 6q-.8 0-1.475.388t-1.1 1.062q-.15.275-.038.538t.413.387" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('psychologique') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #6a50a7">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M21.33 12.91c.09 1.55-.62 3.04-1.89 3.95l.77 1.49c.23.45.26.98.06 1.45c-.19.47-.58.84-1.06 1l-.79.25a1.687 1.687 0 0 1-1.86-.55L14.44 18c-.89-.15-1.73-.53-2.44-1.1c-.5.15-1 .23-1.5.23c-.88 0-1.76-.27-2.5-.79c-.53.16-1.07.23-1.62.22c-.79.01-1.57-.15-2.3-.45a4.105 4.105 0 0 1-2.43-3.61c-.08-.72.04-1.45.35-2.11c-.29-.75-.32-1.57-.07-2.33C2.3 7.11 3 6.32 3.87 5.82c.58-1.69 2.21-2.82 4-2.7c1.6-1.5 4.05-1.66 5.83-.37c.42-.11.86-.17 1.3-.17c1.36-.03 2.65.57 3.5 1.64c2.04.53 3.5 2.35 3.58 4.47c.05 1.11-.25 2.2-.86 3.13c.07.36.11.72.11 1.09m-5-1.41c.57.07 1.02.5 1.02 1.07a1 1 0 0 1-1 1h-.63c-.32.9-.88 1.69-1.62 2.29c.25.09.51.14.77.21c5.13-.07 4.53-3.2 4.53-3.25a2.592 2.592 0 0 0-2.69-2.49a1 1 0 0 1-1-1a1 1 0 0 1 1-1c1.23.03 2.41.49 3.33 1.3c.05-.29.08-.59.08-.89c-.06-1.24-.62-2.32-2.87-2.53c-1.25-2.96-4.4-1.32-4.4-.4c-.03.23.21.72.25.75a1 1 0 0 1 1 1c0 .55-.45 1-1 1c-.53-.02-1.03-.22-1.43-.56c-.48.31-1.03.5-1.6.56c-.57.05-1.04-.35-1.07-.9a.968.968 0 0 1 .88-1.1c.16-.02.94-.14.94-.77c0-.66.25-1.29.68-1.79c-.92-.25-1.91.08-2.91 1.29C6.75 5 6 5.25 5.45 7.2C4.5 7.67 4 8 3.78 9c1.08-.22 2.19-.13 3.22.25c.5.19.78.75.59 1.29c-.19.52-.77.78-1.29.59c-.73-.32-1.55-.34-2.3-.06c-.32.27-.32.83-.32 1.27c0 .74.37 1.43 1 1.83c.53.27 1.12.41 1.71.4c-.15-.26-.28-.53-.39-.81a1.038 1.038 0 0 1 1.96-.68c.4 1.14 1.42 1.92 2.62 2.05c1.37-.07 2.59-.88 3.19-2.13c.23-1.38 1.34-1.5 2.56-1.5m2 7.47l-.62-1.3l-.71.16l1 1.25zm-4.65-8.61a1 1 0 0 0-.91-1.03c-.71-.04-1.4.2-1.93.67c-.57.58-.87 1.38-.84 2.19a1 1 0 0 0 1 1c.57 0 1-.45 1-1c0-.27.07-.54.23-.76c.12-.1.27-.15.43-.15c.55.03 1.02-.38 1.02-.92" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('romance') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #bf263c">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M12 20.325q-.35 0-.712-.125t-.638-.4l-1.725-1.575q-2.65-2.425-4.788-4.812T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.325 0 2.5.562t2 1.538q.825-.975 2-1.537t2.5-.563q2.35 0 3.925 1.575T22 8.15q0 2.875-2.125 5.275T15.05 18.25l-1.7 1.55q-.275.275-.637.4t-.713.125" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('sci_fi') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #3bafda">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M6.952 12.67q.63-1.42 1.447-2.705q.816-1.284 1.793-2.461L8.777 7.21q-.404-.081-.783.03q-.379.112-.67.404L4.537 10.43q-.298.298-.22.727q.078.429.482.59zm12.527-8.847q-2.304.048-4.345 1.025T11.467 7.45q-1.046 1.046-1.853 2.273q-.806 1.227-1.443 2.565q-.125.268-.125.548t.212.49l2.567 2.549q.212.212.482.221q.27.01.537-.115q1.339-.618 2.566-1.443q1.227-.826 2.273-1.873Q18.308 11.04 19.285 9t1.025-4.345q0-.162-.062-.304q-.061-.142-.192-.273q-.131-.13-.273-.192q-.143-.062-.304-.062m-5.485 6.315q-.44-.44-.44-1.066q0-.626.44-1.066q.44-.44 1.067-.44q.626 0 1.066.44q.44.44.44 1.066q0 .626-.44 1.066q-.44.44-1.066.44q-.626 0-1.067-.44m-2.55 7.043l.923 2.152q.162.404.59.484q.43.081.728-.217l2.784-2.785q.293-.292.404-.683q.112-.392.03-.795l-.288-1.397q-1.182.977-2.464 1.794q-1.282.816-2.707 1.447m-6.298-1.035q.587-.586 1.423-.58q.837.007 1.423.594q.587.586.587 1.423q0 .836-.587 1.423q-.834.834-1.962 1.05q-1.128.215-2.278.325q.11-1.17.337-2.288q.228-1.118 1.057-1.947" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('seinen') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #8e8271">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('shojo') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #d770ad">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('shonen') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #4a89dc">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('sport') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #7db1b1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M9.075 20.975q-1.175.125-2.837-.062T3.7 20.3q-.35-.8-.587-2.5T3 14.9zm2.375-.4l-8.05-8.05q.425-1.875 1.238-3.412t1.887-2.638Q7.6 5.4 9.138 4.613t3.312-1.188l8.1 8.1q-.4 1.85-1.175 3.4t-1.85 2.625q-1.125 1.1-2.687 1.888t-3.388 1.137M10.1 15.3l5.2-5.2q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275l-5.2 5.2q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275m10.85-6.15l-6.075-6.125q1.2-.15 2.95.05T20.3 3.7q.45 1 .625 2.588t.025 2.862" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('surnaturel') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #ed5565">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M17.09 4.56c-.7-1.03-1.5-1.99-2.4-2.85c-.35-.34-.94-.02-.84.46c.19.94.39 2.18.39 3.29c0 2.06-1.35 3.73-3.41 3.73c-1.54 0-2.8-.93-3.35-2.26c-.1-.2-.14-.32-.2-.54c-.11-.42-.66-.55-.9-.18c-.18.27-.35.54-.51.83A13.772 13.772 0 0 0 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8c0-3.49-1.08-6.73-2.91-9.44M11.71 19c-1.78 0-3.22-1.4-3.22-3.14c0-1.62 1.05-2.76 2.81-3.12c1.47-.3 2.98-.93 4.03-1.92c.28-.26.74-.14.82.23c.23 1.02.35 2.08.35 3.15c.01 2.65-2.14 4.8-4.79 4.8" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('thriller') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #5d9cec">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                    <path
                      fill="currentColor"
                      d="M256 120a8 8 0 0 1-8 8H8a8 8 0 0 1 0-16h27.92l47.5-65.41a16 16 0 0 1 25.31-.72l12.85 14.9l.2.23a7.95 7.95 0 0 0 12.44 0l.2-.23l12.85-14.9a16 16 0 0 1 25.31.72l47.5 65.41H248a8 8 0 0 1 8 8m-76 24a36 36 0 0 0-35.77 32h-32.46a36 36 0 1 0-1.83 16h36.12A36 36 0 1 0 180 144" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('tranche_de_vie') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #2ecc71">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M5 21q-.425 0-.712-.288T4 20q0-.425.288-.712T5 19h14q.425 0 .713.288T20 20q0 .425-.288.713T19 21zm3-4q-1.65 0-2.825-1.175T4 13V5q0-.825.588-1.412T6 3h14q.825 0 1.413.588T22 5v3q0 .825-.587 1.413T20 10h-2v3q0 1.65-1.175 2.825T14 17zm10-9h2V5h-2z" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('yaoi') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #e0c341">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M20 5v4q0 .425-.288.713T19 10q-.425 0-.712-.288T18 9V7.425l-3.975 3.95q.475.7.725 1.488T15 14.5q0 2.3-1.6 3.9T9.5 20q-2.3 0-3.9-1.6T4 14.5q0-2.3 1.6-3.9T9.5 9q.825 0 1.625.237t1.475.738L16.575 6H15q-.425 0-.712-.288T14 5q0-.425.288-.712T15 4h4q.425 0 .713.288T20 5M9.5 11q-1.45 0-2.475 1.025T6 14.5q0 1.45 1.025 2.475T9.5 18q1.45 0 2.475-1.025T13 14.5q0-1.45-1.025-2.475T9.5 11" />
                  </svg>
                </div>

                <div
                  v-if="item?.genre?.indexOf('yuri') !== -1 && item?.genre"
                  class="anime-genre"
                  style="background-color: #fc6e51">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M11 19h-1q-.425 0-.712-.288T9 18q0-.425.288-.712T10 17h1v-2.1q-1.975-.35-3.238-1.888T6.5 9.45q0-2.275 1.613-3.862T12 4q2.275 0 3.888 1.588T17.5 9.45q0 2.025-1.263 3.563T13 14.9V17h1q.425 0 .713.288T15 18q0 .425-.288.713T14 19h-1v1q0 .425-.288.713T12 21q-.425 0-.712-.288T11 20zm1-6q1.45 0 2.475-1.025T15.5 9.5q0-1.45-1.025-2.475T12 6q-1.45 0-2.475 1.025T8.5 9.5q0 1.45 1.025 2.475T12 13" />
                  </svg>
                </div>
              </div>
              <div class="md:flex justify-between hidden">
                <div class="flex">
                  <div class="md:mr-5 my-1">
                    <div class="flex mb-1">
                      <svg
                        class="mr-3 self-center text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M17 9V7a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-2l5 4V5l-5 4z"></path>
                      </svg>
                      <span class="font-medium text-gray-200 w-[100px]">{{ $t("infos.studio") }}</span>
                      <span class="text-white line-clamp-1">{{ item?.studio || $t("infos.unknown") }}</span>
                    </div>
                    <div class="flex mb-1">
                      <svg
                        class="mr-3 self-center text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M12 20a8 8 0 0 0 8-8a8 8 0 0 0-8-8a8 8 0 0 0-8 8a8 8 0 0 0 8 8m0-18a10 10 0 0 1 10 10a10 10 0 0 1-10 10C6.47 22 2 17.5 2 12A10 10 0 0 1 12 2m.5 5v5.25l4.5 2.67l-.75 1.23L11 13V7h1.5Z"></path>
                      </svg>
                      <span class="font-medium text-gray-200 w-[100px]">{{ $t("infos.duration") }}</span>
                      <span class="text-white">{{ item?.avgTime || $t("infos.unknown") }}</span>
                    </div>
                    <div class="flex">
                      <svg
                        class="mr-3 self-center text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24">
                        <path
                          fill="currentColor"
                          d="M8 14q-.425 0-.712-.288T7 13q0-.425.288-.712T8 12q.425 0 .713.288T9 13q0 .425-.288.713T8 14Zm4 0q-.425 0-.712-.288T11 13q0-.425.288-.712T12 12q.425 0 .713.288T13 13q0 .425-.288.713T12 14Zm4 0q-.425 0-.712-.288T15 13q0-.425.288-.712T16 12q.425 0 .713.288T17 13q0 .425-.288.713T16 14ZM5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V3q0-.425.288-.712T7 2q.425 0 .713.288T8 3v1h8V3q0-.425.288-.712T17 2q.425 0 .713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22H5Zm0-2h14V10H5v10Z"></path>
                      </svg>
                      <span class="font-medium text-gray-200 w-[100px]">{{ $t("infos.aired") }}</span>
                      <span class="text-white">{{ formaterDate(item?.debut) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="md:block hidden">
                <div class="anime-genres flex md:mt-2">
                  <div
                    v-if="item?.genre?.indexOf('action') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #f6bb42">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M19 16V6h3v10zM12 4H7S2 4 2 8v6c0 1.77 1 2.76 2.07 3.31A3.996 3.996 0 0 1 8 14h3v2H8a2 2 0 0 0-2 2a2 2 0 0 0 2 2h5c4 0 4-4 4-4V6s-1-2-5-2" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.action")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('aventure') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #8cc152">
                    <v-icon class="text-white">mdi-compass</v-icon>

                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.adventure")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('comedie') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #e8ce4d">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M7 22q-2.5 0-4.25-1.75T1 16V9h12v7q0 2.5-1.75 4.25T7 22m-2-7.5q.425 0 .713-.288T6 13.5q0-.425-.288-.712T5 12.5q-.425 0-.712.288T4 13.5q0 .425.288.713T5 14.5m2 3.4q.975 0 1.738-.513T9.5 16h-5q0 .875.763 1.388T7 17.9m2-3.4q.425 0 .713-.287T10 13.5q0-.425-.288-.712T9 12.5q-.425 0-.712.288T8 13.5q0 .425.288.713T9 14.5m8 .5q-.65 0-1.287-.137T14.5 14.45V7.5H11V2h12v7q0 2.5-1.75 4.25T17 15m-2-7.5q.425 0 .713-.288T16 6.5q0-.425-.288-.712T15 5.5q-.425 0-.712.288T14 6.5q0 .425.288.713T15 7.5m-.5 3.4h5q0-.875-.763-1.388T17 9q-.85 0-1.675.45T14.5 10.9M19 7.5q.425 0 .713-.288T20 6.5q0-.425-.288-.712T19 5.5q-.425 0-.712.288T18 6.5q0 .425.288.713T19 7.5" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.comedy")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('drame') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #3c3b3d">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12 2c5.523 0 10 4.477 10 10a9.959 9.959 0 0 1-1.065 4.496a1.975 1.975 0 0 0-.398-.775l-.123-.135L19 14.172l-1.414 1.414l-.117.127a2 2 0 0 0 1.679 3.282A9.974 9.974 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2m0 13c-1.38 0-2.63.56-3.534 1.463l-.166.174l.945.86C10.035 17.182 10.982 17 12 17c.905 0 1.754.144 2.486.396l.269.1l.945-.86A4.987 4.987 0 0 0 12 15m-3.5-5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m7 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.drama")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('ecchi') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #ec87c0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                      <path
                        fill="currentColor"
                        d="M226 88.08c-.4-1-.82-2-1.25-3a87.93 87.93 0 0 0-30.17-37H216a8 8 0 0 0 0-16H112a88.12 88.12 0 0 0-87.72 81A32 32 0 0 0 0 144a8 8 0 0 0 16 0a16 16 0 0 1 8.57-14.16A87.69 87.69 0 0 0 46 178.22l12.56 35.16A16 16 0 0 0 73.64 224h12.72a16 16 0 0 0 15.07-10.62l1.92-5.38h57.3l1.92 5.38A16 16 0 0 0 177.64 224h12.72a16 16 0 0 0 15.07-10.62L221.64 168H224a24 24 0 0 0 24-24v-32a24 24 0 0 0-22-23.92M152 72h-40a8 8 0 0 1 0-16h40a8 8 0 0 1 0 16m28 56a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.ecchi")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('fantastique') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #2aba66">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M14.5 12a2.5 2.5 0 0 1-5 0a2.5 2.5 0 0 1 5 0m7.5 0c0 5.52-4.48 10-10 10S2 17.52 2 12S6.48 2 12 2s10 4.48 10 10m-2 0h-4c0-2.21-1.79-4-4-4s-4 1.79-4 4H4c0 4.41 3.59 8 8 8s8-3.59 8-8" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.fantastic")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('historique') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #aa8e69">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M6.5 20c1.7 0 4.15.65 5.5 1.5c1.35-.85 3.8-1.5 5.5-1.5c1.65 0 3.35.3 4.75 1.05c.1.05.15.05.25.05c.25 0 .5-.25.5-.5V6c-.6-.45-1.25-.75-2-1c-1.11-.35-2.33-.5-3.5-.5c-1.95 0-4.05.4-5.5 1.5c-1.45-1.1-3.55-1.5-5.5-1.5c-1.17 0-2.39.15-3.5.5c-.75.25-1.4.55-2 1v14.6c0 .25.25.5.5.5c.1 0 .15 0 .25-.05C3.15 20.3 4.85 20 6.5 20m5.5-.5V8c1.35-.85 3.8-1.5 5.5-1.5c1.2 0 2.4.15 3.5.5v11.5c-1.1-.35-2.3-.5-3.5-.5c-1.7 0-4.15.65-5.5 1.5" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.historical")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('horreur') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #434a54">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M6.5 16.5h-2q-.46 0-.73-.27q-.27-.27-.27-.73V7H3q-.213 0-.356-.144q-.144-.144-.144-.357t.144-.356Q2.787 6 3 6h3.5V3.5h-1q-.213 0-.356-.144Q5 3.212 5 3t.144-.356Q5.288 2.5 5.5 2.5h3q.213 0 .356.144Q9 2.788 9 3t-.144.356Q8.713 3.5 8.5 3.5h-1V6H11q.213 0 .356.144q.144.144.144.357t-.144.356Q11.213 7 11 7h-.5v8.5q0 .46-.27.73q-.27.27-.73.27h-2V21q0 .213-.144.356q-.144.144-.357.144t-.356-.144Q6.5 21.213 6.5 21zm-2-1h5v-2.308H7.25q-.177 0-.31-.132q-.132-.133-.132-.31t.132-.31q.133-.132.31-.132H9.5v-2.116H7.25q-.177 0-.31-.132q-.132-.133-.132-.31t.132-.31q.133-.132.31-.132H9.5V7h-5zm10 6q-.46 0-.73-.27q-.27-.27-.27-.73v-7q0-.717.308-1.186q.307-.47.582-.781q.256-.293.433-.515q.177-.222.177-.518v-1h-.5q-.213 0-.356-.144Q14 9.212 14 9t.144-.356q.144-.143.356-.143h5q.213 0 .356.144Q20 8.788 20 9t-.144.356q-.143.143-.356.143H19v1q0 .296.183.527q.182.23.444.523q.275.312.574.777q.299.465.299 1.166V20.5q0 .46-.27.73q-.27.27-.73.27zm0-7.192h5V13.5q0-.49-.225-.823q-.225-.333-.538-.677q-.262-.28-.5-.626Q18 11.03 18 10.5v-1h-2v1q0 .518-.238.863q-.237.345-.493.631q-.313.344-.541.68q-.228.336-.228.826zm0 6.192h5v-2.308h-5z" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.horror")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('isekai') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #baa286">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12 22q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12q0-.175-.012-.363t-.013-.312q-.125.725-.675 1.2T18 13h-2q-.825 0-1.412-.587T14 11v-1h-4V8q0-.825.588-1.412T12 6h1q0-.575.313-1.012t.762-.713q-.5-.125-1.012-.2T12 4Q8.65 4 6.325 6.325T4 12h5q1.65 0 2.825 1.175T13 16v1h-3v2.75q.5.125.988.188T12 20" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.isekai")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('josei') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #48cfad">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.josei")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('magique') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #e9573f">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 14 14">
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="m12.35 3.824l.84-2.48a.4.4 0 0 0-.51-.51l-2.48.84a.41.41 0 0 1-.37-.05L7.73.054a.4.4 0 0 0-.6.33v2.62a.43.43 0 0 1-.17.33l-2.14 1.51a.4.4 0 0 0 .11.71l2.5.78a.38.38 0 0 1 .26.26l.78 2.5a.4.4 0 0 0 .71.11l1.46-2.1a.43.43 0 0 1 .33-.17h2.62a.4.4 0 0 0 .33-.64l-1.57-2.1a.41.41 0 0 1 0-.37M.293 12.293l5.241-5.241l1.096.342l.33 1.06l-5.253 5.253a1 1 0 0 1-1.414-1.414"
                        clip-rule="evenodd" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.magic")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('mecha') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #aab2bd">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M18.41 4L16 6.41v.18L18.41 9H22v2h-4.41L16 9.41V12h-1a2 2 0 0 1-2-2V7.5H9.86c-.09.37-.24.72-.44 1.05L15.18 19H20a2 2 0 0 1 2 2v1H2v-1a2 2 0 0 1 2-2h6.61l-4.69-8.5a4.006 4.006 0 0 1-3.81-3A4 4 0 0 1 5 2.63c2.13-.55 4.31.73 4.86 2.87H13V3a2 2 0 0 1 2-2h1v2.59L17.59 2H22v2zM6 4.5a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.mecha")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('musique') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #a0cecb">
                    <v-icon class="text-white">mdi-music-note</v-icon>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.music")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('mystere') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #8067b7">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M11.75 19h-.25q-3.55 0-6.025-2.475T3 10.5q0-3.55 2.475-6.025T11.5 2q1.775 0 3.313.662t2.7 1.825q1.162 1.163 1.824 2.7T20 10.5q0 3.35-1.888 6.225t-4.762 4.5q-.25.125-.5.138t-.45-.113q-.2-.125-.35-.325t-.175-.475zm-.275-3.025q.425 0 .725-.3t.3-.725q0-.425-.3-.725t-.725-.3q-.425 0-.725.3t-.3.725q0 .425.3.725t.725.3M9.3 8.375q.275.125.55.013t.45-.363q.225-.3.525-.463T11.5 7.4q.6 0 .975.337t.375.863q0 .325-.187.65t-.663.8q-.625.55-.925 1.038t-.3.987q0 .3.213.513t.512.212q.3 0 .5-.225t.3-.525q.125-.425.45-.775t.6-.625q.525-.525.788-1.05t.262-1.05q0-1.15-.788-1.85T11.5 6q-.8 0-1.475.388t-1.1 1.062q-.15.275-.038.538t.413.387" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.mystery")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('psychologique') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #6a50a7">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M21.33 12.91c.09 1.55-.62 3.04-1.89 3.95l.77 1.49c.23.45.26.98.06 1.45c-.19.47-.58.84-1.06 1l-.79.25a1.687 1.687 0 0 1-1.86-.55L14.44 18c-.89-.15-1.73-.53-2.44-1.1c-.5.15-1 .23-1.5.23c-.88 0-1.76-.27-2.5-.79c-.53.16-1.07.23-1.62.22c-.79.01-1.57-.15-2.3-.45a4.105 4.105 0 0 1-2.43-3.61c-.08-.72.04-1.45.35-2.11c-.29-.75-.32-1.57-.07-2.33C2.3 7.11 3 6.32 3.87 5.82c.58-1.69 2.21-2.82 4-2.7c1.6-1.5 4.05-1.66 5.83-.37c.42-.11.86-.17 1.3-.17c1.36-.03 2.65.57 3.5 1.64c2.04.53 3.5 2.35 3.58 4.47c.05 1.11-.25 2.2-.86 3.13c.07.36.11.72.11 1.09m-5-1.41c.57.07 1.02.5 1.02 1.07a1 1 0 0 1-1 1h-.63c-.32.9-.88 1.69-1.62 2.29c.25.09.51.14.77.21c5.13-.07 4.53-3.2 4.53-3.25a2.592 2.592 0 0 0-2.69-2.49a1 1 0 0 1-1-1a1 1 0 0 1 1-1c1.23.03 2.41.49 3.33 1.3c.05-.29.08-.59.08-.89c-.06-1.24-.62-2.32-2.87-2.53c-1.25-2.96-4.4-1.32-4.4-.4c-.03.23.21.72.25.75a1 1 0 0 1 1 1c0 .55-.45 1-1 1c-.53-.02-1.03-.22-1.43-.56c-.48.31-1.03.5-1.6.56c-.57.05-1.04-.35-1.07-.9a.968.968 0 0 1 .88-1.1c.16-.02.94-.14.94-.77c0-.66.25-1.29.68-1.79c-.92-.25-1.91.08-2.91 1.29C6.75 5 6 5.25 5.45 7.2C4.5 7.67 4 8 3.78 9c1.08-.22 2.19-.13 3.22.25c.5.19.78.75.59 1.29c-.19.52-.77.78-1.29.59c-.73-.32-1.55-.34-2.3-.06c-.32.27-.32.83-.32 1.27c0 .74.37 1.43 1 1.83c.53.27 1.12.41 1.71.4c-.15-.26-.28-.53-.39-.81a1.038 1.038 0 0 1 1.96-.68c.4 1.14 1.42 1.92 2.62 2.05c1.37-.07 2.59-.88 3.19-2.13c.23-1.38 1.34-1.5 2.56-1.5m2 7.47l-.62-1.3l-.71.16l1 1.25zm-4.65-8.61a1 1 0 0 0-.91-1.03c-.71-.04-1.4.2-1.93.67c-.57.58-.87 1.38-.84 2.19a1 1 0 0 0 1 1c.57 0 1-.45 1-1c0-.27.07-.54.23-.76c.12-.1.27-.15.43-.15c.55.03 1.02-.38 1.02-.92" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.psychological")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('romance') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #bf263c">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M12 20.325q-.35 0-.712-.125t-.638-.4l-1.725-1.575q-2.65-2.425-4.788-4.812T2 8.15Q2 5.8 3.575 4.225T7.5 2.65q1.325 0 2.5.562t2 1.538q.825-.975 2-1.537t2.5-.563q2.35 0 3.925 1.575T22 8.15q0 2.875-2.125 5.275T15.05 18.25l-1.7 1.55q-.275.275-.637.4t-.713.125" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.romance")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('sci_fi') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #3bafda">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M6.952 12.67q.63-1.42 1.447-2.705q.816-1.284 1.793-2.461L8.777 7.21q-.404-.081-.783.03q-.379.112-.67.404L4.537 10.43q-.298.298-.22.727q.078.429.482.59zm12.527-8.847q-2.304.048-4.345 1.025T11.467 7.45q-1.046 1.046-1.853 2.273q-.806 1.227-1.443 2.565q-.125.268-.125.548t.212.49l2.567 2.549q.212.212.482.221q.27.01.537-.115q1.339-.618 2.566-1.443q1.227-.826 2.273-1.873Q18.308 11.04 19.285 9t1.025-4.345q0-.162-.062-.304q-.061-.142-.192-.273q-.131-.13-.273-.192q-.143-.062-.304-.062m-5.485 6.315q-.44-.44-.44-1.066q0-.626.44-1.066q.44-.44 1.067-.44q.626 0 1.066.44q.44.44.44 1.066q0 .626-.44 1.066q-.44.44-1.066.44q-.626 0-1.067-.44m-2.55 7.043l.923 2.152q.162.404.59.484q.43.081.728-.217l2.784-2.785q.293-.292.404-.683q.112-.392.03-.795l-.288-1.397q-1.182.977-2.464 1.794q-1.282.816-2.707 1.447m-6.298-1.035q.587-.586 1.423-.58q.837.007 1.423.594q.587.586.587 1.423q0 .836-.587 1.423q-.834.834-1.962 1.05q-1.128.215-2.278.325q.11-1.17.337-2.288q.228-1.118 1.057-1.947" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.sci_fi")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('seinen') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #8e8271">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.seinen")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('shojo') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #d770ad">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.shojo")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('shonen') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #4a89dc">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="m21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58c.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41c0-.55-.23-1.06-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.shonen")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('sport') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #7db1b1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M9.075 20.975q-1.175.125-2.837-.062T3.7 20.3q-.35-.8-.587-2.5T3 14.9zm2.375-.4l-8.05-8.05q.425-1.875 1.238-3.412t1.887-2.638Q7.6 5.4 9.138 4.613t3.312-1.188l8.1 8.1q-.4 1.85-1.175 3.4t-1.85 2.625q-1.125 1.1-2.687 1.888t-3.388 1.137M10.1 15.3l5.2-5.2q.275-.275.275-.7t-.275-.7q-.275-.275-.7-.275t-.7.275l-5.2 5.2q-.275.275-.275.7t.275.7q.275.275.7.275t.7-.275m10.85-6.15l-6.075-6.125q1.2-.15 2.95.05T20.3 3.7q.45 1 .625 2.588t.025 2.862" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.sport")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('surnaturel') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #ed5565">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M17.09 4.56c-.7-1.03-1.5-1.99-2.4-2.85c-.35-.34-.94-.02-.84.46c.19.94.39 2.18.39 3.29c0 2.06-1.35 3.73-3.41 3.73c-1.54 0-2.8-.93-3.35-2.26c-.1-.2-.14-.32-.2-.54c-.11-.42-.66-.55-.9-.18c-.18.27-.35.54-.51.83A13.772 13.772 0 0 0 4 14c0 4.42 3.58 8 8 8s8-3.58 8-8c0-3.49-1.08-6.73-2.91-9.44M11.71 19c-1.78 0-3.22-1.4-3.22-3.14c0-1.62 1.05-2.76 2.81-3.12c1.47-.3 2.98-.93 4.03-1.92c.28-.26.74-.14.82.23c.23 1.02.35 2.08.35 3.15c.01 2.65-2.14 4.8-4.79 4.8" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.surnatural")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('thriller') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #5d9cec">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 256 256">
                      <path
                        fill="currentColor"
                        d="M256 120a8 8 0 0 1-8 8H8a8 8 0 0 1 0-16h27.92l47.5-65.41a16 16 0 0 1 25.31-.72l12.85 14.9l.2.23a7.95 7.95 0 0 0 12.44 0l.2-.23l12.85-14.9a16 16 0 0 1 25.31.72l47.5 65.41H248a8 8 0 0 1 8 8m-76 24a36 36 0 0 0-35.77 32h-32.46a36 36 0 1 0-1.83 16h36.12A36 36 0 1 0 180 144" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.thriller")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('tranche_de_vie') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #2ecc71">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M5 21q-.425 0-.712-.288T4 20q0-.425.288-.712T5 19h14q.425 0 .713.288T20 20q0 .425-.288.713T19 21zm3-4q-1.65 0-2.825-1.175T4 13V5q0-.825.588-1.412T6 3h14q.825 0 1.413.588T22 5v3q0 .825-.587 1.413T20 10h-2v3q0 1.65-1.175 2.825T14 17zm10-9h2V5h-2z" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.slice_of_life")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('yaoi') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #e0c341">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M20 5v4q0 .425-.288.713T19 10q-.425 0-.712-.288T18 9V7.425l-3.975 3.95q.475.7.725 1.488T15 14.5q0 2.3-1.6 3.9T9.5 20q-2.3 0-3.9-1.6T4 14.5q0-2.3 1.6-3.9T9.5 9q.825 0 1.625.237t1.475.738L16.575 6H15q-.425 0-.712-.288T14 5q0-.425.288-.712T15 4h4q.425 0 .713.288T20 5M9.5 11q-1.45 0-2.475 1.025T6 14.5q0 1.45 1.025 2.475T9.5 18q1.45 0 2.475-1.025T13 14.5q0-1.45-1.025-2.475T9.5 11" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.yaoi")
                    }}</span>
                  </div>

                  <div
                    v-if="item?.genre?.indexOf('yuri') !== -1 && item?.genre"
                    class="anime-genre"
                    style="background-color: #fc6e51">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24">
                      <path
                        fill="currentColor"
                        d="M11 19h-1q-.425 0-.712-.288T9 18q0-.425.288-.712T10 17h1v-2.1q-1.975-.35-3.238-1.888T6.5 9.45q0-2.275 1.613-3.862T12 4q2.275 0 3.888 1.588T17.5 9.45q0 2.025-1.263 3.563T13 14.9V17h1q.425 0 .713.288T15 18q0 .425-.288.713T14 19h-1v1q0 .425-.288.713T12 21q-.425 0-.712-.288T11 20zm1-6q1.45 0 2.475-1.025T15.5 9.5q0-1.45-1.025-2.475T12 6q-1.45 0-2.475 1.025T8.5 9.5q0 1.45 1.025 2.475T12 13" />
                    </svg>
                    <span itemprop="genre" class="tag-name ml-1" style="font-weight: bold">{{
                      $t("infos.type.yuri")
                    }}</span>
                  </div>
                </div>
                <p
                  class="line-clamp-3 text-white relative left-[-108px] md:static desc"
                  v-if="selectedLanguage == 'fr'">
                  {{ item?.description || $t("infos.no_synopsis") }}
                </p>
                <p class="line-clamp-3 text-white relative left-[-108px] md:static desc" v-else>
                  {{ item?.descriptionEn || $t("infos.no_synopsis") }}
                </p>
              </div>
            </div>
          </div>

          <p class="line-clamp-2 leading-[1.2] text-white left-[-108px] md:hidden" v-if="selectedLanguage == 'fr'">
            {{ item?.description || $t("infos.no_synopsis") }}
          </p>
          <p class="line-clamp-2 leading-[1.2] text-white left-[-108px] md:hidden" v-else>
            {{ item?.description || $t("infos.no_synopsis") }}
          </p>
        </v-sheet>
        <div
          class="aos-animate relative z-[1] h-[350px] discover"
          v-else-if="index > 3 && global.animesSuggest[index]?.[0] && index !== global.animesSuggest.length - 1">
          <div class="font-medium text-xl mb-4 pt-2" v-if="selectedLanguage == 'fr'">
            {{ global.animesSuggest[index][0]?.tagFr }}
          </div>
          <div class="font-medium text-xl mb-4 pt-2" v-else>{{ global.animesSuggest[index][0]?.tagEn }}</div>
          <swiper
            class="hidden md:block"
            :modules="modules"
            :spaceBetween="15"
            :slidesPerView="1"
            :navigation="true"
            :freeMode="true"
            :virtual="true"
            :breakpoints="{
              660: { slidesPerGroup: 2, slidesPerView: 3 },
              860: { slidesPerGroup: 3, slidesPerView: 4 },
              1060: { slidesPerGroup: 4, slidesPerView: 5 },
              1260: { slidesPerGroup: 5, slidesPerView: 6 },
            }">
            <swiper-slide
              v-for="(card, index2) in global.animesSuggest[index]"
              :key="index2"
              :virtualIndex="index2"
              class="min-w-[168px] max-w-[168px] imgPoster select-none">
              <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                <v-img
                  :alt="card?.title"
                  loading="lazy"
                  class="rounded-xl bg-second"
                  :src="'/images/upload/' + card?.poster"
                  width="168"
                  height="250">
                  <template v-slot:placeholder>
                    <v-sheet class="h-full">
                      <v-skeleton-loader
                        type="image"
                        class="rounded-xl overflow-hidden shadow-3xl"
                        color="#020420"></v-skeleton-loader>
                    </v-sheet>
                  </template>
                </v-img>
              </router-link>
              <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                <div class="font-medium line-clamp-2 leading-5 my-1">{{ card?.title }}</div>
              </router-link>
              <div class="text-gray-200 text-xs">{{ formaterDate(card?.debut) }}</div>
            </swiper-slide>
          </swiper>
          <div class="md:hidden flex overflow-auto scrollbar-none">
            <div
              v-for="(card, index2) in global.animesSuggest[index]"
              :key="index2"
              class="min-w-[136px] max-w-[136x] imgPoster select-none mr-[12px]">
              <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                <v-img
                  :alt="card?.title"
                  loading="lazy"
                  class="rounded-xl bg-second"
                  :src="'/images/thumbnail/' + card?.poster"
                  width="136"
                  height="204">
                </v-img>
              </router-link>
              <router-link :to="'/anime/' + card?.url" @click="global.animesSuggestIndex = index">
                <div class="font-medium line-clamp-2 leading-5 my-1">{{ card?.title }}</div>
              </router-link>
              <div class="text-gray-200 text-xs">{{ formaterDate(card?.debut) }}</div>
            </div>
          </div>
        </div>
        <div v-if="index === global.animesSuggest.length - 1 && isLoading" class="flex justify-center h-[350px]">
          <v-progress-circular :size="50" color="#00bdbf" indeterminate></v-progress-circular>
        </div>
      </template>
    </v-virtual-scroll>
  </div>
</template>
<script setup>
  import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
  import { useFetch } from "@vueuse/core";
  import { useGlobalStore } from "@/stores/global";
  import { Swiper, SwiperSlide } from "swiper/vue";
  import "swiper/css";
  import "swiper/css/navigation";
  import "swiper/css/virtual";
  import { Navigation, Virtual } from "swiper/modules";
  import { useSeoMeta } from "@unhead/vue";
  import { useI18n } from "vue-i18n";
  import { useAuthStore } from "@/stores/auth";

  const auth = useAuthStore();
  const { t } = useI18n();
  const modules = [Navigation, Virtual];
  const global = useGlobalStore();
  const isLoading = ref(null);
  const isLoadingFetch = ref(false);
  const size = ref(350);
  const virtualScroller = ref(null);
  const titre = ref(null);
  const studio = ref(null);
  const theme = ref(null);
  const genre = ref(null);
  const origine = ref(null);
  const nbrEp = ref(null);
  const typeFormat = ref(null);
  const saison = ref(null);
  const annee = ref(null);
  const selectedLanguage = ref(localStorage.getItem("lang"));
  const anime = ref([]);
  const page = ref(0);
  const animeSearch = ref(false);
  const itemsToShow = ref(18);
  const visibleAnime = ref([]);
  useSeoMeta({
    title: "Anime - Baranimes",
    ogTitle: "Anime - Baranimes",
    description: computed(() => t("home.description")),
    ogDescription: computed(() => t("home.description")),
    ogImage: "https://baranimes.com/images/global/favicon.png",
    ogType: "website",
    ogUrl: window.location.href,
    ogSiteName: "baranimes.com",
    twitterCard: "summary_large_image",
    twitterTitle: "Anime - Baranimes",
    twitterSite: "@baranimes",
    twitterDescription: computed(() => t("home.description")),
    twitterImage: "https://baranimes.com/images/global/favicon.png",
  });
  watch(isLoading, (value) => {
    if (value === true) {
      global.pageLoad(1);
    }
  });
  watch(page, (pageIndex) => {
    const start = (pageIndex - 1) * itemsToShow.value;
    const end = pageIndex * itemsToShow.value;
    visibleAnime.value = [];
    for (let i = start; i < end && i < anime.value.length; i++) {
      visibleAnime.value.push(anime.value[i]);
    }
  });
  function submenu(submenu) {
    document.querySelectorAll(".submenu>span")[0].removeAttribute("class");
    document.querySelectorAll(".submenu>span")[0].classList.add("slider_list_menu" + submenu);

    document.querySelectorAll(".slider_list").forEach((_, i) => {
      document.querySelectorAll(".slider_list")[i].classList.remove("active");
    });
    if (submenu == 1) {
      document.getElementById("calc2rem").classList.add("test");
      animeSearch.value = true;
    } else {
      document.getElementById("calc2rem").classList.remove("test");
      animeSearch.value = false;
    }
  }
  const search = () => {
    console.log(
      titre.value,
      studio.value,
      theme.value,
      genre.value,
      origine.value,
      nbrEp.value,
      typeFormat.value,
      saison.value,
      annee.value
    );
  };
  const handleScroll = async (event) => {
    if (animeSearch.value) {
      return;
    }
    const virtualScroll = event.target;
    const scrollPosition = virtualScroll.scrollTop + virtualScroll.clientHeight;
    const totalHeight = virtualScroll.scrollHeight;
    if (scrollPosition + 300 >= totalHeight && isLoadingFetch.value === true && virtualScroll.scrollTop > 1000) {
      setTimeout(function () {
        handleScroll(event);
      }, 500);
      return false;
    }
    if (scrollPosition + 300 >= totalHeight && isLoadingFetch.value === false && virtualScroll.scrollTop > 1000) {
      isLoadingFetch.value = true;
      const { data: response } = await useFetch("/api/animes", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idAnime: [],
        }),
      });
      const r = JSON.parse(response.value);
      global.animesSuggest = [...global.animesSuggest, ...r];

      setTimeout(function () {
        isLoadingFetch.value = false;
      }, 500);
    }
  };

  onMounted(async () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      // eslint-disable-next-line
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-0HY7K2YF6F");
    document.getElementsByTagName("html")[0].classList.add("overflow-hidden");
    document.getElementById("header").classList.add("gutter");
    if (global.animesSuggestIndex) {
      isLoading.value = true;
      setTimeout(function () {
        virtualScroller.value.scrollToIndex(global.animesSuggestIndex - 1);
      }, 1);
    } else {
      global.animesSuggest = [{}, {}, [{}, {}, {}, {}, {}, {}, {}], [{}, {}, {}, {}, {}, {}, {}]];

      const { data: response } = await useFetch("/api/animes/start", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const r = JSON.parse(response.value);
      global.animesSuggest = [{}, {}];
      global.animesSuggest = [...global.animesSuggest, ...r];
      setTimeout(async function () {
        const { data: response } = await useFetch("/api/animes", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idAnime: [],
          }),
        });
        const r = JSON.parse(response.value);
        global.animesSuggest = [...global.animesSuggest, ...r];
      }, 1);
    }
    const { data: response2 } = await useFetch("/api/animes/all", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
    });
    anime.value = JSON.parse(response2.value);
    page.value = 1;
    global.pageLoad();
  });

  onBeforeUnmount(() => {
    document.getElementsByTagName("html")[0].classList.remove("overflow-hidden");
    document.getElementById("header").classList.remove("gutter");
  });

  function formaterDate(dateStr) {
    if (!dateStr) {
      return "Unknown";
    }
    let date = new Date(dateStr);
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let options = { timeZone: timeZone, year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat(undefined, options).format(date);
  }
</script>
<style scoped>
  :deep(.v-input__control) {
    border-radius: 5px;
    overflow: hidden;
  }
  :deep(.v-slider.v-input--horizontal > .v-input__control) {
    overflow: visible;
    margin: 0;
  }
  .slider_list {
    display: none;
  }
  .active.slider_list {
    display: block;
  }
  .slide_title {
    margin-bottom: 15px;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 700;
    border-left: 4px solid;
    padding-left: 20px;
  }
  .submenu {
    white-space: nowrap;
    overflow: visible;
    width: 50%;
    text-align: center;
    transition: 0.3s;
    margin-bottom: 35px;
    position: relative;
    margin-top: 5px;
  }
  .submenu > span {
    min-width: calc(50% - 10px);
    border-radius: 100px;
    font-weight: 600;
    height: 33px;
    margin: 0 5px;
    background: rgb(255 255 255 / 10%);
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .submenu > span:first-child::before {
    content: "";
    width: calc(50% - 10px);
    position: absolute;
    opacity: 1;
    border-radius: 100px;
    height: 33px;
    transition: 0.4s;
    pointer-events: none;
    background: #008ca8;
  }
  .submenu > span > span {
    position: relative;
  }
  .submenu .slider_list_menu0::before {
    left: 5px;
  }

  .submenu .slider_list_menu1::before {
    left: calc(50% + 5px);
  }

  .Stars {
    --percent: calc(var(--rating) / 5 * 100%);
    display: inline-block;
    font-size: 28px;
    line-height: 1;
    margin-top: -2px;
    &::before {
      content: "★★★★★";
      background: linear-gradient(90deg, #fc0 var(--percent), #fff var(--percent));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  :deep(.swiper-button-prev),
  :deep(.swiper-button-next) {
    color: white;
    font-size: 5px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 2px rgba(0, 5, 13, 0.5);
    background: rgb(15 17 43 / 95%);
  }
  :deep(.swiper-button-prev::after),
  :deep(.swiper-button-next::after) {
    font-size: 27px;
    font-weight: bold;
  }

  :deep(.v-virtual-scroll__container) {
    max-width: 1200px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* background: linear-gradient(180deg, #0000 40%, rgb(2 4 32 / 85%) 87%, #020420 100%),
      linear-gradient(
        90deg,
        #008ca8 -25%,
        rgb(2 4 32) 30%,
        rgb(2 4 32 / 100%) 25%,
        rgb(2 4 32) 35%,
        #0000 70%,
        #0000 100%
      ); */
    background: linear-gradient(180deg, #0000 40%, rgba(2, 4, 32, 0.85) 87%, #020420),
      linear-gradient(90deg, #020420de -25%, #020420db 30%, #020420de 0, #020420c9 35%, #0000 70%, #0000);
  }

  #infos {
    z-index: 1;
    display: flex;
    flex-direction: column;
  }
  .overlay-bottom {
    position: absolute;
    left: 0;
    width: 100%;
    background: linear-gradient(0deg, hsla(0, 0%, 100%, 0) 15%, #02041f);
    z-index: 0;
  }
  .anime-genres {
    height: 40px;
    overflow: hidden;
  }
  .anime-genres .anime-genre {
    display: flex;
    align-items: center;
    color: white;
    padding: 5px;
    padding-right: 12px;
    overflow: hidden;
    border-radius: 8px;
    white-space: nowrap;
    margin-right: 10px;
    height: 34px;
  }
  :deep(.v-virtual-scroll__container) {
    margin-top: -80px;
  }
  .calc2rem .search {
    display: none;
  }
  @media (min-width: 640px) {
    :deep(.v-virtual-scroll__container) {
      margin-left: 2rem;
      margin-right: 2rem;
      width: calc(100% - 4rem);
    }
  }
  @media (min-width: 768px) {
    .calc2rem.test :deep(.v-virtual-scroll__item),
    .calc2rem.test .discover {
      display: none;
    }
    .calc2rem.test :deep(.v-virtual-scroll__item:nth-child(1)),
    .calc2rem.test :deep(.v-virtual-scroll__item:nth-child(2)),
    .calc2rem.test :deep(.v-virtual-scroll__item:nth-child(3)),
    .calc2rem.test .search {
      display: initial;
    }
    :deep(.v-virtual-scroll__item:last-child .aos-animate) {
      transform: translate(0, 300px);
    }
    .aos-animate {
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
      transform: translate(0, 0px);
      transition-duration: 0.4s;
    }
  }
  @media (max-width: 767px) {
    :deep(.swiper-button-prev),
    :deep(.swiper-button-next) {
      display: none;
    }
    .desc {
      display: none;
    }
    .overlay {
      background: linear-gradient(180deg, #0000 40%, rgb(2 4 32 / 85%) 90%, #020420 100%);
    }
    .anime-genres {
      height: 34px;
    }
    .anime-genres .anime-genre {
      margin-left: 0;
      margin-right: 10px;
      padding-right: 4px;
    }
    :deep(.v-virtual-scroll__container) {
      margin-top: -180px;
    }
  }
  @media (max-width: 639px) {
    :deep(.v-virtual-scroll__container) {
      width: calc(100% - 2rem);
    }
    #infos {
      width: calc(100% - 2rem);
    }
  }
</style>
